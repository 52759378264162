import React, { useEffect }  from "react";

import { useMsal } from "@azure/msal-react"
import { resetPasswordRequest } from "../../authConfig";

const ResetPassword = () => {
    const { instance } = useMsal();
    useEffect(()=> {
        instance.loginRedirect(resetPasswordRequest).catch((error) => {
            
        });
    } , [])

    return null
}
export default ResetPassword