import  React , {useEffect} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function TabsWrappedLabel({ viewby, filterby, handleTab , handleFilter , filterTab ,  viewType  , showViewBy}) {
  // const [value, setValue] = React.useState("List");
  const [value, setValue] = React.useState("");
  const [filterType, setFilterType] = React.useState("");

  useEffect (()=> {
setValue(viewType)
  } , [viewType])
  useEffect (()=> {
setFilterType(filterTab)
  } , [filterTab])
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTab(newValue);
  };
  //   const handleFilterbyChange = (event, newValue) => {
  //     setValue(newValue);
  //     handleTab(newValue)
  //   };

  return (
    // <div class="col-12 col-md-9 col-lg-9 d-flex align-items-center">
    <Box sx={{ width: "100%" }} display={"flex"} alignItems={"center"} height={30}>
     {showViewBy === false?"": <><div class="view-text view-by">View by</div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        className="nav nav-tabs"
        // sx={{maxWidth:"50px"}}
        sx={{minWidth:"50"}}
      >
        {viewby?.map((item, key) => {
          return (
            <Tab
              value={item}
              label={item}
              style={{ textTransform: "none"}}
              className="nav-item"
              sx={{minWidth:40}}

              //   handleTab={handleTab}
            />
          );
        })}
      </Tabs>
      </>
      
}
      <div class="view-text ps-4 me-2 filter-by">Filter by</div>
      <Tabs
        value={filterType}
        //   onChange={handleFilterbyChange}
        aria-label="wrapped label tabs example"
        className="filter-data"
        sx={{minWidth:"100"}}
      >
        {filterby?.map((item, key) => {
          return (
            <Tab
              value={item}
              label={item}
              style={{ textTransform: "none" }}
              //   handleTab={handleTab}
              sx={{minWidth:"40px" , marginRight:'12px'}}
              onClick={()=> handleFilter(item)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}