import React , {useEffect , useState} from "react";
import CloseButton from "../../components/CloseButton";
import { Box } from "@mui/material";
import { RenderTextInput } from "../../components/TextField";
import Switch from "../../components/Switch";
import ButtonComponent from "../../components/Button";

const RenewPolicyModal = (props) => {
    const [show, setShow] = useState(false);
    const [inceptionDate, setInceptionDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [renewalreminder, setRenewalreminder] = useState(false);


    const handleRenewalreminder = () => {
        // Toggle the state when the checkbox is clicked
        setRenewalreminder(!renewalreminder);
      };

    useEffect(()=> {
        setShow(props.showrenewModal)
            } , [])

    return (
        <Box  
        
        style={{display:"block"}}
        >
     <Box className="">
         <Box className="">
             
             <Box className="modal-body renew-modal" sx={{padding:'24px'}}>

                 <Box className="icon-fill d-block me-auto mt-2 mb-2 text-center">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                         <path d="M8.66602 1.33325H3.99935C3.64573 1.33325 3.30659 1.47373 3.05654 1.72378C2.80649 1.97383 2.66602 2.31296 2.66602 2.66659V13.3333C2.66602 13.6869 2.80649 14.026 3.05654 14.2761C3.30659 14.5261 3.64573 14.6666 3.99935 14.6666H11.9994C12.353 14.6666 12.6921 14.5261 12.9422 14.2761C13.1922 14.026 13.3327 13.6869 13.3327 13.3333V5.99992M8.66602 1.33325L13.3327 5.99992M8.66602 1.33325L8.66613 5.99992L13.3327 5.99992" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                 </Box>
                 <Box className="cancel-policy text-center">Renew policy</Box>
                 <Box className="cancel-subtext text-center">Details below will display for all members with access to this policy information</Box>
                 <Box className="mb-3">
                     <label className="form-label">Inception date</label>
                     <RenderTextInput type="Date" fullWidth id="basicDate" placeholder="Select date" data-input 
                     onChange={(e)=> setInceptionDate(e.target.value)}
                     
                     />

                 </Box>
                 <Box className="mb-3">
                     <label className="form-label">Expiration date</label>
                     <RenderTextInput type="Date" fullWidth  id="basicDate" placeholder="Select date" data-input 
                     onChange={(e)=> setExpirationDate(e.target.value)}
                     
                     />

                 </Box>
                 <Box className="cancel-subtext text-start mb-1">Set renewal reminder (30 days before expiration date)</Box>
                 <Box className="">
                     
                     <Switch 
                     id="checkboxinp" type="checkbox" 
                     onChange={handleRenewalreminder}
                     checked={renewalreminder}
                     />
                 </Box>
                 <Box sx={{display:"flex" , justifyContent:"space-between"}} className="d-flex justify-content-between mt-3">
                     <ButtonComponent type="button" className="btn btn-secondary outline-btn btn-md" 
onClick={()=> props.handleCloseRenewModal()}
variant={"outlined"}
color={"white"}
sx={{width:"49%"}}

                     >Cancel</ButtonComponent>
                     <ButtonComponent type="button" className="btn btn-default btn-fill"
onClick={()=> props.handleRenewPolicy(inceptionDate , expirationDate , renewalreminder)}   disabled={inceptionDate==="" || expirationDate === ""}     
variant={"contained"}              
color={"primary"}
sx={{width:"49%"}}
                     >Confirm</ButtonComponent>
                 </Box>
             </Box>
         </Box>
     </Box>
 </Box>
    )
}

export default RenewPolicyModal