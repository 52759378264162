import { NOTIFICATION_CONST } from "../actions/actionTypes/NotificationActionTypes";

let initialState = {
  notificationsList: [],
  createdtask: "",
  latestNotification:[],
  markAllAsReadNotificationList:"",
  
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_CONST.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationsList: [],
        isLoading: true,
      };
    case NOTIFICATION_CONST.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationsList: action.payload,
        isLoading: false,
        error: "",
      };
    case NOTIFICATION_CONST.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationsList: [],
        isLoading: false,
        error: action.error,
      };
    case NOTIFICATION_CONST.GET_LATEST_NOTIFICATION_REQUEST:
      return {
        ...state,
        latestNotificationList: [],
        isLoading: true,
      };
    case NOTIFICATION_CONST.GET_LATEST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        latestNotificationList: action.payload,
        isLoading: false,
        error: "",
      };
    case NOTIFICATION_CONST.GET_LATEST_NOTIFICATION_FAILURE:
      return {
        ...state,
        latestNotificationList: [],
        isLoading: false,
        error: action.error,
      };
    case NOTIFICATION_CONST.CLEAR_NOTIFICATION_REQUEST:
      return {
        ...state,
        clearNotificationList: [],
        isLoading: true,
      };
    case NOTIFICATION_CONST.CLEAR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        clearNotificationList: action.payload,
        isLoading: false,
        error: "",
      };
    case NOTIFICATION_CONST.CLEAR_NOTIFICATION_FAILURE:
      return {
        ...state,
        clearNotificationList: [],
        isLoading: false,
        error: action.error,
      };
    case NOTIFICATION_CONST.MARK_ALL_AS_READ_NOTIFICATION_REQUEST:
      return {
        ...state,
        markAllAsReadNotificationList: [],
        isLoading: true,
      };
    case NOTIFICATION_CONST.MARK_ALL_AS_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        markAllAsReadNotificationList: action.payload,
        isLoading: false,
        error: "",
      };
    case NOTIFICATION_CONST.MARK_ALL_AS_READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        markAllAsReadNotificationList: [],
        isLoading: false,
        error: action.error,
      };
    

    default:
      return state;
  }
};
export default Reducer;
