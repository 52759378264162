import React, { useEffect, useState } from "react";



import { useDispatch, } from "react-redux";

//Material-UI components
import { Avatar, Box, Grid, Typography } from "@mui/material";

//Custom MUI components
import GridContainer from "../../components/GridContainer";

import Button from "../../components/Button";



//Custom styles for components
import { StyledTooltip } from "styles/CustomStyle";
import Switch from "../../components/Switch";

import { TaskComplete, TaskDelete } from "../../store/actions/TasksAction";
import ConfirmationModal from "components/ConfirmationModal";
import { dateFormat } from "../../constants";

import DOMPurify from 'dompurify'

const ViewTaskModal = ({
  handleClose,
  handleEdit,
  taskDetails,

  userList,
  handleDuplicate,

}) => {
  //state variables declaration

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [assignedto, setassinedto] = useState([]);
  const [creator, setCreator] = useState("");

  

  const dispatch = useDispatch();

  useEffect(() => {
    if (userList && taskDetails) {
      const filterIds = taskDetails?.assignedTo?.map((obj) => obj.id);
      const assinedtooption = userList.filter((obj) =>
        filterIds?.includes(obj.id)
      );
      const createroption = userList?.filter(
        (item, key) => item.id === taskDetails?.createdBy
      );

      setassinedto(assinedtooption);
      setCreator(createroption);
    }
  }, [userList, taskDetails]);

  const handlemark = (type) => {
    let payload = {
      Id: taskDetails?.id,
      IsCompleted: type === "Completed" ? true : false,
      ActionPerformedBy: JSON.parse(localStorage.getItem("user"))?.sub
        ? JSON.parse(localStorage.getItem("user"))?.sub
        : "",
    };
    dispatch(TaskComplete(payload));
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    let payload = {
      Id: taskDetails?.id,
      ActionPerformedBy: "b63fb773-a48e-44f2-9dc2-a0e95177eaa2",
    };
    dispatch(TaskDelete(payload));
  };

  const handleDelete = () => {
    // dispatch(deleteTask())
    setShowConfirmation(true);
  };

  return (
    <Box style={{ padding: "12px 20px" }}>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Task"}
          subTitle={"delete this task"}
          buttonname="Delete"
        />
      )}
      <Box>
        <Box
          className="sidepanel-header add-contact"
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--grey-200)",
            // padding: "12px 20px",
            padding: "12px 0px",
            // justifyContent: "space-between",
          }}
        >
          <button
            
            className="btn-close"
            onClick={() => handleClose()}
            style={{ marginRight: "3px", padding: "9px" }}
          ></button>
          <StyledTooltip title= {taskDetails?.isCompleted?"Completed tasks cannot be edited":""} >
            <Typography variant="span">
              <Button
                variant={taskDetails?.isCompleted?"outlined":"contained"}
                color={taskDetails?.isCompleted? "white":"grey"}
                onClick={() => handleEdit()}
                disabled={taskDetails?.isCompleted}
              >
                Edit
              </Button>
          </Typography>
          </StyledTooltip>
          <Button
            variant={"contained"}
            color={"grey"}
            onClick={() => handleDuplicate(taskDetails)}
            sx={{marginLeft:"3px"}}
          >
           Duplicate
          </Button>

          {taskDetails?.isDraft === false && (
            <span style={{ marginLeft: "5px" }}>
              <Button
                variant={"contained"}
                color={"grey"}
                onClick={() =>
                  handlemark(
                    taskDetails?.isCompleted === false ? "Completed" : "Unmark"
                  )
                }
              >
                {taskDetails?.isCompleted === false
                  ? "Mark Complete"
                  : "Unmark Complete"}
              </Button>
            </span>
          )}
        </Box>
        <Box className="sidepanel-body add-contact">
          <GridContainer sx={{ marginTop: "10px" }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Update task
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box className="form-label">
                Client
              </Box>
              <Box className="view-contact-details mb-3">
                {taskDetails?.clientName}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box className="form-label">
                Tags
              </Box>
              <Box className="mb-3">
                {taskDetails?.tags?.map((item, key) => {
                  return (
                    <Typography
                      variant="span"
                      className="view-contact-details"
                      style={{
                        backgroundColor: item.color,
                        width: "auto",
                        marginRight: "10px",
                        padding: "5px",
                        borderRadius: "10px",
                      }}
                      key={item?.id}
                    >
                      {item.name}{" "}
                    </Typography>
                  );
                })}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box className="form-label">
                Creator
              </Box>
              <Box className="view-contact-details mb-3">
                {creator?.[0]?.givenName}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box className="form-label">
                {/* Task title */}
                Task
              </Box>
              <Box className="view-contact-details mb-3">
                {taskDetails?.title}
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box className="form-label">
                Description
              </Box>
              <Box className="view-contact-details mb-3">
                {taskDetails?.description}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box className="form-label">
                Due Date
              </Box>
              <Box className="view-contact-details mb-3">
                {dateFormat(taskDetails?.dueDate)}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="span" className="form-label">
                Set reminder
              </Typography>
            </Grid>
            <Grid item
              xs={12}
              md={6}
              lg={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Switch checked={taskDetails?.isSetReminder || false} disabled={true} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {/* <label for="exampleFormControlInput1" className="form-label">
                Add reminder
              </label> */}
              {/* <Box className="view-contact-details mb-3">{reminder}</Box> */}
              <Box className="view-contact-details mb-3">
                {taskDetails?.reminder?.option}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box className="mb-3">
                <Box className="form-label">
                  Notes
                </Box>

                {taskDetails?.notes?.map((note) => {
                  const input = note.description;

                  // Regular expression to match mentions
                  const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;

                  // Replace mentions with names and apply color
                  const replacedText = input.replace(
                    mentionRegex,
                    (match, name) => `<span className="coloredText">@${name}</span>`
                  );
                  

                  return (
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(replacedText) }}
                    ></div>
                  );
                })}
              </Box>
            </Grid>

            <hr />

            <Grid item xs={12} md={12} lg={12}>
              <Box className="mb-3">
                <Box className="form-label">
                  Assigned to
                </Box>
                {assignedto?.map((item, key) => {
                  return (
                    <Typography
                      component={"div"}
                      sx={{ display: "flex", marginBottom: "10px" }}
                      className="view-contact-details"
                      key={item?.id}
                    >
                      <Avatar
                        variant="square"
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#bdbdbd !important",
                        }}
                        className="client-initials"
                      >
                        {item.givenName?.[0]} {item?.surName?.[0]}
                      </Avatar>{" "}
                      <Typography variant="span" sx={{ marginLeft: "10px" }}>
                        {item.givenName}
                      </Typography>
                    </Typography>
                  );
                })}
              </Box>
            </Grid>
            <GridContainer className="row pb-3 w-100">
              <hr />
              <Box
                className="delete-text text-center"
                onClick={() => handleDelete()}
              >
                Delete
              </Box>
            </GridContainer>
          </GridContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewTaskModal;
