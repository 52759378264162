import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Button";
import { Box, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Welcome = () => {
  let history = useNavigate();
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      
    });
  };

  useEffect(() => {
    let activeAccount;
    if (instance) {
      activeAccount = instance.getActiveAccount();
    }
    if (!activeAccount) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("firstLogin")
    }
  }, []);

  return (
    <Box>
      <Box className="bloc-video">
        <video autoPlay muted loop>
          <source src="wwwroot/assets/images/violet-bg.mp4" type="video/mp4" />
        </video>
      </Box>
      <Box className="welcome">
        <Box className="d-flex justify-content-between flex-column h-100 pt-5 text-center pb-2">
          <Box>
            <img
              src="wwwroot/assets/images/welcome-logo-white.png"
              className="img-fluid"
              alt="Welcome Logo White"
            />
          </Box>
          <Box>
            {/* <Box className="welcome-main-text">Platform Name</Box> */}
            <Box>
              <img src="wwwroot/assets/images/White-main-logo.png" className="img-fluid violet-logo"></img>
            </Box>
            <Box className="welcome-sub-text d-block ms-auto me-auto mt-4">
              Multinational collaboration tool <br /> facilitating world-class customer service
            </Box>
          </Box>
          <Box>
            <Box>
              <ButtonComponent
                type="button"
                color={"buttonColorWhite"}
                variant={"contained"}
                className="btn btn-primary sign-up d-block ms-auto me-auto btn-xl"
                // onClick={() => history('/Signup')}

                onClick={() => handleLoginRedirect()}
              >
                Sign Up
              </ButtonComponent>
            </Box>
            <Box
              className="login-text mt-3"
              // onClick={() => history('/Login')}

              onClick={() => handleLoginRedirect()}
              sx={{ color: "#FFF" }}
            >
              <Typography variant="span" sx={{ cursor: "pointer", zIndex:'100' }}>
                {/* <a href=""> */}I already have an account
              </Typography>
              {/* </a> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
