import * as React from "react";

import Grid from "@mui/material/Grid";

export default function ContainerComponent({
  sx,
  className,
  children,
  ref,
  id,
  style,
}) {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={sx}
        className={className}
        ref={ref}
        id={id}
        style={style}
      >
        {children}
      </Grid>
    </React.Fragment>
  );
}
