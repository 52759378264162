import { Box } from "@mui/material";
import SwipeableTextMobileStepper from "./HomeCarousel";


const HomeDialog = ({handleClose , show}) => {
    return (
        <Box>
            <Box className="modal-dialog modal-dialog-centered">
                <Box className="modal-content">
                    
                    <Box className="modal-body">

                        <SwipeableTextMobileStepper handleClose={handleClose}/>
                       
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default HomeDialog;