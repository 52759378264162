import { ACTION_TYPES } from "./ActionTypes";
import { CONTACTS_CONST } from "./actionTypes/ContactsActionTypes";
import netWorkService from "../../network/NetworkService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { successToast, errorToast, loadingToast } from "components/Toast";
import * as base from "./BaseActions";
import { startLoading, stopLoading } from "./LoaderAction";

export const AddContacts = (params) => (dispatch) => {
  loadingToast("Adding contact...");
  dispatch(startLoading());

  return netWorkService
    .addContacts(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setaddedContactData("success"));
        setTimeout(
          () => successToast("Contact has been added successfully"),
          980
        );
        return "added";
      } else if (response.status === 400) {
        response.json().then((responseJson) => {
          dispatch(setaddedContactData(responseJson));

          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {
      setTimeout(
        () => errorToast("Failed to add contacts. Please try again later"),
        980
      );
    });
};

export const setaddedContactData = (payload) => ({
  type: ACTION_TYPES.SET_ADDED_CONTACT_DATA,
  payload,
});
export const AddMulitpleContacts = (params) => (dispatch) => {
  return netWorkService
    .addMulitpleContacts(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setaddedmultipleContactData("success"));
      } else {
        if (response?.status === 400) {
          response.json().then((responseJson) => {
            errorToast(responseJson?.errors?.[0]);
          });
        }
      }
    })
    .catch((error) => {
      console.error("Error adding contacts:", error);
    });
};

export const setaddedmultipleContactData = (payload) => ({
  type: ACTION_TYPES.SET_ADD_MULTIPLE_CONTACT_DATA,
  payload,
});
//Modified By: Anjali
//Modified On: 22-02-2024
//Reason: suffixes and prefixes are not loading

export const ContactsPrefixes = () => (dispatch) => {
  let params = {
    query: `
    query Prefixes {
      prefixes {
          prefixId
          name
      }
  }
    `,
  };
  return netWorkService
    .contactsgraphql(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setContactsgraphqlData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setContactsgraphqlData = (payload) => ({
  type: ACTION_TYPES.SET_CONTACTS_PREFIXES,
  payload,
});
export const ContactsSuffix = () => (dispatch) => {
  let params = {
    query: `
    query Suffixes {
      suffixes {
          suffixId
          name
      }
  }
    `,
  };
  return netWorkService
    .contactsgraphql(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setContactsSuffix(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setContactsSuffix = (payload) => ({
  type: ACTION_TYPES.SET_CONTACTS_SUFFIX,
  payload,
});
export const ContactsPronoun = () => (dispatch) => {
  let params = {
    query: `
    query Pronouns {
      pronouns {
          pronounId
          name
      }
  }
    `,
  };
  return netWorkService
    .contactsgraphql(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setContactsPronoun(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setContactsPronoun = (payload) => ({
  type: ACTION_TYPES.SET_CONTACTS_PRONOUN,
  payload,
});
export const ContactType = () => (dispatch) => {
  return netWorkService
    .contactType()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setContactTypeData(responseJson?.data));
        });
      }
    })
    .catch((error) => {});
};

const setContactTypeData = (payload) => ({
  type: ACTION_TYPES.SET_CONTACT_TYPE_DATA,
  payload,
});
export const GetContact = (id, clientProducingBroker) => (dispatch) => {
  let params = {
    query: `
    
    query ClientContacts($id: UUID!) {
      clientContacts(clientId: $id clientProducingBroker:"${clientProducingBroker}") {
        nodes {
          id
          firstName
          clientPrimaryContact
          country {
            id
            country
            region
            countryCode
        }
          email
          mobilePhone
          countryId
        }
      }
    }
    `,
    variables: {
      id: id,
    },
  };
  return netWorkService
    .contactsgraphql(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setContactListData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setContactListData = (payload) => ({
  type: ACTION_TYPES.SET_CONTACT_LIST_DATA,
  payload,
});

export const GetContactDetails = (id) => (dispatch) => {
  let params = {
    query: `
    
    query ContactDetails {
      contactDetails(contactId: "${id}") {
          nodes {
              id
              contactTypeId
              contactTypeName
              firstName
              lastName
              isCompany
              companyName
              workPhone
              email
              street1
              clientPrimaryContact
              city
              state
              zipcode
              clientPrimaryContact
              countryId
              country {
                id
                country
                region
                countryCode
            }
              isActive
              createdOn
              middleName
              prefixId
              prefixName
              suffixId
              suffixName
              pronounId
              pronounName
              mobilePhone
              fax
              street2
              county
          }
      }
  }
    `,
    variables: {
      id: id,
    },
  };

  dispatch(startLoading());

  return netWorkService
    .contactsgraphql(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setContactDetailsData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setContactDetailsData = (payload) => ({
  type: ACTION_TYPES.SET_CONTACT_DETAILS_DATA,
  payload,
});
export const deleteContactData =
  (params, ClientProducingBroker) => (dispatch) => {
    loadingToast("Deleting contact...");
    dispatch(startLoading());

    return netWorkService
      .deleteContactData(params, ClientProducingBroker)
      .then((response) => {
        toast.dismiss();
        dispatch(stopLoading());

        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          dispatch(setdeletedContactData("success"));
          setTimeout(() => successToast("Contact deleted successfully"), 980);
          return "Contact deleted successfully";
        } else if (response.status === 400) {
          response.json().then((responseJson) => {
            dispatch(setdeletedContactData(responseJson));

            setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
          });
        }
      })
      .catch((error) => {
        setTimeout(
          () => errorToast("Failed to delete contact. Please try again later"),
          980
        );
      });
  };

export const setdeletedContactData = (payload) => ({
  type: ACTION_TYPES.SET_DELETED_CONTACT_DATA,

  payload,
});
export const updateContactData = (params) => (dispatch) => {
  loadingToast("Updating contact...");
  dispatch(startLoading());

  return netWorkService
    .updateContact(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setupdatedContactData("success"));
        setTimeout(() => successToast("Contact updated successfully"), 980);
        return "Contact updated successfully";
      } else if (response.status === 400) {
        response.json().then((responseJson) => {
          dispatch(setupdatedContactData(responseJson));

          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {
      setTimeout(
        () => errorToast("Failed to update contact. Please try again later"),
        980
      );
    });
};

export const setupdatedContactData = (payload) => ({
  type: ACTION_TYPES.SET_UPDATED_CONTACT_DATA,

  payload,
});
export const deleteMultipleContactData = (params) => (dispatch) => {
  return netWorkService
    .deleteMultipleContact(params)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setdeletedContactData("success"));
      } else if (response.status === 400) {
        response.json().then((responseJson) => {
          dispatch(setdeletedContactData(responseJson));
        });
      }
    })
    .catch((error) => {});
};
export const recentContactData = (params) => (dispatch) => {
  dispatch(base.getRequest(CONTACTS_CONST.GET_RECENT_CONTACTS_REQUEST));
  const payload = {
    query: `
    query RecentContacts {
      recentContacts {
          clientId
          contactId
          firstName
          middleName
          lastName
          companyName
          email
          countryId
          country {
            id
            country
            region
            countryCode
        }
          memberFirmId
      }
  }
    `,
  };
  return netWorkService
    .recentContact(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              CONTACTS_CONST.GET_RECENT_CONTACTS_SUCCESS,
              responseJson
            )
          );
        });
      } else if (response.status === 400) {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(CONTACTS_CONST.GET_RECENT_CONTACTS_FAILURE, "error")
          );
        });
      }
    })
    .catch((error) => {});
};
