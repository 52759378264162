import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Welcome to Violet',
    description: `We’re glad to have you onboard. Here are some quick tips to get you up and running.`,
    imgPath:
      require('assets/images/Home-new.png'),
  },
  {
    label: 'Overview',
    description: `An overview can be found on the home page.`,
    imgPath:
      require('assets/images/home-2.png'),
  },
  {
    label: 'Assigned clients',
    description: `View and manage your clients from the navigation bar on the left of the screen.`,
    imgPath:
      require('assets/images/home-3.png'),
  },
  {
    label: 'Map View',
    description: `Use the map view to view your clients’ headcounts, total costs, and renewals!`,
    imgPath:
      require('assets/images/home-4.png'),
  },
];

function SwipeableTextMobileStepper({handleClose}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const autoPlayInterval = 50000

  return (
    <Box sx={{ flexGrow: 1 }}>

      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={autoPlayInterval}
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Paper
        square
        elevation={0}
        sx={{
          bgcolor: 'background.default',
          height:'150px !important'
        }}
      >
        <Typography sx={{ textAlign: 'center', fontFamily: 'Sling-bold', fontSize: '36px', fontWeight: '600', lineHeight: '44px', padding: '6px 10px 0 10px', marginTop: '20px' }}>{images[activeStep].label}</Typography>
        <Typography sx={{ textAlign: 'center', fontFamily: 'Inter', fontSize: '14px', fontWeight: '400', lineHeight: '20px', padding: '10px 20px' }}>{images[activeStep].description}</Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
      />
      <Box sx={{display:'flex' , justifyContent:'space-between' , padding:'10px 20px'}}>
        <Button size="small" onClick={()=> handleClose()} disabled={activeStep === 0} className='dialog-skip'>
          Skip
        </Button>
        <Button
          size="small" className='dialog-next'
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          Next
        </Button>
      </Box>

    </Box>
  );
}

export default SwipeableTextMobileStepper;