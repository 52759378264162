import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Box, Grid, InputAdornment } from "@mui/material";

//Custom MUI components
import PageTitle from "components/PageTitle";

import Table from "components/Table";
import GridContainer from "components/GridContainer";
import ButtonComponent from "components/Button";

import { RenderTextInput } from "components/TextField";
import DrawerComponent from "components/Drawer";
import Dialog from "components/Dialog";

import FilterSection from "./FilterUser";

import AddUserModal from "./AddUser";

import ViewUser from "./ViewUser";

import { USERS_COLUMNS } from "constants";

//actions
import { getCountries } from "store/actions/CountriesAction";
import { getClientList } from "store/actions/ClientAction";
import {
  ContactsPrefixes,
  ContactsPronoun,
  ContactsSuffix,
} from "store/actions/ContactsAction";
import {
  createdUserData,
  getUserByFilter,
  getUserById,
  getUserList,
  setUpdatedUser,
  setdeletedUser,
} from "store/actions/UserAction";

import { getSortedMembers } from "store/actions/MemberFirmAction";
const UsersList = () => {
  //state variables declaration
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [countries, setCountries] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [selectedMember, setselectedMember] = useState([]);
  const [userDetails, setUserDetails] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [prefixes, setPrefixes] = useState([]);
  const [suffixes, setsuffixes] = useState([]);
  const [pronouns, setPronouns] = useState([]);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");
  const [members, setMembers] = useState([]);
  const [showViewAndEditUser, setShowViewAndEditUser] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [searchUser, setSearchUser] = useState("");
  const [sortASC, setSortASC] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [editShowUser, setShowEditUser] = useState(false);

  //extract specific pieces of state from the Redux store
  const statedata = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      clientListData: state?.fetchData?.clientListData,
      usersList: state?.user?.usersList,
      userDetails: state?.user?.userByid,
      createdUser: state?.user?.createduser,
      prefixes: state?.contact?.contactsPrefixes,
      suffix: state?.contact?.contactsSuffix,
      pronoun: state?.contact?.contactspronoun,
      updatedUser: state?.user?.updateduser,
      member: state?.fetchData?.memberData,
      deletedUser: state?.user?.deleteduser,
      sortedMemberFirmData: state?.memberFirm?.sortedMemberFirmData,
    };
  });

  const dispatch = useDispatch();

  // Fetch data from Redux store on component mount
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getClientList(true));
    dispatch(ContactsPrefixes());
    dispatch(ContactsSuffix());
    dispatch(ContactsPronoun());
    dispatch(getUserList());
    dispatch(getSortedMembers());
    setLoggedInUserDetails(JSON.parse(localStorage.getItem("user")));
  }, [dispatch]);

  //change country
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    setselectedMember("");
    setActiveTab("All");
    setSearchUser("")
    
    let payload = {
      countryId: selectedOption?.value ? selectedOption?.value : null,
      newUsers: activeTab === "NewUsers" ? true : null,
      memberFirmId: null,
      statusSort: "ASC",
    };

    if (selectedOption?.value !== "") {
      dispatch(getUserByFilter(payload));
    } else {
      dispatch(getUserList());
    }
  };
  const handleActiveTab = (tab) => {
    setActiveTab(tab);

    setSelectedCountry([]);
    setselectedMember("");
    setSearchUser("");

    setUsersList([]);

    if (tab === "NewUsers") {
      let payload = {
        countryId: selectedCountry?.value ? selectedCountry?.value : null,
        newUsers: true,
        memberFirmId: selectedMember?.value ? selectedMember?.value : null,
        statusSort: "ASC",
      };
      dispatch(getUserByFilter(payload));
    } else {
      setSelectedCountry("");
      setselectedMember("");

      dispatch(getUserList());
    }
  };

  //Update countries when Redux store updates
  useEffect(() => {
    if (statedata?.countries) {
      setCountries(statedata?.countries);
    }
  }, [statedata?.countries]);

  //update prefixes
  useEffect(() => {
    if (statedata?.prefixes?.data?.prefixes) {
      setPrefixes(statedata.prefixes?.data?.prefixes);
    }
  }, [statedata.prefixes?.data?.prefixes]);

  //update suffixes
  useEffect(() => {
    if (statedata?.suffix?.data?.suffixes) {
      setsuffixes(statedata.suffix?.data?.suffixes);
    }
  }, [statedata.suffix?.data?.suffixes]);

  //update pronouns
  useEffect(() => {
    if (statedata?.pronoun?.data?.pronouns) {
      setPronouns(statedata.pronoun?.data?.pronouns);
    }
  }, [statedata.pronoun?.data?.pronouns]);

  useEffect(() => {
    if (statedata?.sortedMemberFirmData?.data?.members?.nodes) {
      setMembers(statedata.sortedMemberFirmData?.data?.members?.nodes);
    }
  }, [statedata.sortedMemberFirmData?.data?.members?.nodes]);

  //Effect to update clientsList state when clientListData changes in Redux store
  useEffect(() => {
    if (statedata?.clientListData?.data?.clients_List?.nodes) {
      setClientList(statedata?.clientListData?.data?.clients_List?.nodes);
    }
  }, [statedata?.clientListData?.data?.clients_List?.nodes]);

  //get country name
  const getCountryName = useCallback(
    (id) => {
      let countrydata = statedata?.countries?.filter(
        (item, key) => item.id === id
      );
      return countrydata?.[0]?.country;
    },
    [statedata?.countries]
  );
  //Effect to update usersList state when usersList changes in Redux store
  useEffect(() => {
    if (statedata?.usersList) {
      let data =
        statedata?.usersList?.length > 0 &&
        statedata?.usersList
          .map((item, key) => {
            // Check if either givenName or surName is not null or undefined
            if (
              (item.givenName !== null && item.givenName !== undefined) ||
              (item.surName !== null && item.surName !== undefined)
            ) {
              let fullName = `${item.givenName ? item.givenName : ""} ${
                item.surName ? item.surName : ""
              }`;
              let countryname = getCountryName(item.countryId);

              return {
                ...item,
                fullName: fullName?.trim(), // Trim to remove any leading or trailing spaces
                status: item.approvalStatus,

                access: item.role ? item.role : "",
                country: countryname ? countryname : "",
                countryId: item.countryId,
              };
            } else {
              // Return null for rows where both givenName and surName are null or undefined
              return null;
            }
          })
          .filter((item) => item !== null); // Filter out null values

      setUsersList(data);
    }
  }, [statedata?.usersList, getCountryName]);

  //Effect to update usersList state when usersList changes in Redux store
  useEffect(() => {
    if (statedata?.userDetails) {
      let userdetails = {
        ...statedata?.userDetails,
      };

      setUserDetails(userdetails);
    }
  }, [statedata?.userDetails]);

  useEffect(() => {
    if (statedata?.createdUser) {
      if (statedata?.createdUser === "success") {
        dispatch(getUserList());
      }
      setShowAddUserModal(false);
      dispatch(createdUserData(""));
    }
  }, [statedata?.createdUser, dispatch]);
  useEffect(() => {
    if (statedata?.updatedUser === "success") {
      setShowEditUser(false);
      dispatch(getUserList());
      dispatch(setUpdatedUser(""));
    }
  }, [statedata?.updatedUser, dispatch]);
  useEffect(() => {
    if (statedata?.deletedUser === "success") {
      dispatch(getUserList());
      setShowViewAndEditUser(false);
      dispatch(setdeletedUser(""));
    }
  }, [statedata?.deletedUser, dispatch]);

  const handleSelectMember = (event) => {
    setselectedMember(event);
    setActiveTab("All");
    setSelectedCountry("");
    setSearchUser("");
    setUsersList([]);
    let payload = {
      countryId: selectedCountry?.value ? selectedCountry?.value : null,
      newUsers: activeTab === "NewUsers" ? true : null,

      memberFirmId: event?.value ? event?.value : "",
      statusSort: "ASC",
    };

    if (event?.value !== "") {
      dispatch(getUserByFilter(payload));
    } else {
      dispatch(getUserList());
    }
  };

  const handleSearch = (e) => {
    setSelectedCountry("");
    setselectedMember("");
    setActiveTab("All");
    setSearchUser(e.target.value);

    if (e.target.value?.length >= 1 && statedata?.usersList) {
      let user =
        statedata?.usersList?.length > 0 &&
        statedata?.usersList
          ?.map((item, key) => {
            let name = `${item.givenName ? item.givenName : ""} ${
              item.surName ? item.surName : ""
            }`;

            let countryname = getCountryName(item.countryId);

            if (name?.toLowerCase().includes(e.target.value?.toLowerCase())) {
              return {
                ...item,
                fullName: name?.trim(),
                status: item.approvalStatus,

                access: item.role ? item.role : "",
                country: countryname ? countryname : "",
                countryId: item.countryId,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
      setUsersList(user);
    } else {
      dispatch(getUserList());
    }
  };

  useEffect(() => {
    if (sortBy === "approvalStatus") {
      let payload = {
        countryId: selectedCountry?.value ? selectedCountry?.value : null,
        newUsers: activeTab === "NewUsers" ? true : null,
        memberFirmId: selectedMember?.length === 0 ? null : selectedMember,
        statusSort: sortASC === true ? "ASC" : "DESC",
      };
      dispatch(getUserByFilter(payload));
    }
  }, [
    sortASC,
    activeTab,
    dispatch,
    selectedCountry?.value,
    selectedMember,
    sortBy,
  ]);

  const handleSort = (e) => {
    setSortASC(!sortASC);

    setSortBy(e);
  };

  //get user details
  const handleUserDetails = (id) => {
    setUserDetails("");
    setShowViewAndEditUser(true);

    dispatch(getUserById({ Id: id }));
  };

  return (
    <Box id="main" className="main">
      <GridContainer sx={{ marginBottom: "30px !important" }}>
        <Grid xs={12} md={11} lg={11}>
          <PageTitle title={"Users"} />
        </Grid>
        {loggedInUserDetails?.extension_Role !== "MemberContributor" && (
          <Grid xs={12} md={1} lg={1}>
            <ButtonComponent
              variant={"contained"}
              color={"grey"}
              onClick={() => setShowAddUserModal(true)}
              sx={{ float: "right" }}
            >
              Add user
            </ButtonComponent>
          </Grid>
        )}

        {/* Add user modal */}
        {showAddUserModal && (
          <Dialog
            className="secondary-modal-show"
            maxWidth="false"
            maxHeight="150px"
            open={showAddUserModal}
            onClose={() => setShowAddUserModal(false)}
            showCloseIcon="false"
            sx={{ p: 0 }}
            body={
              <AddUserModal
                handleClose={() => setShowAddUserModal(false)}
                page="add"
                userRole={loggedInUserDetails?.extension_Role}
              />
            }
          />
        )}

        {/* edit user */}

        {editShowUser && (
          <Dialog
            className="secondary-modal-show"
            maxWidth="false"
            maxHeight="150px"
            open={editShowUser}
            onClose={() => setShowEditUser(false)}
            showCloseIcon="false"
            sx={{ p: 0 }}
            body={
              <AddUserModal
                handleClose={() => setShowEditUser(false)}
                page="edit"
                userDetails={userDetails}
                userRole={loggedInUserDetails?.extension_Role}
              />
            }
          />
        )}

        {/* View user */}
        {showViewAndEditUser && (
          <DrawerComponent
            isOpen={showViewAndEditUser}
            handleClose={() => setShowViewAndEditUser(false)}
            width={350}
            body={
              <ViewUser
                showViewAndEditUser={showViewAndEditUser}
                handleClose={() => setShowViewAndEditUser(false)}
                userDetails={userDetails}
                handleEdit={() => {
                  setShowViewAndEditUser(false);
                  setShowEditUser(true);
                }}
                countries={countries}
                prefixes={prefixes}
                suffixes={suffixes}
                pronouns={pronouns}
                members={members}
              />
            }
          />
        )}
      </GridContainer>

      {/* Filter and search section */}
      <Box className="section client-dashboard">
        <GridContainer>
          <Grid xs={12} md={12} lg={12}>
            <Box className="dashboard-content">
              <Box className="dashboard-main-head row ms-0 me-0">
                <GridContainer
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    xs={12}
                    md={8}
                    lg={8}
                    className=" d-flex align-items-center"
                  >
                    <FilterSection
                      countries={countries}
                      clientList={clientList}
                      handleCountryChange={handleCountryChange}
                      selectedCountry={selectedCountry}
                      handleSelectMember={handleSelectMember}
                      members={members}
                      loggedInUserDetails={loggedInUserDetails}
                      selectedMember={selectedMember}
                      activeTab={activeTab}
                      handleActiveTab={handleActiveTab}
                    />
                  </Grid>

                  <Grid xs={12} md={3} lg={3} sx={{ marginRight: "23px" }}>
                    <RenderTextInput
                      placeholder="Type to search users"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M14 14L11.1 11.1M12.6667 7.33334C12.6667 10.2789 10.2789 12.6667 7.33334 12.6667C4.38782 12.6667 2 10.2789 2 7.33334C2 4.38782 4.38782 2 7.33334 2C10.2789 2 12.6667 4.38782 12.6667 7.33334Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </InputAdornment>
                        ),
                      }}
                      value={searchUser}
                      onChange={(e) => handleSearch(e)}
                    />
                  </Grid>
                </GridContainer>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>
      
        <Table
          columns={USERS_COLUMNS}
          rows={usersList}
          handleRow={(e) => handleUserDetails(e)}
          from="users"
          handleSort={(e) => handleSort(e)}
        />
      
    </Box>
  );
};
export default UsersList;
