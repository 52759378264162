import {LOADER_CONST} from "./actionTypes/LoaderActionTypes"
export const startLoading = () => {
    return {
        type: LOADER_CONST.START_LOADING,
    };
};

export const stopLoading = () => {
    return {
        type: LOADER_CONST.STOP_LOADING,
    };
}
