import { Box, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Paths } from "../../constants";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as TaskIcon } from "assets/icons/task.svg";
import { ReactComponent as ClientIcon } from "assets/icons/star.svg";
import { ReactComponent as MapIcon } from "assets/icons/map.svg";
import { ReactComponent as ReportIcon } from "assets/icons/report.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as MemberFirmIcon } from "assets/icons/memberFirm.svg";

const SideBar = () => {
  const { instance } = useMsal();
  const [showloading, setshowloading] = useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");


  useEffect(() => {
    let activeAccount;
    if (instance) {
      activeAccount = instance.getActiveAccount();
    }
    if (!activeAccount) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("firstLogin");
    }
    setLoggedInUserDetails(JSON.parse(localStorage.getItem("user")));
  }, [instance]);

  const handleLogoutRedirect = () => {
    setshowloading(true);
    if (instance) {
      instance.logoutRedirect();
    }
  };

  return (
    <>
      <Box id="sidebar" className="sidebar">
        <List
          className="sidebar-nav"
          id="sidebar-nav"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <NavLink className="nav-link mb-1" to={Paths.HOME}>
              <ListItem className="nav-item nav-link mb-0">
                <HomeIcon />

                <Typography variant="span">Home</Typography>
              </ListItem>
            </NavLink>
            <NavLink className="nav-link mb-1" to={Paths.TASKS}>
              <ListItem className="nav-item nav-link mb-0">
                <TaskIcon />

                <Typography variant="span">Tasks</Typography>
              </ListItem>
            </NavLink>
            {loggedInUserDetails?.extension_Role === "HqAdmin" && (
              <NavLink className="nav-link mb-1" to={Paths.MEMBER_FIRM}>
                <ListItem className="nav-item nav-link mb-0">
                  <MemberFirmIcon />

                  <Typography variant="span">Member firm</Typography>
                </ListItem>
              </NavLink>
            )}

            {loggedInUserDetails?.extension_Role !== "MemberContributor" && (
              <NavLink className="nav-link" to={Paths.USERS}>
                <ListItem className="nav-item nav-link mb-0">
                  <UserIcon />

                  <Typography variant="span">Users</Typography>
                </ListItem>
              </NavLink>
            )}
            <NavLink className="nav-link" to="/client-dashboard">
              <ListItem className="nav-item nav-link">
                <ClientIcon />

                <Typography variant="span">Clients</Typography>
              </ListItem>
            </NavLink>
            <NavLink className="nav-link mb-1" to="/Map">
              <ListItem className="nav-item nav-link mb-0">
                <MapIcon />

                <Typography variant="span">Maps</Typography>
              </ListItem>
            </NavLink>
            <NavLink className="nav-link mb-1" to="/Calendar">
              <ListItem className="nav-item nav-link mb-0">
                <CalendarIcon />

                <Typography variant="span">Calendar</Typography>
              </ListItem>
            </NavLink>
            <NavLink className="nav-link  mb-1" to={Paths.REPORTS}>
              <ListItem className="nav-item nav-link mb-0">
                <ReportIcon />

                <Typography variant="span">Reports</Typography>
              </ListItem>
            </NavLink>
            {/* <NavLink className="nav-link  mb-1" to="/">
              <ListItem className="nav-item nav-link mb-0">
                <img src="assets/icons/analytic.svg" alt="analytic"  />

                <Analytic />

                <Typography variant="span">Analytics</Typography>
              </ListItem>
            </NavLink> */}
          </Box>
          <Box>
            <Box
              className="nav-link"
              sx={{ cursor: showloading === true ? "" : "pointer" }}
            >
              <ListItem className="nav-item nav-link">
                {showloading === true ? (
                  <Typography
                    variant="span"
                    style={{ marginRight: "5px", paddingLeft: "0px" }}
                  >
                    <i className="fas fa-spinner fa-spin" />
                  </Typography>
                ) : (
                  ""
                )}

                <LogoutIcon />

                <Typography
                  variant="span"
                  onClick={() =>
                    showloading === true ? "" : handleLogoutRedirect()
                  }
                  id="logout"
                >
                  Logout
                </Typography>
              </ListItem>
            </Box>
          </Box>
        </List>
      </Box>
    </>
  );
};

export default SideBar;
