import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Avatar, Box, Grid, Typography } from "@mui/material";

//Custom MUI components
import GridContainer from "components/GridContainer";
import ReactCountryFlag from "react-country-flag";

import ButtonComponent from "components/Button";
import ConfirmationModal from "components/common/ConfirmationModal";
import {
  
  deleteMemeber,
  memberusers,
} from "store/actions/MemberFirmAction";
import { setmemeberfirmclient } from "store/actions/ClientAction";

const ViewMemberFirm = ({
  handleClose,
  handleEdit,

  memberFirmDetails,
}) => {
  //state variables declaration

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [usersList, setUserList] = useState([]);
  const [clientsList, setClientList] = useState([]);
  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      prefixes: state?.contact?.contactsPrefixes,
      suffix: state?.contact?.contactsSuffix,
      pronoun: state?.contact?.contactspronoun,
      member: state?.fetchData?.memberData,
      clientListData: state?.fetchData?.clientListData,
      usermemberfirm: state?.memberFirm?.userbymemberData,
      memberfirmclient: state?.fetchData?.memberfirmclient,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setmemeberfirmclient([]));
      dispatch(memberusers([]));
    };
  }, []);
  useEffect(() => {
    if (stateData?.usermemberfirm) {
      setUserList(stateData?.usermemberfirm);
    }
    if (stateData?.memberfirmclient?.data?.clients?.nodes) {
      setClientList(stateData?.memberfirmclient?.data?.clients?.nodes);
    }
  }, [
    stateData?.usermemberfirm,
    stateData?.memberfirmclient?.data?.clients?.nodes,
  ]);

  const handleConfirm = () => {
    dispatch(
      deleteMemeber({
        id: memberFirmDetails?.id,
        actionPerformedBy: JSON.parse(localStorage.getItem("user"))?.sub,
      })
    );
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handledelete = () => {
    setShowConfirmation(true);
  };

  return (
    <Box style={{ padding: "12px 20px" }}>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Member Firm"}
          subTitle={"delete this member firm"}
          buttonname="Delete"
        />
      )}
      <Box>
        <Box
          className="sidepanel-header add-contact"
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--grey-200)",
            padding: "12px 0px",
          }}
        >
          <a
            href="javascript:void(0)"
            className="btn-close"
            onClick={() => handleClose()}
            style={{ marginRight: "5px", padding: "9px" }}
          ></a>

          <ButtonComponent
            variant={"contained"}
            color={"grey"}
            onClick={() => handleEdit()}
          >
            Edit
          </ButtonComponent>
        </Box>
        <Box className="sidepanel-body add-contact">
          <Box class="sidepanel-body add-contact">
            <GridContainer sx={{ marginTop: "10px" }}>
              <Grid xs="12" md="12" lg="12">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {memberFirmDetails?.memberName}
                </Typography>
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3 client-text">Member firm avatar</Box>

                <Avatar
                  variant="square"
                  sx={{ borderRadius: "10px", marginLeft: "0px !important" }}
                ></Avatar>
              </Grid>

              <Grid xs="12" md="12" lg="12" sx={{ mt: 2 }}>
                <Box className="mb-3 client-text">Details</Box>
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Member firm name
                  </label>
                </Box>
                <Box>{memberFirmDetails?.memberName}</Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Member firm HQ Location
                  </label>

                  <Box>
                    <ReactCountryFlag
                      countryCode={memberFirmDetails?.countryCode}
                      svg
                      style={{ width: "21px", height: "21px" }}
                    />{" "}
                    {memberFirmDetails?.country}
                  </Box>
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Address
                  </label>

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#000",
                      fontWeight: "500",
                    }}
                  >
                    {memberFirmDetails?.street1}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#000",
                      fontWeight: "500",
                    }}
                  >
                    {memberFirmDetails?.street2}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#000",
                      fontWeight: "500",
                    }}
                  >
                    {memberFirmDetails?.zipcode}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Members
                  </label>

                  {usersList?.map((item, key) => {
                    return (
                      <Box
                        className="toast align-items-center d-block mb-3"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        sx={{ boxShadow: "none" }}
                        key={item?.id}
                      >
                        <Box
                          className="toast-body"
                          sx={{
                            display: "flex",
                            
                            justifyContent: "space-between",
                            padding: "10px",
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              sx={{
                                backgroundColor: "lightgrey",
                                padding: "3px",
                                borderRadius: "3px",
                                marginRight: "5px",
                              }}
                            >
                              {item?.givenName?.[0]}
                              {item?.surName?.[0]}
                            </Typography>
                            <Box className="client-text">
                              {item?.givenName}
                              <Typography className="membermail">
                                {item.mail}
                              </Typography>
                            </Box>
                          </Typography>

                          <Typography
                            style={{
                              flex: 1,
                              marginLeft: "10px",
                              overflowWrap: "break-word",
                              position: "absolute",
                              right: "8%",
                            }}
                            className="memberaccess"
                          >
                            {item.access}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <hr />

              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Clients
                  </label>
                  {clientsList?.map((item) => {
                    return (
                      <Box
                        className="toast align-items-center d-block mb-3"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        sx={{ boxShadow: "none" }}
                        key={item?.id}
                      >
                        <Box
                          className="toast-body"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              sx={{
                                backgroundColor: "lightgrey",
                                padding: "5px",
                                borderRadius: "3px",
                                marginRight: "5px",
                              }}
                            >
                              {item?.clientName?.split(" ")?.[0]?.[0]}
                              {item?.clientName?.split(" ")?.[1]?.[0]}
                            </Typography>
                            <Box className="membername client-text">
                              {item?.clientName}
                              <Typography className="membermail">
                                {item.county}
                              </Typography>
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <GridContainer className="row pb-3 w-100">
                <hr />
                <Box
                  className="delete-text text-center"
                  onClick={() => handledelete()}
                >
                  Delete
                </Box>
              </GridContainer>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewMemberFirm;
