import NetworkService from "../../network/NetworkService";
import * as base from "./BaseActions";
import { COUNTRIES_CONST } from "./actionTypes/CountriesActionTypes";

export const getCountries = () => (dispatch) => {
  dispatch(base.getRequest(COUNTRIES_CONST.GET_COUNTRIES_REQUEST));
  return NetworkService.getCountries()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(COUNTRIES_CONST.GET_COUNTRIES_SUCCESS, responseJson)
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(COUNTRIES_CONST.GET_COUNTRIES_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const getRegionData = () => (dispatch) => {
  let payload = {
    query: `
    query Regions {
      regions
  }
    `,
  };

  dispatch(base.getRequest(COUNTRIES_CONST.GET_REGIONS_REQUEST));
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(
            base.getSuccess(COUNTRIES_CONST.GET_REGIONS_SUCCESS, responseJson)
          );
        });
      } else {
        response?.json().then((responseJson) => {
          dispatch(
            base.getFailure(COUNTRIES_CONST.GET_REGIONS_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const getRegionByCountryData = (params, id) => (dispatch) => {
  let queryparam = params === true ? `where: { region: { eq: "${id}" } }` : "";
  let payload = {
    query: `
    query Countries {
      countries (${queryparam} order: {country:ASC} first: 300) {
        nodes {
          id
          country
          region
          countryCode
        }
      }
  }
  `,
  };

  dispatch(base.getRequest(COUNTRIES_CONST.GET_REGIONS_BY_COUNTRIES_REQUEST));
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              COUNTRIES_CONST.GET_REGIONS_BY_COUNTRIES_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response?.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              COUNTRIES_CONST.GET_REGIONS_BY_COUNTRIES_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
      
    });
};
