

import { combineReducers } from 'redux'

import fetchDataReducer from "./ClientReducer"
import countriesReducer from "./CountriesReducer"
import contact from "./ContactsReducer"
import documents from "./DocumentsReducer"
import user from "./UserReducer"
import tasks from "./TasksReducer"
import memberFirm from "./MemeberFirmReducer"
import dasboard from "./Dashboard"
import loader from './LoaderReducer'
import profile from './ProfileReducer'
import reports from './ReportsReducer'
import notifications from './NotificationReducer'
const rootReducer = combineReducers({
    fetchData: fetchDataReducer,
    countries:countriesReducer,
    contact:contact,
    documents:documents,
    user:user,
    tasks:tasks,
    memberFirm:memberFirm,
    dasboard:dasboard,
    loader:loader,
    profile: profile,
    report: reports,
    notifications: notifications,


});

export default rootReducer;