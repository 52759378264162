import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Select from "react-select";

import {
  getClientDetails,
  getClientMapData,
  getClientList,
  getClientTotalCostData,
  getClientRenewalStatusData,
  setClientMapData,
  setClientRenewalStatusData,
  setClientTotalCostData,
  setClientListData,
} from "../../store/actions/ClientAction";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

import GridContainer from "../../components/GridContainer";
import { Box, Grid, Typography } from "@mui/material";

import PageTitle from "../../components/PageTitle";
import ButtonComponent from "../../components/Button";
import FilterSection from "components/Tabs";
import {
  countriesOptions,
  customFilterOption,
  dropDownOptions,
} from "../../constants";
import ClientDetails from "../client/ClientDetails";
import DrawerComponent from "../../components/Drawer";

import { customStyles, usersCountryDropdown } from "styles/CustomStyle";
import {
  getCountries,
  getRegionByCountryData,
  getRegionData,
} from "../../store/actions/CountriesAction";

const Map = () => {
  const [clients, setClients] = useState([]);
  const [clientMapData, setclientMapData] = useState([]);

  const [viewtype, setviewType] = useState("Region");
  const [selectedClient, setselectedClient] = useState(null);
  const [showClientView, setShowClientView] = useState(false);
  const [clientDetail, setClientDetail] = useState("");
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [filterTab, setFilterTab] = useState("");
  const [showref, setShowref] = useState(false);

  const stateData = useSelector((state) => {
    return {
      clientData: state?.fetchData?.clientdata,

      clientDetailsData: state?.fetchData?.clientDetailsData,
      clientsListData: state?.fetchData?.clientListData,
      clientMapData: state?.fetchData?.clientMapData,
      clientTotalCostData: state?.fetchData?.clientTotalCostData,
      clientRenewalStatusData: state?.fetchData?.clientRenewalStatusData,
      countries: state?.countries?.Countries,
      regionData: state?.countries?.regionData,
      regionByCountryData: state?.countries?.regionByCountryData,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  const locationRef = useRef();
  function formatNumberToK(number) {
    if (number >= 1000) {
      const rounded = Math.round(number / 1000);
      return `${rounded}k`;
    } else {
      return number?.toString();
    }
  }

  const GetMapId = (country) => {
    const countryData = stateData?.countries?.find(
      (item) => item.country === country
    );
    return countryData ? countryData.countryCode : "";
  };

  const renderData = (element) => {
    if (filterTab === "Head count") {
      return formatNumberToK(element.totalHeadcountByCountry);
    } else if (filterTab === "Total cost") {
      return element.totalPremiumByCountry;
    }
  };
  const ConvertMapResponseToAM5Map = (response) => {
    let mapdata = [];

    response.forEach((element) => {
      const mapObject = {
        id: GetMapId(element.country),

        value: filterTab !== "Renewal Status" ? "" + renderData(element) : "",

        polygonSettings: element?.color
          ? {
              fill: am5.color(element.color),
            }
          : {},
      };

      mapdata.push(mapObject);
    });

    return mapdata;
  };

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    // let chart = root.container.children.push(
    //   am5map.MapChart.new(root, {})
    // );

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "translateX",
        panY: "translateY",
        projection: am5map.geoMercator(),
      })
    );

    // Create main polygon series for countries
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
      })
    );

    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: "value",
        //   calculateAggregates: true,
        polygonIdField: "id",
        // templateField: "polygonSettings"
      })
    );

    let rectangleTemplate = am5.Template.new({});

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      let container = am5.Container.new(root, {});

      container.children.push(
        // am5.Circle.new(root, {
        //   radius: 0,
        //   fillOpacity: 0.8,
        //   fill: am5.color(0xffffff),
        //   cursorOverStyle: "pointer",
        //   tooltipText: `{name}: [bold]{value}[/]`
        // }, circleTemplate)

        am5.Rectangle.new(
          root,
          {
            minWidth: filterTab === "Renewal Status" ? 150 : 90,
            height: 25,
            radius: 5,
            fillOpacity: 0.8,
            // fill: am5.color(0xffffff),
            // fill: color,
            fill: filterTab === "Renewal Status" ? "" : am5.color(0xffffff),
            cursorOverStyle: "pointer",
            centerX: am5.p50,
            centerY: am5.p50,
            // tooltipText: `{name}: [bold]{value}[/]`
            // tooltipText: `{name}`
          },
          rectangleTemplate
        )
      );

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true,
      });
    });

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          // text: "{value.formatNumber('#.')}",
          text: "[bold]{value}",
          // fill:color,
          // fill: color,
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center",
        }),
        dynamic: true,
      });
    });

    // minValue and maxValue must be set for the animations to work
    //   bubbleSeries.set("heatRules", [
    //   {
    //     target: rectangleTemplate,
    //     dataField: "value",
    //     min: 10,
    //     max: 50,
    //     key: "radius",
    //   }
    // ]);

    if (clientMapData != null) {
      bubbleSeries.data.setAll(clientMapData);
    }

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      toggleKey: "active",
      interactive: true,
      templateField: "polygonSettings",
    });

    polygonSeries.data.setAll(clientMapData);

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: root.interfaceColors.get("primaryButtonHover"),
    });

    polygonSeries.mapPolygons.template.states.create("active", {
      fill: root.interfaceColors.get("primaryButtonHover"),
    });

    var previousPolygon;

    polygonSeries.mapPolygons.template.on("active", function (active, target) {
      if (previousPolygon && previousPolygon != target) {
        previousPolygon.set("active", false);
      }
      if (target.get("active")) {
        polygonSeries.zoomToDataItem(target.dataItem);
      } else {
        chart.goHome();
      }
      previousPolygon = target;
    });

    //Zoom Control
    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    // Set clicking on "water" to zoom out
    chart.chartContainer.get("background").events.on("click", function () {
      chart.goHome();
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [clientMapData, viewtype]);

  useEffect(() => {
    let payload = {
      filterName: "map",
      isDraft: false,
      for: "dropdowns",
    };

    dispatch(getClientList(payload));
    dispatch(getCountries());
    dispatch(getRegionData());
    dispatch(getRegionByCountryData(false, ""));
    return () => {
      setClients([]);

      dispatch(setClientListData(""));

      dispatch(setClientMapData(""));
      dispatch(setClientRenewalStatusData(""));
      dispatch(setClientTotalCostData(""));
      setClientMapData([]);
    };
  }, []);

  useEffect(() => {
    if (stateData.clientsListData?.data?.clients_List?.nodes) {
      setClients(stateData?.clientsListData?.data?.clients_List?.nodes);
    }
  }, [stateData.clientsListData?.data?.clients_List?.nodes]);
  useEffect(() => {
    setclientMapData([]);
    if (stateData.clientMapData?.data?.clients_Map_Country_TotalHeadcount) {
      const mapResponse = ConvertMapResponseToAM5Map(
        stateData?.clientMapData?.data?.clients_Map_Country_TotalHeadcount
      );
      setclientMapData(mapResponse);
    }
  }, [stateData.clientMapData?.data?.clients_Map_Country_TotalHeadcount]);

  useEffect(() => {
    if (stateData?.regionData?.data?.regions) {
      setRegions(stateData?.regionData?.data?.regions);
    }
  }, [stateData?.regionData?.data?.regions]);
  useEffect(() => {
    if (stateData?.regionByCountryData?.data?.countries?.nodes) {
      setCountries(stateData?.regionByCountryData?.data?.countries?.nodes);
    }
  }, [stateData?.regionByCountryData?.data?.countries?.nodes]);
  useEffect(() => {
    setclientMapData([]);
    if (stateData.clientTotalCostData?.data?.clients_Map_Country_TotalPremium) {
      const mapResponse = ConvertMapResponseToAM5Map(
        stateData?.clientTotalCostData?.data?.clients_Map_Country_TotalPremium
      );
      setclientMapData(mapResponse);
    }
  }, [stateData.clientTotalCostData?.data?.clients_Map_Country_TotalPremium]);

  useEffect(() => {
    setclientMapData([]);
    if (
      stateData.clientRenewalStatusData?.data?.clients_Map_Country_RenewalStatus
    ) {
      const mapResponse = ConvertMapResponseToAM5Map(
        stateData?.clientRenewalStatusData?.data
          ?.clients_Map_Country_RenewalStatus
      );
      setclientMapData(mapResponse);
    }
  }, [stateData.clientRenewalStatusData]);

  //Modified By: Anjali
  //Modified On: 15-03-2024
  //JiraId: https://bosframework.atlassian.net/browse/WBN-232
  //Reason: the map retains the data of the previously selected client

  const fetchClientMapData = async (clientId, country, region) => {
    dispatch(getClientMapData(clientId, country, region));
  };

  useEffect(() => {
    if (stateData.clientDetailsData) {
      setClientDetail(stateData.clientDetailsData?.data?.clientsDetails[0]);
    }
  }, [stateData.clientDetailsData]);

  const getMapData = (type, country, region) => {
    if (type === "Total cost") {
      return dispatch(
        getClientTotalCostData(selectedClient?.value, country, region)
      );
    } else if (type === "Renewal Status") {
      return dispatch(
        getClientRenewalStatusData(selectedClient?.value, country, region)
      );
    } else if (type === "Head count") {
      return fetchClientMapData(selectedClient?.value, country, region);
    }
  };
  const handleCountryChange = (e) => {
    setSelectedCountry(e);

    let country = e?.value ? e?.value : null;
    let region = selectedRegion?.value ? selectedRegion?.value : null;

    getMapData(filterTab, country, region);
  };
  const handleRegionChange = (e) => {
    setSelectedRegion(e);
    setSelectedCountry("");

    let region = e?.value;
    getMapData(filterTab, null, region);

    dispatch(getRegionByCountryData(true, e?.value));
  };

  const handleSelectClient = (event) => {
    setselectedClient(event);

    setSelectedCountry("");
    setSelectedRegion("");

    if (event?.value !== "") {
      setFilterTab("Head count");

      let country = selectedCountry?.value ? selectedCountry?.value : null;
      let region = selectedRegion?.value ? selectedRegion?.value : null;

      fetchClientMapData(event.value, country, region);
    } else {
      setclientMapData([]);
      setFilterTab("");
      setSelectedRegion("");
      setSelectedCountry("");
    }
  };

  const handleFilter = (e) => {
    if (selectedClient?.value !== "") {
      setFilterTab(e);
      let country = selectedCountry?.value ? selectedCountry?.value : null;
      let region = selectedRegion?.value ? selectedRegion?.value : null;
      getMapData(e, country, region);
    }
  };

  const handleClientView = () => {
    setShowClientView(true);
    dispatch(getClientDetails(selectedClient?.value));
  };

  let renewalStatus = [
    {
      status: "Active",
      color: "#00FF00",
    },
    { status: "Renewal In Progress", color: "#FFFF00" },
    { status: "Renewal Past Due", color: "#FF0000" },
  ];

  const regionsOption = regions?.map((item, key) => {
    return {
      value: item,
      label: item,
    };
  });

  return (
    <>
      {showClientView && (
        <DrawerComponent
          isOpen={showClientView}
          handleClose={() => {
            setShowClientView(false);
            setShowref(false);
          }}
          // width={1000}
          width={"80vw"}
          body={
            <ClientDetails
              clientDetails={clientDetail}
              locationRef={locationRef}
              handleClose={() => {
                setShowClientView(false);
                setShowref(false);
              }}
              showref={showref}
              handlesetShowref={() => setShowref(false)}
            />
          }
        />
      )}

      <Box id="main" className="main ">
        <GridContainer sx={{ marginBottom: "32px" }}>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            className=" d-flex align-items-center"
          >
            <PageTitle title={"Map"} sx={{ marginBottom: "10px" }} />

            <Select
              options={dropDownOptions(
                clients?.length > 0 ? clients : [],
                "id",
                "clientName"
              )}
              placeholder="Client"
              onChange={handleSelectClient}
              value={selectedClient}
              styles={{
                ...customStyles(),
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              }}
              // className="users-country-select"
              maxMenuHeight={300}
              menuPortalTarget={document.body}
              className="map-select"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            className="d-flex align-items-center"
          >
            {filterTab === "Renewal Status"
              ? renewalStatus?.map((item, key) => {
                  return (
                    <Box
                      className="client-text d-flex align-items-center"
                      sx={{ marginLeft: "10px" }}
                      key={item?.status}
                    >
                      <Box
                        sx={{
                          backgroundColor: item.color,
                          width: "10px",
                          height: "10px",
                        }}
                      ></Box>
                      <Box sx={{ marginLeft: "3px" }}>- {item.status}</Box>
                    </Box>
                  );
                })
              : ""}
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={2}
            className=" head-btn d-flex justify-content-end"
          >
            <ButtonComponent
              type="button"
              className="btn btn-default add-client btn-md"
              variant={
                !selectedClient || selectedClient?.value === ""
                  ? "outlined"
                  : "contained"
              }
              color={
                !selectedClient || selectedClient?.value === ""
                  ? "white"
                  : "grey"
              }
              onClick={() => handleClientView()}
              disabled={!selectedClient || selectedClient?.value === ""}
            >
              View Client
            </ButtonComponent>
          </Grid>
        </GridContainer>

        <Box className="section client-dashboard container-fluid ps-0 pe-0">
          <GridContainer>
            <Grid item xs={12} md={12} lg={12}>
              <Box className="dashboard-content">
                <Box className="dashboard-main-head row ms-0 me-0">
                  {/* Client filter section */}
                  <Grid item xs={12} md={5} lg={5}>
                    <Box
                      sx={{ width: "100%" }}
                      display={"flex"}
                      alignItems={"center"}
                      height={30}
                    >
                      <Typography
                        variant="span"
                        className="view-text me-2 filter-by"
                        style={{
                          paddingLeft: "0px !important",
                          paddingRight: "0px !important",
                        }}
                      >
                        View by
                      </Typography>
                      <Box className="d-flex align-items-center filter-data">
                        <Typography
                          variant="span"
                          sx={{ width: "140px", zIndex: "100 !important" }}
                        >
                          <Select
                            className="users-country-select"
                            options={regionsOption}
                            placeholder="Region"
                            // filterOption={customFilterOption}
                            onChange={handleRegionChange}
                            value={selectedRegion}
                            styles={{
                              ...customStyles(),
                              ...usersCountryDropdown(),
                            }}
                            dropdownPosition="auto"
                            maxMenuHeight={300}
                          />
                        </Typography>
                        <Typography
                          variant="span"
                          sx={{ width: "140px", zIndex: "100 !important" }}
                        >
                          <Select
                            className="users-country-select"
                            options={countriesOptions(countries)}
                            placeholder="Country"
                            filterOption={customFilterOption}
                            onChange={handleCountryChange}
                            value={selectedCountry}
                            styles={{
                              ...customStyles(),
                              ...usersCountryDropdown(),
                            }}
                            dropdownPosition="auto"
                            maxMenuHeight={300}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    className=" d-flex align-items-center"
                  >
                    <FilterSection
                      filterby={["Head count", "Total cost", "Renewal Status"]}
                      handleTab={setviewType}
                      viewType={viewtype}
                      handleFilter={(e) => selectedClient && handleFilter(e)}
                      filterTab={filterTab}
                      showViewBy={false}
                      selectedCountry={selectedCountry}
                      countries={countries}
                      handleCountryChange={handleCountryChange}
                    />
                  </Grid>

                  {/* <Box id="chartdiv"></Box> */}
                  <Box className="tab-content" id="myTabContent">
                    {viewtype === "Region" ? (
                      <Box
                        className={`tab-pane fade ${
                          viewtype == "Region" ? "show active" : ""
                        }`}
                      >
                        <Box id="chartdiv"></Box>
                      </Box>
                    ) : (
                      <Box
                        className={`tab-pane fade ${
                          viewtype === "Country" ? "show active" : ""
                        }`}
                      >
                        {/* <GridContainer className=" m-0 grid-view">
                          <Grid item xs={12} md={12} lg={12} className=" p-0">
                            <Box className=" p-2 m-0"></Box>
                          </Grid>
                        </GridContainer> */}
                        <Box id="chartdiv"></Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </GridContainer>
        </Box>
      </Box>
    </>
  );
};

export default Map;
