import {  Button, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";

import { countryIdToCodeMap } from "./Countrycodes";


export const CLIENT_API_URL=process.env.REACT_APP_CLIENT_API_URL
export const COVERAGE_API_URL=process.env.REACT_APP_COVERAGE_API_URL

export const CONTACTS_API_URL = process.env.REACT_APP_CONTACTS_API_URL
export const DOCUMENTS_API_URL = process.env.REACT_APP_DOCUMENTS_API_URL
export const USERS_API_URL = process.env.REACT_APP_USERS_API_URL
export const TASKS_API_URL = process.env.REACT_APP_TASKS_API_URL
export const REACT_APP_GATEWAY_API_URL = process.env.REACT_APP_GATEWAY_API_URL
export const REACT_APP_REPORTS_API_URL = process.env.REACT_APP_REPORTS_API_URL
export const REACT_APP_AGGREGATOR_API_URL = process.env.REACT_APP_AGGREGATOR_API_URL

export const loggedInUserDetails =  JSON.parse(localStorage.getItem("user"))

export const countriesOptions = (countries) => {
  const defaultOption = {
    value: "",
    label: (
    <div>Select</div>
  )
  }

  let formattedOptions =  countries.map((country) => ({
    value: country.id,
    label: (
      <div>
        
        <Typography variant="span" sx={{ marginRight: "10px" }}>
          <ReactCountryFlag countryCode={country.countryCode} svg />
        </Typography>
        {country.country}
      </div>
    ),
  }));
  return [defaultOption, ...formattedOptions]
};




export const policiesOptions = (policies , isLoading) => {
  const defaultOption = {
    value: "",
    label: (
    <div>Select</div>
  )
  };
  const loadingOption = {
    value: "",
    label: (
    <span className="client-text">Loading</span>
  )
  };

  let formattedOptions =  policies?.map((policy) => ({
    value: policy.id,
    label: (
      <div>
        
        <Typography variant="span" sx={{ marginRight: "10px" }}>
          <ReactCountryFlag countryCode={countryIdToCodeMap[policy.countryId]} svg />
        </Typography>
       {policy.coverageType}/{policy.policyNumber}
      </div>
    ),
  }));

if (isLoading === true) {
  return [defaultOption, ...formattedOptions , loadingOption]
}
else {
  return [defaultOption, ...formattedOptions]
}
  
}
export const policiesOptionsWithSelectAll = (policies , ismulti) => {
  

  let formattedOptions =  policies?.map((policy) => ({
    value: policy.id,
    label: (
      <div>
        
        <Typography variant="span" sx={{ marginRight: "10px" }}>
          <ReactCountryFlag countryCode={countryIdToCodeMap[policy.countryId]} svg />
        </Typography>
       {policy.coverageType}/{policy.policyNumber}
      </div>
    ),
  }));

  let defaultOption = { label: <Button
    className="add-tag-button"
    
    sx={{
      textTransform: "none",
      padding: "10px 0 5px 10px !important",
      display: "flex",
      justifyContent: "flex-start",
      fontSize: "12px !important"
    }}
  ><i className="fa fa-plus m-1" />Select All</Button>, value: "select-all" }

  if(formattedOptions?.length>0) {
  return [defaultOption , ...formattedOptions]
  }
  else {
    return formattedOptions
  }

  
}


export const Paths = {
  CHANGE_PASSWORD: "/ChangePassword",
  FIRST_TIME_LOGIN: "/FirstTimeLogin",
  FORGOT_PASSWORD: "/ForgotPassword",
  REMINDER_SENT: "/ReminderSent",
  SESSION_TIME_OUT_LOGIN: "/SessionTimeOutLogin",
  TWO_FA: "/TwoFA",
  LOGIN: "/Login",
  SIGN_UP: "/Signup",
  CLIENT_DASHBOARD: "/client-dashboard",
  MAP: "/Map",
  CALENDAR: "/Calendar",
  WELCOME: "/",
  USERS: "/users",
  TASKS: "/tasks",
  MEMBER_FIRM: "/memberFirm",
  REPORTS: "/reports",
  RESET_PASSWORD: "/resetpassword",
  HOME: "/home",
  HEALTHCHECK:"/healthz"
};

export const customFilterOption = (option, rawInput) => {
  const searchTerm = rawInput.toLowerCase();
  // Check if option.label is a string before applying toLowerCase()
  // const optionLabel =
  //   typeof option.label.props.children[1] === "string"
  //     ? option.label.props.children[1]
  //     : "";
  const optionLabel =
    typeof option.label.props.children[1] === "string"
      ? option.label.props.children[1]
      : "";

  return optionLabel.toLowerCase().includes(searchTerm);
};

//Modified By: Anjali
//Modified On: 05-03-2024
//JiraId:https://bosframework.atlassian.net/browse/WBN-216
//Reason: should allow only pdf , doc , docx , csv , xlsx , xls document

//validate document
export const validateDocument = (file) => {
  let path = file?.path;
  if (
    path.indexOf(".pdf") !== -1 ||
    path.indexOf(".PDF") !== -1 ||
    path.indexOf(".doc") !== -1 ||
    path.indexOf(".docx") !== -1 ||
    path.indexOf(".csv") !== -1 ||
    path.indexOf(".xlsx") !== -1 ||
    path.indexOf(".xls") !== -1
  ) {
    
    if (file.size < 5 * 1024 * 1024) {
      return true;
    } else {
      return "File size exceeds 5MB limit.";
    }
  }
  
  else {
    return "Only pdf , doc , docx , csv , xlsx , xls  files are allowed.";
    
  }
};

export const LOCATION_COLUMNS = [
  {
    id: "id",
    accessor: "locationName",
    label: "Location name",

    align: "left",
  },
  {
    id: "id",
    accessor: "locationNumber",
    label: "Number",
    
    align: "left",
  },
  {
    id: "id",
    accessor: "country",
    label: "Country",
    
    align: "left",
  },
  {
    id: "id",
    accessor: "totalCost",
    label: "Total cost",
    
    align: "left",
  },
  {
    id: "id",
    accessor: "headCount",
    label: "Head count",
    
    align: "left",
  },
  {
    id: "id",
    accessor: "address",
    label: "Address ",
    
    align: "left",
  },
];

export const CONTACT_COLUMNS = [
  {
    id: "id",
    accessor: "firstName",
    label: "Name",
    minWidth: 210,
    align: "left",
  },
  {
    id: "id",
    accessor: "country",
    label: "Country",
    minWidth: 210,
    align: "left",
  },
  {
    id: "id",
    accessor: "email",
    label: "Email",
    minWidth: 210,
    align: "left",
  },
  {
    id: "id",
    accessor: "mobilePhone",
    label: "Number",
    minWidth: 210,
    align: "left",
  },
];

export const BROKER_COLUMNS = [
  {
    id: "id",
    accessor: "memberName",
    label: "Company",
  },
  {
    id: "id",
    accessor: "brokerType",
    label: "Broker type",
  },
  {
    id: "id",
    accessor: "country",
    label: "Country",
  },
  {
    id: "id",
    accessor: "email",
    label: "Email",
  },
  {
    id: "id",
    accessor: "number",
    label: "Number",
  },
];

export const POLICY_COLUMNS = [
  {
    id: "id",
    accessor: "policyNumber",
    label: "Policy number",
    isDraft: "isDraft",
    

    align: "left",
    isSort: false,
  },
  {
    id: "id",
    accessor: "coverageType",
    label: "Coverage type",
    
    isDraft: "isDraft",

    align: "left",
    isSort: false,
  },
  {
    id: "id",
    accessor: "country",
    label: "Country",
    
    isDraft: "isDraft",
    
    align: "left",
    isSort: false,
  },
  {
    id: "id",
    accessor: "startExpiryDate",
    label: "St - Exp date",

    minWidth: 100,

    isDraft: "isDraft",

    align: "left",
    isSort: true,
  },
  {
    id: "id",
    accessor: "policyStatus",
    label: "Status",
    
    isDraft: "isDraft",
    
    align: "left",
    isSort: false,
  },
];
export const DOCUMENT_COLUMNS = [
  {
    id: "id",
    accessor: "documentDisplayName",
    label: "Name",
    minWidth: 210,
    align: "left",
    isSort: true,
  },
  {
    id: "id",
    accessor: "description",
    label: "Note",
    minWidth: 210,
    align: "left",
    isSort: false,
  },
  {
    id: "id",
    accessor: "uploadDate",
    label: "Upload date",
    minWidth: 210,
    align: "left",
    isSort: false,
  },
  {
    id: "id",
    accessor: "visibility",
    label: "Visibility",
    minWidth: 210,
    align: "left",
    isSort: true,
  },
];



//check document size
export const checkDocumentSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "n/a";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) return bytes + " " + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};
export const dateFormat = (dateString) => {
  if (!dateString) return "";

  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const statusOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Active" },
  { value: "Unapproved", label: "Inactive" },
];

export const USERS_COLUMNS = [
  {
    id: "id",
    accessor: "fullName",
    label: "Name",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "memberFirmName",
    label: "Member firm",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "approvalStatus",
    label: "Status",
    isDraft: "isDraft",
    isSort: true,
    
  },
  {
    id: "id",
    accessor: "access",
    label: "Access",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "country",
    label: "Location",
    isDraft: "isDraft",
    isSort: false,
    minWidth: "200px",
  },
];

export const REPORTS_COLUMNS = [
  {
    id: "id",
    accessor: "displayName",
    label: "Name",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "clientName",
    label: "Client",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "templateName",
    label: "Template Type",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "createdOn",
    label: "Date created",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "createdByUserName",
    label: "Created by",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "status",
    label: "Status",
    isDraft: "isDraft",
    isSort: false,
    
  },
];

export const REPORTS_POLICY_TABLE = [
  {
    id: "id",
    accessor: "policyNumber",
    label: "Policy number",
    isDraft: "isDraft",
    isSort: true,
    minWidth: "200px",
  },
  {
    id: "id",
    accessor: "coverageType",
    label: "Coverage type",
    isDraft: "isDraft",
    isSort: true,
    minWidth: "200px",
  },
];

export const DOCUMENT_COUMNS = [
  {
    id: "id",
    accessor: "documentDisplayName",
    label: "Document name",
    minWidth: 150,
    align: "left",
    isSort: true,
  },
  {
    id: "id",
    accessor: "uploadDate",
    label: "Upload date",
    minWidth: 150,
    align: "left",
    isSort: false,
  },
];

export const TASKS_COLUMNS = [
  {
    id: "id",
    accessor: "title",
    label: "Title",
    isDraft: "isDraft",
    isSort: "false",
    minWidth: "170px",
  },
  {
    id: "id",
    accessor: "description",
    label: "Description",
    isDraft: "isDraft",
    isSort: "false",
    minWidth: "170px",
  },
  {
    id: "id",
    accessor: "clientName",
    label: "Client",
    isDraft: "isDraft",
    isSort: true,
    minWidth: "170px",
  },
  {
    id: "id",
    accessor: "dueDate",
    label: "Due Date",
    isDraft: "isDraft",
    isSort: true,
    minWidth: "170px",
  },
  {
    id: "id",
    accessor: "tags",
    label: "Category",
    isDraft: "isDraft",
    isSort: "false",
  },
  {
    id: "id",
    accessor: "assignedTo",
    label: "Assigned to",
    isDraft: "isDraft",
    isSort: "false",
  },
];

export const getBase64 = (
  file,
  updateProgressbarpercentage,
  updatebase64,
  handleProgressbar
) => {
  return new Promise((resolve, reject) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        updateProgressbarpercentage(parseInt(progress));
      }
    };

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load something...
    reader.onload = () => {
      baseURL = reader.result;
      updatebase64(baseURL.split("base64,")[1]);
      setTimeout(() => handleProgressbar(false), 1000);
      resolve(baseURL);
    };

    reader.onerror = (error) => {
      handleProgressbar(false);
      reject(error);
    };
  });
};

export const limitPercentageValue = (inputValue, stateFunc) => {
  if (inputValue === "") stateFunc(inputValue);
  if (!isNaN(inputValue)) {
    // Convert the input value to a int
    const numericValue = parseFloat(inputValue, 10);
    // Ensure the number is within the desired range (1 - 100) as it is percentage value
    if (numericValue >= 1 && numericValue <= 100) {
      // Update the state only if the value is valid
      stateFunc(inputValue);
    }
  }
};

export const limitDecimalValue = (inputValue, stateFunc) => {
  if (inputValue === "") stateFunc(inputValue);
  //valid number and after decimal it should be only 2 digits
  if (!isNaN(inputValue)) {
    // Convert the input value to a int
    const numericValue = parseFloat(inputValue, 10);
    // Ensure the number is within the desired range (1 - 1000000000)
    if (numericValue >= 1 && numericValue <= 1000000000) {
      // Update the state only if the value is valid
      stateFunc(inputValue);
    }
  }
};
export const mapAndConvertObject = (
  inputObject,
  coverageTypeId,
  policyId,
  CoverageTypeName
) => {
  const filteredObj = {};
  for (const key in inputObject) {
    if (!key.includes("code")) {
      filteredObj[key] = inputObject[key];
    }
  }

  if (filteredObj) {
    const fieldsWithData = Object.entries(filteredObj).map(
      ([coverageFieldId, value]) => {
        if (Array.isArray(value)) {
          const multipleValues = value.join(", ");
          return {
            coverageFieldId,
            value: multipleValues,
          };
        } else {
          // If it's not an array, treat it as a single value
          return {
            coverageFieldId,
            value,
          };
        }
      }
    );

    return {
      coverageTypeId,
      policyId,
      fieldsWithData,
      CoverageTypeName,
    };
  }
};

export const MEMBER_FIRM_COUMNS = [
  {
    id: "id",
    accessor: "memberName",
    label: "Member Firm",
    minWidth: 150,
    align: "left",
  },
  {
    id: "id",
    accessor: "country",
    label: "Country",
    minWidth: 150,
    align: "left",
  },
  {
    id: "id",
    accessor: "clientCount",
    label: "Clients",
    minWidth: 150,
    align: "left",
  },
  {
    id: "id",
    accessor: "adminCount",
    label: "Admins",
    minWidth: 150,
    align: "left",
  },
  {
    id: "id",
    accessor: "contributorCount",
    label: "Contributors",
    minWidth: 150,
    align: "left",
  },
];

export const REPORTS_CLIENTS = [
  {
    id: "id",
    accessor: "clientName",
    label: "Company Name",
    isDraft: "isDraft",
  },
  {
    id: "id",
    accessor: "hqCountry",
    label: "HQ Country",
    isDraft: "isDraft",
  }
];

export const CLIENT_DASHBOARD_COLUMNS = [
  {
    id: "id",
    accessor: "clientName",
    label: "Company",
    isDraft: "isDraft",
    isSort: true,
  },
  {
    id: "id",
    accessor: "policiesCount",
    label: "Policies",
    isDraft: "isDraft",
    isSort: false,
  },
  {
    id: "id",
    accessor: "country",
    label: "HQ Country",
    isDraft: "isDraft",
    isSort: false,
    
  },
  {
    id: "id",
    accessor: "nextExpiringPolicyDate",
    label: "Next exp.policy date",
    isDraft: "isDraft",
    isSort: true,
  },
  {
    id: "id",
    accessor: "policyStatus",
    label: "Policy Status",
    isDraft: "isDraft",
    isSort: true,
  },
  {
    id: "id",
    accessor: "openTasks",
    label: "Open tasks",
    isDraft: "isDraft",
    isSort: false,
  },
];

export const ACTIVE_CLIENTS_COLUMNS = [
  {
    id: "id",
    accessor: "clientName",
    label: "Company",
    isDraft: "isDraft",
    isSort: "false",
    
  },
  {
    id: "id",
    accessor: "nextExpiringPolicyDate",
    label: "Next policy exp date",
    isDraft: "isDraft",
    isSort: "false",
    
  },
  {
    id: "id",
    accessor: "country",
    label: "HQ Company",
    isDraft: "isDraft",
    isSort: "false",
    
  },
];
export const FREQUENT_COLLABORATION_COLUMNS = [
  {
    id: "id",
    accessor: "name",
    label: "Name",
    isDraft: "isDraft",
    isSort: "false",
    
  },
  {
    id: "id",
    accessor: "memberFirmName",
    label: "Brokerage Name",
    isDraft: "isDraft",
    isSort: "false",
    
  },
  {
    id: "id",
    accessor: "country",
    label: "Country",
    isDraft: "isDraft",
    isSort: "false",
    
  },
];

export const OptionWithDelete = (props) => {
  const {
    children,
    data,
    onSelect,
    isSelected,
    onDelete,
    onDeleteoption,
    backgroundColor,
    onChange,
    ...rest
  } = props;

  const handleOptionSelect = () => {
    if (typeof children === "string") {
      onSelect(data, {
        action: isSelected ? "deselect-option" : "select-option",
      });
    }
    // onChange(data, { action: isSelected ? 'deselect-option' : 'select-option' });
  };

  const handleDeleteOption = (e) => {
    // e.stopPropagation(); // Prevent the click from propagating to the parent div
    onDelete(data);
  };

  return (
    <div {...rest} className="ps-2 pt-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: backgroundColor,
          height: "30px",
          maxWidth: "fit-content",
          padding: "0px 8px",
          borderRadius: "10px",
          marginBottom: "10px",
          cursor: "pointer",
        }}

        onClick={handleOptionSelect}
        
      >
        <div
          // onClick={handleOptionSelect}
          style={{
            color: "#000",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Inter",
            textTransform: "capitalize",
            marginRight: "10px",
          }}
        >
          {children}
        </div>
        {/*delete functionality we may need it in future*/}
        {/* {isSelected && <DeleteIcon onClick={handleDeleteOption} />} */}
        {/* <CloseButton onClick={handleDeleteOption} /> */}

        {/* {typeof children === "string" && (
          <div onClick={handleDeleteOption}>
            {" "}
            <img
              src="wwwroot/assets/images/x-circle.png"
              className="img-fluid"
              style={{ position: "relative", bottom: "2px" }}
            ></img>{" "}
          </div>
        )} */}
      </div>
      {/* <span onClick={handleDeleteOption}>x</span> */}
    </div>
  );
};

export const COLORS = [
  "#A6F4C5",
  "#A6F4C5",
  "#FEDF89",
  "#B9E6FE",
  "#EAECF0",
  "#FFCCF7",
];

export const getRandomColor = (colorArray) => {
  const min = 1;
  const max = 6;
  const randomIndex = Math.round(min + Math.random() * (max - min));

  return colorArray[randomIndex];
};

export const changeDateFormat = (originalDateString) => {
  const originalDate = new Date(originalDateString);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[originalDate.getMonth()];
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
};


export const clearSession = () => {

  let element = document.getElementById("logout");

  if (element) {
    element.click();
  }
  
};

export const dropDownOptions = (options , id , accessor , isLoading) => {
  
  const defaultOption = {
    value: "",
    label: "Select"
  };
  const loadingOption = {
    value: "",
    label: <span className="client-text">Loading...</span>,
    isDisabled: true
  };
  
  const formattedOptions = options.map((option) => ({
    value: option[id],
    label: option[accessor]
  }));

  if(isLoading) {
    return [defaultOption, ...formattedOptions , loadingOption]
  }
  else {
    return [defaultOption, ...formattedOptions]
  }
  
}

// const allowedPattern =  /^[\p{L}\p{N} .,:;\/'çÇßåÅäÄöÖøØæÆéÉèÈêÊëËîÎïÏôÔœŒùÙûÛüÜÿŸğĞıİşŞàÀâÂ-]*$/u
// const allowedPattern =  /^[\p{L}\p{N} .,:;\/'çÇßåÅäÄöÖøØæÆéÉèÈêÊëËîÎïÏôÔœŒùÙûÛüÜÿŸğĞıİşŞàÀâÂ\u0900-\u097F-]*$/u
const allowedPattern =  /^[\p{L}\p{N} .,:;\/'çÇßåÅäÄöÖøØæÆéÉèÈêÊëËîÎïÏôÔœŒùÙûÛüÜÿŸğĞıİşŞàÀâÂ\u0900-\u097F\u0370-\u03FF-]*$/u


export const validateInput = (name) => {
  

  // Regex to disallow special characters
  // const disallowedPattern = /[<>[\]{}\\|*?:;"`~@#$%^&+=!]/g;
  /* Allowed characters
1. Norwegian
Å, å , Æ, æ , Ø, ø
2. Swedish
Å, å , Ä, ä , Ö, ö
3. French
À, à , Â, â , Æ, æ , Ç, ç , É, é , È, è , Ê, ê , Ë, ë , Î, î , Ï, ï , Ô, ô , Œ, œ , Ù, ù , Û, û , Ü, ü , Ÿ, ÿ
4. Russian
А, а , Б, б , В, в , Г, г , Д, д , Е, е , Ё, ё , Ж, ж , З, з , И, и , Й, й , К, к , Л, л , М, м , Н, н , О, о , П, п , Р, р , С, с , Т, т , У, у , Ф, ф , Х, х , Ц, ц , Ч, ч , Ш, ш , Щ, щ , Ъ, ъ , Ы, ы , Ь, ь , Э, э , Ю, ю , Я, я
5. German
Ä, ä , Ö, ö , Ü, ü , ß
6. Turkish
Ç, ç , Ğ, ğ , İ, ı , Ö, ö , Ş, ş , Ü, ü
7. English
8. Numeric
9.Greek
10.Hindi
*/
  
  // const allowedChar =
  //   /^[A-Za-z0-9\- \tÅåÆæØøÄäÖöÜüÇçĞğİıŞşÀàÂâÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿАаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя]*$/;

    const allowedChar = ['Å', 'å', 'Æ', 'æ', 'Ø', 'ø' , 'Å', 'å', 'Ä', 'ä', 'Ö', 'ö' , 'À', 'à', 'Â', 'â', 'Æ', 'æ', 'Ç', 'ç', 'É', 'é', 'È', 'è', 'Ê', 'ê', 'Ë', 'ë', 
             'Î', 'î', 'Ï', 'ï', 'Ô', 'ô', 'Œ', 'œ', 'Ù', 'ù', 'Û', 'û', 'Ü', 'ü', 'Ÿ', 'ÿ' , 'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'Ж', 'ж', 'З', 'з', 
              'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 'П', 'п', 'Р', 'р', 
              'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 'Ч', 'ч', 'Ш', 'ш', 'Щ', 'щ', 
              'Ъ', 'ъ', 'Ы', 'ы', 'Ь', 'ь', 'Э', 'э', 'Ю', 'ю', 'Я', 'я' , 'Ä', 'ä', 'Ö', 'ö', 'Ü', 'ü', 'ß' , 'Ç', 'ç', 'Ğ', 'ğ', 'İ', 'ı', 'Ö', 'ö', 'Ş', 'ş', 'Ü', 'ü' , '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' , 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', // Uppercase
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        const allAllowedChars = [
          ...allowedChar,
          ' ', '-',
          '.', ',', ';', ':', '-', '/', "'" , 
      ]

      


  if(name?.length>0) {
// for (let char of name) {
//   if (!allAllowedChars.includes(char)) {
//       return "Input contains invalid characters"; // Invalid character found
//   }
// }
if (!allowedPattern.test(name)) {
    return "Input contains invalid characters";
  }

  }
return ""
  // return "";
};
export const handleSpecialcharactersValidationForMention = (text) => {
  // Regex to match mentions in the format @Name(UUID)
  const mentionRegex = /@\[[^\]]+\]\([^\)]+\)/g;

  // Regex to match special characters outside of mention format
  // This regex assumes mentions are valid and should be ignored in the validation
  // const specialCharsRegex = /[<>[\]{}\\|*?:;"`~@#$%^&+=!]/g;
  
  // const allowedChar =
  //   /^[A-Za-z0-9\- \tÅåÆæØøÄäÖöÜüÇçĞğİıŞşÀàÂâÇçÉéÈèÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿАаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя]*$/;

  const allowedChar = ['Å', 'å', 'Æ', 'æ', 'Ø', 'ø' , 'Å', 'å', 'Ä', 'ä', 'Ö', 'ö' , 'À', 'à', 'Â', 'â', 'Æ', 'æ', 'Ç', 'ç', 'É', 'é', 'È', 'è', 'Ê', 'ê', 'Ë', 'ë', 
    'Î', 'î', 'Ï', 'ï', 'Ô', 'ô', 'Œ', 'œ', 'Ù', 'ù', 'Û', 'û', 'Ü', 'ü', 'Ÿ', 'ÿ' , 'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'Ж', 'ж', 'З', 'з', 
     'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 'П', 'п', 'Р', 'р', 
     'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 'Ч', 'ч', 'Ш', 'ш', 'Щ', 'щ', 
     'Ъ', 'ъ', 'Ы', 'ы', 'Ь', 'ь', 'Э', 'э', 'Ю', 'ю', 'Я', 'я' , 'Ä', 'ä', 'Ö', 'ö', 'Ü', 'ü', 'ß' , 'Ç', 'ç', 'Ğ', 'ğ', 'İ', 'ı', 'Ö', 'ö', 'Ş', 'ş', 'Ü', 'ü' , '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' , 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', // Uppercase
'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  const allAllowedChars = [
  ...allowedChar,
  ' ', '-',
  '.', ',', ';', ':', '-', '/', "'"
  ]

  // Find all mentions in the text
  const mentions = text?.match(mentionRegex) || [];

  // Remove mentions from the text to validate the remaining content
  let cleanedText = text;
  mentions.forEach((mention) => {
    cleanedText = cleanedText.replace(mention, "");
  });

  
  

  // for (let char of cleanedText) {
  //   if (!allAllowedChars.includes(char)) {
  //       return "Input contains invalid characters"; // Invalid character found
  //   }
  // }
  if (!allowedPattern.test(cleanedText)) {
    return "Input contains invalid characters";
  }

  return ""
};

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const MODULENAME= {
  "Task":"tasks-dashboard",
  "Policy":"clients-dashboard",
  "PolicyRenewal":"clients-dashboard",

}
export const NAVIGATION= {
  "Task":Paths.TASKS,
  "User":Paths.USERS,
  "Policy":Paths.CLIENT_DASHBOARD,
  "MemberFirm": Paths.MEMBER_FIRM,
  "PolicyRenewal":Paths.CLIENT_DASHBOARD,

}
export const MODULES = {
  POLICY_RENEWAL: "PolicyRenewal",
  USERS: "Users",
  MEMBER_FIRM:"MemberFirm",
  
  CONTACT:"Contact",

}
export const SUCCESS_MESSAGES = {
  USER_CREATE:"User has been added successfully",
  
  SUCCESS:"success",

  UPDATE_USER:"User has been updated successfully",

  DELETE_USER:"User has been deleted successfully",

  POLICY_CREATE:"Policy being added",
  
  CANCEL_POLICY:"Policy has been canceled Successfully",

  RENEW_POLICY:"Policy successfully renewed",

  LOCATION_CREATE:"Location has been added successfully",

  DELETE_LOCATION:"Location deleted successfully",

  UPDATE_LOCATION:"Location updated successfully",

  UPDATE_POLICY:"Policy updated successfully",

  UPDATE_CLIENT:"Client has been updated successfully",

  DOCUMENT_DELETE:"Document has been deleted successfully",

  DELETE_POLICY:"Policy has been deleted successfully",

  ARCHIEVE_CLIENTS:"Selected clients achieved",
  
  CREATE_INSURER:"Insurer has been created successfully",
  
  CREATE_EXCLUSION:"Exclusion has been created successfully",

  CREATE_CLIENT:"Client has been added successfully",
  
  DELETE_MEMBER_FIRM:"Member firm deleted successfully",

  UPDATE_MEMBER_FIRM:"Member firm updated successfully",
  
  CREATE_MEMBER_FIRM:"Member firm added successfully",

}
export const ERROR_MESSAGES = {
  NOT_FOUND:"The resource you are trying to update was not found",
  UNEXPECTED_ERROR:"An unexpected error occurred",
  NETWORK_ERROR:"Network error occurred",
  GET_USER_DETAILS:"Failed to fetch user details",
  DELETE_USER:"Failed to delete user",

  POLICY_CREATE:"Failed to add Policy Please try again later",

  CANCEL_POLICY:"Failed to cancel policy Please try again later",
  
  RENEW_POLICY:"Failed to renewal Policy Please try again later",
  
  REVOKE_POLICY:"Failed to revoke Policy Please try again later",

  LOCATION_CREATE:"Failed to add location. Please try agin later.",

  DELETE_LOCATION:"Failed to delete location. Please try again later.",

  UPDATE_LOCATION:"Failed to update location. Please try again later.",

  UPDATE_POLICY:"Failed to update location Please try again later",
  
  ERROR:"error",
  
  UPDATE_CLIENT:"Failed to update client Please try again later",

  DOCUMENT_DELETE:"Failed to delete document Please try again later",

  UNARCHIEVE_CLIENT:"Selected clients unachieved",
  
  CREATE_INSURER:"Failed to create insurer Please try again later",

  CREATE_EXCLUSION:"Failed to create exclusion Please try again later",
  
  CREATE_CLIENT:"Failed to create client Please try again later",
  
  INVALID_CHARACTERS:"Input contains invalid characters",
  
  DELETE_MEMBER_FIRM:"Failed to delete member firm. Please try again later",
  
  CREATE_MEMBER_FIRM:"Failed to create member firm. Please try again later",

}
export const LOADING_MESSAGES = {
  UPDATING_USER:"Updating user...",
  CREATING_USER:"Creating User...",
  DELETING_USER:"Deleting user...",
  CREATING_POLICY:"Adding policy...",

  CANCELLING_POLICY:"Canceling policy...",

  RENEWING_POLICY:"Renewing policy...",

  WAIT_MOMENT:"Please wait a moment...",

  ADD_LOCATION:"Adding location...",
  
  DELETING_LOCATION:"Deleting location...",

  UPDATING_LOCATION:"Updating location...",

  UPDATE_POLICY:"Updating policy...",

  UPDATE_CLIENT:"Updating client...",

  DELETING_DOCUMENT:"Deleting document...",

  DELETING_POLICY:"Deleting policy...",

  ARCHIEVING_CLIENTS:"Achieving selected clients...",

  UNARCHIEVING_CLIENTS:"Unachieving selected clients...",

  CREATE_INSURER:"Creating insurer...",

  CREATE_EXCLUSION:"Creating exclusion...",

  CREATE_CLIENT:"Adding client...",

  CREATE_MEMBER_FIRM:"Creating member firm...",
  
  UPDATE_MEMBER_FIRM:"Updating member firm...",
  
  DELETE_MEMBER_FIRM:"Deleting member firm...",
  
}
export const FIELD_MESSAGES = {
  policynumber: "Policy number",
  country: "Country",
  insurer: "Insurer",
  nameinsure: "Name insurer",
  comment: "Comments"
}
export const POLICY_TABS = {
  policynumber: 2,
  country: 2,
  insurer: 2,
  nameinsure: 2,
  comment: 5,
  servicingbroker:1,
  selectedgCoverage:2,
  language:2,
  policytype:2,
  selectedExclusion:3,
  currency:4,
  totalEstimatedAnnualPremium:4,
  premiumPaymentFrequency:4,
  commisionRate:4,
  commissionPaymentFrequency:4,
  charges:4,
  taxes:4,
  

}
export const fileNameRegex = /filename\s*=\s*["]?([^";]+)["]?/i