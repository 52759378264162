import React, { useEffect, useState } from "react";

import "assets/css/Custom.css";

import Box from "@mui/material/Box";

import { RenderTextInput } from "../../components/TextField";
import ButtonComponent from "../../components/Button";
import GridContainer from "../../components/GridContainer";

import InputAdornment from "@mui/material/InputAdornment";
import { Grid } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  searchClientsData,
  setSearchClientsData,
} from "../../store/actions/ClientAction";

const MyModal = (props) => {
  const [clientData, setClientData] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [searchword, setSearchword] = useState("");

  const dispatch = useDispatch();
  
  const stateData = useSelector((state) => {
    return {
      searchClientsData: state?.fetchData?.searchClientsData,
    };
  } , shallowEqual);

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));

    return () => {
      dispatch(setSearchClientsData(""));
    };
  }, [dispatch]);

  useEffect(() => {
    if (stateData?.searchClientsData?.data?.searchClients?.nodes) {
      setClientData(stateData?.searchClientsData?.data?.searchClients?.nodes);
    }
  }, [stateData?.searchClientsData?.data?.searchClients?.nodes]);
  
  const handleSearchClient = (e) => {
    dispatch(searchClientsData(e?.target?.value));
    props.handlesetclientName(e.target.value);
    setSearchword(e?.target?.value);
  };

  return (
    <Box>
      <Box className="modal-body p-0">
        <Box className="p-3">
          <RenderTextInput
            type="search"
            className="form-control ps-2 p-3 client-name-input"
            placeholder="Type client name"
            onChange={(e) => handleSearchClient(e)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ marginLeft: "27px !important" }}
                >
                  {" "}
                  <img
                    src="wwwroot/assets/images/help-circle.png"
                    className="img-fluid search-info"
                    alt="info"
                    style={{ zIndex: "0 !important" }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ padding: "0px !important" }}
          />
        </Box>

        {searchword &&
          clientData?.map((item, key) => {
            return (
              <>
                <GridContainer sx={{ marginBottom: "10px" }}>
                  <Grid
                    xs={12}
                    md={6}
                    lg={6}
                    className="col-12 col-md-6 col-lg-6 search-client-name"
                    sx={{ paddingLeft: "3%" }}
                  >
                    {item.clientName}
                  </Grid>
                  {item.isBroker === true ||
                  loggedInUser?.extension_Role === "HqAdmin" ||
                  loggedInUser?.extension_Role === "HqContributor" ? (
                    <Grid
                      xs="12"
                      md="6"
                      lg="6"
                      className="col-12 col-md-6 col-lg-6 search-client-location"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleOpenlocation(item.clientId)}
                    >
                      {/* Add as location */}
                      Add new location
                    </Grid>
                  ) : (
                    ""
                  )}
                </GridContainer>
              </>
            );
          })}

        {searchword && (
          <Box className="row btn-row">
            <ButtonComponent
              className="btn btn-default add-policy"
              onClick={() => props.handleShowSecondaryModal()}
              variant={"contained"}
              color={"grey"}
            >
              Create as new
            </ButtonComponent>
          </Box>
        )}

        {/*Modified By: Anjali
        Modified On: 05-03-2024
        JiraId: https://bosframework.atlassian.net/browse/WBN-106
        Reason: close modal when clicked on client check*/}

        {searchword && clientData?.length === 0 && (
          <GridContainer
            className="p-2 check-status-div align-items-center"
            sx={{ width: "auto !important" }}
          >
            <Grid xs={12} md={8} lg={8}>
              <Box className="main-text">Quick client check</Box>
              <Box className="sub-text">
                Could this be added to an existing client as a location?
              </Box>
            </Grid>
            <Grid
              xs={12}
              md={4}
              lg={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                type="button"
                className="btn btn-default check-clients-btn"
                onClick={() => props.onClose()}
              >
                Check clients
              </button>
            </Grid>
          </GridContainer>
        )}
      </Box>
    </Box>
  );
};

export default MyModal;
