
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Paths } from "constants";
import AuthLayout from "components/layout/AuthLayout";
import ProtectedRoute from "routes/ProtectedRoutes";

import ClientDashboard from "pages/client/ClientDashboard";

import Map from "pages/map/Map";
import Calendar from "pages/calendar/Calendar";

import UsersList from "pages/users";

import TasksList from "pages/tasks"
import MemberFirmList from "pages/memberFirm";
import Dasboard from "pages/dasboard";
import Reports from "pages/reports";
import HealthCheck from "../healthCheck";
import ResetPassword from "pages/authentication/ResetPassword"

const RoutesComponent = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useState(() =>
    JSON.parse(localStorage.getItem("user"))
  );

  useEffect(() => {
    setLoggedInUserDetails(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <Router>
      <Routes>
        {/* Unauthenticated routes */}
        <Route path={Paths.HEALTHCHECK} element={<HealthCheck />} />
        <Route path={Paths.RESET_PASSWORD} element={<ResetPassword />} />

        {/* Authenticated routes */}
        <Route
          path={Paths.HOME}
          element={
            <AuthLayout>
              <Dasboard />
            </AuthLayout>
          }
        />
        <Route
          path={Paths.CLIENT_DASHBOARD}
          element={
            <AuthLayout>
              <ClientDashboard />
            </AuthLayout>
          }
        />
        <Route
          path={Paths.MAP}
          element={
            <AuthLayout>
              <Map />
            </AuthLayout>
          }
        />
        <Route
          path={Paths.CALENDAR}
          element={
            <AuthLayout>
              <Calendar />
            </AuthLayout>
          }
        />
        <Route
          path={Paths.USERS}
          element={
            <ProtectedRoute
              allowedRoles={["HqAdmin", "MemberAdmin", "HqContributor"]}
              user={loggedInUserDetails}
            >
              <AuthLayout>
                <UsersList />
              </AuthLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Paths.TASKS}
          element={
            <AuthLayout>
              <TasksList />
            </AuthLayout>
          }
        />
        <Route
          path={Paths.MEMBER_FIRM}
          element={
            <ProtectedRoute
              allowedRoles={["HqAdmin"]}
              user={loggedInUserDetails}
            >
              <AuthLayout>
                <MemberFirmList />
              </AuthLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Paths.REPORTS}
          element={
            <AuthLayout>
              <Reports />
            </AuthLayout>
          }
        />

        <Route path="*" element={<Navigate to={Paths.HOME} />} />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
