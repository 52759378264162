import React, { useEffect , useState } from "react";
import ButtonComponent from "../../components/Button";
import { Box } from "@mui/material";

const CancelPolicyModal = (props) => {
    const [show, setShow] = useState(false);
    const [cancelPolicyDate, setCancelPolicyDate] = useState("");
    const [reason, setReason] = useState("");

    useEffect(()=> {
setShow(props.showCancelModal)
    } , [])

    return (
        <Box 
        style={{display:"block"}}
        
         >
     
             
             <Box className="modal-body cancel-modal" style={{padding: '24px'}}>

                 <Box className="icon-fill d-block ms-auto me-auto mt-2 text-center" sx={{left:"5%"}}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                         <path d="M8.00038 11.3334H8.00813M8.00038 6.00002V8.66669M6.86051 2.57335L1.21384 12C1.09741 12.2016 1.03581 12.4302 1.03516 12.663C1.03451 12.8958 1.09483 13.1248 1.21012 13.327C1.32541 13.5293 1.49165 13.6979 1.69231 13.8159C1.89296 13.934 2.12104 13.9975 2.35384 14H13.6472C13.88 13.9975 14.108 13.934 14.3087 13.8159C14.5094 13.6979 14.6756 13.5293 14.7909 13.327C14.9062 13.1248 14.9665 12.8958 14.9658 12.663C14.9652 12.4302 14.9036 12.2016 14.7872 12L9.14051 2.57335C9.02166 2.37742 8.85432 2.21543 8.65464 2.103C8.45495 1.99058 8.22966 1.93152 8.00051 1.93152C7.77135 1.93152 7.54606 1.99058 7.34637 2.103C7.14669 2.21543 6.97935 2.37742 6.86051 2.57335Z" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                 </Box>
                 <Box className="cancel-policy text-center">Cancel policy</Box>
                 <Box className="cancel-subtext text-center">Details below will display for all members with access to this policy information.</Box>
                 <Box className="mb-3">
                     <label class="form-label">Reason</label>
                     <textarea class="form-control" rows="2" placeholder="Type your reason here"
                     onChange={(e)=> setReason((e.target.value)?.trim())}
                     ></textarea>
                 </Box>
                 <Box className="mb-4">
                     <label class="form-label">Cancellation date</label>
                     <input type="Date" class="form-control" id="basicDate" placeholder="Select date" data-input
                     max={props.maxExpiration}
                     onChange={(e)=> setCancelPolicyDate(e.target.value)}
                     />

                 </Box>
                 <Box sx={{display:"flex" , justifyContent:"space-between"}} className="d-flex justify-content-between mt-3">
                     <ButtonComponent type="button" className="btn btn-secondary outline-btn btn-md" 
                     variant={"outlined"} 
                     color={"white"}
                     sx={{width:"49%"}}

                     onClick={()=> props.handleCloseCancelModal()}>Cancel</ButtonComponent>
                     <ButtonComponent type="button" className="btn btn-danger"
                     variant={"contained"}
                     color={"red"}

                      onClick={()=> props.handleCancelPolicy(cancelPolicyDate , reason)} disabled={reason==="" || cancelPolicyDate===""}>Delete</ButtonComponent>
                 </Box>
             </Box>
         
 </Box>
    )
}

export default CancelPolicyModal