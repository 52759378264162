import React from "react";
import Dialog from "../Dialog";
import { Box, Typography } from "@mui/material";
import ButtonComponent from "../Button";

const PIIConfirmationModal = ({
  setConfirmationOpen,
  confirmationOpen,
  handleCancelUpload,
  handleConfirmUpload,
}) => {
  return (
    <Dialog
      maxWidth="xs"
      maxHeight="150px"
      open={confirmationOpen}
      onClose={() => setConfirmationOpen(false)}
      // showCloseIcon="false"
      showCloseIcon="true"
      body={
        <Box sx={{ padding: "15px" , textAlign:'justify !important'}}>
          <Typography variant="p">
            <Typography
              variant="span"
              sx={{ color: "var(--color-text-critical) !important" }}
            >
              CAUTION:
            </Typography>{" "}
            Documents containing Personally Identifiable Information (PII)
            should not be uploaded. PII includes information that can be used to
            distinguish or trace an individual’s identity either directly or
            indirectly through linkages with other information. If your document
            includes PII (such as names, dates of birth, ID or passport numbers,
            etc.), please do not upload
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop:"10px" }}>
            <ButtonComponent
              variant={"outlined"}
              color={"red"}
              // sx={{ marginRight: "10px" }}
              onClick={handleCancelUpload}
              sx={{width:"49%"}}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              variant={"contained"}
              color={"primary"}
              onClick={handleConfirmUpload}
              sx={{width:"49%"}}
            >
              Okay
            </ButtonComponent>
          </Box>
        </Box>
      }
    />
  );
};
export default PIIConfirmationModal;
