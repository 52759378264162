import { ERROR_MESSAGES } from "constants";
import { POLICY_TABS } from "constants";
import { FIELD_MESSAGES } from "constants";

export const navigateTo = (navigate, path, state) => {
  navigate({ pathname: path }, { state });
};


export const clearSubIdFromLocation = (navigate, location) => {
  
  navigate(
    { pathname: location.pathname },
    {
      state: {
        ...location.state,
        subId: null,
        subModuleName:"",
        
      },
    }
  );
};



export const handleNavigationState = (
  condition,
  callback,
  navigate,
  location
) => {
  if (condition) {
    
    callback();
    
    navigateTo(navigate, location.pathname, {
        ...location.state, 
        id: null, 
      
    });
  }
};
export const startProgress = (setProgress, interval = 1000, increment = 50) => {
  const mockProgress = setInterval(() => {
    setProgress((prev) => {
      const newProgress = prev + increment;
      if (newProgress >= 100) {
        clearInterval(mockProgress);
        return 100;
      }
      return newProgress;
    });
  }, interval);

  // Optionally return the clearInterval function to stop it manually
  return () => clearInterval(mockProgress);
}



export function getFirstError(data, activeTab) {
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      
      if (POLICY_TABS[key] === activeTab) {
        return null;  
      }
      
      if(value === ERROR_MESSAGES.INVALID_CHARACTERS) {
      return { field: key, message: `${FIELD_MESSAGES[key]} ${value}` }; 
      }
      else {
        return { field: key, message: `${value}` }
      }
    }
  }
  return null; 
}