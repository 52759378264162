import { ACTION_TYPES } from "./ActionTypes";
import netWorkService from "../../network/NetworkService";
import { errorToast, loadingToast,  successToast } from "components/Toast";
import {  toast } from "react-toastify"
import { startLoading, stopLoading } from "./LoaderAction";

export const getDocumentsTypes = (params) => (dispatch) => {
  return netWorkService.getDocumentTypes(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setDocumentstypesData(responseJson?.data));
        });
      }
    })
    .catch((error) => {
      
    });
};

export const setDocumentstypesData = (payload) => ({
  type: ACTION_TYPES.GET_DOCUMENTS_TYPE,
  payload,
});
export const addDocuments = (params , headers) => (dispatch) => {
  return netWorkService.uploadDocument(params , headers)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(addDocumentData("success"))
        
      }
    })
    .catch((error) => {
      
    });
};

export const addDocumentData = (payload) => ({
  type: ACTION_TYPES.ADD_DOCUMENT,
  payload,
})

export const uploaddocument = (params , from , headers) => (dispatch) => {
    loadingToast("Adding document...")
    dispatch(startLoading())

  
   return netWorkService.uploadDocument(params , headers)
      .then((response) => {
        toast.dismiss()
        dispatch(stopLoading())

        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          dispatch(uploadDocumentData(`success-${from}`));
          setTimeout(()=> successToast("Document has been added successfully") , 980)
          return "added";
        } else if (response.status === 400) {
          response.json().then((responseJson) => {
            dispatch(uploadDocumentData(responseJson));
            
            setTimeout(()=> errorToast("Failed to add document") , 980)
            
          });
        }
      })
      .catch((error) => {
        
        setTimeout(() => errorToast("Failed to add document. Please try again later"), 980)
      })
      
};
export const uploadMultipledocument = (params , from , headers , fileName) => async(dispatch) => {
    
    dispatch(startLoading())

  
   return await netWorkService.uploadDocument(params , headers)
      .then((response) => {
        toast.dismiss()
        dispatch(stopLoading())

        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          dispatch(uploadDocumentData(`success-${from}`));
          
          return "added";
        } else if (response.status === 400) {
          response.json().then((responseJson) => {
            dispatch(uploadDocumentData(responseJson));
            
            setTimeout(()=> errorToast(`Failed to add ${fileName}`) , 980)
            
          });
        }
      })
      .catch((error) => {
        
        setTimeout(() => errorToast("Failed to add document. Please try again later"), 980)
      })
      
};

export const uploadDocumentData = (payload) => ({
  type: ACTION_TYPES.UPLOAD_DOCUMENT,
  payload,
});

export const getDocuemntsData = (payload) => (dispatch) => {
  let isSort =
  payload?.isSort === true
          ? `order: { ${payload?.sortdocumentsName}: ${
            payload?.sortDocumentsASC === true ? "ASC" : "DESC"
            } }`
          : ""
  let params = {
    query: `
    query DocumentsByClient() {
      documentsByClient(${isSort}) {
        nodes {
          documentId
          documentTypeId
          documentTypeName
          documentName
          documentDisplayName
          description
          fileFormat
          isArchived
          visibility
          uploadDate
      }
    }
  }
                  `,
                  variables: { id: payload?.clientId },
  };
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    clientId: payload?.clientId,
    // UserId: payload.UserId,
    // ProducingFirmId: payload.ProducingFirmId,
  };
  return netWorkService.getDocuments(params, headers)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            setDocumentsData(responseJson?.data?.documentsByClient?.nodes)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const setDocumentsData = (payload) => ({
  type: ACTION_TYPES.GET_DOCUMENTS_DATA,
  payload,
});
export const updateDocuemntsData = (payload , from , header) => (dispatch) => {
   
  return netWorkService.updateDocuments(payload , from , header)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
          dispatch(
            setupdateDocumentsData("success-Policy")
          );
        
      }
    })
    .catch((error) => {
      
    });
};

export const setupdateDocumentsData = (payload) => ({
  type: ACTION_TYPES.GET_UPDATE_DOCUMENTS_DATA,
  payload,
});

