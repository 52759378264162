import React, { useEffect, useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

//MUI - Components
import Box from "@mui/material/Box";

//custom mui components
import Table from "../../components/Table";
import { Paths, TASKS_COLUMNS, dateFormat } from "../../constants";

import { getTasksList } from "../../store/actions/TasksAction";

import { useNavigate } from "react-router-dom";
const Tasks = () => {
  const [tasksList, setTasksList] = useState([]);
  const [sortName, setSortName] = useState("");
  const [sortASC, setSortASC] = useState(true);

  const stateData = useSelector((state) => {
      
    return {
      tasksListData: state?.tasks?.tasksList,
    };
  } , shallowEqual);
  const dispatch = useDispatch();

  const navigate = useNavigate()
  useEffect(() => {
    let payload = {
      isSort: false,
      sortName: sortName,
      sortBy: sortASC === true ? "ASC" : "DESC",

      from: "dashboard-task",
      
    }

    dispatch(getTasksList(payload));
  }, [dispatch , sortASC , sortName]);

  useEffect(() => {
    if (stateData?.tasksListData?.data?.dashboardTasks?.nodes) {
      let tasks = stateData?.tasksListData?.data?.dashboardTasks?.nodes
        ?.map((item, key) => {
          if (!item.isCompleted) {
            return {
              ...item,
              dueDate: dateFormat(item.dueDate),
              category: item.tags,
              id: item.id,
              
            };
          }
          return null;
        })
        .filter((item) => item !== null);
      setTasksList(tasks);
    }
  }, [stateData?.tasksListData?.data?.dashboardTasks?.nodes]);

  useEffect(()=> {
    if(sortName) {
      let payload = {
        isSort: true,
        sortName: sortName,
        sortBy: sortASC === true ? "ASC" : "DESC",

        from: "dashboard-task",

      }
      dispatch(getTasksList(payload))
    }
  } , [sortASC , sortName , dispatch])

  const handleSortTasks = (e) => {

    if(e === sortName) {
      setSortASC(!sortASC)
    }
    else {
      setSortASC(true)
    }
    setSortName(e)
    // setSortASC(!sortASC)
  }

  const handletask = (id) => {
    navigate(Paths.TASKS , {state: {from:"tasks-dashboard" ,  id:  id}})
  };

  return (
    <Box>
      <Box className="subtext" sx={{ marginTop: "20px" , marginBottom:"10px" }}>
        Your Tasks
      </Box>

      {tasksList?.length>0?
        <Table
          columns={TASKS_COLUMNS}
          rows={tasksList}
          from="tasksDashboard"
          checkedId={[]}
          handleRow={(id) => handletask(id)}
          handleSort={(e)=> handleSortTasks(e)}
        />
        :
        <Box className="company-name" sx={{color:"var(--color-text-grey-tertiary)"}}>No tasks have been added
        </Box>
      }
    </Box>
  );
};
export default Tasks;
