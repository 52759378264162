import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import Card from "../../components/Card";
import GridContainer from "../../components/GridContainer";

import { useNavigate } from "react-router-dom";
import { Paths } from "../../constants";

const QuickLinks = () => {
  let history = useNavigate();

  
  return (
    <Box>
      <Box className="subtext" sx={{ marginTop: "25px" }}>
        Quick links
      </Box>
      <GridContainer>
        <Grid item
          xs={12}
          md={3}
          lg={3}
          className=" card-parent quick_links"
          onClick={() => history(Paths.TASKS , {state:{ showModal: true }})}
          sx={{ cursor: "pointer" }}
        >
          <Card className="d-flex flex-row align-items-center card">
            <Typography component={"div"} className="quick-links">
              <i className="fas fa-solid fa-plus"></i>
            </Typography>
            <Typography component={"div"} sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">New task</Box>
              <Box className="quick-links-subtext">Go to create a task</Box>
            </Typography>
          </Card>
        </Grid>
        <Grid item
          xs={12}
          md={3}
          lg={3}
          className=" card-parent quick_links"
          onClick={() => history(Paths.CLIENT_DASHBOARD , {state: { showModal: true }})}
          sx={{ cursor: "pointer" }}
        >
          <Card className="d-flex flex-row align-items-center card">
            <Typography component={"div"} className="quick-links">
              <i className="fas fa-solid fa-plus"></i>
            </Typography>
            <Typography component={"div"} sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Add new client</Box>
              <Box className="quick-links-subtext">
                Go to create a new client
              </Box>
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} lg={3} className=" card-parent quick_links ">
          <Card className="d-flex flex-row align-items-center card ">
            <Typography component={"div"} className="quick-links">
              <i className="fas fa-solid fa-plus"></i>
            </Typography>
            <Typography component={"div"} sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Add new contact</Box>
              <Box className="quick-links-subtext">Quick link</Box>
            </Typography>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={3} lg={3} className=" card-parent quick_links ">
          <Card className="d-flex flex-row align-items-center card ">
            <Typography className="quick-links">
              <i className="fas fa-solid fa-plus"></i>
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Go to Analytics</Box>
              <Box className="quick-links-subtext">Review your performance</Box>
            </Typography>
          </Card>
        </Grid> */}
      </GridContainer>
    </Box>
  );
};
export default QuickLinks;
