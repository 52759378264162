import { LOADER_CONST } from "../actions/actionTypes/LoaderActionTypes";

const initialState = {
    loading: false,
};

const Loader = (state = initialState, action) => {
    switch (action.type) {
        case LOADER_CONST.START_LOADING:
            return { ...state, loading: true };
        case LOADER_CONST.STOP_LOADING:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default Loader