import { ACTION_TYPES } from "./ActionTypes";
import netWorkService from "../../network/NetworkService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  loadingToast,
} from "components/Toast";
import { startLoading, stopLoading } from "./LoaderAction";
import { SUCCESS_MESSAGES , ERROR_MESSAGES , LOADING_MESSAGES } from "constants";

export const CreateUser = (params) => async (dispatch) => {
  try {
    loadingToast(LOADING_MESSAGES.CREATING_USER);
    dispatch(startLoading());

    const response = await netWorkService.addUser(params);

    toast.dismiss();
    dispatch(stopLoading());

    if (response?.ok) {
      
      dispatch(createdUserData("success"));

      setTimeout(() => successToast(SUCCESS_MESSAGES.USER_CREATE), 980);
    } else {
      const errorMessage =
        response?.status === 404
          ? ERROR_MESSAGES.NOT_FOUND
          : response?.status === 400&&(await response?.json())?.errors?.[0];

      dispatch(createdUserData(errorMessage));
      if(errorMessage) {
        setTimeout(() => errorToast(errorMessage), 980);
      }
    }
  } catch (error) {
    toast.dismiss();
    dispatch(stopLoading());
    const errorMessage = error?.errors?.[0] || ERROR_MESSAGES.UNEXPECTED_ERROR;
    setTimeout(() => errorToast(errorMessage), 980);
  }
};

export const createdUserData = (payload) => ({
  type: ACTION_TYPES.CREATE_USER_SUCCESS,
  payload,
});
export const getUserList = (params) => async (dispatch) => {
  try {
    dispatch(startLoading());

    const response = await netWorkService.getUsers(params);

    dispatch(stopLoading());

    if (response?.ok) {
      const responseJson = await response?.json();
      dispatch(setUserList(responseJson?.data || []));
    } else {
      const errorJson = await response.json();
      const errorMessage =
        errorJson?.errors?.[0] || ERROR_MESSAGES.UNEXPECTED_ERROR;

      if (response?.status === 403 || response?.status !== 500) {
        dispatch(setUserListFailure(errorMessage));
      }
    }
  } catch (error) {
    dispatch(stopLoading());
    const errorMessage = error?.errors?.[0] || ERROR_MESSAGES.NETWORK_ERROR;
    dispatch(setUserListFailure(errorMessage));
  }
};

export const setUserList = (payload) => ({
  type: ACTION_TYPES.GET_USER_LIST_SUCCESS,
  payload,
});
export const setUserListFailure = (payload) => ({
  type: ACTION_TYPES.GET_USER_LIST_FAILURE,
  payload,
});

export const getUserListInTasks = (params) => async (dispatch) => {
  try {
    dispatch(startLoading());

    const response = await netWorkService.getUsersInTasks(params);

    dispatch(stopLoading());

    if (response?.ok) {
      const responseJson = await response.json();
      dispatch(setUserList(responseJson || []));
    } else {
      const errorJson = await response.json();
      const errorMessage =
        errorJson?.errors?.[0] || ERROR_MESSAGES.UNEXPECTED_ERROR;

      if (response?.status === 403 || response?.status === 500) {
        dispatch(setUserListFailure(errorMessage));
      }
    }
  } catch (error) {
    dispatch(stopLoading());
    const errorMessage = error?.errors?.[0] || ERROR_MESSAGES.NETWORK_ERROR;
    dispatch(setUserListFailure(errorMessage));
  }
};

export const getUserById = (params) => async (dispatch) => {
  try {
    dispatch(startLoading());

    const response = await netWorkService.getUserById(params);

    dispatch(stopLoading());

    if (response?.ok) {
      const responseJson = await response.json();
      dispatch(setUserById(responseJson?.data || null));
    } 
  } catch (error) {
    dispatch(stopLoading());
    
  }
};

export const setUserById = (payload) => ({
  type: ACTION_TYPES.GET_USER_BY_ID_SUCCESS,
  payload,
});
export const updateUser = (params) => async (dispatch) => {
  try {
    loadingToast(LOADING_MESSAGES.UPDATING_USER);
    dispatch(startLoading());

    const response = await netWorkService.updateUser(params);

    toast.dismiss();
    dispatch(stopLoading());

    if (response?.ok) {
      dispatch(setUpdatedUser(SUCCESS_MESSAGES.SUCCESS));
      setTimeout(() => successToast(SUCCESS_MESSAGES.UPDATE_USER), 980);
    } else {
      const errorJson = await response?.json();
      const errorMessage =
        errorJson?.errors?.[0] || ERROR_MESSAGES.UNEXPECTED_ERROR;

      if (response.status === 404) {
        setTimeout(() => errorToast(ERROR_MESSAGES.NOT_FOUND), 980);
      } else {
        dispatch(setUpdatedUser(errorJson));
        setTimeout(() => errorToast(errorMessage), 980);
      }
    }
  } catch (error) {
    dispatch(stopLoading());
    toast.dismiss();
    
  }
};

export const setUpdatedUser = (payload) => ({
  type: ACTION_TYPES.UPDATE_USER_SUCCESS,
  payload,
});
export const deleteUser = (params) => async (dispatch) => {
  try {
    loadingToast(LOADING_MESSAGES.DELETING_USER);
    dispatch(startLoading());

    const response = await netWorkService.deleteUser(params);

    toast.dismiss();
    dispatch(stopLoading());

    if (response?.ok) {
      dispatch(setdeletedUser(SUCCESS_MESSAGES.SUCCESS));
      setTimeout(() => successToast(SUCCESS_MESSAGES.DELETE_USER), 980);
    } else {
      const errorJson = await response?.json();
      const errorMessage = errorJson?.errors?.[0] || ERROR_MESSAGES.DELETE_USER;

      dispatch(setdeletedUser(errorJson));
      setTimeout(() => errorToast(errorMessage), 980);
    }
  } catch (error) {
    dispatch(stopLoading());
    toast.dismiss();

    
  }
};

export const setdeletedUser = (payload) => ({
  type: ACTION_TYPES.DELETE_USER_SUCCESS,
  payload,
});
export const changeFirstLogin = (params) => async (dispatch) => {
  try {
    const response = await netWorkService.changeFirstLogin(params);

    if (response?.ok) {
      dispatch(setChangeFirstLogin(SUCCESS_MESSAGES.SUCCESS));
    } else {
      const responseJson = await response?.json();
      dispatch(setChangeFirstLogin(responseJson));
    }
  } catch (error) {
    dispatch(
      setChangeFirstLogin({
        error: error?.errors?.[0] || ERROR_MESSAGES.NETWORK_ERROR,
      })
    );
  }
};

export const setChangeFirstLogin = (payload) => ({
  type: ACTION_TYPES.SET_CHANGE_FIRST_LOGIN,
  payload,
});
export const getUserByFilter = (params) => async (dispatch) => {
  try {
    dispatch(startLoading());

    const response = await netWorkService.getUserByFilter(params);

    dispatch(stopLoading());

    if (response?.ok) {
      const responseJson = await response.json();
      dispatch(setUserByFilterData(responseJson?.data));
    } else {
      const responseJson = await response?.json();
      dispatch(setUserByFilterData(responseJson));
    }
  } catch (error) {
    dispatch(stopLoading());

    dispatch(
      setUserByFilterData({
        error: error?.errors?.[0] || ERROR_MESSAGES.NETWORK_ERROR,
      })
    );
  }
};

export const setUserByFilterData = (payload) => ({
  type: ACTION_TYPES.SET_USER_BY_FILTER_DATA,
  payload,
});
