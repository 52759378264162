import React from "react";

import GridContainer from "../../../components/GridContainer";
import Select from "react-select";
import { RenderTextInput } from "../../../components/TextField";

import { Box, Grid, InputAdornment } from "@mui/material";
import { customStyles } from "styles/CustomStyle";
import {  blockInvalidChar, dropDownOptions } from "../../../constants";

const Tab4 = ({
  tab,
  selectedCurrency,
  handleCurrency,
  errors,
  currencies,
  totalEstimatedAnnualPremium,
  limitDecimalValue,
  handleTotalEstimatedAnnualPremium,
  selectedPaymentFrequency,
  handlePaymentFrequency,
  handlePremiumPaid,
  commisionRate,
  limitPercentageValue,
  handleCommisionrate,
  selectedCommisionPaymentFrequency,
  handleCommisionPaymentFrequency,
  charges,
  handleCharges,
  taxes,
  handleTaxes,
  premiumpaymentfrequencyOption,

}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 4 ? "active" : ""}`}
      id="pills-premium"
      role="tabpanel"
      aria-labelledby="pills-premium-tab"
      tabIndex="0"
    >
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Currency*</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Select
            aria-label="Default select example"
            value={selectedCurrency}
            onChange={handleCurrency}
            styles={customStyles(errors.currency)}
            
            options={dropDownOptions(currencies , "id" , "currency")}
          />

          {errors.currency && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.currency}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row mt-2">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Total Estimated Annual Premium</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="number"
            placeholder="0.00"
            value={totalEstimatedAnnualPremium}
            data-input
            fullWidth
            onChange={(e) => {
              limitDecimalValue(
                e.target.value,
                handleTotalEstimatedAnnualPremium
              );
            }}
            errors={errors.totalEstimatedAnnualPremium}
            onKeyDown={blockInvalidChar}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Premium Payment Frequency</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          

          <Select
            options={premiumpaymentfrequencyOption}
            placeholder="Select a Frequency"
            onChange={handlePaymentFrequency}
            value={selectedPaymentFrequency}
            styles={customStyles(errors.premiumPaymentFrequency)}
          />

          {errors.premiumPaymentFrequency && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.premiumPaymentFrequency}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      {selectedPaymentFrequency === "1" ? (
        <>
          <GridContainer className="row">
            <Grid item xs={12} md={6} lg={6}>
              <Box className="client-text">Date Premium Paid</Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <RenderTextInput
                type="Date"
                className="form-control"
                id="basicDate2"
                placeholder="Select date"
                data-input
                fullWidth
                onChange={handlePremiumPaid}
              />
            </Grid>
          </GridContainer>
          <hr />
        </>
      ) : (
        ""
      )}
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Commision Rate</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="number"
            id="basicDate2"
            placeholder="Commision Rate %"
            data-input
            value={commisionRate}
            fullWidth
            onChange={(e) => {
              limitPercentageValue(e.target.value, handleCommisionrate);
            }}
            errors={errors.commisionRate}
            onKeyDown={blockInvalidChar}
            
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ marginLeft: "27px !important" }}
                >
                  {" "}
                  
                  <img
                            src="wwwroot/assets/images/percent.png"
                            className="img-fluid"
                            alt="percent"
                            
                          />
                          
                </InputAdornment>
              ),
            }}

          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Commision Payment Frequency</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          

          <Select
            options={premiumpaymentfrequencyOption}
            placeholder="Select a Frequency"
            onChange={handleCommisionPaymentFrequency}
            value={selectedCommisionPaymentFrequency}
            styles={customStyles(errors.commissionPaymentFrequency)}
          />

          {errors.commissionPaymentFrequency && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.commissionPaymentFrequency}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Charges</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="number"
            id="basicDate2"
            placeholder="Charges"
            data-input
            value={charges}
            fullWidth
            onChange={(e) => limitDecimalValue(e.target.value, handleCharges)}
            onKeyDown={blockInvalidChar}
            errors={errors.charges}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Taxes</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="number"
            id="basicDate2"
            placeholder="Taxes"
            data-input
            value={taxes}
            fullWidth
            onChange={(e) => limitDecimalValue(e.target.value, handleTaxes)}
            onKeyDown={blockInvalidChar}
            errors={errors.taxes}
          />
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Tab4;
