import * as React from "react";
import { FC } from "react";
// import { DialogContent, DialogActions } from "@mui/material";

// import { StyledDialog } from "../../styles";
import { DialogContent, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { StyledDialog } from "./common/CustomStyle";
import CloseIcon from '@mui/icons-material/Close'


const Dialog = ({ maxWidth, height ,  open, top, body, showCloseIcon ,  bottom, onClose, sx, className ,...props }) => {
  return (
    <StyledDialog
      maxWidth={maxWidth || "lg"}
      height={height}
      // onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={className}
      scroll={'paper'}
      sx={sx}
      {...props}>
      {top || ""}
      {showCloseIcon === "true" &&
      <>
      <DialogTitle sx={{ m: 0, p: 2  , backgroundColor:"transparent"}} id="customized-dialog-title">
          {/* Modal title */}
          {" "}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 0,
            color: (theme) => theme.palette.grey[500],
            // backgroundColor:"transparent",
            background:"none",
            filter:"invert(.5) grayscale(100%) brightness(200%)"
          }}
        >
          <CloseIcon onClose={onClose}/>
        </IconButton>
        </>
        }
      <DialogContent dividers sx={{borderRadius:"10px"}}>{body}</DialogContent>
      {bottom && <DialogActions sx={actionsStyles}>{bottom}</DialogActions>}
    </StyledDialog>
  );
};

export default Dialog;

const actionsStyles = {
  display: "flex",
  margin: "5px auto 5px auto"
};