
import netWorkService from "../../network/NetworkService";

import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  
  loadingToast,
} from "components/Toast";
import * as base from "./BaseActions";
import { startLoading, stopLoading } from "./LoaderAction"

import { REPORTS_CONST } from "./actionTypes/ReportsActionsTypes";
import { fileNameRegex } from "constants";


export const getReports = (params) => async (dispatch) => {
  
  dispatch(base.getRequest(REPORTS_CONST.GET_REPORTS_REQUEST));
  dispatch(startLoading())
  
  let payload = {
    query:`
    
    query Reports {
    reports(first: 300) {
        nodes {
            id
            name
            displayName
            templateId
            status
            createdByUserName
            isActive
            isDeleted
            createdOn
            modifiedOn
            createdBy
            templateName
            modifiedBy
            clients {
                id
                name
            }
            policies {
                id
                name
            }
        }
    }
}

    `
  }

  return await netWorkService.getReports(payload)
    .then((response) => {
      dispatch(stopLoading())
      
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
        response.json().then((responseJson) => {
        dispatch(
            base.getSuccess(REPORTS_CONST.GET_REPORTS_SUCCESS, responseJson)
          );
        })
        
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(REPORTS_CONST.GET_REPORTS_FAILURE, responseJson)
          );
          setTimeout(()=> errorToast(responseJson?.errors?.[0]) , 980)
        });
      }
    })
    .catch((error) => {
      
    });
};
export const createReport = (params) => async (dispatch) => {
  
  dispatch(base.getRequest(REPORTS_CONST.CREATE_REPORTS_REQUEST));
  dispatch(startLoading())
  
  
loadingToast("Please wait...")
  return await netWorkService.addReport(params)
    .then((response) => {
      dispatch(stopLoading())
      toast.dismiss()
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        

        dispatch(
            base.getSuccess(REPORTS_CONST.CREATE_REPORTS_SUCCESS, "success")
          );

        setTimeout(()=> successToast("Report generation has been initiated.") , 980)
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(REPORTS_CONST.CREATE_REPORTS_FAILURE, responseJson)
          );
          setTimeout(()=> errorToast(responseJson?.errors?.[0]) , 980)
        });
      }
    })
    .catch((error) => {
      
    });
};
export const deleteReport = (params) => async (dispatch) => {
  
  dispatch(base.getRequest(REPORTS_CONST.DELETE_REPORTS_REQUEST));
  dispatch(startLoading())
  
  
loadingToast("Please wait...")
  return await netWorkService.deleteReport(params)
    .then((response) => {
      dispatch(stopLoading())
      toast.dismiss()
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        

        dispatch(
            base.getSuccess(REPORTS_CONST.DELETE_REPORTS_SUCCESS, "success")
          );

        setTimeout(()=> successToast("Report has been deleted successfully") , 980)
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(REPORTS_CONST.DELETE_REPORTS_FAILURE, responseJson)
          );
          setTimeout(()=> errorToast(responseJson?.errors?.[0]) , 980)
        });
      }
    })
    .catch((error) => {
      
    });
};
function getFileNameFromDisposition(disposition) { 
  const fileNameMatch = disposition.match(fileNameRegex); 
  return fileNameMatch ? fileNameMatch[1] : null; 
}
export const getGeneratedReport = (params , name) => async (dispatch) => {
  dispatch(base.getRequest(REPORTS_CONST.GET_GENERATED_REPORT_REQUEST));
  dispatch(startLoading());

  return await netWorkService.getGeneratedReport(params)
    .then(async (response) => {
      dispatch(stopLoading());

      if (response.status === 200 || response.ok === true) {
        
        // Convert response to blob for file download (Excel or ZIP file)
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        
        // Default file name for Excel
         
        let contentDisposition = response.headers.get('content-disposition')
        
        const fileName = getFileNameFromDisposition(contentDisposition)
        
        
        
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the blob URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        
      } 
      
    })
    .catch((error) => {
      dispatch(stopLoading());
      console.error("Error fetching report: ", error);
    });
};
export const getGeneratedPDFReport = (params ) => async (dispatch) => {
  dispatch(base.getRequest(REPORTS_CONST.GET_GENERATED_REPORT_REQUEST));
  dispatch(startLoading());

  return await netWorkService.getGeneratedPDFReport(params)
    .then(async (response) => {
      dispatch(stopLoading());

      if (response.status === 200 || response.ok === true) {
        
        // Convert response to blob for file download (PDF file)
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        
        
         
        let contentDisposition = response.headers.get('content-disposition')
        
        const fileName = getFileNameFromDisposition(contentDisposition)
        
        
        
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the blob URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        
      } 
      
    })
    .catch((error) => {
      dispatch(stopLoading());
      console.error("Error fetching report: ", error);
    });
};


export const getTemplates = (params) => async (dispatch) => {
  
  dispatch(base.getRequest(REPORTS_CONST.GET_TEMPLATES_REQUEST));
  dispatch(startLoading())
  
  let payload = {
    query:`
    query Templates {
    templates {
        nodes {
            id
            name
            description
            content
        }
    }
}
    `
  }
  
// loadingToast("Please wait...")
  return await netWorkService.getTemplate(payload)
    .then((response) => {
      dispatch(stopLoading())
      // toast.dismiss()
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
        response.json().then((responseJson) => {
        dispatch(
            base.getSuccess(REPORTS_CONST.GET_TEMPLATES_SUCCESS, responseJson)
          );
        })
        // setTimeout(()=> successToast("Password has been changed") , 980)
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(REPORTS_CONST.GET_TEMPLATES_FAILURE, responseJson)
          );
          setTimeout(()=> errorToast(responseJson?.errors?.[0]) , 980)
        });
      }
    })
    .catch((error) => {
      
    });
};




