
import NetworkService from "../../network/NetworkService";

import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  
  loadingToast,
} from "../../components/common/Toast";
import * as base from "./BaseActions";
import { startLoading, stopLoading } from "./LoaderAction"
import { PROFILE_CONST } from "./actionTypes/ProfileActionTypes";


export const changePassword = (params) => async (dispatch) => {
  
  dispatch(base.getRequest(PROFILE_CONST.CHANGE_PASSWORD_REQUEST));
  dispatch(startLoading())
  
  
loadingToast("Please wait...")
  return await NetworkService.changePassword(params)
    .then((response) => {
      dispatch(stopLoading())
      toast.dismiss()
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        

        dispatch(
            base.getSuccess(PROFILE_CONST.CHANGE_PASSWORD_SUCCESS, "success")
          );

        setTimeout(()=> successToast("Password has been changed") , 980)
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(PROFILE_CONST.CHANGE_PASSWORD_FAILURE, responseJson)
          );
          setTimeout(()=> errorToast(responseJson?.errors?.[0]) , 980)
        });
      }
    })
    .catch((error) => {
      
    });
};
export const getTimeZones = (params) => async (dispatch) => {
  
  dispatch(base.getRequest(PROFILE_CONST.GET_TIMEZONES_REQUEST));
  
  
  

  return await NetworkService.getTimezones(params)
    .then((response) => {
      
      
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
        response.json().then((responseJson) => {
        dispatch(
            base.getSuccess(PROFILE_CONST.GET_TIMEZONES_SUCCESS, responseJson)
          );
        })
        
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(PROFILE_CONST.GET_TIMEZONES_FAILURE, responseJson)
          );
          
        });
      }
    })
    .catch((error) => {
      
    });
};



