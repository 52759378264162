import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Box, Grid, InputAdornment } from "@mui/material";

//Custom MUI components
import PageTitle from "components/PageTitle";

import GridContainer from "components/GridContainer";
import { RenderTextInput } from "components/TextField";
import Table from "components/Table";

import ButtonComponent from "components/Button";

import Dialog from "components/Dialog";

import DrawerComponent from "components/Drawer";

import FilterSection from "./FilterSection";
import AddMemberFirm from "./AddMemberFirm";

import { MEMBER_FIRM_COUMNS } from "constants";

import { getCountries } from "store/actions/CountriesAction";

import ViewMemberFirm from "./ViewMemberFirm";
import {
  createMemberFirm,
  deleteMemberFirm,
  filterMemberFirmsList,
  getMemberByid,
  getMemberFirmsList,
  getUserByMemeberid,
  updateMemberFirm,
} from "store/actions/MemberFirmAction";
import {
  getClientList,
  getmemberfirmclients,
} from "store/actions/ClientAction";

const MemberFirmList = () => {
  //state variables declaration
  const [selectedClient, setSelectedClient] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [showMemberfirm, setShowAddMemberfirm] = useState(false);
  const [clientsList, setClientsList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [memberfirmList, setmemberfirmList] = useState([]);

  const [memberFirmDetails, setMemberFirmDetails] = useState([]);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");
  const [selectedTab, setSelectedTab] = useState("All");
  const [showViewmemberfirm, setshowViewMemebrfirm] = useState(false);
  const [searchMemberFirm, setSearchMemberFirm] = useState("");
  const [showEditMemberFirm, setShowEditMemberFirm] = useState(false);

  //extract specific pieces of state from the Redux store
  const statedata = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      clientListData: state?.fetchData?.clientListData,
      memberFirmList: state?.memberFirm?.memberFirmList,
      memberFirmByidData: state?.memberFirm?.memberFirmByidData,
      createdmemberData: state?.memberFirm?.createdmemberData,
      updatedmemberFirm: state?.memberFirm?.updatedmemberData,
      deletedmember: state?.memberFirm?.deletedmemberData,

      memberListBynameData: state?.memberFirm?.memberListBynameData,
      memberListByclientData: state?.memberFirm?.memberListByclientData,
    };
  });

  const dispatch = useDispatch();

  //Fetch data from Redux store on component mount
  useEffect(() => {
    dispatch(getCountries());

    let clientpayload = {
      filterName: "activeOnly",
      isDraft: false,
    };

    dispatch(getClientList(clientpayload));

    dispatch(getMemberFirmsList());

    setLoggedInUserDetails(JSON.parse(localStorage.getItem("user")));
  }, []);

  //Effect to update clientsList state when clientListData changes in Redux store
  useEffect(() => {
    if (statedata?.clientListData) {
      setClientsList(statedata?.clientListData?.data?.clients_List?.nodes);
    }
  }, [statedata?.clientListData]);

  //Effect to update countries state when countries changes in Redux store
  useEffect(() => {
    if (statedata?.countries) {
      setCountries(statedata?.countries);
    }
  }, [statedata?.countries]);

  useEffect(() => {
    if (statedata?.memberFirmByidData?.data?.members?.nodes) {
      let memberFirmdetails =
        statedata?.memberFirmByidData?.data?.members?.nodes?.map(
          (item) => {
            return {
              memberName: item.memberName,
              country: item.country?.country,
              street1: item?.street1,
              street2: item?.street2,
              zipcode: item?.zipcode,
              client: item?.clients ? item?.clients : [],
              members: item?.members ? item.members : [],
              countryCode: item?.country?.countryCode,
              countrId: item?.country?.id,
              base64EncodedLogo: item?.base64EncodedLogo,
              city: item.city,
              state: item.state,
              id: item.id,
              createdBy: item.createdBy,
              createdOn: item.createdOn,
            };
          }
        );

      setMemberFirmDetails(memberFirmdetails?.[0]);
    }
  }, [statedata?.memberFirmByidData?.data?.members?.nodes]);

  useEffect(() => {
    if (statedata?.memberFirmList?.data?.members?.nodes) {
      let memberFirmList = statedata?.memberFirmList?.data?.members?.nodes?.map(
        (item) => {
          return {
            country: item.country?.country,
            memberName: item?.memberName,
            id: item.id,
            adminCount: item?.adminCount,
            contributorCount: item?.contributorCount,
            clientCount: item?.clientCount,
            countryId: item?.country?.id,
          };
        }
      );

      setmemberfirmList(memberFirmList);
    }
  }, [statedata?.memberFirmList?.data?.members?.nodes]);
  useEffect(() => {
    if (statedata?.memberListBynameData) {
      let memberFirmList = statedata?.memberListBynameData?.map((item) => {
        return {
          country: item.country?.country,
          memberName: item?.memberName,
          id: item.id,
          adminCount: item?.adminCount,
          contributorCount: item?.contributorCount,
          clientCount: item?.clientCount,
          countryId: item?.country?.id,
        };
      });

      setmemberfirmList(memberFirmList);
    }
  }, [statedata?.memberListBynameData]);
  useEffect(() => {
    if (statedata?.memberListByclientData) {
      let memberFirmList = statedata?.memberListByclientData?.map(
        (item) => {
          return {
            country: item.country?.country,
            memberName: item?.memberName,
            id: item.id,
            adminCount: item?.adminCount,
            contributorCount: item?.contributorCount,
            clientCount: item?.clientCount,
            countryId: item?.country?.id,
          };
        }
      );

      setmemberfirmList(memberFirmList);
    }
  }, [statedata?.memberListByclientData]);

  useEffect(() => {
    if (
      statedata?.createdmemberData === "success" ||
      statedata?.updatedmemberFirm === "success" ||
      statedata?.deletedmember === "success"
    ) {
      dispatch(getMemberFirmsList());
      setShowAddMemberfirm(false);
      setShowEditMemberFirm(false);
      dispatch(createMemberFirm(""));
      dispatch(updateMemberFirm(""));
      setshowViewMemebrfirm(false);
      dispatch(deleteMemberFirm(""));
    }
  }, [
    statedata?.createdmemberData,
    statedata?.updatedmemberFirm,
    statedata?.deletedmember,
  ]);

  const handleSelectClient = useCallback((e) => {
    setSelectedClient(e);
    setSelectedCountry([]);
    setSearchMemberFirm("");

    if (e?.label !== "Select") {
      let payload = {
        clientId: e?.value ? e?.value : "",
        memberName: null,
        countryId: null,
      };
      dispatch(filterMemberFirmsList(payload));
    } else {
      dispatch(getMemberFirmsList());
    }
  }, []);

  const handleCountryChange = useCallback((e) => {
    setSelectedCountry(e);

    setSelectedClient("");

    setmemberfirmList([]);
    setSearchMemberFirm("");

    let payload = {
      clientId: "",
      memberName: null,
      countryId: e?.value ? e?.value : "",
    };
    dispatch(filterMemberFirmsList(payload));
  }, []);
  const handleTab = (e) => {
    setSelectedTab(e);
    setSelectedCountry("");

    setSearchMemberFirm("");

    setSelectedClient("");
    setmemberfirmList([]);

    dispatch(getMemberFirmsList());
  };

  const handleChangemember = (id) => {
    setshowViewMemebrfirm(true);

    setMemberFirmDetails("");

    dispatch(getMemberByid(id));
    dispatch(getUserByMemeberid({ id: id }));
    dispatch(getmemberfirmclients(id));
  };
  const memberFirmColumns = useMemo(() => MEMBER_FIRM_COUMNS, []);

  const searchMemeber = (e) => {
    setSearchMemberFirm(e);

    setSelectedCountry("");

    if (e?.length >= 1) {
      if (e?.length > 2) {
        let payload = {
          clientId: "",
          memberName: e ? e : "",
          countryId: null,
        };
        dispatch(filterMemberFirmsList(payload));
      }
    } else {
      dispatch(getMemberFirmsList());
    }
  };

  return (
    <Box id="main" className="main">
      <GridContainer sx={{ marginBottom: "30px !important" }}>
        <Grid xs={12} md={9} lg={9}>
          <PageTitle title={"Member Firm"} />
        </Grid>
        <Grid
          xs={12}
          md={3}
          lg={3}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {loggedInUserDetails?.extension_Role === "HqAdmin" && (
            <ButtonComponent
              variant={"contained"}
              color={"grey"}
              onClick={() => setShowAddMemberfirm(true)}
            >
              Create member firm
            </ButtonComponent>
          )}
        </Grid>

        {/* Add member firm */}
        {showMemberfirm && (
          <Dialog
            className="secondary-modal-show"
            maxWidth="false"
            maxHeight="150px"
            open={showMemberfirm}
            onClose={() => setShowAddMemberfirm(false)}
            showCloseIcon="false"
            sx={{ p: 0 }}
            body={
              <AddMemberFirm
                handleClose={() => setShowAddMemberfirm(false)}
                loggedInUserDetails={loggedInUserDetails}
              />
            }
          />
        )}
        {showEditMemberFirm && (
          <Dialog
            className="secondary-modal-show"
            maxWidth="false"
            maxHeight="150px"
            open={showEditMemberFirm}
            onClose={() => setShowEditMemberFirm(false)}
            showCloseIcon="false"
            sx={{ p: 0 }}
            body={
              <AddMemberFirm
                handleClose={() => setShowEditMemberFirm(false)}
                memberFirmDetails={memberFirmDetails}
                page="edit"
              />
            }
          />
        )}

        {/*View and edit member firm */}
        {showViewmemberfirm && (
          <DrawerComponent
            isOpen={showViewmemberfirm}
            handleClose={() => setshowViewMemebrfirm(false)}
            width={350}
            body={
              <ViewMemberFirm
                showViewAndEditUser={showViewmemberfirm}
                handleClose={() => setshowViewMemebrfirm(false)}
                memberFirmDetails={memberFirmDetails}
                handleEdit={() => {
                  setshowViewMemebrfirm(false);
                  setShowEditMemberFirm(true);
                }}
                loggedInUserDetails={loggedInUserDetails}
              />
            }
          />
        )}
      </GridContainer>

      <Box className="section client-dashboard">
        <GridContainer>
          <Grid xs={12} md={12} lg={12}>
            <Box className="dashboard-content">
              <Box className="dashboard-main-head row ms-0 me-0">
                <GridContainer
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    xs={12}
                    md={6}
                    lg={6}
                    className=" d-flex align-items-center"
                  >
                    <FilterSection
                      handleSelectClient={handleSelectClient}
                      handleCountryChange={handleCountryChange}
                      clientList={clientsList}
                      countries={countries}
                      selectedClient={selectedClient}
                      selectedCountry={selectedCountry}
                      selectedTab={selectedTab}
                      handleTab={(e) => handleTab(e)}
                      loggedInUserDetails={loggedInUserDetails}
                    />
                  </Grid>
                  <Grid xs="12" md="3" lg="3" sx={{ marginRight: "23px" }}>
                    <RenderTextInput
                      placeholder="Type to search firms"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M14 14L11.1 11.1M12.6667 7.33334C12.6667 10.2789 10.2789 12.6667 7.33334 12.6667C4.38782 12.6667 2 10.2789 2 7.33334C2 4.38782 4.38782 2 7.33334 2C10.2789 2 12.6667 4.38782 12.6667 7.33334Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => searchMemeber(e.target.value)}
                      value={searchMemberFirm}
                    />
                  </Grid>
                </GridContainer>
                <Box sx={{ padding: "0 !important" }}>
                  <Table
                    columns={memberFirmColumns}
                    rows={memberfirmList}
                    handleRow={handleChangemember}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>
    </Box>
  );
};
export default MemberFirmList;
