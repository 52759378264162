import { Box, Typography, Grid } from "@mui/material";

import { useEffect, useState } from "react";

import {
  dropDownOptions,
  policiesOptionsWithSelectAll,
  validateInput,
} from "../../constants";
import { customStyles } from "../../components/common/CustomStyle";
import {
  getClientList,
  getClientPolicies,
  setClientPolicies,
} from "../../store/actions/ClientAction";
import GridContainer from "../../components/GridContainer";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RenderTextInput } from "../../components/TextField";
import ButtonComponent from "../../components/Button";
import { createReport } from "../../store/actions/ReportsActions";
import { name } from "@azure/msal-browser/dist/packageMetadata";

const CreateReport = ({ handleClose }) => {
  const [selectedClient, setSelectedClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [policies, setPolicies] = useState([]);
  const [reportName, setReportName] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [error, setError] = useState({
    template: "",
    client: "",
    policy: "",

    reportName: "",
  });

  const statedata = useSelector((state) => {
    return {
      clientListData: state?.fetchData?.clientListData,

      policies: state?.fetchData?.clientPoliciesData,

      templates: state?.report?.templates,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    let payload = {
      filterName: "activeOnly",
      isDraft: false,
    };

    dispatch(getClientList(payload));

    return () => {
      dispatch(setClientPolicies(""));
    };
    
  }, []);

  useEffect(() => {
    if (selectedClient?.value) {
      let client = clientList?.filter(
        (item, key) => item.id === selectedClient?.value
      );
      let ClientProducingBroker = client?.[0]?.producingBroker?.id;

      let payload = {
        isSort: false,
        sortName: "",
        sortASC: "",
        isDraft: false,
      };

      dispatch(
        getClientPolicies(
          selectedClient?.value,
          ClientProducingBroker,
          true,
          payload
        )
      );
    } else if (selectedClient?.value === "") {
      setSelectedPolicy("");
      setPolicies([]);
      dispatch(setClientPolicies(""));
    }
  }, [selectedClient?.value]);

  useEffect(() => {
    if (statedata?.policies) {
      setPolicies(statedata?.policies?.data?.policiesForClient?.nodes);
    }
  }, [statedata?.policies]);

  useEffect(() => {
    if (statedata?.clientListData?.data?.clients_List?.nodes) {
      setClientList(statedata?.clientListData?.data?.clients_List?.nodes);
    }
  }, [statedata?.clientListData?.data?.clients_List?.nodes]);
  useEffect(() => {
    if (statedata?.templates?.data?.templates?.nodes) {
      setTemplates(statedata?.templates?.data?.templates?.nodes);
    }
  }, [statedata?.templates?.data?.templates?.nodes]);

  const validation = () => {
    let selectedpolicy = selectedPolicy
      ? selectedPolicy?.map((item, key) => item?.value)
      : [];

    const updatedErrors = {
      template:
        selectedTemplate !== ""
          ? selectedTemplate?.value !== ""
            ? ""
            : "Please Select Template"
          : "Please Select Template",
      reportName:
        reportName?.trim() !== ""
          ? validateInput(reportName) || ""
          : "Please enter report name",
      client:
        selectedClient !== ""
          ? selectedClient?.value !== ""
            ? ""
            : "Please select client"
          : "Please select client",
      policy: selectedpolicy?.length > 0 ? "" : "Please select policy",
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  const generateReport = () => {
    const isvalid = validation();
    let selectedClientvalue = dropDownOptions(clientList, "id", "clientName")
      ?.filter((item, key) => item?.value === selectedClient?.value)
      ?.map((client, key) => {
        return {
          id: client?.value,
          name: client?.label,
        };
      });
    let selectedpolicy = selectedPolicy?.map((item, key) => item?.value);
    let selectedPolicyArray = selectedpolicy
      .map((id) => {
        let policy = dropDownOptions(policies, "id", "policyNumber").find(
          (item) => item.value === id
        );
        return policy ? { id: policy.value, name: policy.label } : null;
      })
      .filter((item) => item !== null);
    let templateName = templates?.find(
      (item, key) => item?.id === selectedTemplate?.value
    );

    if (isvalid) {
      let payload = {
        name: reportName ? reportName : "",
        templateId: selectedTemplate?.value ? selectedTemplate?.value : "",
        clients: selectedClientvalue?.length > 0 ? selectedClientvalue : "",
        policies: selectedPolicyArray?.length > 0 ? selectedPolicyArray : [],
        templateName: templateName?.name,
      };

      dispatch(createReport(payload));
    }
  };

  const handleSelectClient = (e) => {
    setSelectedClient(e);

    setSelectedPolicy("");
  };

  const resetFieldError = (field, name) =>
    setError((prevState) => ({
      ...prevState,
      [field]: name ? validateInput(name) || "" : "",
    }));

  const handleChange = (selectedOptions) => {
    // Check if "Select All" was selected

    const isSelectAll = selectedOptions.find(
      (option) => option.value === "select-all"
    );

    if (isSelectAll) {
      // Select all options

      setSelectedPolicy(
        policiesOptionsWithSelectAll(policies)
          ?.map((policy) => policy)
          ?.filter((item) => item?.value !== "select-all")
      );
    } else {
      // Normal selection behavior

      setSelectedPolicy(selectedOptions);
    }
  };

  return (
    <Box style={{ padding: "12px 20px" }}>
      <Box>
        <Box
          className="sidepanel-header add-contact"
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--grey-200)",
            padding: "12px 20px",
          }}
        >
          <a
            href="javascript:void(0)"
            className="btn-close"
            onClick={() => handleClose()}
            style={{ marginRight: "5px", padding: "9px" }}
          ></a>

          <ButtonComponent
            variant={"contained"}
            color={"grey"}
            onClick={() => generateReport()}
          >
            Generate report
          </ButtonComponent>
        </Box>

        <Box className="sidepanel-body add-contact">
          <Box class="sidepanel-body add-contact">
            <Box class="contact-new-head">
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Create report
              </Typography>
            </Box>

            <GridContainer>
              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3"></Box>

                <Select
                  options={dropDownOptions(templates, "id", "name")}
                  placeholder="Template"
                  onChange={(e) => {
                    setSelectedTemplate(e);
                    resetFieldError("template");
                  }}
                  value={selectedTemplate}
                  styles={customStyles(error.template)}
                  className="users-country-select"
                  maxMenuHeight={300}
                />

                {error.template && (
                  <Box
                    className="error-msge mb-3"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.template}
                  </Box>
                )}
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3"></Box>

                <Select
                  options={dropDownOptions(clientList, "id", "clientName")}
                  placeholder="Client"
                  onChange={(e) => {
                    handleSelectClient(e);
                    resetFieldError("client");
                  }}
                  value={selectedClient}
                  styles={customStyles(error.client)}
                  className="users-country-select"
                  maxMenuHeight={300}
                />

                {error.client && (
                  <Box
                    className="error-msge mb-3"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.client}
                  </Box>
                )}
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3"></Box>

                <Select
                  options={policiesOptionsWithSelectAll(policies, true)}
                  placeholder="Policy"
                  onChange={(e) => {
                    handleChange(e);
                    resetFieldError("policy");
                  }}
                  value={selectedPolicy}
                  styles={customStyles(error.policy)}
                  className="users-country-select"
                  isMulti
                  maxMenuHeight={300}
                />

                {error.policy && (
                  <Box
                    className="error-msge mb-3"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.policy}
                  </Box>
                )}
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3"></Box>

                <RenderTextInput
                  placeholder={"Report name"}
                  onChange={(e) => {
                    setReportName(e.target.value);
                    resetFieldError("reportName", e?.target?.value);
                  }}
                  value={reportName}
                  errors={error.reportName}
                />
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateReport;
