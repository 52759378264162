export const PROFILE_CONST = {

    CHANGE_PASSWORD_REQUEST:"CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS:"CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE:"CHANGE_PASSWORD_FAILURE",

    GET_TIMEZONES_REQUEST:"GET_TIMEZONES_REQUEST",
    GET_TIMEZONES_SUCCESS:"GET_TIMEZONES_SUCCESS",
    GET_TIMEZONES_FAILURE:"GET_TIMEZONES_FAILURE",
    
}

