import React, { useEffect, useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import CloseButton from "../CloseButton";
import ButtonComponent from "../Button";
import { RenderTextInput } from "../TextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ChangePassword = (props) => {
  const [show, setShow] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setShow(props.showConfirmation);
  }, [props.showConfirmation]);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validatePassword = (password) => {
    const criteria = [
      /.{8,64}/,
      /[a-z]/,
      /[A-Z]/,
      /\d/,
      /[!@#$%^&*(),.?":{}|<>]/,
    ];

    const fulfilledCriteria = criteria.filter((criterion) =>
      criterion.test(password)
    ).length;

    return fulfilledCriteria >= 3;
  };

  const resetFieldError = (field, name) =>
    setError((prevState) => ({
      ...prevState,
      [field]: "",
    }));

  const handleConfirm = () => {
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      const updatedErrors = {
        oldPassword: oldPassword === "" ? "Please Enter Old Password" : "",
        newPassword: newPassword === "" ? "Please Enter New Password" : "",
        confirmPassword:
          confirmPassword === "" ? "Please Enter Confirm Password" : "",
      };

      setError(updatedErrors);
    } else {
      if (!validatePassword(newPassword)) {
        setError(() => {
          return {
            newPassword:
              "Password must be 8-64 characters and include at least 3 of the following: a lowercase letter, an uppercase letter, a digit, and a symbol",
          };
        });
        return;
      }

      if (newPassword !== confirmPassword) {
        setError(() => {
          return {
            confirmPassword: "New password and confirm password do not match",
          };
        });
        return;
      }

      let payload = {
        currentPassword: oldPassword,
        newPassword: newPassword,
      };

      props.handleConfirm(payload);
    }
  };

  return (
    <Box
      id="cancelModal"
      tabIndex="-1"
      aria-labelledby="cancelModalLabel"
      aria-hidden="true"
      className={`modal fade cancel-modal ${show ? "show" : ""}`}
      role="dialog"
      style={{ display: show ? "block" : "none" }}
    >
      <Box className="modal-dialog modal-dialog-centered">
        <Box className="modal-content">
          <Box className="modal-header">
            <CloseButton
              className="btn-close"
              onClick={() => props.handleCloseConfirmation()}
            />
          </Box>
          <Box className="modal-body">
            <Box
              className="icon-fill d-block ms-auto me-auto mt-2"
              sx={{ left: "5%" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8.00038 11.3334H8.00813M8.00038 6.00002V8.66669M6.86051 2.57335L1.21384 12C1.09741 12.2016 1.03581 12.4302 1.03516 12.663C1.03451 12.8958 1.09483 13.1248 1.21012 13.327C1.32541 13.5293 1.49165 13.6979 1.69231 13.8159C1.89296 13.934 2.12104 13.9975 2.35384 14H13.6472C13.88 13.9975 14.108 13.934 14.3087 13.8159C14.5094 13.6979 14.6756 13.5293 14.7909 13.327C14.9062 13.1248 14.9665 12.8958 14.9658 12.663C14.9652 12.4302 14.9036 12.2016 14.7872 12L9.14051 2.57335C9.02166 2.37742 8.85432 2.21543 8.65464 2.103C8.45495 1.99058 8.22966 1.93152 8.00051 1.93152C7.77135 1.93152 7.54606 1.99058 7.34637 2.103C7.14669 2.21543 6.97935 2.37742 6.86051 2.57335Z"
                  stroke="#F04438"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
            <Box className="cancel-policy">Change Password</Box>
            <Box sx={{ marginBottom: "10px" }}>
              <RenderTextInput
                value={oldPassword}
                label={"Old Password"}
                placeholder={"Old Password"}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  resetFieldError("oldPassword");
                }}
                type={showOldPassword ? "text" : "password"}
                errors={error.oldPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginLeft: "27px !important", cursor: "pointer" }}
                      onClick={() => handleClickShowOldPassword()}
                    >
                      {showOldPassword ? (
                        <Visibility sx={{ fontSize: "19px !important" }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: "19px !important" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <RenderTextInput
                value={newPassword}
                label={"New Password"}
                placeholder={"New Password"}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  resetFieldError("newPassword");
                }}
                type={showNewPassword ? "text" : "password"}
                errors={error.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginLeft: "27px !important", cursor: "pointer" }}
                      onClick={() => handleClickShowNewPassword()}
                    >
                      {showNewPassword ? (
                        <Visibility sx={{ fontSize: "19px !important" }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: "19px !important" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <RenderTextInput
                value={confirmPassword}
                label={"Confirm Password"}
                placeholder={"Confirm Password"}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  resetFieldError("confirmPassword");
                }}
                type={showConfirmPassword ? "text" : "password"}
                errors={error.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginLeft: "27px !important", cursor: "pointer" }}
                      onClick={() => handleClickShowConfirmPassword()}
                    >
                      {showConfirmPassword ? (
                        <Visibility sx={{ fontSize: "19px !important" }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: "19px !important" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box
              className="d-flex justify-content-between"
              sx={{ marginTop: "10px" }}
            >
              <ButtonComponent
                type="button"
                className="btn btn-secondary outline-btn btn-md"
                variant={"outlined"}
                color={"white"}
                sx={{ width: "50%" }}
                data-bs-dismiss="modal"
                onClick={() => props.handleCloseConfirmation()}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                type="button"
                className="btn btn-danger"
                variant={"contained"}
                color={"primary"}
                sx={{ width: "45%" }}
                onClick={handleConfirm}
              >
                Submit
              </ButtonComponent>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
