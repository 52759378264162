import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Textarea, customStyles } from "styles/CustomStyle";
import ButtonComponent from "./Button";
import Dropzone from "./Dropzone";
import Select from "react-select";
import CloseButton from "./CloseButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { dropDownOptions, validateDocument, validateInput } from "../constants";

const DocumentsUpload = ({
  handleFileChange,

  showprogress,
  progressbarpercentage,
  documentTypes,

  from,

  clientDetail,

  handleCloseDocumentUpload,
}) => {
  const [document, setDocument] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setvisibility] = useState("client");

  const [error, setError] = useState("");
  const [validationerror, setValidationError] = useState("");
  const [accessgroupid, setaccessgroupid] = useState(clientDetail?.id);
  const [anchorEl, setAnchorEl] = useState(null);

  const [fileType, setFileType] = useState("");
  const [selecteddocumentType, setSelectedDocumentType] = useState("");
  const [filename, setfilename] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    return () => handleDocumentType();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleremovefile = (item, index) => {
    setDocument([]);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFile = (event) => {
    setDocument(event);
    //   handleFileChange(event);
    fileUpload(event);
  };

  const resetFieldError = (field, name) =>
    setValidationError((prevState) => ({
      ...prevState,
      [field]: name ? validateInput(name) || "" : "",
    }));

  const handleDocumentType = (e) => {
    setSelectedDocumentType(e);
  };
  const isValid = () => {
    const updatedErrors = {
      selecteddocumentType:
        selecteddocumentType === "" ||
        selecteddocumentType === undefined ||
        selecteddocumentType?.value === ""
          ? "Please select document type"
          : "",
      selectedFiles:
        document === "" || document?.length === 0
          ? "Please upload document"
          : "",
      filename: filename === "" ? "Please enter file name" : "",
    };

    setValidationError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  const handlefilename = (e) => {
    setfilename(e);
  };
  const fileUpload = (event) => {
    const files = event;
    

    let popfiletype = files?.[0]?.path?.split(".")?.pop();
    let filetype = popfiletype ? popfiletype.toLowerCase() : popfiletype;

    setFileType(`${filetype}`);

    setfilename(files?.[0]?.name);
  };

  const handleToSubmitDocument = () => {
    let valid = isValid();
    if (valid) {
      handleFileChange(
        document,
        filename,
        fileType,
        accessgroupid,
        visibility,
        description,
        selecteddocumentType?.value
      );
    }
  };

  const handleChangeVisibility = (e) => {
    setvisibility(e.target.value);
    if (e.target.value === "client") {
      const id = clientDetail?.id;
      setaccessgroupid(id);
    } else if (e.target.value === "ProducingFirm") {
      
      let producingFirmid = clientDetail?.producingBrokerId;
      setaccessgroupid(producingFirmid);
    }
  };

  const validateFile = (file) => {
    let validate = validateDocument(file);
    if (validate === true) {
      setError("");
      return true;
    } else {
      setError(validate);
      return false;
    }
  };
  const removeFile = (file, index) => {
    handleremovefile(file, index);
    setDocument([]);
  };
  const inputRef = useRef(null);

  return (
    <>
      <Box className="form-container">
        <Box className="upload-files-container">
          <Box className="drag-file-area">
            <Typography
              variant="span"
              className="material-icons-outlined upload-icon"
            >
              <Box className="icon-fill d-block mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.66602 1.33325H3.99935C3.64573 1.33325 3.30659 1.47373 3.05654 1.72378C2.80649 1.97383 2.66602 2.31296 2.66602 2.66659V13.3333C2.66602 13.6869 2.80649 14.026 3.05654 14.2761C3.30659 14.5261 3.64573 14.6666 3.99935 14.6666H11.9994C12.353 14.6666 12.6921 14.5261 12.9422 14.2761C13.1922 14.026 13.3327 13.6869 13.3327 13.3333V5.99992M8.66602 1.33325L13.3327 5.99992M8.66602 1.33325L8.66613 5.99992L13.3327 5.99992"
                    stroke="#6941C6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </Box>
              <Box className="cancel-policy text-center MuiBox-root css-0">
                Document upload
              </Box>
              <Box className="cancel-subtext">
                These documents should be everything but the{" "}
                {from ? from?.toLowerCase() : ""} documents
              </Box>
            </Typography>
            <Typography className="upload-text text-start mb-1">
              Document type*
            </Typography>

            <Select
              className="text-start"
              sx={{ zIndex: "110" }}
              onChange={(e) => {
                handleDocumentType(e);
                resetFieldError("selecteddocumentType");
              }}
              options={dropDownOptions(
                documentTypes?.length > 0 ? documentTypes : [],
                "documentTypeId",
                "name"
              )}
              value={selecteddocumentType}
              styles={customStyles(validationerror.selecteddocumentType)}
            />
            {validationerror.selecteddocumentType && (
              <Typography
                className="error-msge"
                style={{
                  color: "#dc3545",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                {validationerror.selecteddocumentType}
              </Typography>
            )}
            <Typography className="upload-text text-start mt-2">
              Upload*
            </Typography>
            {document?.length === 0 ? (
              <Box className="document-upload-outer mb-3">
                <Dropzone
                  onFileUpload={handleFile}
                  validateFile={validateFile}
                />
              </Box>
            ) : (
              <>
                {document?.map((item, key) => {
                  return (
                    <Box
                      className="toast align-items-center d-block mb-3 mt-3"
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{ display: "flex", width: "100%" }}
                        className="d-flex"
                      >
                        <Grid
                          container
                          className="toast-body"
                          style={{
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Grid item xs={1}>
                            {" "}
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ width: "30px", height: "30px" }}
                            >
                              <path
                                d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                                fill="#E9D7FE"
                              />
                              <path
                                d="M20.667 13.3333H16.0003C15.6467 13.3333 15.3076 13.4738 15.0575 13.7238C14.8075 13.9739 14.667 14.313 14.667 14.6666V25.3333C14.667 25.6869 14.8075 26.0261 15.0575 26.2761C15.3076 26.5262 15.6467 26.6666 16.0003 26.6666H24.0003C24.354 26.6666 24.6931 26.5262 24.9431 26.2761C25.1932 26.0261 25.3337 25.6869 25.3337 25.3333V18M20.667 13.3333L25.3337 18M20.667 13.3333L20.6671 18L25.3337 18"
                                stroke="#6941C6"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Grid>
                          <Grid item xs={10}>
                            <input
                              type="text"
                              value={filename}
                              className="form-control upload-input-field"
                              placeholder="Typing"
                              disabled={
                                selectedValue === "edit"
                                  ? false
                                  : filename
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                handlefilename(e.target.value);
                              }}
                              onBlur={(e) => setSelectedValue("")}
                              style={{ backgroundColor: "#FFF" }}
                              ref={inputRef}
                            ></input>
                          </Grid>

                          <Grid item xs={1}>
                            {showprogress ? (
                              <CloseButton
                                onClick={() => removeFile(item, key)}
                              />
                            ) : (
                              <>
                                <IconButton
                                  aria-controls="vertical-menu"
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                  sx={{ zIndex: "0 !important" }}
                                >
                                  <MoreHorizIcon />
                                </IconButton>

                                <Menu
                                  id="vertical-menu"
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  getContentAnchorEl={null}
                                  sx={{
                                    "& .MuiPaper-root": {
                                      padding: "5px",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setSelectedValue("edit");

                                      setTimeout(() => {
                                        inputRef.current.focus();
                                      }, 20);
                                      handleClose();
                                    }}
                                    sx={{ fontSize: "13px !important" }}
                                  >
                                    Edit name
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setSelectedValue("delete");
                                      handleClose();
                                      removeFile(item, key);
                                    }}
                                    sx={{ fontSize: "13px !important" }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      {showprogress && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5%",
                              marginRight: "auto",
                              marginTop: 16,
                              width: "83%",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "lightgrey",
                                height: 8,
                                flex: "1",
                                borderRadius: "10px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#53389e",
                                  height: 8,
                                  width: `${progressbarpercentage}%`,
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                            <span
                              style={{ marginLeft: "5px", fontSize: "14px" }}
                            >
                              {progressbarpercentage}%
                            </span>
                          </div>
                        </>
                      )}
                    </Box>
                  );
                })}
              </>
            )}
            {validationerror.selectedFiles && (
              <Typography
                className="error-msge"
                style={{
                  color: "#dc3545",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                {validationerror.selectedFiles}
              </Typography>
            )}
            {validationerror.filename && !validationerror.selectedFiles && (
              <Typography
                className="error-msge"
                style={{
                  color: "#dc3545",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                {validationerror.filename}
              </Typography>
            )}
            <Typography
              sx={{
                color: "var(--color-text-critical) !important",
                textAlign: "left",
              }}
            >
              {error && <p>{error}</p>}
            </Typography>

            <Typography className="upload-text text-start mb-1">
              Visibility
            </Typography>
            <Box
              className="visibility-box text-start ps-2"
              sx={{ zIndex: "100" }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="client"
                  name="radio-buttons-group"
                  onChange={(e) => {
                    handleChangeVisibility(e);
                    resetFieldError("visibility");
                  }}
                >
                  <FormControlLabel
                    value="client"
                    control={<Radio sx={{ color: "#53389e !important" }} />}
                    label="All associated with this client"
                  />
                  <FormControlLabel
                    value="ProducingFirm"
                    control={<Radio sx={{ color: "#53389e !important" }} />}
                    label="producing broker and my firm only"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Typography className="upload-text text-start mb-1 mt-2">
              Notes
            </Typography>
            <Textarea
              className={`form-control w-100 document-textarea ${
                validationerror.description ? "is-invalid" : ""
              }`}
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Add any notes to support the document"
              onChange={(e) => {
                setDescription(e.target.value);
                resetFieldError("description", e.target.value);
              }}
            ></Textarea>

            {validationerror.description && (
              <Typography
                className="error-msge"
                style={{
                  color: "#dc3545",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                {validationerror.description}
              </Typography>
            )}

            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              className="d-flex justify-content-between mt-3"
            >
              <ButtonComponent
                type="button"
                className="btn btn-secondary outline-btn btn-md"
                variant={"outlined"}
                color={"white"}
                sx={{ width: "49%" }}
                onClick={() => handleCloseDocumentUpload()}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                type="button"
                className="btn btn-default btn-fill"
                variant={"contained"}
                color={"primary"}
                sx={{ width: "49%" }}
                onClick={() => handleToSubmitDocument()}
              >
                Confirm
              </ButtonComponent>
            </Box>
          </Box>
          <Typography className="cannot-upload-message">
            {" "}
            <Typography variant="span" className="material-icons-outlined">
              error
            </Typography>{" "}
            Please select a file first{" "}
            <Typography
              variant="span"
              className="material-icons-outlined cancel-alert-button"
            >
              cancel
            </Typography>{" "}
          </Typography>
          <Box className="file-block">
            <Box className="file-info">
              {" "}
              <Typography
                variant="span"
                className="material-icons-outlined file-icon"
              >
                description
              </Typography>{" "}
              <Typography variant="span" className="file-name">
                {" "}
              </Typography>{" "}
              |{" "}
              <Typography variant="span" className="file-size">
                {" "}
              </Typography>{" "}
            </Box>
            <Typography
              variant="span"
              className="material-icons remove-file-icon"
            >
              delete
            </Typography>
            <Box className="progress-bar"> </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DocumentsUpload;
