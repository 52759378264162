import { ACTION_TYPES } from "./ActionTypes";
import NetworkService from "../../network/NetworkService";

import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  
  loadingToast,
} from "../../components/common/Toast";
import { startLoading, stopLoading } from "./LoaderAction";

const getQueryParam = (params) => {
  switch (params.filterName) {
    case "Active":
      return `where: { isActive: { eq: true} }`;
    case "Drafts":
      return `where: { isDraft: { eq: true } }`;
    case "Archived":
      return `where: { isArchived: { eq: true } }`;
    case "Date modified":
      return `isFilterByDateModified: true`;
    case "All":
      return `where: { isArchived: { eq: false } }`;
    case "Policy exp.date":
      return `where: { isArchived: { eq: false } isDraft: { eq: false }} isFilterByPolicyExpirationDate: true`;
    case "map":
    case "calendar":
    case "activeOnly":
      
      return `where: {isDraft : { eq: ${params?.isDraft} } }`;
    default:
      return "";
  }
};

export const ClientData = (params) => (dispatch) => {
  loadingToast("Adding client...");
  dispatch(startLoading());

  return NetworkService.addClient(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          localStorage.setItem("clientId", responseJson?.data?.id);
          dispatch(setClientData(responseJson));
          setTimeout(
            () => successToast("Client has been added successfully"),
            980
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(setClientDataFailed(responseJson));
          // errorToast(responseJson);
          setTimeout(() => errorToast(responseJson), 980);
        });
      }
    })
    .catch((error) => {
      setTimeout(
        () => errorToast("Failed to create client Please try again later"),
        980
      );
    });
};

export const setClientData = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_DATA,
  payload,
});
export const setClientDataFailed = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_DATA_FAILED,
  payload,
});
export const getClient = (params) => (dispatch) => {
  return NetworkService.getClient(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientList(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setClientList = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_LIST,
  payload,
});
export const getClientDetails = (id) => (dispatch) => {
  let query = {
    query: `
    query ClientsDetails($id: UUID!) {
      clientsDetails(clientId: $id) {
        id
        status
        modifiedOn
        country
        contactName
        clientPhone
        clientEmail
        totalCost
        totalCommission
        totalHeadCount
        clientName
        isDraft
        isArchived
        locationNumber
        locationName
        headcount
        street1
        street2
        city
        state
        zipcode
        county
        producingBrokerId
        countryId
        createdOn
        createdBy
    }
    }
    `,
    variables: {
      id: id,
    },
  };

  // loadingToast("Please wait a moment...");
  dispatch(startLoading());

  return NetworkService.clientDetails(query)
    .then((response) => {
      toast.dismiss();

      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientDetails(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setClientDetails = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_DETAILS,
  payload,
});

export const getPolicyDetails = (id) => (dispatch) => {
  let query = {
    query: `
    query PolicyDetails {
      policyDetails(policyId: "${id}") {
          id
          isDraft
          policyNumber
          isLocallyAdmittedCoverage
          namedInsured
          coverageTypeId
          isAutoRenewal
          coverageTypeName
          exclusions
          policyExclusions {
            id
            exclusion
        }
          isActive
          createdOn
          createdBy
          isRenewPolicyButtonVisible
          createdUserMemberFirmId
          comments
          policyType {
              id
              policyType
          }
          country {
              id
              country
              region
          }
          insurer {
              id
              code
              description
          }
          
          
          language {
              id
              language
          }
          currency {
              id
              currency
              symbol
          }
          policyPeriodDetail {
              id
              term
              inceptionDate
              expirationDate
              isRenewalBegun
              isRenewReminderSet
              renewalStatusId
              isPolicyCancelled
              cancellationDate
              cancellationReasonsId
              cancellationReasonOther
              totalEstimatedAnnualPremiumExcludingChargesAndTaxes
              premiumPaymentFrequency
              premiumPaymentDate
              commissionRate
              commissionPaymentFrequency
              totalEstimatedAnnualCommission
              charges
              taxes
              totalPremium
              additionalBrokerFees
              renewalStatus {
                  id
                  status
                  color
              }
              cancellationReasons {
                  id
                  reason
              }
          }
          producingBroker {
            id
            memberName
            memberDisplayName
            street1
            street2
            city
            state
            zipcode
            county
            country{
              id
              country
            }
            countryId
            website
            logoBlobKey
            logoFileExtension
            isActive
            isDeleted
            createdOn
            modifiedOn
            createdBy
            modifiedBy
        }
        servicingBroker {
            id
            memberName
            brokerType
            country
            email
            countryId
            number
            memberDisplayName
        },

            userName
            userEmail

      }
  }
  
                  `,
    variables: {
      id: id,
    },
  };

  dispatch(startLoading());

  return NetworkService.clientDetails(query)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setPolicyDetails(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setPolicyDetails = (payload) => ({
  type: ACTION_TYPES.SET_POLICY_DETAILS,
  payload,
});
export const getClientPolicies =
  (id, ClientProducingBroker, order, payload) => (dispatch) => {
    // let orderBy = order === true ? `order: {policyNumber: ASC}`:""
    const clientProducingBrokerQuery = ClientProducingBroker
    ? `clientProducingBroker: "${ClientProducingBroker}"`
    : '';
    let orderBy =
    payload.isSort === true
        ? `order: {${payload?.sortName}: ${payload?.sortASC}}`
        : `order: {policyNumber: ASC}`;
    let isDraft =
    payload?.isDraft===false
        ? `where: { isDraft: { eq: ${payload?.isDraft} } }`
        : ``;

    let query = {
      query: `
    query PoliciesForClient {
      policiesForClient(
          clientId: "${id}"
          ${clientProducingBrokerQuery}
          ${orderBy}
          ${isDraft}
      ) {
          nodes {
              id
              policyNumber
              coverageType
              startExpiryDate
              country
              countryId
              
              isDraft
              renewalStatus {
                id
                status
                color
            }
            isActive
            
          }
      }
  }
  
                  `,
    };
    return NetworkService.clientDetails(query)
      .then((response) => {
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          response.json().then((responseJson) => {
            dispatch(setClientPolicies(responseJson));
          });
        }
      })
      .catch((error) => {});
  };

export const setClientPolicies = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_POLICIES,
  payload,
});
export const AddNewPolicy = (params) => (dispatch) => {
  loadingToast("Adding policy...");
  dispatch(startLoading());

  return NetworkService.addPolicy(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setPolicyData(responseJson));
          // successToast("Policy being added");
          setTimeout(() => successToast("Policy being added"), 980);
        });
      } else {

        if (
          response.status === 400
        ) {
          response.json().then((responseJson) => {
            
          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980)
          
          })
        }
        
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to add Policy Please try again later"),
        980
      );
    });
};

export const setPolicyData = (payload) => ({
  type: ACTION_TYPES.ADD_NEW_POLICY,
  payload,
});
export const getMember = () => (dispatch) => {
  return NetworkService.Member()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setMemberData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setMemberData = (payload) => ({
  type: ACTION_TYPES.GET_MEMBERS_DATA,
  payload,
});
export const GetGetCoverageTypes = () => (dispatch) => {
  return NetworkService.Coverage()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setCoverageTypes(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setCoverageTypes = (payload) => ({
  type: ACTION_TYPES.SET_COVERAGE_TYPES,
  payload,
});
export const GetGetCoverageTypesById = (id) => (dispatch) => {
  return NetworkService.getByidCoverage(id)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setCoverageTypesById(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setCoverageTypesById = (payload) => ({
  type: ACTION_TYPES.SET_COVERAGE_TYPE_BY_ID_DATA,
  payload,
});
export const addFieldData = (id) => (dispatch) => {
  return NetworkService.CoverageData(id)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson)=> {
        //   dispatch(setFiledData(responseJson))
        // })
        dispatch(setFiledData("success"));
      }
    })
    .catch((error) => {});
};

const setFiledData = (payload) => ({
  type: ACTION_TYPES.SET_FIELD_DATA,
  payload,
});
export const updateFieldData = (id) => (dispatch) => {
  return NetworkService.updateCoverageData(id)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson)=> {
        //   dispatch(setFiledData(responseJson))
        // })
        dispatch(setupdateFiledData("success"));
      }
    })
    .catch((error) => {});
};

const setupdateFiledData = (payload) => ({
  type: ACTION_TYPES.SET_UPDATE_FIELD_DATA,
  payload,
});
export const GetCurrencies = () => (dispatch) => {
  return NetworkService.GetCurrencies()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setCurrencies(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setCurrencies = (payload) => ({
  type: ACTION_TYPES.SET_CURRENCIES_DATA,
  payload,
});
export const GetLanguages = () => (dispatch) => {
  return NetworkService.GetLanguages()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setLanguages(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setLanguages = (payload) => ({
  type: ACTION_TYPES.SET_LANGUAGES_DATA,
  payload,
});
export const GetPolicyTypes = () => (dispatch) => {
  return NetworkService.GetPolicyTypes()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setPolicyTypes(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setPolicyTypes = (payload) => ({
  type: ACTION_TYPES.SET_POLICY_TYPES_DATA,
  payload,
});
export const Insurer = () => (dispatch) => {
  return NetworkService.Insurer()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setInsureData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

const setInsureData = (payload) => ({
  type: ACTION_TYPES.SET_INSURE_DATA,
  payload,
});
export const Coverage = () => (dispatch) => {
  return NetworkService.Coverage()
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setCoverageData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setCoverageData = (payload) => ({
  type: ACTION_TYPES.SET_COVERAGE_DATA,
  payload,
});
export const CancelPolicy = (params) => (dispatch) => {
  loadingToast("Canceling policy...");
  dispatch(startLoading());

  return NetworkService.CancelPolicy(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 204 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setCancelPolicyData("success"));
        // successToast("Policy has been canceled Successfully");
        setTimeout(
          () => successToast("Policy has been canceled Successfully"),
          980
        );
      }
    })
    .catch((error) => {
      setTimeout(
        () => errorToast("Failed to cancel policy Please try again later"),
        980
      );
    });
};

export const setCancelPolicyData = (payload) => ({
  type: ACTION_TYPES.SET_CANCEL_POLICY_DATA,
  payload,
});
export const RenewalPolicy = (params) => (dispatch) => {
  loadingToast("Renewing policy...");
  dispatch(startLoading());

  return NetworkService.RenewalPolicy(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 204 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setRenewalPolicyData("success"));
        // successToast("Policy successfully renewed");
        setTimeout(() => successToast("Policy successfully renewed"), 980);
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to renewal Policy Please try again later"),
        980
      );
    });
};

export const setRenewalPolicyData = (payload) => ({
  type: ACTION_TYPES.SET_RENEWAL_POLICY_DATA,
  payload,
});
export const RevokeCancellation = (params) => (dispatch) => {
  loadingToast("Please wait a moment...");
  dispatch(startLoading());

  return NetworkService.RevokeCancellation(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 204 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setRevokeCancellationData("success"));
      }
    })
    .catch((error) => {
      // console.log(error);
      setTimeout(
        () => successToast("Failed to revoke Policy Please try again later"),
        980
      );
    });
};

export const setRevokeCancellationData = (payload) => ({
  type: ACTION_TYPES.SET_REVOKE_CANCELLATION_DATA,
  payload,
});
export const getCoverageDetailsData = (params) => (dispatch) => {
  return NetworkService.getCoverageDetailsData(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setCoverageDetailsData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setCoverageDetailsData = (payload) => ({
  type: ACTION_TYPES.SET_COVERAGE_DETAILS_DATA,
  payload,
});

export const getClientList = (params) => async (dispatch) => {
  // let orderBy = order === true ? `(order:{clientName:ASC})` :""
  let sort = params?.isSort
    ? `order: {${params.sortName}: ${params.sortBy}}`
    : (params?.filterName === "Date modified" || params?.filterName ==="Policy exp.date")?"":"order: {clientName: ASC}";
  let queryparam = getQueryParam(params);
  let payload = {
    query: `
    query Clients_List {
      clients_List (${queryparam} ${sort} first: 300) {
        nodes {
          id
          isActive
          isDraft
          isArchived
          dateModified
          clientName
          policiesCount
          countryId
          country
          region
          nextExpiringPolicyDate
          policyStatus
          openTasks
          producingBroker {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              county
              countryId
          }
      }
  }
}   `,
  };

  if (params.from === "clientsList") {
    dispatch(startLoading());
  }

  return await NetworkService.clientGraphql(payload)
    .then((response) => {
      if (params.from === "clientsList") {
        dispatch(stopLoading());
      }

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // dispatch(setCoverageDetailsData("success"))
        response.json().then((responseJson) => {
          dispatch(setClientListData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setClientListData = (payload) => ({
  type: ACTION_TYPES.GET_CLIENT_LIST,
  payload,
});
export const getClientLocation = (id) => (dispatch) => {
  let payload = {
    query: `
    query LocationsForClient($id: UUID!) {
      locationsForClient(clientId: $id) {
        locationName
        locationNumber
        country
        headCount
        address
        totalCost
      }
    }   `,
    variables: {
      id: id,
    },
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientLocationData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setClientLocationData = (payload) => ({
  type: ACTION_TYPES.GET_CLIENT_LOCATION,
  payload,
});
export const getClientLocationDetails = (id) => (dispatch) => {
  let payload = {
    query: `
    query LocationDetails {
      locationDetails(locationId: "${id}") {
          id
          isHQ
          locationHierarchy
          locationNumber
          locationName
          description
          activeDate
          inactiveDate
          elevation
          latitude
          longitude
          street1
          street2
          city
          state
          zipcode
          county
          countryId
          country
          region
          countryCode
          createdUserMemberFirmId
          headcount
          clientId
          isActive
          isDeleted
          createdOn
          createdBy
      }
  }  `,
  };

  dispatch(startLoading());

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientLocationdetailsData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setClientLocationdetailsData = (payload) => ({
  type: ACTION_TYPES.GET_CLIENT_LOCATION_DETAILS,
  payload,
});
export const addLocation = (payload) => (dispatch) => {
  loadingToast("Adding location...");
  dispatch(startLoading());

  return NetworkService.addLocation(payload)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setaddlocation(responseJson));
          setTimeout(
            () => successToast("Location has been added successfully"),
            980
          );
        });
      } else {
        if(response.status === 400) {
          response.json().then((responseJson) => {
            
            setTimeout(
              () => errorToast(responseJson?.errors?.[0]),
              980
            );
          });
        }
        else {
        setTimeout(
          () => errorToast("Failed to add location. Please try agin later."),
          980
        );
      }
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to add location Please try again later"),
        980
      );
    });
};

export const setaddlocation = (payload) => ({
  type: ACTION_TYPES.SET_ADD_LOCATION,
  payload,
});
export const deleteLocation = (payload) => (dispatch) => {
  loadingToast("Deleting location...");
  dispatch(startLoading());

  return NetworkService.deleteLocation(payload)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 204 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setdeleteLocation("success"));
        setTimeout(() => successToast("Location deleted successfully"), 980);
      } else {
        setTimeout(
          () =>
            errorToast("Failed to delete location. Please try again later."),
          980
        );
      }
    })
    .catch((error) => {});
};

export const setdeleteLocation = (payload) => ({
  type: ACTION_TYPES.SET_DELETED_LOCATION,
  payload,
});
export const updateLocation = (payload) => (dispatch) => {
  loadingToast("Updating location...");
  dispatch(startLoading());

  return NetworkService.updateLocation(payload)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setupdateLocation("success"));
        setTimeout(() => successToast("Location updated successfully"), 980);
      } else {
        if(response.status === 400) {
          response.json().then((responseJson) => {
            
            setTimeout(
              () => errorToast(responseJson?.errors?.[0]),
              980
            );
          });
        }
        else {
        setTimeout(
          () =>
            errorToast("Failed to update location. Please try again later."),
          980
        );
      }

        // dispatch(setupdateLocation("error"));
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to update location Please try again later"),
        980
      );
    });
};

export const setupdateLocation = (payload) => ({
  type: ACTION_TYPES.SET_UPDATE_LOCATION,
  payload,
});
export const getmemberfirmclients = (id) => (dispatch) => {
  let payload = {
    query: `query Clients {
    clients(
         brokerId:  "${id}" 
    ) {
      nodes {
        id
        clientName
        isDraft
        isArchived
        contactName
        clientPhone
        clientEmail
        totalCost
        totalCommission
        totalHeadCount
        locationNumber
        locationName
        headcount
        street1
        street2
        city
        state
        zipcode
        county
        producingBrokerId
        countryId
        isActive
        isDeleted
        createdOn
        modifiedOn
        createdBy
        modifiedBy
    }
    }
}
  `,
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setmemeberfirmclient(responseJson));
        });
      }
    })
    .catch((error) => {});
};
export const getclientLocations = (id, ClientProducingBroker) => (dispatch) => {
  const clientProducingBrokerQuery = ClientProducingBroker
    ? `clientProducingBroker: "${ClientProducingBroker}"`
    : '';
  let payload = {
    query: `
    query LocationsForClient {
      locationsForClient(
          clientId: "${id}"
          ${clientProducingBrokerQuery}
      ) {
          nodes {
              id
              clientId
              locationName
              locationNumber
              country
              headCount
              address
              isHQ
              street1
              street2
              city
              state
              zipcode
              totalCost
              locationHierarchy
              description
              activeDate
              inactiveDate
              elevation
              latitude
              longitude
              countryId
              county
          }
          pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
          }
      }
  }
  
   `,
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientLocations(responseJson));
        });
      }
    })
    .catch((error) => {});
};
export const setClientLocations = (payload) => ({
  type: ACTION_TYPES.GET_CLIENT_LOCATIONS,
  payload,
});

export const getclientMember = (id) => (dispatch) => {
  let payload = {
    query: `
    query BrokersForClient($id: UUID!) {
      brokersForClient(clientId: $id) {
        nodes{
        memberName
        brokerType
        id
        country
        email
        memberDisplayName
        number
        countryId
        }
      }
    }  `,
    variables: {
      id: id,
    },
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientMember(responseJson));
        });
      }
    })
    .catch((error) => {});
};
export const setClientMember = (payload) => ({
  type: ACTION_TYPES.GET_CLIENT_MEMBER,
  payload,
});
export const updatePolicy = (params) => (dispatch) => {
  loadingToast("Updating policy...");
  dispatch(startLoading());

  return NetworkService.updatePolicy(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setUpdatedPolicyData("success"));
        setTimeout(() => successToast("Policy updated successfully"), 980);
      }
      else {
        if (
          response.status === 400
        ) {
        response.json().then((responseJson) => {
          
          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980)
          
        })
      }
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to update location Please try again later"),
        980
      );
    });
};
export const setUpdatedPolicyData = (payload) => ({
  type: ACTION_TYPES.UPDATED_POLICY_DATA,
  payload,
});
export const getClientDetailsByid = (params) => (dispatch) => {
  let payload = {
    query: `
    query Clients {
      clients(where: { id: { eq: "${params}" } }) {
          nodes {
              id
              isDraft
              isArchived
              clientName
              locationNumber
              locationName
              headcount
              street1
              street2
              city
              state
              zipcode
              county
              producingBrokerId
              countryId
              isActive
              isDeleted
              createdOn
              modifiedOn
              createdBy
              modifiedBy
              country {
                id
                country
                region
                countryCode
              }
        }
    }
}
    `,
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setClientDetailsByid(responseJson));
        });
      }
    })
    .catch((error) => {});
};
export const setClientDetailsByid = (payload) => ({
  type: ACTION_TYPES.GET_CLIENT_DETAILS_BY_ID,
  payload,
});
export const getPolicyDetailsByid = (params) => (dispatch) => {
  let payload = {
    query: `
    query Policies {
      policies(where: { id: { eq: "${params}" } }) {
          nodes {
              id
              isDraft
              policyNumber
              policyTypeId
              countryId
              coverageTypeId
              coverageTypeName
              isLocallyAdmittedCoverage
              insurerId
              namedInsured
              producingBrokerId
              languageId
              currencyId
              isAutoRenewal
              exclusions
              policyExclusions {
            id
            exclusion
        }
              comments
              policyPeriodId
              isActive
              isDeleted
              createdOn
              modifiedOn
              createdBy
              modifiedBy
              country {
                  id
                  country
                  region
                  countryCode
              }
              policyType {
                  id
                  policyType
              }
              insurer {
                  id
                  code
                  description
                  isActive
                  isDeleted
                  createdOn
                  modifiedOn
                  createdBy
                  modifiedBy
              }
              currency {
                  id
                  currency
              }
              language {
                  id
                  language
              }
              policyPeriod {
                  id
                  inceptionDate
                  expirationDate
                  isRenewalBegun
                  isRenewReminderSet
                  renewalStatusId
                  isPolicyCancelled
                  cancellationDate
                  cancellationReasonsId
                  cancellationReasonOther
                  totalEstimatedAnnualPremiumExcludingChargesAndTaxes
                  premiumPaymentFrequency
                  premiumPaymentDate
                  commissionRate
                  commissionPaymentFrequency
                  totalEstimatedAnnualCommission
                  charges
                  taxes
                  totalPremium
                  additionalBrokerFees
                  isActive
                  isDeleted
                  createdOn
                  modifiedOn
                  createdBy
                  modifiedBy
                  renewalStatus {
                      id
                      status
                  }
              }
          }
      }
  }
    `,
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setPolicyDetailsByid(responseJson));
        });
      }
    })
    .catch((error) => {});
};
export const setPolicyDetailsByid = (payload) => ({
  type: ACTION_TYPES.GET_POLICY_DETAILS_BY_ID,
  payload,
});
export const updateClient = (params) => (dispatch) => {
  loadingToast("Updating client...");

  dispatch(startLoading());
  return (
    NetworkService.updateClient(params)
      .then((response) => {
        toast.dismiss();
        dispatch(stopLoading());
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          dispatch(setupdatedClientData("success"));
          setTimeout(
            () => successToast("Client has been updated successfully"),
            980
          );
        } else {
          response.json().then((responseJson) => {
            setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
            dispatch(setupdatedClientData("error"));
          });
        }
        // });
      })
      // })
      .catch((error) => {
        setTimeout(
          () => successToast("Failed to update client Please try again later"),
          980
        );
      })
  );
  // })}
};
export const setupdatedClientData = (payload) => ({
  type: ACTION_TYPES.UPDATE_CLIENT_SUCCESS,
  payload,
});

export const setmemeberfirmclient = (payload) => ({
  type: ACTION_TYPES.SET_MEMBER_FIRM_CLIENT,
  payload,
});
export const searchClients = (params) => (dispatch) => {
  let queryparam = getQueryParam(params);
  let payload = {
    query: `query Clients_List {
    clients_List(clientName: "${params?.clientName}" ${queryparam}) {
      nodes {
        id
        isActive
        isDraft
        isArchived
        dateModified
        clientName
        policiesCount
        countryId
        country
        region
        nextExpiringPolicyDate
        policyStatus
        openTasks
        producingBroker {
            id
            memberName
            memberDisplayName
            street1
            street2
            city
            state
            zipcode
            county
            countryId
        }
    }
    }
}


  `,
  };
  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setClientListData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const deleteDocument = (id, category, params, from) => (dispatch) => {
  if (from !== "edit") {
    loadingToast("Deleting document...");
    dispatch(startLoading());
  }
  return NetworkService.DeleteDocument(id, category, params)
    .then((response) => {
      toast.dismiss();
      if (from !== "edit") {
        dispatch(stopLoading());
      }
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson) => {
        dispatch(setDeletedDocumentData(`success-${category}`));
        if (from !== "edit") {
          setTimeout(
            () => successToast("Document has been deleted successfully"),
            980
          );
        }

        // })
      } else {
        response?.json().then((responseJson) => {
          if (from !== "edit") {
            setTimeout(() => errorToast(responseJson), 980);
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
      if (from !== "edit") {
        setTimeout(
          () =>
            successToast("Failed to delete document Please try again later"),
          980
        );
      }
    });
};
export const setDeletedDocumentData = (payload) => ({
  type: ACTION_TYPES.DELETE_DOCUMENT_SUCCESS,
  payload,
});
export const deletePolicy = (id) => (dispatch) => {
  loadingToast("Deleting policy...");

  dispatch(startLoading());
  return NetworkService.DeletePolicy(id)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson) => {
        dispatch(setDeletedPolicyData("success"));
        setTimeout(
          () => successToast("Policy has been deleted successfully"),
          980
        );

        // })
      } else {
        response?.json().then((responseJson) => {
          setTimeout(() => errorToast(responseJson), 980);
        });
      }
    })
    .catch((error) => {});
};
export const setDeletedPolicyData = (payload) => ({
  type: ACTION_TYPES.DELETE_POLICY_SUCCESS,
  payload,
});

export const archiveMultipleClients = (params) => (dispatch) => {
  loadingToast(
    params?.Archive === true
      ? "Achieving selected clients..."
      : "Unachieving selected clients..."
  );
  return NetworkService.ArchiveClients(params)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setArchiveMutipleClientsData("success"));
        setTimeout(
          () =>
            successToast(
              params?.Archive === true
                ? "Selected clients achieved"
                : "Selected clients unachieved"
            ),
          980
        );
      } else {
        response?.json().then((responseJson) => {
          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {});
};

export const setArchiveMutipleClientsData = (payload) => ({
  type: ACTION_TYPES.ARCHIVE_MULTIPLE_CLIENTS,
  payload,
});
export const getBrokerForPolicy = (params) => (dispatch) => {
  let payload = {
    query: `
    query BrokersForPolicy {
      brokersForPolicy(policyId: "${params}") {
        nodes{
          id
          memberName
          brokerType
          country
          email
          memberDisplayName
          number
          countryId
        }
      }
  }
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setBrokerForPolicyData(responseJson));
        });
      } else {
        response?.json().then((responseJson) => {});
      }
    })
    .catch((error) => {});
};

export const setBrokerForPolicyData = (payload) => ({
  type: ACTION_TYPES.SET_BROKER_FOR_POLICY,
  payload,
});
export const getDocumentsForPolicy =
  (header, params, policypayload) => (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
      UserId: header.UserId,
      ClientId: header.ClientId,
      ProducingFirmId: header.ProducingFirmId,
    };

    let sort =
      policypayload.isSort === true
        ? `order: {${policypayload.sortName}: ${policypayload.sortBy}}`
        : "";
    let payload = {
      query: `
    query DocumentsByPolicy {
      documentsByPolicy(policyId: "${params}" ${sort}) {
          nodes {
              documentId
              documentTypeId
              documentTypeName
              documentName
              documentDisplayName
              description
              fileFormat
              isArchived
              visibility
              uploadDate
              accessGroup
          }
      }
  }
  
    `,
    };

    return NetworkService.documentsForPolicy(payload, headers)
      .then((response) => {
        toast.dismiss();
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          response?.json().then((responseJson) => {
            dispatch(setDocumentsPolicyData(responseJson));
          });
        } else {
          response?.json().then((responseJson) => {});
        }
      })
      .catch((error) => {});
  };

export const setDocumentsPolicyData = (payload) => ({
  type: ACTION_TYPES.SET_DOCUMENTS_FOR_POLICY,
  payload,
});
export const getClientMapData = (params, country, region) => (dispatch) => {
  let queryparam =
    region === null ? `region: ${region}` : `region: "${region}"`;

  let payload = {
    query: `
    query Clients_Map_Country_TotalHeadcount {
      clients_Map_Country_TotalHeadcount(
          clientId: "${params}"
          countryId: ${country}
          ${queryparam}
      ) {
          country
          totalHeadcountByCountry
      }
  }   
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setClientMapData(responseJson));
        });
      } else {
        response?.json().then((responseJson) => {});
      }
    })
    .catch((error) => {});
};

export const setClientMapData = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_MAP_DATA,
  payload,
});
export const getClientCalendarData = (params) => (dispatch) => {
  let payload = {
    query: `
    query Clients_Calendar {
      clients_Calendar(clientId: "${params}") {
          policyNumber
        policies {
            clientId
            policyId
            isActive
            policyNumber
            policyStatus
            statusColor
            startExpiryDate
            coverageType
            inceptionDate
            expirationDate
        }
      }
  }  
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setCalendarByClientData(responseJson));
        });
      } else {
        response?.json().then((responseJson) => {});
      }
    })
    .catch((error) => {});
};

export const setCalendarByClientData = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_CALENDAR_DATA,
  payload,
});
export const getClientTotalCostData =
  (params, country, region) => (dispatch) => {
    let queryparam =
      region === null ? `region: ${region}` : `region: "${region}"`;

    let payload = {
      query: `
    query Clients_Map_Country_TotalPremium {
      clients_Map_Country_TotalPremium(
          clientId: "${params}"
          countryId: ${country}
          ${queryparam}
      ) {
          country
          totalPremiumByCountry
      }
  }
    `,
    };

    return NetworkService.clientGraphql(payload)
      .then((response) => {
        toast.dismiss();
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          response?.json().then((responseJson) => {
            dispatch(setClientTotalCostData(responseJson));
          });
        } else {
          response?.json().then((responseJson) => {});
        }
      })
      .catch((error) => {});
  };

export const setClientTotalCostData = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_TOTAL_COST_DATA,
  payload,
});
export const getClientRenewalStatusData =
  (params, country, region) => (dispatch) => {
    let queryparam =
      region === null ? `region: ${region}` : `region: "${region}"`;
    let payload = {
      query: `
    query Clients_Map_Country_RenewalStatus {
      clients_Map_Country_RenewalStatus(
          clientId: "${params}"
          countryId: ${country}
          ${queryparam}
      ) {
          country
          renewalStatus
          color
      }
  }
    `,
    };

    return NetworkService.clientGraphql(payload)
      .then((response) => {
        toast.dismiss();
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          response?.json().then((responseJson) => {
            dispatch(setClientRenewalStatusData(responseJson));
          });
        } else {
          response?.json().then((responseJson) => {});
        }
      })
      .catch((error) => {});
  };

export const setClientRenewalStatusData = (payload) => ({
  type: ACTION_TYPES.SET_CLIENT_RENEWAL_STATUS_DATA,
  payload,
});
export const getClientCalendarByPastDueData = (params) => (dispatch) => {
  let payload = {
    query: `
    query Clients_Calendar {
      clients_Calendar(
          clientId: "${params}"
          isFilterByRenewalStatus: true
      ) {
        policyNumber
        policies {
            clientId
            policyId
            isActive
            policyNumber
            policyStatus
            statusColor
            startExpiryDate
            coverageType
            inceptionDate
            expirationDate
        }
      }
  }
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setCalendarByClientData(responseJson));
        });
      } else {
        response?.json().then((responseJson) => {});
      }
    })
    .catch((error) => {});
};

export const getClientActiveInactiveData = (params, active) => (dispatch) => {
  let payload = {
    query: `
    query Clients_Calendar {
      clients_Calendar(
          clientId: "${params}"
          isFilterByActive: ${active}
      ) {
        policyNumber
        policies {
            clientId
            policyId
            isActive
            policyNumber
            policyStatus
            statusColor
            startExpiryDate
            coverageType
            inceptionDate
            expirationDate
        }
      }
  }
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setCalendarByClientData(responseJson));
        });
      } else {
        response?.json().then((responseJson) => {});
      }
    })
    .catch((error) => {});
};

export const getClientExpirationDateData = (params, active) => (dispatch) => {
  let payload = {
    query: `
    query Clients_Calendar {
      clients_Calendar(
          clientId: "${params}"
          isSortedByPolicyExpiryDateAsc: true
      ) {
        policyNumber
        policies {
            clientId
            policyId
            isActive
            policyNumber
            policyStatus
            statusColor
            startExpiryDate
            coverageType
            inceptionDate
            expirationDate
        }
      }
  }
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response?.json().then((responseJson) => {
          dispatch(setCalendarByClientData(responseJson));
        });
      } else {
        response?.json().then((responseJson) => {});
      }
    })
    .catch((error) => {});
};
export const getModifiedDateData = (params) => (dispatch) => {
  let sort = params?.isSort
    ? `order: {${params.sortName}: ${params.sortBy}}`
    : "";

  let payload = {
    query: `
    query Clients_List {
      clients_List(isFilterByDateModified: ${params?.isDateModified} ${sort}) {
        nodes {
          id
          isActive
          isDraft
          isArchived
          dateModified
          clientName
          policiesCount
          countryId
          country
          region
          nextExpiringPolicyDate
          policyStatus
          openTasks
          producingBroker {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              county
              countryId
          }
      }
      }
  }
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson) => {
        response?.json().then((responseJson) => {
          dispatch(setClientListData(responseJson));
        });
        // })
      } else {
      }
    })
    .catch((error) => {});
};
export const addInsurerData = (params) => (dispatch) => {
  loadingToast("Creating insurer...");
  dispatch(startLoading());

  return NetworkService.addInsurer(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson) => {
        response?.json().then((responseJson) => {
          dispatch(setaddInsurerData(responseJson));

          setTimeout(
            () => successToast("Insurer has been created successfully"),
            980
          );
        });
        // })
      } else {
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to create insurer Please try again later"),
        980
      );
    });
};

export const setaddInsurerData = (payload) => ({
  type: ACTION_TYPES.SET_ADD_INSURER_DATA,
  payload,
});
export const addExclusion = (params) => (dispatch) => {
  loadingToast("Creating exclusion...");
  dispatch(startLoading());

  return NetworkService.addExclusion(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
        response?.json().then((responseJson) => {
          dispatch(setaddExclusionData(responseJson));

          setTimeout(
            () => successToast("Exclusion has been created successfully"),
            980
          );
        });
        
      } else {
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to create exclusion Please try again later"),
        980
      );
    });
};

export const setaddExclusionData = (payload) => ({
  type: ACTION_TYPES.SET_ADD_EXCLUSION_DATA,
  payload,
});
export const getPolicyExclusion = (params) => (dispatch) => {
  
  

  return NetworkService.getPolicyExclusion(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
        response?.json().then((responseJson) => {
          dispatch(setPolicyExclusionData(responseJson));

          
        });
        
      } else {
      }
    })
    .catch((error) => {
      setTimeout(
        () => successToast("Failed to create exclusion Please try again later"),
        980
      );
    });
};

export const setPolicyExclusionData = (payload) => ({
  type: ACTION_TYPES.SET_POLICY_EXCLUSION_DATA,
  payload,
});
export const searchClientsData = (params) => (dispatch) => {
  let payload = {
    query: `
    query SearchClients {
      searchClients(clientName: "${params}") {
          nodes {
              clientId
              clientName
              isBroker
          }
      }
  }
    `,
  };

  return NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response?.json().then((responseJson) => {
        response?.json().then((responseJson) => {
          dispatch(setSearchClientsData(responseJson));
        });
        // })
      } else {
      }
    })
    .catch((error) => {});
};

export const setSearchClientsData = (payload) => ({
  type: ACTION_TYPES.SET_SEARCH_CLIENTS_DATA,
  payload,
});
