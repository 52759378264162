import React from "react";

import Grid from "@mui/material/Grid";
import Card from "../../components/Card";
import Box from "@mui/material/Box";
import ReactCountryFlag from "react-country-flag";
import { countryIdToCodeMap } from "../../constants/Countrycodes";
function ClientGridView({ client, showClientDetails }) {
  function handleRowClick() {
    {
      showClientDetails(client.id);
    }
  }

  const getColor = (policyStatus) => {

    if (policyStatus === "Active") {
      return "complete";
    } else if (policyStatus === "Renewal Past Due") {
      return "past-due";
    } else if (policyStatus === "Renewal In Progress") {
      return "inprogress";
    }

  }
  return (
    <>
      <Grid
        xs="12"
        md="4"
        lg="3"
        className="card-parent"
        onClick={() => handleRowClick()}
      >
        <Card>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="d-flex align-items-center">
                <ReactCountryFlag countryCode={countryIdToCodeMap[client.countryId]} svg />
                <span title={client.country} className="country" style={{ paddingLeft: "5px" , maxWidth:'90%' , overflow:'hidden' , textOverflow:"ellipsis" , whiteSpace:'nowrap' }}>{client.country}</span>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Box title={client.policyStatus} className={getColor(client?.policyStatus)} sx={{width:"100%" , minWidth:'100%' , maxWidth:'90%' , textOverflow:'ellipsis' , overflow:"hidden" , whiteSpace:'nowrap'}}>{client.policyStatus}</Box>
            </Grid>
          </Grid>
          <Box class="mt-auto company-name">{client.clientName}</Box>
          <Box class="company-subname">{client?.producingBroker?.memberName?client?.producingBroker?.memberName:""}</Box>
        </Card>
      </Grid>
    </>
  );
}

export default ClientGridView;
