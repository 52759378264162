export const REPORTS_CONST = {

    GET_REPORTS_REQUEST:"GET_REPORTS_REQUEST",
    GET_REPORTS_SUCCESS:"GET_REPORTS_SUCCESS",
    GET_REPORTS_FAILURE:"GET_REPORTS_FAILURE",
    CREATE_REPORTS_REQUEST:"CREATE_REPORTS_REQUEST",
    CREATE_REPORTS_SUCCESS:"CREATE_REPORTS_SUCCESS",
    CREATE_REPORTS_FAILURE:"CREATE_REPORTS_FAILURE",
    DELETE_REPORTS_REQUEST:"DELETE_REPORTS_REQUEST",
    DELETE_REPORTS_SUCCESS:"DELETE_REPORTS_SUCCESS",
    DELETE_REPORTS_FAILURE:"DELETE_REPORTS_FAILURE",
    GET_TEMPLATES_REQUEST:"GET_TEMPLATES_REQUEST",
    GET_TEMPLATES_SUCCESS:"GET_TEMPLATES_SUCCESS",
    GET_TEMPLATES_FAILURE:"GET_TEMPLATES_FAILURE",
    GET_GENERATED_REPORT_REQUEST:"GET_GENERATED_REPORT_REQUEST",
    GET_GENERATED_REPORT_SUCCESS:"GET_GENERATED_REPORT_SUCCESS",
    GET_GENERATED_REPORT_FAILURE:"GET_GENERATED_REPORT_FAILURE",

    
    
}

