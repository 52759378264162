import React from "react";
import { Box } from "@mui/material";
import {ReactComponent as LeftArrow} from "assets/icons/leftArrow.svg"
import {ReactComponent as RightArrow} from "assets/icons/rightArrow.svg"

function Calendar_Policy({ policy, index, left, width, topValue }) {
  const BoxStyle = {
    position: "absolute !important",
    top: topValue + ` !important`,
    left: `${left}%` + ` !important`,

    width: `${width}%` + ` !important`,
  };
  let policyStatus = policy.policyStatus;
  let statusColor = policy.statusColor;
  const statusStyles = {
    backgroundColor: policy?.isActive ? statusColor : "grey",
    borderRadius: "30px",
    padding: "3px 4px",
    overflow: "hidden",
    minWidth: "30px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  const title = `#${policy.policyNumber}, ${policy.coverageType}, ${
    policy.startExpiryDate
  }, ${policy.isActive ? policyStatus : "In-Active"}`;

  return (
    <Box className="subscription-div" sx={BoxStyle} title={title}>
      <Box className="pt-2 d-flex">
        <Box>
          <LeftArrow />

        </Box>
        <Box className="order-id">#{policy.policyNumber}</Box>
        <Box className="coverage-text ellipsis-text">{policy.coverageType}</Box>
        <Box className="year ellipsis-text">{policy.startExpiryDate}</Box>

        {policy.isActive ? (
          <div className="active" style={statusStyles}>
            {policyStatus}
          </div>
        ) : (
          <div className="inactive" style={statusStyles}>
            In-Active
          </div>
        )}
      </Box>
      <Box className="end-icon">
        <RightArrow />
        
      </Box>
    </Box>
  );
}

export default Calendar_Policy;
