import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

export default function DrawerComponent({ isOpen, handleClose, width, body }) {

  

  const content = (
    <Box
      sx={{ width: width }}
      role="presentation"
    //   onClick={toggleDrawer(false)}
    //   onKeyDown={toggleDrawer(false)}
    >
      {body}
    </Box>
  );

  return (
    <div style={{marginTop:"60px" , scrollbarWidth:"none"}}>
      <Drawer
        anchor="right"
        open={isOpen}
        style={{marginTop:"60px" , position:"fixed" , bottom:0}}
        // onClose={toggleDrawer(false)}
        // onClose={handleClose}
      >
        {content}
      </Drawer>
    </div>
  );
}
