import { Button, Grid, List, ListItem } from "@mui/material";
import React from "react";
import GridContainer from "../components/GridContainer";
const NavTabs = ({ tabs, activeTab, onClick, xs, md, lg }) => {
  return (
    <List className="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <GridContainer sx={{ justifyContent: "space-between !important" }}>
        {tabs?.map((item, key) => {
          return (
            
            <Grid item xs={xs} md={md} lg={lg} key={item?.id}>
              <ListItem
                className="nav-item"
                role="presentation"
                sx={{
                  width: "auto",
                  paddingLeft: "0",
                  paddingRight: "0",
                  padding: "0 !important",
                }}
              >
                <Button
                  
                  className={`nav-link ${
                    item.id === activeTab ? "active" : ""
                  }`}
                  onClick={() => onClick(item.id)}
                >
                  {item.name}
                </Button>
              </ListItem>
            </Grid>
            
          );
        })}
      </GridContainer>
    </List>
  );
};
export default NavTabs;
