import { TASKS_CONST } from "../actions/actionTypes/TasksActionTypes";

let initialState = {
  tasksList: [],
  createdtask: "",
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case TASKS_CONST.GET_TASKS_REQUEST:
      return {
        ...state,
        tasksList: [],
        isLoading: true,
      };
    case TASKS_CONST.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasksList: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.GET_TASKS_FAILURE:
      return {
        ...state,
        tasksList: [],
        isLoading: false,
        error: action.error,
      };
    case TASKS_CONST.CREATE_TASKS_REQUEST:
      return {
        ...state,
        createdtask: "",
        isLoading: true,
        error: "",
      };
    case TASKS_CONST.CREATE_TASKS_SUCCESS:
      return {
        ...state,
        createdtask: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.CREATE_TASKS_FAILURE:
      return {
        ...state,
        createdtask: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.GET_TASKS_BY_ID_REQUEST:
      return {
        ...state,
        taskByiddata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.GET_TASKS_BY_ID_SUCCESS:
      return {
        ...state,
        taskByiddata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.GET_TASKS_BY_ID_FAILURE:
      return {
        ...state,
        taskByiddata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.COMPLETE_TASKS_REQUEST:
      return {
        ...state,
        taskCompletedata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.COMPLETE_TASKS_SUCCESS:
      return {
        ...state,
        taskCompletedata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.COMPLETE_TASKS_FAILURE:
      return {
        ...state,
        taskCompletedata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.DELETE_TASKS_REQUEST:
      return {
        ...state,
        taskdeletedata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.DELETE_TASKS_SUCCESS:
      return {
        ...state,
        taskdeletedata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.DELETE_TASKS_FAILURE:
      return {
        ...state,
        taskdeletedata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.GET_TAGS_REQUEST:
      return {
        ...state,
        tagsdata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.GET_TAGS_SUCCESS:
      return {
        ...state,
        tagsdata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.GET_TAGS_FAILURE:
      return {
        ...state,
        tagsdata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.CREATE_TAGS_REQUEST:
      return {
        ...state,
        createdtagsdata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.CREATE_TAGS_SUCCESS:
      return {
        ...state,
        createdtagsdata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.CREATE_TAGS_FAILURE:
      return {
        ...state,
        createdtagsdata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.UPDATE_TASKS_REQUEST:
      return {
        ...state,
        updatedtasksdata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.UPDATE_TASKS_SUCCESS:
      return {
        ...state,
        updatedtasksdata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.UPDATE_TASKS_FAILURE:
      return {
        ...state,
        updatedtasksdata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.GET_REMINDER_REQUEST:
      return {
        ...state,
        reminderdata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.GET_REMINDER_SUCCESS:
      return {
        ...state,
        reminderdata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.GET_REMINDER_FAILURE:
      return {
        ...state,
        reminderdata: "",
        isLoading: false,
        error: action.error,
      };

    case TASKS_CONST.DELETE_MULTIPLE_TASKS_REQUEST:
      return {
        ...state,
        deletedMultipleTasksdata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.DELETE_MULTIPLE_TASKS_SUCCESS:
      return {
        ...state,
        deletedMultipleTasksdata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.DELETE_MULTIPLE_TASKS_FAILURE:
      return {
        ...state,
        deletedMultipleTasksdata: "",
        isLoading: false,
        error: action.payload,
      };

    case TASKS_CONST.GET_TASKS_BY_FILTER_REQUEST:
      return {
        ...state,
        tasksFiltereddata: "",
        isLoading: true,
        error: "",
      };

    case TASKS_CONST.GET_TASKS_BY_FILTER_SUCCESS:
      return {
        ...state,
        tasksFiltereddata: action.payload,
        isLoading: false,
        error: "",
      };
    case TASKS_CONST.GET_TASKS_BY_FILTER_FAILURE:
      return {
        ...state,
        tasksFiltereddata: "",
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default Reducer;
