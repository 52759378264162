import {
  PublicClientApplication,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../authConfig";
import { clearSession } from "../constants";
import { errorToast } from "../components/common/Toast";

class RequestService {
  constructor() {
    this.msalInstance = new PublicClientApplication(msalConfig);
    this.accessToken = null;
    this.state = {
      inProgress: InteractionStatus.None,
      isAuthenticated: false,
      idTokenClaims: null,
    };

    // Create a promise that resolves when init is complete
    // this.initPromise = this.init();
    this.isInitialized = false;
    this.initPromise = null;
  }

  // Initialize and set up event listeners
  async init() {
    if (!this.isInitialized) {
      this.initPromise = (async () => {
        const accounts = this.msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          this.state.isAuthenticated = true;

          // Get idTokenClaims
          const account = accounts[0];
          const idTokenClaims = this.msalInstance.getAccountByHomeId(
            account.homeAccountId
          ).idTokenClaims;
          this.state.idTokenClaims = idTokenClaims;
        }

        this.msalInstance.addEventCallback((event) => {
          if (event.eventType === "msal:loginSuccess") {
            this.state.isAuthenticated = true;
          }
          if (
            event.eventType === "msal:handleRedirectEnd" ||
            event.eventType === "msal:loginFailure"
          ) {
            this.state.inProgress = InteractionStatus.None;
          }
        });

        this.isInitialized = true;
      })();
    }
    return this.initPromise;
  }

  // Method to check if the required role is present
  hasRequiredRole() {
    return this.state.idTokenClaims && this.state.idTokenClaims.extension_Role;
  }
  // Ensure initialization is complete before making requests
  async ensureInit() {
    await this.init();
  }

  // Acquire access token, refreshing if necessary
  async acquireAccessToken() {
    let currentTime = Math.floor(Date.now() / 1000);
    let expirationTime = JSON.parse(localStorage.getItem("user"))?.exp;

    try {
      if (expirationTime < currentTime) {
        const tokenResponse = await this.msalInstance.acquireTokenSilent(
          loginRequest
        );
        this.accessToken = tokenResponse.accessToken;
        localStorage.setItem("accessToken", tokenResponse.accessToken);
      } else {
        this.accessToken = localStorage.getItem("accessToken");
      }
      return this.accessToken;
    } catch (error) {
      console.error("Failed to acquire access token:", error);

      if (
        this.state.isAuthenticated &&
        this.state.inProgress === InteractionStatus.None
      ) {
        await this.msalInstance.loginRedirect(loginRequest);
      }
    }
  }

  // Helper function to handle requests
  async handleRequest(url, method, params = null, headers = {}) {
    const token = await this.acquireAccessToken();

    headers = {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const options = {
      method: method,
      headers: headers,
    };

    if (params) {
      options.body = JSON.stringify(params);
      
    }

    try {
      const response = await fetch(url, options);
      if (response.status === 401 || response.status === 403) {
        clearSession();
      } else if (response.status === 500) {
        setTimeout(() =>
          errorToast("Something went wrong please try again later")
        );
      } else {
        return response;
      }
    } catch (error) {
      console.error("Request failed:", error);
      // throw error;
      return error;
    }
  }
  
  async handleDocumentRequest(url, method, params = null, headers = {}) {
    const token = await this.acquireAccessToken();

    headers = {
      ...headers,
      
      Authorization: `Bearer ${token}`,
    };

    const options = {
      method: method,
      headers: headers,
    };

    if (params) {
      options.body = params;
      
    }
    try {
      const response = await fetch(url, options);
      if (response.status === 401 || response.status === 403) {
        clearSession();
      } else if (response.status === 500) {
        setTimeout(() =>
          errorToast("Something went wrong please try again later")
        );
      } else {
        return response;
      }
    } catch (error) {
      console.error("Request failed:", error);
      // throw error;
      return error;
    }
  }

  // Define request methods
  async getRequest(url) {
    await this.ensureInit();

    {
      console.log(this.initPromise);
    }

    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "GET");
    }
  }

  async postRequest(url, params) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "POST", params);
    }
  }
  async uploadDocumentRequest(url, params , headers) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleDocumentRequest(url, "POST", params , headers);
    }
  }
  async getDocumentRequest(url, params , headers) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleDocumentRequest(url, "GET", params , headers);
    }
  }

  async postRequestWithHeaders(url, params, headers) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "POST", params, headers);
    }
  }

  async putRequest(url, params) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "PUT", params);
    }
  }

  async putRequestWithHeaderAndBody(url, params, payload) {
    const tokenResponse = await this.acquireAccessToken();

    let headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenResponse}`,
      ...params
    };

    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "PUT", payload , headers);
    }
  }
  
  async deleteRequest(url) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "DELETE");
    }
  }

  async deleteRequestWithBody(url, params) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "DELETE", params);
    }
  }

  async deleteRequestWithHeaders(url, params, headers) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "DELETE", params, headers);
    }
  }
  async deleteRequestWithHeaderAndBody(url, params, headers) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "DELETE", params, headers);
    }
  }

  async patchRequest(url, params) {
    await this.ensureInit();
    if (this.hasRequiredRole()) {
      return await this.handleRequest(url, "PATCH", params);
    }
  }
}

export default new RequestService();
