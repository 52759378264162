import React, { useEffect, useState } from "react";

import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Avatar,
} from "@mui/material";

//Custom MUI components
import GridContainer from "../../components/GridContainer";

import Button from "../../components/Button";

import { RenderTextInput } from "../../components/TextField";
import CloseButton from "../../components/CloseButton";

import {
  countriesOptions,
  customFilterOption,
  dropDownOptions,
  loggedInUserDetails,
  statusOptions,
  validateInput,
} from "../../constants";

import { CreateUser, updateUser } from "../../store/actions/UserAction";

//Custom styles for components
import { customStyles } from "../../components/common/CustomStyle";

const AddUser = ({ userDetails, page, userRole, handleClose }) => {
  //state variables declaration

  const [prefixes, setPrefixes] = useState([]);

  const [prefix, setPrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffixes, setsuffixes] = useState([]);
  const [selectedSuffix, setSelectedSuffix] = useState("");
  const [pronouns, setPronouns] = useState([]);
  const [selectedPronoun, setSelectedPronoun] = useState("");
  const [member, setMember] = useState([]);
  const [permission, setPermission] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedmember, setSelectedMember] = useState("");
  const [countries, setCountries] = useState([]);
  const [jobtitle, setJobtitle] = useState("");
  const [selectedstatus, setSelectedStatus] = useState("");
  const [userTypeOptions, setUserTypeOptions] = useState([{value:"memberUser" , label:"Member User"} , {value:"hQUser" , label:"HQ  User"}]);
  const [selectedUserType, setSelectedUserType] = useState("");

  //extract specific pieces of state from the Redux store

  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      prefixes: state?.contact?.contactsPrefixes,
      suffix: state?.contact?.contactsSuffix,
      pronoun: state?.contact?.contactspronoun,
      memberData: state?.fetchData?.memberData,
      sortedMemberFirmData: state?.memberFirm?.sortedMemberFirmData,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let user = loggedInUserDetails;
    if (user) {
      let memberFirm = dropDownOptions(member, "id", "memberName")?.find(
        (item, key) => item.value === user?.extension_MemberFirmId
      );
      setSelectedMember(memberFirm);
    }
  }, []);
  //Effect to update prefixes state when prefixes data changes in Redux store
  useEffect(() => {
    if (stateData?.prefixes?.data?.prefixes) {
      setPrefixes(stateData.prefixes?.data?.prefixes);
    }
  }, [stateData.prefixes?.data?.prefixes]);

  //Effect to update countries state when countries data changes in Redux store
  useEffect(() => {
    if (stateData?.countries) {
      setCountries(stateData.countries);
    }
  }, [stateData.countries]);

  //Effect to update suffixes state when suffixes data changes in Redux store
  useEffect(() => {
    if (stateData?.suffix?.data?.suffixes) {
      setsuffixes(stateData.suffix?.data?.suffixes);
    }
  }, [stateData.suffix?.data?.suffixes]);

  useEffect(()=> {
    if(selectedUserType){
      
      let permissionValue = selectedUserType?.value === "memberUser"?"MemberAdmin":"HqAdmin"
      
      setPermission(permissionValue)
    }
  } , [selectedUserType])

  useEffect(()=> {
    if(page!=="edit") {
      setSelectedUserType(userTypeOptions?.[0])
    }
  } , [])
  
  //Effect to update pronouns state when pronouns data changes in Redux store
  useEffect(() => {
    if (stateData?.pronoun?.data?.pronouns) {
      setPronouns(stateData.pronoun?.data?.pronouns);
    }
  }, [stateData.pronoun?.data?.pronouns]);

  useEffect(() => {
    if (userDetails && prefixes && suffixes && member && page === "edit") {
      setFirstName(userDetails?.givenName);
      let userapprovalstatus =
        userDetails?.approvalStatus === "Active"
          ? "Approved"
          : userDetails?.approvalStatus === "Inactive"
          ? "Unapproved"
          : "Pending";
      let selectedprefixoption = dropDownOptions(
        prefixes,
        "prefixId",
        "name"
      )?.find((item, key) => item.value === userDetails?.prefixId);
      let selectedsuffixoption = dropDownOptions(
        suffixes,
        "suffixId",
        "name"
      )?.find((item, key) => item.value === userDetails?.suffixId);
      let selectedpronounoption = dropDownOptions(
        pronouns,
        "pronounId",
        "name"
      )?.find((item, key) => item.value === userDetails?.pronounId);
      let selectedcountryoption = countriesOptions(countries)?.find(
        (item, key) => item.value === userDetails?.countryId
      );
      let selectedmemberoption = dropDownOptions(
        member,
        "id",
        "memberName"
      )?.find((item, key) => item.value === userDetails?.memberFirmId);
      let selectedstatusoption = statusOptions?.find(
        (item, key) => item.value === userapprovalstatus
      );

      setPrefix(selectedprefixoption);

      setSelectedSuffix(selectedsuffixoption);

      setSelectedPronoun(selectedpronounoption);

      setSelectedCountry(selectedcountryoption);
      setLastName(userDetails?.surName);
      setEmail(userDetails?.mail);
      // setjobtitle(userDetails?.jobtitle);

      setSelectedCountry(selectedcountryoption);

      setSelectedMember(selectedmemberoption);

      setSelectedStatus(selectedstatusoption);
      setJobtitle(userDetails?.jobTitle);
      let userType = (userDetails?.access === "MemberContributor" || userDetails?.access === "MemberAdmin")? userTypeOptions?.find((item , key)=> item?.value === "memberUser"):userTypeOptions?.find((item , key)=> item?.value === "hQUser")
      setSelectedUserType(userType)
      setPermission(userDetails?.access);
    }
  }, [userDetails, prefixes, suffixes, countries, member]);

  //Effect to update member state when member changes in Redux store
  // useEffect(() => {
  //   if (stateData?.memberData) {
  //     setMember(stateData?.memberData);
  //   }
  // }, [stateData?.memberData]);

  useEffect(() => {
    if (stateData?.sortedMemberFirmData?.data?.members?.nodes) {
      setMember(stateData.sortedMemberFirmData?.data?.members?.nodes);
    }
  }, [stateData.sortedMemberFirmData?.data?.members?.nodes]);

  useEffect(() => {
    if (stateData.sortedMemberFirmData?.data?.members?.nodes) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user?.extension_Role !== "HqAdmin") {
        let member = dropDownOptions(
          stateData.sortedMemberFirmData?.data?.members?.nodes,
          "id",
          "memberName"
        )?.find((item, key) => item.value === user?.extension_MemberFirmId);
        setSelectedMember(member);
      }
    }
  }, [stateData.sortedMemberFirmData?.data?.members?.nodes]);
  const handlechangeEmail = (e) => {
    setEmail(e);
    // resetFieldError("email")}

    //after adding specilal characters valiadation at ADB2c again use below this email regex
    // let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    let emailRegex = /^[\w+._%-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    let valid = emailRegex.test(e);
    setError((fields) => {
      return {
        ...fields,
        email: valid ? "" : "Please enter valid email",
      };
    });
  };

  const nameValidation = (field, e) => {
    let firstNameAndLastNameRegex = /^(?!.*@-)[a-zA-Z0-9@\- ]+$/;
    let valid = firstNameAndLastNameRegex.test(e);
    setError((fields) => {
      return {
        ...fields,
        
        [field]: validateInput(e) || "",
      };
    });
  };
  const validation = () => {
    // let emailRegex = /^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g;
    //after adding specilal characters valiadation at ADB2c again use below this email regex
    // let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    let emailRegex = /^[\w+._%-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    let firstNameAndLastNameRegex = /^(?!.*@-)[a-zA-Z0-9@\- ]+$/;
    {
      console.log(firstNameAndLastNameRegex.test(firstName));
    }
    const updatedErrors = {
      firstName:
        firstName?.trim() !== ""
          ? 
          
          validateInput(firstName) || ""
          : "Please enter First/Given name",
      lastName:
        lastName?.trim() !== ""
          ? validateInput(lastName) || ""
          
          : "Please enter Last/Surname",
      email:
        email !== ""
          ? emailRegex.test(email)
            ? ""
            : "Please enter valid email"
          : "Please enter email",
      status:
        selectedstatus !== ""
          ? ""
          : page !== "edit"
          ? ""
          : "Please select status",
      country: selectedCountry?.value ? "" : "Please select country",
      // jobtitle: jobtitle?.trim() !== "" ? "" : "Please enter job title",
      jobtitle: jobtitle?.trim() !== "" ? validateInput(jobtitle) || "" : "Please enter job title",
      memberFirm: selectedmember?.value ? "" : selectedUserType?.value === "hQUser" ?"":"Please select member firm",
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  const resetFieldError = (field , name) =>
    setError((prevState) => ({
      ...prevState,

      // [field]: name? validateInput(name) || "":"",

      [field]: name? validateInput(name) || "":"",
    }));

  //Function to add a new user
  const submit = () => {
    const valid = validation();
    let userapprovalstatus =
      userDetails?.approvalStatus === "Active"
        ? "Approved"
        : userDetails?.approvalStatus === "Inactive"
        ? "Unapproved"
        : "Pending";
    if (valid) {
      let payload = {
        GivenName: firstName,
        Surname: lastName,
        Mail: email,
        PrefixId: prefix?.value,
        SuffixId: selectedSuffix?.value,
        PronounId: selectedPronoun?.value,


        
        UserRole: permission,

        ApprovalStatus:
          userRole === "HqAdmin" ? selectedstatus?.value : userapprovalstatus,
        MemberFirmId: selectedUserType?.value === "hQUser"?null:selectedmember?.value,
        JobTitle: jobtitle ? jobtitle : "",
        CountryId: selectedCountry !== "" ? selectedCountry?.value : "",
        
        timeZoneId:null,
        phoneNumber:null,
        currencyId:null,
        
      };

      if (page === "edit") {
        payload.Id = userDetails?.id;

        dispatch(updateUser(payload));
      } else {
        dispatch(CreateUser(payload));
      }
    }
  };
  
  return (
    <Box className="create-new-modal">
      <Box
        class="modal-header modal-responsive"
        style={{
          backgroundColor: "#FFF",
          //padding: "10px 300px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="span" sx={{ display: "flex" }}>
          <CloseButton onClick={() => handleClose()} />
          <Button
            variant={"contained"}
            color={"grey"}
            sx={{ marginLeft: "10px" }}
            onClick={() => submit()}
          >
            Save
          </Button>
        </Typography>
      </Box>
      <div class="border"></div>
      <Box
        class="modal-body modal-responsive"
        // style={{ padding: "10px 300px" }}
      >
        <GridContainer className="row">
          <Grid xs="12" md="6" lg="6">
            <Box class="new-client-text">
              {page === "edit" ? "Update user" : "Create user"}
            </Box>
            <Box class="main-heading">User</Box>
          </Grid>
          {page === "edit" && userRole === "HqAdmin" && (
            <Grid xs="12" md="6" lg="6">
              <Select
                options={statusOptions}
                placeholder="Select a status*"
                // filterOption={customFilterOption}
                onChange={(e) => setSelectedStatus(e)}
                value={selectedstatus}
                styles={customStyles(error.status)}
                isDisabled={!(userRole === "HqAdmin")}
              />

              {error.status && (
                <Typography
                  className="error-msge"
                  style={{ color: "#dc3545", fontSize: "14px" }}
                >
                  {error.status}
                </Typography>
              )}
            </Grid>
          )}
        </GridContainer>
        <GridContainer className="row mb-3">
        { userRole === "HqAdmin" && (

          <>
          <Grid xs="12" md="6" lg="6">
            
            <Box class="client-text">User Type*</Box>
          </Grid>
          
            <Grid xs="12" md="6" lg="6">
              <Select
                options={userTypeOptions}
                placeholder="Select a user type*"
                // filterOption={customFilterOption}
                onChange={(e) => {setSelectedUserType(e); setSelectedMember("")}}
                value={selectedUserType}
                styles={customStyles(error.userType)}
                isDisabled={!(userRole === "HqAdmin")}
              />

              {error.userType && (
                <Typography
                  className="error-msge"
                  style={{ color: "#dc3545", fontSize: "14px" }}
                >
                  {error.userType}
                </Typography>
              )}
            </Grid>

            </>
            
          )}
        </GridContainer>
        <Box class="tab-content" id="pills-tabContent">
          <Box
            id="pills-add-new"
            role="tabpanel"
            aria-labelledby="pills-add-new-tab"
            tabindex="0"
          >
            <GridContainer className="row" sx={{ marginBottom: "10px" }}>
              

              <>
              <Grid xs="12" md="6" lg="6">
                <Box class="client-text">{selectedUserType?.value === "hQUser"?"Member firm":"Member firm*"}</Box>
                <Box class="new-client-text">Allocate firm for this user</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Select
                  // options={membersOption(member)}
                  options={dropDownOptions(member, "id", "memberName")}
                  // options={[]}
                  placeholder="Select a member"
                  // filterOption={customFilterOption}
                  onChange={(e) => {
                    setSelectedMember(e);
                    resetFieldError("memberFirm");
                  }}
                  value={selectedmember}
                  styles={customStyles(error.memberFirm)}
                  isDisabled={(userRole !== "HqAdmin" && userRole !== "HqContributor")||(selectedUserType?.value === "hQUser")}
                />

                {error.memberFirm && (
                  <Typography
                    className="error-msge"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.memberFirm}
                  </Typography>
                )}
              
              
              </Grid>
              </>
              

            </GridContainer>
            <GridContainer className="row">
              <Grid xs="12" md="6" lg="6">
                <Box class="client-text">Permission</Box>
                <Box class="new-client-text">A users permissions</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Box sx={{ marginLeft: "10px" }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue={permission}
                      value={permission}
                      name="radio-buttons-group"
                      onChange={(e) => {
                        setPermission(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={selectedUserType?.value==="memberUser" ? "MemberAdmin" : "HqAdmin"}
                        control={<Radio sx={{ color: "#53389e !important" }} />}
                        label="Admin"
                      />
                      <Typography
                        className="new-client-text"
                        sx={{ marginLeft: "10px" }}
                      >
                        The ability to be able to add new members
                      </Typography>
                      <FormControlLabel
                        value={selectedUserType?.value==="memberUser"?"MemberContributor":"HqContributor"}
                        control={<Radio sx={{ color: "#53389e !important" }} />}
                        label="Contributor"
                      />
                      <Typography
                        className="new-client-text"
                        sx={{ marginLeft: "10px" }}
                      >
                        Basic access, will not be able to add new members
                      </Typography>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </GridContainer>
            <hr />
            <GridContainer className="row mb-3">
              <Grid class="client-text">Personal information</Grid>
              <Grid xs="12" md="6" lg="6">
                <Box class="client-text">Avatar</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Box className="client-name d-flex align-items-center">
                  <Box className="client-initials" sx={{ marginRight: "10px" }}>
                    {/* MJ */}
                    {!firstName && !lastName ? (
                      <Avatar
                        variant="square"
                        className="client-name client-initials"
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        className="client-name client-initials"
                      >{`${firstName ? firstName[0] : ""}  ${
                        lastName ? lastName[0] : ""
                      }`}</Avatar>
                    )}
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Prefix</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Select
                  // className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setPrefix(e)}
                  value={prefix}
                  options={dropDownOptions(prefixes, "prefixId", "name")}
                  styles={customStyles()}
                  // filterOption={customFilterOption}
                />
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">First/Given name*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <RenderTextInput
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    nameValidation("firstName", e.target.value);
                    // resetFieldError("firstName");
                  }}
                  placeholder="First/Given name"
                  block
                  required
                  errors={error.firstName}
                />
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Last/Surname*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <RenderTextInput
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    nameValidation("lastName", e.target.value);
                    // resetFieldError("lastName");
                  }}
                  placeholder="Last/Surname"
                  block
                  required
                  errors={error.lastName}
                />
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Email*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <RenderTextInput
                  value={email}
                  onChange={(e) => handlechangeEmail(e.target.value)}
                  placeholder="Email"
                  block
                  required
                  errors={error.email}
                  disabled={page === "edit"}
                />
              </Grid>
            </GridContainer>

            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Suffix</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedSuffix(e)}
                  styles={customStyles()}
                  // filterOption={customFilterOption}
                  value={selectedSuffix}
                  options={dropDownOptions(suffixes, "suffixId", "name")}
                />
              </Grid>
            </GridContainer>

            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Pronouns</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedPronoun(e)}
                  value={selectedPronoun}
                  styles={customStyles()}
                  // filterOption={customFilterOption}

                  options={dropDownOptions(pronouns, "pronounId", "name")}
                />
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Country*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedCountry(e);
                    resetFieldError("country");
                  }}
                  value={selectedCountry}
                  styles={customStyles(error.country)}
                  filterOption={customFilterOption}
                  options={countriesOptions(countries)}
                />
                {error.country && (
                  <Typography
                    className="error-msge"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.country}
                  </Typography>
                )}
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Job title*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <RenderTextInput
                  type="text"
                  id="exampleFormControlInput1"
                  onChange={(e) => {
                    setJobtitle(e.target.value);
                    resetFieldError("jobtitle" , e.target.value);
                  }}
                  placeholder="Job title"
                  value={jobtitle}
                  errors={error.jobtitle}
                  required
                />
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddUser;
