import React, { useEffect, useState } from "react";

import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Avatar, Box, Grid, Typography } from "@mui/material";

//Custom MUI components
import GridContainer from "components/GridContainer";

import Button from "components/Button";

import { RenderTextInput } from "components/TextField";
import CloseButton from "components/CloseButton";

import { countriesOptions, customFilterOption, validateInput } from "constants";

//Custom styles for components
import { customStyles } from "components/common/CustomStyle";
import { createMemeber, updateMemeber } from "store/actions/MemberFirmAction";

const AddMemberFirm = ({
  memberFirmDetails,
  page,

  handleClose,
}) => {
  //state variables declaration

  const [memberFirmname, setMemberFirmName] = useState("");
  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipcode] = useState("");

  const [error, setError] = useState({
    memberFirmname: "",
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);

  //extract specific pieces of state from the Redux store

  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,

      memberData: state?.fetchData?.memberData,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (memberFirmDetails && countries && page === "edit") {
      setMemberFirmName(memberFirmDetails?.memberName);
      setState(memberFirmDetails?.state);
      setCity(memberFirmDetails?.city);
      setFirstLine(memberFirmDetails?.street1);
      setSecondLine(memberFirmDetails?.street2);
      let countryoption = countriesOptions(countries)?.find(
        (item) => item.value === memberFirmDetails?.countrId
      );

      setSelectedCountry(countryoption);
      setZipcode(memberFirmDetails?.zipcode);
    }
  }, [memberFirmDetails, countries]);
  //Effect to update countries state when countries data changes in Redux store
  useEffect(() => {
    if (stateData?.countries) {
      setCountries(stateData.countries);
    }
  }, [stateData.countries]);

  const validation = () => {
    const updatedErrors = {
      city:
        city?.trim() !== "" ? validateInput(city) || "" : "Please enter city",
      firstLine:
        firstLine?.trim() !== ""
          ? validateInput(firstLine) || ""
          : "Please enter line1",
      country:
        selectedCountry === ""
          ? "Please select country"
          : selectedCountry?.value !== ""
          ? ""
          : "Please select country",
      memberFirmname:
        memberFirmname?.trim() !== ""
          ? validateInput(memberFirmname) || ""
          : "Please enter member firm name",
      zipCode:
        zipCode?.trim() !== ""
          ? validateInput(zipCode) || ""
          : "Please enter zipcode",
      secondLine:
        secondLine?.trim() !== "" ? validateInput(secondLine) || "" : "",
      state: state?.trim() !== "" ? validateInput(state) || "" : "",
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  const resetFieldError = (field, name) => {
    setError((prevState) => ({
      ...prevState,
      [field]: name ? validateInput(name) || "" : "",
    }));
  };

  //Function to add a new memberFirm
  const addMemberFirm = () => {
    const valid = validation();

    if (valid) {
      let countryname = countries?.filter(
        (item) => item.id === selectedCountry?.value
      );
      let payload = {
        memberName: memberFirmname ? memberFirmname : "",
        memberDisplayName: memberFirmname ? memberFirmname : "",
        street1: firstLine ? firstLine : "",
        street2: secondLine ? secondLine : "",
        city: city ? city : "",
        state: state ? state : "",
        zipcode: zipCode ? zipCode : "",
        country: countryname?.[0],
        countryId: selectedCountry?.value,
        website: "",
        base64EncodedAvatar: "",
        logoFileExtension: "",
      };

      if (page === "edit") {
        payload.id = memberFirmDetails?.id;
        payload.CreatedBy = memberFirmDetails?.createdBy;
        payload.CreatedOn = memberFirmDetails?.createdOn;

        dispatch(updateMemeber(payload));
      } else {
        dispatch(createMemeber(payload));
      }
    }
  };

  return (
    <Box className="create-new-modal">
      <Box
        className="modal-header modal-responsive"
        style={{
          backgroundColor: "#FFF",

          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="span" sx={{ display: "flex" }}>
          <CloseButton onClick={() => handleClose()} />
          <Button
            variant={"contained"}
            color={"grey"}
            sx={{ marginLeft: "10px" }}
            onClick={() => addMemberFirm()}
          >
            Save
          </Button>
        </Typography>
      </Box>
      <div className="border"></div>
      <Box className="modal-body modal-responsive">
        <Box className="new-client-text">
          {page === "edit" ? "Update member firm" : "Create member firm"}
        </Box>
        <Box className="main-heading">Details</Box>

        <Box className="tab-content" id="pills-tabContent">
          <Box
            id="pills-add-new"
            role="tabpanel"
            aria-labelledby="pills-add-new-tab"
            tabindex="0"
          >
            <Box className="detials mb-3 client-text">Firm</Box>
            <GridContainer className="row" sx={{ marginBottom: "10px" }}>
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Member firm name*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <RenderTextInput
                  value={memberFirmname}
                  onChange={(e) => {
                    setMemberFirmName(e.target.value);
                    resetFieldError("memberFirmname", e.target.value);
                  }}
                  placeholder="Type member firm name*"
                  block
                  required
                  errors={error.memberFirmname}
                />
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Avatar</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Box
                  className="client-name d-flex align-items-center"
                  sx={{ marginLeft: "1%" }}
                >
                  {!memberFirmname ? (
                    <Avatar
                      variant="square"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "var(--color-bg-fill-special-grey)",
                        color: "var(--color-text-special)",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      className="client-initials"
                      sx={{
                        backgroundColor:
                          "var(--color-bg-fill-special-grey) !important",
                        marginLeft: "5px",
                      }}
                    >
                      {memberFirmname?.[0]}
                    </Avatar>
                  )}
                </Box>
              </Grid>
            </GridContainer>

            <hr />

            <GridContainer className="row mb-3">
              <Grid>
                <Box className="client-text">HQ Location</Box>
                <Box className="new-client-text">
                  The location the member firm is registered{" "}
                </Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Country*</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Select
                  aria-label="Default select example"
                  placeholder="Select Country*"
                  onChange={(e) => {
                    setSelectedCountry(e);
                    resetFieldError("country");
                  }}
                  value={selectedCountry}
                  options={countriesOptions(countries)}
                  styles={customStyles(error.country)}
                  filterOption={customFilterOption}
                />

                {error.country && (
                  <Box
                    className="error-msge"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.country}
                  </Box>
                )}
              </Grid>
            </GridContainer>
            <GridContainer className="row mb-3">
              <Grid xs="12" md="6" lg="6">
                <Box className="client-text">Address</Box>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                <Typography sx={{ marginBottom: "10px" }}>
                  <RenderTextInput
                    value={firstLine}
                    onChange={(e) => {
                      setFirstLine(e.target.value);
                      resetFieldError("firstLine", e.target.value);
                    }}
                    placeholder="First Line*"
                    block
                    required
                    errors={error.firstLine}
                  />
                </Typography>
                <Typography sx={{ marginBottom: "10px" }}>
                  <RenderTextInput
                    value={secondLine}
                    onChange={(e) => {
                      setSecondLine(e.target.value);
                      resetFieldError("secondLine", e.target.value);
                    }}
                    placeholder="Second Line"
                    block
                    required
                    errors={error.secondLine}
                  />
                </Typography>
                <Typography sx={{ marginBottom: "10px" }}>
                  <RenderTextInput
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      resetFieldError("city", e.target.value);
                    }}
                    placeholder="City*"
                    block
                    required
                    errors={error.city}
                  />
                </Typography>
                <Typography sx={{ marginBottom: "10px" }}>
                  <RenderTextInput
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      resetFieldError("state", e.target.value);
                    }}
                    placeholder="State"
                    block
                    required
                    errors={error.state}
                  />
                </Typography>
                <RenderTextInput
                  value={zipCode}
                  onChange={(e) => {
                    setZipcode(e.target.value);
                    resetFieldError("zipCode", e.target.value);
                  }}
                  placeholder="Zip/Postcode*"
                  block
                  required
                  errors={error.zipCode}
                />
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddMemberFirm;
