import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, allowedRoles, user }) => {
  if (!user) return <Navigate to="/login" replace />;
  if (allowedRoles && !allowedRoles.includes(user.extension_Role))
    return <Navigate to="/" replace />;
  return children;
};

export default ProtectedRoute;
