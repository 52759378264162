import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux'
// import rootReducer from './reducer'
import {thunk} from 'redux-thunk'
import { compose } from 'redux'
import rootReducer from "./store/reducer"

import { Provider } from 'react-redux';



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { msalConfig , loginRequest , resetPasswordRequest} from './authConfig';
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { BrowserRouter as Router } from 'react-router-dom'
import Loader from './components/Loader';
import { clearSession } from './constants';
import { errorToast } from './components/common/Toast';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(async(event) => {
  if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
  ) {
      msalInstance.setActiveAccount(event.payload.account);
      var tokenRepsonse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: event.payload.account
    });
    
    localStorage["idToken"] = tokenRepsonse.idToken;
    localStorage["accessToken"] = tokenRepsonse.accessToken;
    localStorage["user"] = JSON.stringify(tokenRepsonse.idTokenClaims);
  //   if(event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
  //   if(tokenRepsonse.idTokenClaims) {
  //     if(tokenRepsonse.idTokenClaims?.extension_Role) {
  //       return
  //     }
  //     else {
        
  //         // errorToast("You don't have required permissions, please contact Admin")
          
  //         if (window.alert("You don't have required permissions, please contact Admin")) {
  //           clearSession();
            
  //         }
          
        
  //     }
  //   }
  // }
  }
});

const checkTokenExpiration = async() => {
  
  const currentTime = Math.floor(Date.now() / 1000)
  if (JSON.parse(localStorage.getItem("user"))?.exp < currentTime) {
    
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
    
    if(tokenResponse) {
  
  
        localStorage["idToken"] = tokenResponse.idToken;
        localStorage["accessToken"] = tokenResponse.accessToken;
        localStorage["user"] = JSON.stringify(tokenResponse.idTokenClaims);
}
  } catch (error) {
    
    msalInstance.loginRedirect(loginRequest).catch((error) => {
      
    })
  
  }
}
}
checkTokenExpiration()

// Handles user click on forgot password button in SignInSignUp flow and redirect user to reset password flow 
msalInstance.addEventCallback((message) => {
  if (message.eventType === EventType.LOGIN_FAILURE){
    if (message.error.errorMessage.includes("AADB2C90118")) { // The user has forgotten their password.
      msalInstance.loginRedirect(resetPasswordRequest);
    }
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <ToastContainer
        autoClose={1000}
        draggable={true}
      />

      <Loader />
    <App instance={msalInstance}/>
    
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
