import { FormControlLabel } from "@mui/material";
import React from "react";
import {StyledSwitch} from "../components/common/CustomStyle"

const Switch = ({label , onChange , checked , disabled}) => {
    return (
        <FormControlLabel
        control={<StyledSwitch sx={{ m: 1 }} 
        label={label} 
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        />
      }
        
      />
    )
}
export default Switch