import requestService from "./RequestService";

import { REACT_APP_GATEWAY_API_URL } from "../constants";
// const baseUrl="https://localhost:53713/api/"

class NetworkService {
  addClient(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client`;
    return requestService.postRequest(url, params);
  }
  getClient(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client`;
    return requestService.getRequest(url, params);
  }
  clientDetails(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/ClientPolicy/Graphql`;
    return requestService.postRequest(url, params);
  }

  addPolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy`;
    return requestService.postRequest(url, params);
  }
  getCountries() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetCountries`;
    return requestService.getRequest(url);
  }
  Member() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return requestService.getRequest(url);
  }
  GetGetCoverageTypes() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Static/GetGetCoverageTypes`;
    return requestService.getRequest(url);
  }
  GetCurrencies() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetCurrencies`;
    return requestService.getRequest(url);
  }
  GetLanguages() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetLanguages`;
    return requestService.getRequest(url);
  }
  GetPolicyTypes() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetPolicyTypes`;
    return requestService.getRequest(url);
  }
  Insurer() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Insurer`;
    return requestService.getRequest(url);
  }
  ArchiveClients(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client/ArchiveClients`;
    return requestService.postRequest(url, params);
  }

  CancelPolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/CancelPolicy`;
    return requestService.postRequest(url, params);
  }
  RenewalPolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/RenewPolicy`;
    return requestService.postRequest(url, params);
  }
  RevokeCancellation(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/RevokeCancellation`;
    return requestService.postRequest(url, params);
  }

  clientGraphql(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/ClientPolicy/Graphql`;
    return requestService.postRequest(url, params);
  }

  addLocation(params, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Location`;
    return requestService.postRequest(url, params);
  }
  deleteLocation(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Location`;
    return requestService.deleteRequestWithBody(url, params);
  }
  updateLocation(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Location`;
    return requestService.putRequest(url, params);
  }

  updateClient(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client`;
    return requestService.putRequest(url, params);
  }
  updatePolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy`;
    return requestService.putRequest(url, params);
  }

  createMember(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return requestService.postRequest(url, params);
  }
  updateMemberFirm(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return requestService.putRequest(url, params);
  }
  deleteMemberFirm(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return requestService.deleteRequestWithBody(url, params);
  }

  DeletePolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/DeletePolicy`;
    return requestService.postRequest(url, params);
  }

  documentsForPolicy(params, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document/Graphql`;
    return requestService.postRequestWithHeaders(url, params, headers);
  }

  Coverage() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage`;
    return requestService.getRequest(url);
  }
  getByidCoverage(id) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage/${id}`;
    return requestService.getRequest(url);
  }
  CoverageData(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage`;
    return requestService.postRequest(url, params);
  }
  updateCoverageData(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage`;
    return requestService.putRequest(url, params);
  }

  getCoverageDetailsData(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage/Data?PolicyId=${params}`;
    return requestService.getRequest(url, params);
  }

  addExclusion(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/PolicyExclusions`;
    return requestService.postRequest(url , params);
  }
  getPolicyExclusion(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/PolicyExclusions`;
    return requestService.getRequest(url , params);
  }

  contactsgraphql(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/Graphql`;
    return requestService.postRequest(url, params);
  }

  addContacts(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact`;
    return requestService.postRequest(url, params);
  }

  contactType(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/Type`;
    return requestService.getRequest(url, params);
  }
  deleteContactData(params , ClientProducingBroker) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/${params}/${ClientProducingBroker}`;
    return requestService.deleteRequest(url);
  }
  addMulitpleContacts(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/Multiple`;
    return requestService.postRequest(url, params);
  }

  updateContact(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact`;
    return requestService.putRequest(url, params);
  }

  deleteMultipleContact(params) {
    // let url = `${REACT_APP_GATEWAY_API_URL}/Contact?${params}`;
    // return requestService.deleteRequest(url);
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact`;
    return requestService.deleteRequestWithBody(url , params);
  }

  recentContact(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/contact/Graphql`
    return requestService.postRequest(url , params)
}

  getDocumentTypes() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document/Types`;
    return requestService.getRequest(url);
  }
  uploadDocument(params , headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document`;
    return requestService.uploadDocumentRequest(url, params , headers);
  }
  getDocuments(params, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document/Graphql`;
    return requestService.postRequestWithHeaders(url, params, headers);
  }

  DeleteDocument(params, category, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document`;
    return requestService.deleteRequestWithHeaderAndBody(url, params , headers);
  }

  updateDocuments(params , from , headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document`;
    return requestService.putRequestWithHeaderAndBody(url , headers , params);
  }

  getUsers(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return requestService.getRequest(url, params);
  }
  getUsersInTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/Task`;
    return requestService.getRequest(url, params);
  }
  getUserById(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/${params.Id}`;
    return requestService.getRequest(url, params);
  }
  addUser(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return requestService.postRequest(url, params);
  }

  updateUser(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return requestService.putRequest(url, params);
  }
  deleteUser(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return requestService.deleteRequestWithBody(url, params);
  }
  changeFirstLogin(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/ChangeFirstLogin`;
    return requestService.patchRequest(url, params);
  }

  getMemberFirmByuserid(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/MemberFirm/${params?.id}`;
    return requestService.getRequest(url);
  }
  getUserByFilter(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/Filter`;
    return requestService.postRequest(url, params);
  }
  taksGraphql(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/Graphql`;
    return requestService.postRequest(url, params);
  }
  addTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task`;
    return requestService.postRequest(url, params);
  }
  addTag(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/Tag`;
    return requestService.postRequest(url, params);
  }
  taskCompleted(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/UpdateIsCompleted`;
    return requestService.postRequest(url, params);
  }
  updateTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/UpdateTask`;
    return requestService.postRequest(url, params);
  }
  deleteTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/DeleteTask`;
    return requestService.postRequest(url, params);
  }
  getTasksDetails(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/GetTaskDetails`;
    return requestService.postRequest(url, params);
  }
  deleteMultipleTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/DeleteTasks`;
    return requestService.postRequest(url, params);
  }
  getMemberFirm(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List`;
    return requestService.getRequest(url);
  }
  getMemberFirmList(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/ClientPolicy/Graphql`;
    return requestService.postRequest(url , params);
  }
  getMemberFirmByClientId(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List/Client?clientId=${params}`;
    return requestService.getRequest(url);
  }
  getMemberFirmByMemberFirmName(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List/Name?memberName=${params}`;
    return requestService.getRequest(url);
  }
  getMemberFirmByCountry(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List?countryId=${params}`;
    return requestService.getRequest(url);
  }
  addInsurer(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Insurer`;
    return requestService.postRequest(url , params);
  }
  getTimezones(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetTimeZones`;
    return requestService.getRequest(url);
  }
  changePassword(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/users/changePassword`;
    return requestService.postRequest(url, params);
  }
  //Reports
  getReports(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Report/Graphql`;
    return requestService.postRequest(url , params);
  }
  addReport(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/report`;
    return requestService.postRequest(url , params);
  }
  deleteReport(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/report/Delete`;
    return requestService.postRequest(url , params);
  }
  getTemplate(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Report/Graphql`;
    return requestService.postRequest(url , params);
  }
  getGeneratedReport(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/Report/ExcelFile/${params}`;
    return requestService.getDocumentRequest(url , );
  }
  getGeneratedPDFReport(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/Report/PdfFile/${params}`;
    return requestService.getDocumentRequest(url , );
  }
  
  //Notifications

  getNotification() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Notification`;
    return requestService.getRequest(url);
  }
  getLatestNotification(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Notification/Latest?lastNotificationTimestamp=${params}`;
    return requestService.getRequest(url);
  }
  clearNotification(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Notification/Clear`;
    return requestService.patchRequest(url , params);
  }
  markAllAsReadNotification(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Notification/MarkAsRead`;
    return requestService.patchRequest(url , params);
  }
}

const netWorkService = new NetworkService()
export default netWorkService
