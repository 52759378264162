import { ACTION_TYPES } from "../actions/ActionTypes";
import { CONTACTS_CONST } from "../actions/actionTypes/ContactsActionTypes";

const Reducer = (state = null, action) => {

  
  switch (action.type) {
    case ACTION_TYPES.SET_ADDED_CONTACT_DATA:

      return {
        ...state,
        addedcontactData: action.payload,
      };

    case ACTION_TYPES.SET_CONTACTS_PREFIXES:
      return {
        ...state,
        contactsPrefixes: action.payload,
      };
    case ACTION_TYPES.SET_CONTACTS_SUFFIX:
      return {
        ...state,
        contactsSuffix: action.payload,
      };
    case ACTION_TYPES.SET_CONTACTS_PRONOUN:
      return {
        ...state,
        contactspronoun: action.payload,
      };
    case ACTION_TYPES.SET_CONTACT_TYPE_DATA:
      return {
        ...state,
        contactTypeData: action.payload,
      };
    case ACTION_TYPES.SET_CONTACT_LIST_DATA:
      return {
        ...state,
        contactListData: action.payload,
      };
    case ACTION_TYPES.SET_CONTACT_DETAILS_DATA:
      return {
        ...state,
        contactDetailsData: action.payload,
      };
    case ACTION_TYPES.SET_DELETED_CONTACT_DATA:
      return {
        ...state,
        contactDeletedData: action.payload,
      };
    case ACTION_TYPES.SET_ADD_MULTIPLE_CONTACT_DATA:
      return {
        ...state,
        addedmultiplecontactData: action.payload,
      };
    case ACTION_TYPES.SET_UPDATED_CONTACT_DATA:
      return {
        ...state,
        updatedcontactData: action.payload,
      };
    case ACTION_TYPES.DELETE_MULTUPLE_CONTACTS:
      return {
        ...state,
        deletedMultiplecontactData: action.payload,
      };
      
      case CONTACTS_CONST.GET_RECENT_CONTACTS_REQUEST:
      return {
        ...state,
        recentcontactData: "",
        isLoading:true,
        error:""
      }
      
    case CONTACTS_CONST.GET_RECENT_CONTACTS_SUCCESS:
      return {
        ...state,
        recentcontactData: action.payload,
        isLoading:false,
        error:""
      };
    case CONTACTS_CONST.GET_RECENT_CONTACTS_FAILURE:
      return {
        ...state,
        recentcontactData: "",
        isLoading:false,
        error:action.error
      };


    default:
      return state;
  }
};
export default Reducer;
