import React from "react";
import { dropDownOptions } from "../../../constants";
import { customStyles } from "../../../components/common/CustomStyle";

import { Box, Grid, Typography } from "@mui/material";
import GridContainer from "../../../components/GridContainer";

import Select from "react-select";

import Button from "../../../components/Button";
import CloseButton from "../../../components/CloseButton";

const Tab3 = ({
  tab,
  producingbrokeroption,
  errors,
  handleProducingCoverageChange,
  selectedProducingCoverage,
  members,
  selectedgCoverage2,
  handleCoverageChange2,
  addbroker,
  brokerdats,

  removebroker,
  producingbrokers,
}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 3 ? "active" : ""}`}
      id="pills-broker"
      role="tabpanel"
      aria-labelledby="pills-broker-tab"
      tabindex="0"
    >
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Producing broker*</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <Select
            options={dropDownOptions(producingbrokers, "id", "memberName")}
            placeholder="Select Producing broker"
            styles={customStyles(errors.producingbroker)}
            onChange={handleProducingCoverageChange}
            value={selectedProducingCoverage}
            isDisabled={
              JSON.parse(localStorage.getItem("user"))?.extension_Role !==
              "HqAdmin"
            }
          />

          {errors.producingbroker && (
            <Typography
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.producingbroker}
            </Typography>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Servicing broker(s)</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" mb-3">
          <Box>
            <Box className="mt-2">
              <Select
                options={dropDownOptions(members, "id", "memberName")}
                value={selectedgCoverage2}
                placeholder="Select Servicing broker"
                styles={customStyles(errors.members)}
                onChange={handleCoverageChange2}
              />
            </Box>
          </Box>
        </Grid>

        <Grid xs="12" md="6" lg="6" className=" mb-3"></Grid>
        <Grid xs="12" md="6" lg="6" className=" mb-3">
          <Button
            type="button"
            className="btn btn-default add-btn"
            variant={"outlined"}
            color={"primary"}
            onClick={() => addbroker()}
            disabled={!selectedgCoverage2 || selectedgCoverage2?.value === ""}
          >
            Add broker
          </Button>
        </Grid>

        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Servicing brokers added</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" mb-3">
          {brokerdats?.map((item) => {
            return (
              <Box
                className="toast align-items-center d-block mb-3"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                {/*Modified By: Anjali
                         Modified On: 14-03-2024
                         JiraId:https://bosframework.atlassian.net/browse/WBN-230
                         Reason: grey background alignment issue */}

                <Box
                  sx={{ display: "flex", width: "100%" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Box
                    className="toast-body client-text"
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      width: "70%",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          backgroundColor: "lightgrey",

                          padding: "7px",
                          borderRadius: "3px",
                        }}
                      >
                        {item?.memberDisplayName?.split(" ")?.[0]?.[0]}
                        {item?.memberDisplayName?.split(" ")?.[1]?.[0]}
                      </Typography>
                    </Box>

                    <Box sx={{ marginLeft: "10px" }}>
                      {item?.memberDisplayName}

                      <Typography className="membermail">
                        {item.county}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="span" sx={{ marginRight: "1%" }}>
                    <CloseButton onClick={() => removebroker(item.id)} />
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Tab3;
