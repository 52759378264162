import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import GridContainer from "../../../components/GridContainer";
import Select from "react-select";
import { customStyles } from "styles/CustomStyle";

import CloseButton from "../../../components/CloseButton";
import ButtonComponent from "../../../components/Button";
import { dropDownOptions } from "../../../constants";

const Tab1 = ({
  tab,
  from,
  clientDetail,
  selectedgCoverage1,
  handleCoverageChange1,
  errors,
  members,
  addbroker,
  removebroker,
  removebrokerFromDetails,
  brokerDetailsdata,
  brokerdats,
  producingBrokerName,
  producingBrokerCountry,
  

}) => {
  
  return (
    <Box
      className={`tab-pane fade tab-data show ${tab === 1 ? "active" : ""}`}
      id="pills-association"
      role="tabpanel"
      aria-labelledby="pills-association-tab"
      tabIndex="0"
    >
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Client</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-name d-flex align-items-center">
            <Box className="client-initials" sx={{ marginRight: "10px" }}>
              {/* AB */}
              {from === "listing"
                ? `${clientDetail[0]?.clientName?.split(" ")[0]?.[0]} ${clientDetail[0]?.clientName?.split(" ")[1]?clientDetail[0]?.clientName?.split(" ")[1]?.[0]:""}`
                : `${clientDetail?.clientName?.split(" ")[0]?.[0]} ${clientDetail?.clientName?.split(" ")[1]?clientDetail?.clientName?.split(" ")[1]?.[0]:""}`
                ? `${clientDetail?.clientName?.split(" ")[0]?.[0]} ${clientDetail?.clientName?.split(" ")[1]?clientDetail?.clientName?.split(" ")[1]?.[0]:""}`
                : ""}
            </Box>
            <Box className="client-text">
              {from === "listing"
                ? clientDetail[0]?.clientName
                : clientDetail?.clientName
                ? clientDetail?.clientName
                : ""}
            {/* </Box> */}
            <Box className="membermail">
              {from === "listing"
                ? clientDetail[0]?.country
                : clientDetail?.country}
            </Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Producing broker*</Box>
          <Box className="client-subtext">Define from client</Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-name d-flex align-items-center">
            <Box className="client-initials" >
              {producingBrokerName?.split("")[0]}
            </Box>
            <Box className="client-text" sx={{marginLeft:"10px"}}>
            {/* <Box>{producingBrokerName}</Box> */}
            {producingBrokerName}
            <Box className="membermail">{producingBrokerCountry}</Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Servicing broker</Box>
          <Box className="client-subtext">Only servicing brokers already associated with this client will appear here</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" position-relative mb-3">
          <Select
            aria-label="Default select example"
            value={selectedgCoverage1}
            onChange={handleCoverageChange1}
            styles={customStyles(errors.servicingbroker)}
            // options={servicingbrokeroptions}
            // options={membersOption(members)}
            options={dropDownOptions(members , "id" , "memberName")}
          />

          {errors.servicingbroker && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.servicingbroker}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <ButtonComponent
            type="button"
            className="btn btn-default add-btn add-broker-btn"
            onClick={() => addbroker()}
            variant={"outlined"}
            color={"primary"}
            disabled={!selectedgCoverage1 || selectedgCoverage1?.value===""}
          >
            Add broker
          </ButtonComponent>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Servicing brokers added</Box>
        </Grid>

        {/*Modified By: Anjali
                 Modified On: 11-03-2024
                 JiraId:https://bosframework.atlassian.net/browse/WBN-226
                 Reason: grey background alignment issue */}

        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          {brokerdats?.map((item) => {
            return (
              <Box
                className="toast align-items-center d-block mb-3"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <Box
                  sx={{ display: "flex"  , width:"100%"}}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Box
                    className="toast-body"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // paddingBottom: "0px",
                      width:"70%"
                    }}
                  >
                    <Box>
                    <Typography
                      style={{
                        backgroundColor: "lightgrey",

                        padding: "7px",
                        borderRadius: "3px",
                      }}
                    >
                      {item?.memberDisplayName?.split(" ")?.[0]?.[0]}{item?.memberDisplayName?.split(" ")?.[1]?.[0]}
                    </Typography>
                    </Box>
                    <Box className="client-text" style={{ marginLeft: "10px"  , width:"100%"}}>
                    {/* <Typography style={{ marginLeft: "10px" }}>
                      {item.memberName}
                    </Typography> */}
                    {item.memberName}
                    <Typography  className="membermail">
                      {item.country}
                    </Typography>
                    </Box>
                  </Box>

                  <Typography variant="span" sx={{ marginRight: "1%" }}>
                    <CloseButton onClick={() => removebroker(item.id)} />
                  </Typography>
                </Box>
              </Box>
            );
          })}
          
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Tab1;
