import React, { useEffect, useState } from 'react';

const HealthCheck = () => {
  const [status, setStatus] = useState('Healthy');

  useEffect(() => {
    // Check application state
    if (!document.getElementById('root')) {
      setStatus('Unhealthy');
    }

    
  }, []);

  return (
    <div>
      <h1>{status}</h1>
    </div>
  );
};

export default HealthCheck;
