import React from "react";

import Grid from "@mui/material/Grid";
import Card from "../../components/Card";
import Box from "@mui/material/Box";
import GridContainer from "../../components/GridContainer";
import ReactCountryFlag from "react-country-flag";
import { countryIdToCodeMap } from "../../constants/Countrycodes";

const RecentContacts = ({ recentContactsData , handleRecentContacts }) => {
  return (
    <Box>
      <Box className="subtext" sx={{ marginTop: "20px" }}>
        Recent Contacts
      </Box>
      <GridContainer>
        {recentContactsData?.length>0?recentContactsData?.map((item, key) => {
          return (
            <Grid item
              xs={12}
              md={4}
              lg={3}
              key={item?.contactId}
              className="card-parent contact-card-parent"
              sx={{cursor:"pointer"}}
              onClick={()=> handleRecentContacts(item.clientId , item.contactId)}
            >
              <Card className="recent-contacts">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={"space-between"}
                >
                  <Box>
                    {/* <Box className="flag-country">
                      <Box
                        className={"flag " + item.country?.toLowerCase()}
                        id={item.country?.toLowerCase()}
                      ></Box>
                    </Box> */}
                    {/* <Box className="country">  */}
                    <Box>
                    <ReactCountryFlag countryCode={countryIdToCodeMap[item.countryId]} svg style={{width:"30px" , height:"19px"}} /> 
                    {" "}
                    {item?.country}
                    </Box>
                    {/* </Box> */}
                  </Box>
                </Box>
                <Box className="mt-auto company-name">
                  {item?.firstName}
                  {item?.lastName}
                </Box>
                <Box className="company-subname">{item?.contactType?.name}</Box>
              </Card>
            </Grid>
          );
        }):<Box className="company-name" sx={{color:"var(--color-text-grey-tertiary)"}}>No recent contacts have been added</Box>}
      </GridContainer>
    </Box>
  );
};

export default RecentContacts;
