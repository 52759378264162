import { toast } from "react-toastify";
const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));

export const promiseToast = (promise, options) => {
  return toast.promise(
    promise
      .then((result) => (result.success !== undefined ? result.success : null))
      .catch((error) => {
        console.error("Error showing toast:", error);
        throw error;
      }),

    options,
    {
      hideProgressBar: true,
      position: "bottom-center",
      style: {
        // backgroundColor: "black",
        fill: "#000",
        color: "#FFF",
      },
      className: {
        success: "custom-success-toast",
      },
      theme: "dark",
    }
  );
};
export const errorToast = (message) => {
  toast.error(message, {
    hideProgressBar: true,
    position: "bottom-center",
    style: {
      // backgroundColor:"red",
      color: "#FFF",
    },
    theme: "colored",
    icon: "",
  });
};
export const successToast = (message) => {
  toast.success(message, {
    hideProgressBar: true,
    position: "bottom-center",
    style: {
      // backgroundColor:"",
      color: "#FFF",
    },
    theme: "dark",
    // icon:<i />
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="14"
        viewBox="0 0 448 512"
      >
        <path
          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
          fill="#FFF"
        />
      </svg>
    ),
  });
};
export const loadingToast = (message) => {
  toast.loading(message, {
    hideProgressBar: true,
    position: "bottom-center",
    style: {
      // backgroundColor:"",
      color: "#FFF",
    },
    theme: "dark",
    // icon:<i />
    
  });
};
