import React, { useEffect, useState } from "react";

import DialogTitle from "@mui/material/DialogTitle";

import DialogContent from "@mui/material/DialogContent";

import "../../components/common/Custom.css";
// import AlertDialog from "../../components/Modal";
import Box from "@mui/material/Box";

import { RenderTextInput } from "../../components/TextField";
import ButtonComponent from "../../components/Button";
import GridContainer from "../../components/GridContainer";

import InputAdornment from "@mui/material/InputAdornment";
import { Grid, Typography } from "@mui/material";
import {useDispatch , useSelector} from "react-redux"
import { searchClientsData, setSearchClientsData } from "../../store/actions/ClientAction";

const MyModal = (props) => {
  const [show, setShow] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [searchword, setSearchword] = useState("");

  const stateData = useSelector((state) => {
    return {
      searchClientsData: state?.fetchData?.searchClientsData,
      
    };
  })
  
  useEffect(() => {
    setShow(props.showMainModal);
    setLoggedInUser(JSON.parse(localStorage.getItem("user")))
  }, []);

  useEffect(()=> {

    return ()=> {
      dispatch(setSearchClientsData(""))
    }
  } , [])
  
  useEffect(() => {
    setClients(props.clients);
  }, []);

  useEffect(()=> {
if(stateData?.searchClientsData?.data?.searchClients?.nodes) {
  // setSearchword(stateData?.searchClientsData?.data?.searchClients?.nodes)
  setClientData(stateData?.searchClientsData?.data?.searchClients?.nodes)
}
  } , [stateData?.searchClientsData?.
    data?.searchClients?.nodes])
  const dispatch = useDispatch()
  const handleSearchClient = (e) => {
    // let word = e.target.value;
    // setSearchword(e.target.value);
    // props.handlesetclientName(e.target.value)
    // let filteredData = clients?.filter((item) =>
    //   item.clientName.toLowerCase().includes(word.toLowerCase())
    // );

    // setClientData(filteredData);

    dispatch(searchClientsData(e?.target?.value))
    props.handlesetclientName(e.target.value)
    setSearchword(e?.target?.value)
  };

  return (
    <Box>
      <Box className="modal-body p-0">
        <Box className="p-3">
          <RenderTextInput
            type="search"
            className="form-control ps-2 p-3 client-name-input"
            placeholder="Type client name"
            onChange={(e) => handleSearchClient(e)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ marginLeft: "27px !important" }}
                >
                  {" "}
                  <img
                    src="wwwroot/assets/images/help-circle.png"
                    className="img-fluid search-info"
                    alt="info"
                    style={{ zIndex: "0 !important" }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ padding: "0px !important" }}
          />
        </Box>

        {searchword &&
          clientData?.map((item, key) => {
            return (
              <>
                <GridContainer sx={{ marginBottom: "10px" }}>
                  <Grid
                    xs="12"
                    md="6"
                    lg="6"
                    className="col-12 col-md-6 col-lg-6 search-client-name"
                    sx={{ paddingLeft: "3%" }}
                  >
                    {item.clientName}
                  </Grid>
                  {((item.isBroker === true)  ||  (loggedInUser?.extension_Role==="HqAdmin" || loggedInUser?.extension_Role==="HqContributor")) ? 
                  <Grid
                    xs="12"
                    md="6"
                    lg="6"
                    className="col-12 col-md-6 col-lg-6 search-client-location"
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleOpenlocation(item.clientId)}
                  >
                    {/* Add as location */}
                    Add new location
                  </Grid>
                  :""}
                </GridContainer>
              </>
            );
          })}

        {/* {searchword &&
          clientData?.map((item, key) => {
            return (
              <>
                <GridContainer sx={{ marginBottom: "10px" }}>
                  <Grid
                    xs="12"
                    md="6"
                    lg="6"
                    className="col-12 col-md-6 col-lg-6 search-client-name"
                    sx={{ paddingLeft: "3%" }}
                  >
                    {item.clientName}
                  </Grid>
                  <Grid
                    xs="12"
                    md="6"
                    lg="6"
                    className="col-12 col-md-6 col-lg-6 search-client-location"
                  >
                    Contact Company
                  </Grid>
                </GridContainer>
              </>
            );
          })} */}
        {searchword && (
          <Box className="row btn-row">
            {/* <Typography variant="span"> */}
            <ButtonComponent
              // type="button"
              className="btn btn-default add-policy"
              // data-bs-target="#createNewToggle2"
              // data-bs-toggle="modal"
              onClick={() => props.handleShowSecondaryModal()}
              // variant={"outlined"}
              variant={"contained"}
              // color={"white"}
              color={"grey"}
              // sx={{width:"100%"}}
            >
              Create as new
            </ButtonComponent>
            {/* </Typography> */}
          </Box>
        )}

        {/*Modified By: Anjali
        Modified On: 05-03-2024
        JiraId: https://bosframework.atlassian.net/browse/WBN-106
        Reason: close modal when clicked on client check*/}

        {searchword && clientData?.length === 0 && (
          <GridContainer
            className="p-2 check-status-div align-items-center"
            sx={{ width: "auto !important" }}
          >
            <Grid xs="12" md="8" lg="8">
              <Box className="main-text">Quick client check</Box>
              <Box className="sub-text">
                Could this be added to an existing client as a location?
              </Box>
            </Grid>
            <Grid
              xs="12"
              md="4"
              lg="4"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                type="button"
                className="btn btn-default check-clients-btn"
                onClick={() => props.onClose()}
              >
                Check clients
              </button>
            </Grid>
          </GridContainer>
        )}
        {/* <div class="row p-2 check-status-div align-items-center">
                <div class="col-12 col-md-8 col-lg-8">
                  <div class="main-text">Quick client check</div>
                  <div class="sub-text">
                    Could this be added to an existing client as a location?
                  </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4">
                  <button
                    type="button"
                    class="btn btn-default check-clients-btn"
                  >
                    Check clients
                  </button>
                </div>
              </div> */}
      </Box>
    </Box>
  );
};

export default MyModal;
