import React from "react";


import "assets/css/Custom.css"
import { StyledTextField } from "styles/CustomStyle";
export const RenderTextInput = ({
  block,
  errors,
  label,
  mask,
  onChange,
  placeholder,
  required,
  disabled,
  value,
  onfocus,
  messageInput,
  maxLength,
  startDate,
  onKeyDown,
  maxDate,

  onPaste,

  ...rest
}) => (
  <>
    {label && <label className="form-label">{label}</label>}

    <StyledTextField
      type={mask ? "password" : "text"}
      className={errors?"is-invalid form-control":"form-control"}
      // id="exampleFormControlInput1"
      onChange={onChange}
      disabled={disabled}
      onFocus={onfocus}
      value={value}
      maxLength={maxLength ? maxLength : null}
      required={required}
      placeholder={placeholder}
      {...rest}
      // error={errors}
      error={!!errors}
      // inputProps={{min:min}}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: startDate, 

      }}
      onKeyDown={onKeyDown}
      
      onPaste={onPaste}
      
    />
    {errors?<div className="invalid-feedback">{errors}</div>:""}
  </>
);
