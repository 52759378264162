import React from "react";
import Dialog from "../../components/Dialog";
import { Box, Typography } from "@mui/material";
import ButtonComponent from "../../components/Button";

const PermissionModal = ({
    showMessagePopup,
  handleClose,
  
}) => {
  return (
    <Dialog
      maxWidth="xs"
      maxheight="150px"
      open={showMessagePopup}
      onClose={() => handleClose(false)}
     
      showCloseIcon="false"
      body={
        <Box sx={{ padding: "15px" , textAlign:'justify !important'}}>
          <Typography variant="p">
            
          Your credentials are now pending. You will receive a notification once credentials have been approved for use
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop:"10px" }}>
            <ButtonComponent
              variant={"outlined"}
              color={"red"}
              
              onClick={()=> handleClose()}
              sx={{width:"49%"}}
            
            >
              Okay
            </ButtonComponent>
            
          </Box>
        </Box>
      }
    />
  );
};
export default PermissionModal;
