import { DASHBOARD_CONST } from "../actions/actionTypes/DashboardActionTypes";

let initialState = {
  tasksList: [],
  createdtask: "",
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_CONST.GET_FREQUENT_COLLABORATORS_REQUEST:
      return {
        ...state,
        frequentCollaboratorsList: [],
        isLoading: true,
      };
    case DASHBOARD_CONST.GET_FREQUENT_COLLABORATORS_SUCCESS:
      return {
        ...state,
        frequentCollaboratorsList: action.payload,
        isLoading: false,
        error: "",
      };
    case DASHBOARD_CONST.GET_FREQUENT_COLLABORATORS_FAILURE:
      return {
        ...state,
        frequentCollaboratorsList: [],
        isLoading: false,
        error: action.error,
      };

      case DASHBOARD_CONST.GET_ACTIVE_CLIENTS_REQUEST:
      return {
        ...state,
        activeClientsList: [],
        isLoading: true,
        error: "",
      }
      
    case DASHBOARD_CONST.GET_ACTIVE_CLIENTS_SUCCESS:
      return {
        ...state,
        activeClientsList: action.payload,
        isLoading: false,
        error: "",
      };
    case DASHBOARD_CONST.GET_ACTIVE_CLIENTS_FAILURE:
      return {
        ...state,
        activeClientsList: [],
        isLoading: false,
        error: action.error,
      };
    

    default:
      return state;
  }
};
export default Reducer;
