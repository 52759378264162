export const TASKS_CONST = {
    GET_TASKS_REQUEST:"GET_TASKS_REQUEST",
    GET_TASKS_SUCCESS:"GET_TASKS_SUCCESS",
    GET_TASKS_FAILURE:"GET_TASKS_FAILURE",

    CREATE_TASKS_REQUEST:"CREATE_TASKS_REQUEST",
    CREATE_TASKS_SUCCESS:"CREATE_TASKS_SUCCESS",
    CREATE_TASKS_FAILURE:"CREATE_TASKS_FAILURE",
    
    GET_TASKS_BY_ID_REQUEST:"GET_TAKS_BY_ID_REQUEST",
    GET_TASKS_BY_ID_SUCCESS:"GET_TASKS_BY_ID_SUCCESS",
    GET_TASKS_BY_ID_FAILURE:"GET_TASKS_BY_ID_FAILURE",
    
    COMPLETE_TASKS_REQUEST:"COMPLETE_TASKS_REQUEST",
    COMPLETE_TASKS_SUCCESS:"COMPLETE_TASKS_SUCCESS",
    COMPLETE_TASKS_FAILURE:"COMPLETE_TASKS_FAILURE",
    
    DELETE_TASKS_REQUEST:"DELETE_TASKS_REQUEST",
    DELETE_TASKS_SUCCESS:"DELETE_TASKS_SUCCESS",
    DELETE_TASKS_FAILURE:"DELETE_TASKS_FAILURE",

    GET_TAGS_REQUEST:"GET_TAGS_REQUEST",
    GET_TAGS_SUCCESS:"GET_TAGS_SUCCESS",
    GET_TAGS_FAILURE:"GET_TAGS_FAILURE",
    
    CREATE_TAGS_REQUEST:"CREATE_TAGS_REQUEST",
    CREATE_TAGS_SUCCESS:"CREATE_TAGS_SUCCESS",
    CREATE_TAGS_FAILURE:"CREATE_TAGS_FAILURE",
    
    UPDATE_TASKS_REQUEST:"UPDATE_TASKS_REQUEST",
    UPDATE_TASKS_SUCCESS:"UPDATE_TASKS_SUCCESS",
    UPDATE_TASKS_FAILURE:"UPDATE_TASKS_FAILURE",
    
    GET_REMINDER_REQUEST:"GET_REMINDER_REQUEST",
    GET_REMINDER_SUCCESS:"GET_REMINDER_SUCCESS",
    GET_REMINDER_FAILURE:"GET_REMINDER_FAILURE",
    
    DELETE_MULTIPLE_TASKS_REQUEST:"DELETE_MULTIPLE_TASKS_REQUEST",
    DELETE_MULTIPLE_TASKS_SUCCESS:"DELETE_MULTIPLE_TASKS_SUCCESS",
    DELETE_MULTIPLE_TASKS_FAILURE:"DELETE_MULTIPLE_TASKS_FAILURE",
    
    GET_TASKS_BY_FILTER_REQUEST:"GET_TASKS_BY_FILTER_REQUEST",
    GET_TASKS_BY_FILTER_SUCCESS:"GET_TASKS_BY_FILTER_SUCCESS",
    GET_TASKS_BY_FILTER_FAILURE:"GET_TASKS_BY_FILTER_FAILURE",


}