import {  DASHBOARD_CONST,  } from "./actionTypes/DashboardActionTypes";
import netWorkService from "../../network/NetworkService";


import "react-toastify/dist/ReactToastify.css";

import * as base from "./BaseActions";
import { startLoading, stopLoading } from "./LoaderAction";

export const getFrequentCollaboratorsList = () => async (dispatch) => {
  dispatch(base.getRequest(DASHBOARD_CONST.GET_FREQUENT_COLLABORATORS_REQUEST));
  dispatch(startLoading())
  const payload = {
    query: `
    query DashboardTasks {
      recentCollaborators(first: 5) {
          nodes {
              id
              name
              email
              profileImage
              memberFirmId
              memberFirmName
              countryId
          }
      }
  }
  
    
  
    
      `,
  };

  return await netWorkService.taksGraphql(payload)
    .then((response) => {
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_FREQUENT_COLLABORATORS_SUCCESS, responseJson)
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_FREQUENT_COLLABORATORS_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};
export const getActiveClientsList = () => async (dispatch) => {
  dispatch(base.getRequest(DASHBOARD_CONST.GET_ACTIVE_CLIENTS_REQUEST));
  dispatch(startLoading())
  const payload = {
    query: `
    query ActiveClient {
      activeClient(first:5) {
          nodes {
              id
              isActive
              isDraft
              isArchived
              dateModified
              clientName
              policiesCount
              countryId
              country
              region
              nextExpiringPolicyDate
              policyStatus
              openTasks
              producingBroker {
                id
                memberName
                memberDisplayName
                street1
                street2
                city
                state
                zipcode
                county
                countryId
                website
                logoBlobKey
                logoFileExtension
                isActive
                isDeleted
                createdOn
                modifiedOn
                createdBy
                modifiedBy
            }
          }
      }
  }
  
  
    
  
    
      `,
  };

  return await netWorkService.clientGraphql(payload)
    .then((response) => {
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(DASHBOARD_CONST.GET_ACTIVE_CLIENTS_SUCCESS, responseJson)
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(DASHBOARD_CONST.GET_ACTIVE_CLIENTS_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};



