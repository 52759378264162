import { Box, Grid } from "@mui/material";
import React from "react";
import GridContainer from "../../../components/GridContainer";

import { RenderTextInput } from "../../../components/TextField";
import Switch from "../../../components/Switch";
import CreatableSelect from "react-select/creatable"
import {  dropDownOptions } from "../../../constants";
import { customStyles } from "styles/CustomStyle"

const Tab3 = ({
  tab,
  handleInspectionDate,
  errors,
  inceptionStartDate,
  handleExpirationDate,
  startDate,
  endDate,
  handleRenewalReminder,
  renewalReminder,
  selectedExclusion,
  handleExclusion,
  exclusion,
  handleExclusionChange,
  handleEvergreenRenewal,
  evergreenRenewal,
  exclusionOption,
  maxDate,
  formatCreateLabel,

}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 3 ? "active" : ""}`}
      id="pills-terms"
      role="tabpanel"
      aria-labelledby="pills-terms-tab"
      tabIndex="0"
    >
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Inception Date*</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="Date"
            id="basicDate"
            placeholder="Select date"
            data-input
            fullWidth
            onChange={(e) => handleInspectionDate(e.target.value)}
            errors={errors.inceptionDate}
            value={inceptionStartDate}
            onKeyDown={(e) => {
              handleInspectionDate(e.target.value)
            }}
            startDate={maxDate?maxDate:""}
          />
        </Grid>
      </GridContainer>
      <GridContainer className="row mt-2">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Expiration Date*</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="Date"
            id="basicDate1"
            placeholder="Select date"
            data-input
            fullWidth
            onChange={(e) => handleExpirationDate(e.target.value)}
            errors={errors.expirationDate}
            startDate={startDate ? startDate : ""}
            value={endDate}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Set renewal reminder</Box>
          <Box className="client-subtext">
            A task reminder will be set 30 days before expiration date
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" ">
          <Switch
            id="checkboxinp"
            type="checkbox"
            onChange={handleRenewalReminder}
            fullWidth
            checked={renewalReminder || false}
            label={"No"}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Exclusions</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          

          
          <CreatableSelect

            options={dropDownOptions(exclusionOption , "id" ,"exclusion")}
            placeholder="Select a exclusion"

            onChange={handleExclusion}

            value={selectedExclusion}
            styles={customStyles(errors?.exclusion)}
            formatCreateLabel={formatCreateLabel}
            isMulti
            isClearable
            noOptionsMessage={({ inputValue }) =>
              inputValue ? null : exclusionOption?.length===0&&"Type to create an option"
            } 
          />

                {errors.exclusion && (
                <Box
                  className="error-msge"
                  style={{ color: "#dc3545", fontSize: "14px" }}
                >
                  {errors.exclusion}
                </Box>
              )}

        </Grid>
        
        <Grid item xs={12} md={6} lg={6} className=" mt-2">
          
          

        </Grid>
        
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Tacit/Evergreen Renewal</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" ">
          <Switch
            id="checkboxinp"
            type="checkbox"
            onChange={handleEvergreenRenewal}
            fullWidth
            checked={evergreenRenewal || false}
            label={"No"}
          />
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default Tab3;
