import { ACTION_TYPES } from "./ActionTypes";
import NetworkService from "../../network/NetworkService";
import { errorToast, loadingToast,  successToast } from "../../components/common/Toast";
import {  toast } from "react-toastify"
import { startLoading, stopLoading } from "./LoaderAction";

export const getDocumentsTypes = (params) => (dispatch) => {
  return NetworkService.getDocumentTypes(params)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setDocumentstypesData(responseJson));
        });
      }
    })
    .catch((error) => {
      
    });
};

export const setDocumentstypesData = (payload) => ({
  type: ACTION_TYPES.GET_DOCUMENTS_TYPE,
  payload,
});
export const addDocuments = (params , headers) => (dispatch) => {
  return NetworkService.uploadDocument(params , headers)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(addDocumentData("success"))
        
      }
    })
    .catch((error) => {
      
    });
};

export const addDocumentData = (payload) => ({
  type: ACTION_TYPES.ADD_DOCUMENT,
  payload,
})

export const uploaddocument = (params , from , headers) => (dispatch) => {
    loadingToast("Adding document...")
    dispatch(startLoading())

  
   return NetworkService.uploadDocument(params , headers)
      .then((response) => {
        toast.dismiss()
        dispatch(stopLoading())

        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          dispatch(uploadDocumentData(`success-${from}`));
          setTimeout(()=> successToast("Document has been added successfully") , 980)
          return "added";
        } else if (response.status === 400) {
          response.json().then((responseJson) => {
            dispatch(uploadDocumentData(responseJson));
            
            setTimeout(()=> errorToast("Failed to add document") , 980)
            
          });
        }
      })
      .catch((error) => {
        
        setTimeout(() => errorToast("Failed to add document. Please try again later"), 980)
      })
      
};
export const uploadMultipledocument = (params , from , headers , fileName) => async(dispatch) => {
    
    dispatch(startLoading())

  
   return await NetworkService.uploadDocument(params , headers)
      .then((response) => {
        toast.dismiss()
        dispatch(stopLoading())

        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.ok === true
        ) {
          dispatch(uploadDocumentData(`success-${from}`));
          
          return "added";
        } else if (response.status === 400) {
          response.json().then((responseJson) => {
            dispatch(uploadDocumentData(responseJson));
            
            setTimeout(()=> errorToast(`Failed to add ${fileName}`) , 980)
            
          });
        }
      })
      .catch((error) => {
        
        setTimeout(() => errorToast("Failed to add document. Please try again later"), 980)
      })
      
};

export const uploadDocumentData = (payload) => ({
  type: ACTION_TYPES.UPLOAD_DOCUMENT,
  payload,
});

export const getDocuemntsData = (payload) => (dispatch) => {
  let params = {
    query: `
    query DocumentsByClient {
      documentsByClient {
          nodes {
              documentId
              documentTypeId
              documentTypeName
              documentName
              documentDisplayName
              description
              fileFormat
              isArchived
              accessGroup
              accessGroupId
          }
      }
  }
    `,
  };
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    clientId: "5a28235b-182c-4290-bfe9-8e1f88a52b68",
    UserId: payload.UserId,
    ProducingFirmId: payload.ProducingFirmId,
  };
  return NetworkService.getDocuments(params, headers)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            setDocumentsData(responseJson?.data?.documentsByClient?.nodes)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const setDocumentsData = (payload) => ({
  type: ACTION_TYPES.GET_DOCUMENTS_DATA,
  payload,
});
export const updateDocuemntsData = (payload , from , header) => (dispatch) => {
   
  return NetworkService.updateDocuments(payload , from , header)
    .then((response) => {
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        
          dispatch(
            setupdateDocumentsData("success-Policy")
          );
        
      }
    })
    .catch((error) => {
      
    });
};

export const setupdateDocumentsData = (payload) => ({
  type: ACTION_TYPES.GET_UPDATE_DOCUMENTS_DATA,
  payload,
});

