import React from "react";

//Material-UI components
import { Box, Typography } from "@mui/material";
import Select from "react-select";

//Custom styles for components
import {
  customStyles,
  usersCountryDropdown,
} from "../../components/common/CustomStyle";

import {
  countriesOptions,
  customFilterOption,
  dropDownOptions,
} from "../../constants";

const FilterSection = ({
  countries,

  handleSelectMember,

  selectedMember,

  members,

  loggedInUserDetails,

  selectedCountry,
  handleCountryChange,
  handleActiveTab,
  activeTab,

  handleNewUser,
}) => {
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      height={30}
    >
      <Typography
        variant="span"
        className="view-text me-2 filter-by"
        style={{
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        Filter by
      </Typography>
      <Box className="d-flex align-items-center filter-data">
        <Box
          sx={{ fontSize: "14px" }}
          className={activeTab === "All" ? "active" : ""}
          onClick={() => handleActiveTab("All")}
        >
          All
        </Box>
        {loggedInUserDetails?.extension_Role === "HqAdmin" && (
          <Typography
            variant="span"
            sx={{ width: "140px", zIndex: "100 !important", fontSize: "14px" }}
          >
            <Select
              options={dropDownOptions(members, "id", "memberName")}
              placeholder="Member"
              onChange={handleSelectMember}
              value={selectedMember}
              styles={{ ...customStyles(), ...usersCountryDropdown() }}
              className="users-country-select"
              maxMenuHeight={300}
            />
          </Typography>
        )}
        <Typography
          variant="span"
          sx={{ width: "200px", zIndex: "100 !important", fontSize: "14px" }}
        >
          <Select
            className="users-country-select"
            options={countriesOptions(countries)}
            placeholder="Country"
            filterOption={customFilterOption}
            onChange={handleCountryChange}
            value={selectedCountry}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            dropdownPosition="auto"
            maxMenuHeight={300}
          />
        </Typography>

        <Box
          sx={{ fontSize: "14px" }}
          className={
            activeTab === "NewUsers"
              ? "active users-new-user"
              : "users-new-user"
          }
          onClick={() => handleActiveTab("NewUsers")}
        >
          New users
        </Box>
      </Box>
    </Box>
  );
};
export default FilterSection;
