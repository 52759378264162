import React from "react";

const NavigationButton = ({ handleTab, sx, arrowDirection }) => {
  return (
    <button
      onClick={handleTab}
      style={{
        border: "1px solid  grey",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        padding: "5px",
        ...sx,
      }}
    >
      {arrowDirection === "left" ? (
        <img src="/wwwroot/assets/images/arrow-left.png" alt="arrow-left" />
      ) : (
        <img src="/wwwroot/assets/images/arrow-right.png" alt="arrow-right" />
      )}
    </button>
  );
};
export default NavigationButton;
