export const defaultStyle = {
    control: {
      backgroundColor: "#fff",
    //   fontSize: 18,
      fontWeight: "normal",
      // color:"red !important"
    },
    highlighter: {
      // overflow: "hidden",
      backgroundColor: "#fff",
    },
    // input: {
    //   margin: 0,
    //   overflow: "auto",
    //   height: 30
    // },
    // input: {
    //   margin: 0,
    //   overflow: "hidden",
    //   height: 30
    // },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
        border: "1px solid silver"
      },
      highlighter: {
        padding: 9,
        // color:"blue"
      },
      // input: {
      //   padding: 9,
      //   minHeight: 3,
      //   outline: 0,
      //   border: 0
      // }
    },
    suggestions: {
      bottom: "0",
      top: "unset",
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
        zIndex:"3 !important"
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#cee4e5",
          zIndex:"3 !important"
        }
      }
    }
  }

  export const defaultMentionStyle = {
    backgroundColor: "#fff",
    color:"#6941c6",
    bottom:0,
    padding:"1px"
    // zIndex:100
  }