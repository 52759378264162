export const NOTIFICATION_CONST = {
    GET_NOTIFICATION_REQUEST:"GET_NOTIFICATION_REQUEST",
    GET_NOTIFICATION_SUCCESS:"GET_NOTIFICATION_SUCCESS",
    GET_NOTIFICATION_FAILURE:"GET_NOTIFICATION_FAILURE",

    GET_LATEST_NOTIFICATION_REQUEST:"GET_LATEST_NOTIFICATION_REQUEST",
    GET_LATEST_NOTIFICATION_SUCCESS:"GET_LATEST_NOTIFICATION_SUCCESS",
    GET_LATEST_NOTIFICATION_FAILURE:"GET_LATEST_NOTIFICATION_FAILURE",

    CLEAR_NOTIFICATION_REQUEST:"CLEAR_NOTIFICATION_REQUEST",
    CLEAR_NOTIFICATION_SUCCESS:"CLEAR_NOTIFICATION_SUCCESS",
    CLEAR_NOTIFICATION_FAILURE:"CLEAR_NOTIFICATION_FAILURE",
    
    MARK_ALL_AS_READ_NOTIFICATION_REQUEST:"MARK_ALL_AS_READ_NOTIFICATION_REQUEST",
    MARK_ALL_AS_READ_NOTIFICATION_SUCCESS:"MARK_ALL_AS_READ_NOTIFICATION_SUCCESS",
    MARK_ALL_AS_READ_NOTIFICATION_FAILURE:"MARK_ALL_AS_READ_NOTIFICATION_FAILURE",

}