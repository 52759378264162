import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function TabsWrappedLabel({
  viewby,
  filterby,
  handleTab,
  handleFilter,
  filterTab,
  viewType,
  showViewBy,
}) {
  // Ensure default value is valid
  const [value, setValue] = React.useState(viewby?.[0] || "");
  const [filterType, setFilterType] = React.useState(filterTab || "");

  useEffect(() => {
    // Ensure viewType matches an item in viewby or default to the first item
    if (viewType && viewby && viewby.includes(viewType)) {
      setValue(viewType);
    } else {
      setValue(viewby?.[0] || "");
    }
  }, [viewType, viewby]);

  useEffect(() => {
    // Ensure filterTab matches an item in filterby or default to the first item
    if (filterTab && filterby && filterby.includes(filterTab)) {
      setFilterType(filterTab);
    } else {
      setFilterType(filterby?.[0] || "");
    }
  }, [filterTab, filterby]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTab(newValue);
  };

  // Ensure valid value for Tabs component
  const validViewbyValue = viewby?.includes(value) ? value : viewby?.[0] || "";
  const validFilterValue = filterby?.includes(filterType) ? filterType : filterby?.[0] || "";

  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      height={30}
    >
      {showViewBy === false ? null : (
        <>
          <div className="view-text view-by">View by</div>
          <Tabs
            value={validViewbyValue}  // Use the valid value here
            onChange={handleChange}
            aria-label="View tabs"
            className="nav nav-tabs"
            sx={{ minWidth: "50" }}
          >
            {viewby?.map((item) => (
              <Tab
                key={item}
                value={item}
                label={item}
                style={{ textTransform: "none" }}
                className="nav-item"
                sx={{ minWidth: 40 }}
              />
            ))}
          </Tabs>
        </>
      )}
      <div className="view-text ps-4 me-2 filter-by">Filter by</div>
      <Tabs
        value={validFilterValue}  // Use the valid value here
        aria-label="Filter tabs"
        className="filter-data"
        sx={{ minWidth: "100" }}
      >
        {filterby?.map((item) => (
          <Tab
            key={item}
            value={item}
            label={item}
            style={{ textTransform: "none" }}
            sx={{ minWidth: "40px", marginRight: "12px" }}
            onClick={() => handleFilter(item)}
          />
        ))}
      </Tabs>
    </Box>
  );
}
