import React from "react";

import { blockInvalidChar, countriesOptions, customFilterOption } from "../../../constants";
import { Box, Grid, Typography } from "@mui/material";
import GridContainer from "../../../components/GridContainer";
import { RenderTextInput } from "../../../components/TextField";
import Select from "react-select";
import { customStyles } from "../../../components/common/CustomStyle";
import Button from "../../../components/Button";
import CloseButton from "../../../components/CloseButton";

const Tab2 = ({
  tab,
  setClientLocationName,
  resetFieldError,
  clientLocationName,
  errors,
  setClientHeadcount,
  clientHeadcount,
  setClientLocationNumber,
  countries,
  clientLocationNumber,
  setClientcity,
  clientcity,
  setClientstate,
  clientstate,
  setClientFirstLine,
  clientFirstLine,
  setClientSecondLine,
  clientSecondLine,
  handleCountry,
  country,
  setClientPostcode,
  clientPostcode,
  addlocation,
  locationdata,
  removelocationDetails,
  locationDetailsData,
  removelocation,
}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 2 ? "active" : ""}`}
      id="pills-add-new-loc"
      role="tabpanel"
      aria-labelledby="pills-add-new-loc-tab"
      tabindex="0"
    >
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Location details</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <Box className="mb-12">
            <Box className="client-subtext">Location name </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="Location name"
              onChange={(e) => {
                setClientLocationName(e.target.value);
                resetFieldError("clientLocationName" , e.target.value);
                resetFieldError("duplicateLocationName");
              }}
              value={clientLocationName}
              errors={errors.clientLocationName}
            />
          </Box>
          <Box>
            <Box className="client-subtext">Headcount </Box>
            <RenderTextInput
              type="number"
              min={0}
              id="exampleFormControlInput1"
              placeholder="Head count"
              maxLength={225}
              onChange={(e) => {
                setClientHeadcount(e.target.value);
                resetFieldError("clientHeadcount");
              }}
              onKeyPress={(e) => {
                // Get the pressed key code
                const keyCode = e.which || e.keyCode;

                // Allow digits (0-9) and prevent the minus sign
                if (keyCode < 48 || keyCode > 57) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get the pasted text
                const pastedText = e.clipboardData.getData("text");

                // Prevent pasting if it contains a minus sign
                if (pastedText.includes("-")) {
                  e.preventDefault();
                }
              }}
              value={clientHeadcount}
              errors={errors.clientHeadcount}
              onKeyDown={blockInvalidChar}
            />
          </Box>

          <Box className="mb-2">
            <Box className="client-subtext">Location number </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="Location number"
              onChange={(e) => {
                setClientLocationNumber(e.target.value);
                resetFieldError("clientLocationNumber" , e.target.value);
              }}
              value={clientLocationNumber}
              errors={errors.clientLocationNumber}
            />
          </Box>
          <Box className="mb-2">
            <Box className="client-subtext">City </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="City"
              onChange={(e) => {
                setClientcity(e.target.value);
                resetFieldError("clientCity" , e.target.value);
              }}
              value={clientcity}
              errors={errors.clientCity}
            />
          </Box>
          <Box className="mb-2">
            <Box className="client-subtext">State </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="State"
              onChange={(e) => {
                setClientstate(e.target.value);
                resetFieldError("clientState" , e.target.value);
              }}
              value={clientstate}
              errors={errors.clientState}
            />
          </Box>
          <Box>
            <Box className="client-subtext">Address </Box>
            <Typography sx={{ marginBottom: "10px" }}>
              <RenderTextInput
                type="email"
                id="exampleFormControlInput1"
                placeholder="First line"
                onChange={(e) => {
                  setClientFirstLine(e.target.value);
                  resetFieldError("clientFirstLine" , e.target.value);
                }}
                maxLength={225}
                value={clientFirstLine}
                errors={errors.clientFirstLine}
              />
            </Typography>

            <Typography sx={{marginBottom:"10px"}}>
            <RenderTextInput
              type="email"
              className="form-control mb-2"
              id="exampleFormControlInput1"
              placeholder="Second line"
              onChange={(e) => {setClientSecondLine(e.target.value);resetFieldError("clientSecondLine" , e.target.value)}}
              maxLength={225}
              value={clientSecondLine}
              
              errors={errors.clientSecondLine}

            />

</Typography>

            <Box className="country-flag mb-3">
              <Select
                options={countriesOptions(countries)}
                placeholder="Select a country"
                filterOption={customFilterOption}
                styles={customStyles(errors.clientContry)}
                onChange={(e) => {
                  handleCountry(e);
                  resetFieldError("clientContry");
                }}
                value={country}
              />
            </Box>
            {errors.clientContry && (
              <Typography
                className="error-msge"
                style={{ color: "#dc3545", fontSize: "14px" }}
              >
                {errors.clientContry}
              </Typography>
            )}
            <RenderTextInput
              type="email"
              id="exampleFormControlInput1"
              placeholder="Zip/Postcode"
              onChange={(e) => {
                setClientPostcode(e.target.value);
                resetFieldError("clientPostcode" , e.target.value);
              }}
              value={clientPostcode}
              errors={errors.clientPostcode}
            />
          </Box>
          <Grid xs="12" md="6" lg="6" className=" mb-3"></Grid>

          {errors?.duplicateLocationName ? (
            <Grid xs="12" md="12" lg="12" className=" mb-3">
              <Typography
                className="error-msge"
                style={{ color: "#dc3545", fontSize: "14px" }}
              >
                {errors?.duplicateLocationName}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid className=" mb-3">
            <Button
              type="button"
              className="btn btn-default add-btn"
              variant={"outlined"}
              color={"primary"}
              onClick={() => addlocation()}
            >
              Add Location
            </Button>
          </Grid>
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Add location</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" mb-3">
          {locationdata?.map((item, key) => {
            return (
              <Box
                className="toast align-items-center d-block mb-3"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <Box
                  sx={{ display: "flex" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Box className="toast-body">
                    {item.LocationName} {item.LocationNumber} {item.Zipcode}{" "}
                    {item.Street1} {item.Street2} <br />
                    {item.County} {item.State} {item.City}{" "}
                  </Box>

                  <Typography sx={{ marginRight: "10px" }}>
                    <CloseButton
                      onClick={() =>
                        removelocation(
                          item?.id ? item?.id : key,
                          item?.id ? "id" : "index"
                        )
                      }
                    />
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Tab2;
