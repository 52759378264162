

import {ACTION_TYPES} from "../actions/ActionTypes"

let initialState = {
    createuser:"",
    userList:[],
    userByid:"",
    updateduser:""

}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CREATE_USER_SUCCESS:
            return {
                ...state,
                createduser:action.payload
            }
        case ACTION_TYPES.GET_USER_LIST_SUCCESS:
            return {
                ...state,
                usersList:action.payload
            }
        case ACTION_TYPES.GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                userByid:action.payload
            }
        case ACTION_TYPES.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateduser:action.payload
            }
        case ACTION_TYPES.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteduser:action.payload
            }
        case ACTION_TYPES.SET_CHANGE_FIRST_LOGIN:
            return {
                ...state,
                changeFirstLogin:action.payload
            }
        case ACTION_TYPES.SET_USER_BY_FILTER_DATA:
            return {
                ...state,
                usersList:action.payload
            }
        case ACTION_TYPES.GET_USER_LIST_FAILURE:
            return {
                ...state,
                usersList:[],
                error:action.payload
            }
        
        default: return state
    }
}
export default Reducer