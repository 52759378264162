import { Checkbox, Dialog, TableCell, TextField, Tooltip, styled, tableCellClasses, tooltipClasses } from "@mui/material";



import Switch from '@mui/material/Switch';

import Typography from '@mui/material/Typography';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

export const customStyles = (error) => {
    return {
      control: (provided, state) => {
        
        return {
          ...provided,
          
          fontFamily: 'Inter !important',
          borderColor: error
            ? "#dc3545"
            : state.isFocused
            ? "#7F56D9"
            : provided.borderColor,
          borderWidth: state.isFocused ? "2px" : error ? "2px" : "1px",
          // boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(127, 86, 217, 0.25)' : provided.boxShadow,
          boxShadow: state.isFocused ? "" : provided.boxShadow,
          "&:hover": {
            borderColor: error? "#dc3545" : "#7F56D9" ,
            borderWidth:  "2px",
          },
        };
      },
      
      

    };
  }


export const tagStyles = () =>  {
 return { multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.color, // Set background color based on the color property of the option
    // You can add more styling properties here as needed
    borderRadius: "10px"
  }),
}
}

 export const usersCountryDropdown = () => {
    return{
      control: (provided, state) => {
        
        return {
          ...provided,
          minHeight:'30px !important',
          height: '30px !important',
          border:'none !important',
          color:'#000',
        }},
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display:'none',
        
      }),
      indicatorContainer: (provided, state) => ({
        ...provided,
       // backgroundColor: 'red',
        display:'flex',
        alignItems:'center',
        height:'30px !important',
        minHeight:'30px !important',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
       height:'30px !important',
        minHeight:'30px !important',
        alignItems:'center',
        color:'#101828',
      }),
      clearIndicator: (provided, state) => ({
        ...provided,
        height:'30px !important',
        minHeight:'30px !important',
      }),

      placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#000 !important',
            fontWeight:"bold"
        }
    }
    
    }
  }

  //mui styled components
  export const  StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      width: "100%",
      // minHeight: "300px",
      minHeight: "fit-content",
    },
    "& .MuiDialogContent-root": {
      flexDirection: "column",
      overflowY: "auto", 
      maxHeight: "calc(100vh - 80px)", 
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1)
    },
    ".MuiBackdrop-root": {
      backdropFilter:"blur(3px)"
    },
    ".MuiDialog-container": {
minHeight:"auto" , 
padding: '0px !important'
    },
    ".MuiDialog-paper": {
      backgroundColor:"transparent",
      boxShadow:"none",
      borderStyle:"none"
    },
    ".MuiDialogContent-dividers": {
      backgroundColor:"#FFF"
    }
    
  }));
  
  
  
  
  





export const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'var(--brand-800)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : 'var(--brand-800)',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  ".MuiSwitch-thumb": {
    backgroundColor:"#FFF"
  }
}));





export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    borderBottom: "1px solid var(--color-border-default)",
    background: "var(--color-bg-fill-tertiary)",
    // color: theme.palette.common.white,
    color: "var(--color-text-grey-secondary)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root" : {
    padding:0,
    border:"grey !important",
    // color:"var(--brand-800)"
    color:"rgba(0,0,0,.25) !important"
  },
  "&.Mui-checked": {
    color:"var(--brand-800) !important"
  }
}))
export const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiCheckbox-root" : {
    padding:0
  }
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "&.MuiInputBase-root" : {
    padding: '3px 0 3px 8px !important'
  },
  "&.MuiOutlinedInput-root" : {

  },
  "&.MuiInputBase-colorPrimary" : {

  },
  // "& label.Mui-focused" : {
  //   borderColor:"var(--brand-800)",
  //   borderWidth:"2px"
  // }
  "& .MuiOutlinedInput-root": {
    padding:"5px !important",
    "&.Mui-focused fieldset": {
      // borderColor: "var(--brand-800)",
      borderColor: "#7F56D9 !important",
      boxShadow:"0 0 0 0.1rem #7F56D9 !important",
      borderWidth:"1px !important",
    },
    "&.Mui-error fieldset": {
      borderColor: "#dc3545 !important",
      borderWidth:"0px !important",
      boxShadow:"0 0 0 0.1rem #dc3545 !important",
    }
  },
  
}))




  

export  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: grey;
    background: #fff;
    border: 1px solid grey !important;
    

    &:hover {
      border-color: #7F56D9;
    }

    &:focus {
      border-color: #7F56D9;
      box-shadow: 0 0 0 0.1rem #7F56D9;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }))