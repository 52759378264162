import {  TASKS_CONST } from "./actionTypes/TasksActionTypes";
import netWorkService from "../../network/NetworkService";

import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  
  loadingToast,
} from "components/Toast";
import * as base from "./BaseActions";
import {  startLoading, stopLoading } from "./LoaderAction"

const getQueryParam = (params) => {
  
  switch(params?.filterName) {
    case "All":
      return `isAll:true  isUpcomingFilter: false  where: {  isCompleted: { eq: false } ${(params?.tagId && params?.tagId!=="")? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""} ${(params?.clientId && params?.clientId!=="") ? `clientId: { eq: "${params.clientId}" }`:""}}`
    case "Completed":
      return `where: { isCompleted: { eq: true } ${params?.tagId&&params?.tagId!==""? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""} ${params?.clientId&&params?.clientId!=="" ? `clientId: { eq: "${params.clientId}" }`:""}} isUpcomingFilter: false`
    case "Draft":
      return `where: { isDraft: { eq: true } ${params?.tagId&&params?.tagId!==""? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""} ${params?.clientId&&params?.clientId!=="" ? `clientId: { eq: "${params.clientId}" }`:""}} isUpcomingFilter: false`
    case "Upcoming":
      return `isUpcomingFilter: true  where: {   isCompleted: { eq: false } ${params?.tagId&&params?.tagId!==""? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""} ${params?.clientId&&params?.clientId!=="" ? `clientId: { eq: "${params.clientId}" }`:""}}`
    case "MyCreatedTask":
      return `isCreatedByFilter: true  where: { isCompleted: { eq: false } ${params?.tagId&&params?.tagId!==""? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""} ${params?.clientId&&params?.clientId!=="" ? `clientId: { eq: "${params.clientId}" }`:""}}`
    case "AllAndClients":
      return `${(params?.clientId || params?.tagId)?`where: { ${params?.clientId!=="" ? `clientId: { eq: "${params.clientId}" }`:""} ${params?.tagId? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""}}`:""} , isUpcomingFilter: false`
    case "AllAndCategory":
      return `${(params?.clientId|| params?.tagId)?`where: { ${params?.tagId!==""? `tags: { some: { id: { eq: "${params.tagId}" } } }`:""}  ${params?.clientId ? `clientId: { eq: "${params.clientId}" }`:""}}`:""} , isUpcomingFilter: false`
    case "AssignedToMe":
      return `where: { isCompleted: { eq: false } ${params?.tagId&&params?.tagId!==""? `tags: { some: { id: { eq: "${params?.tagId}" } } }`:""} ${params?.clientId&&params?.clientId!=="" ? `clientId: { eq: "${params.clientId}" }`:""}} isAssignedTo:true isUpcomingFilter: false`
      default:
        return ""
  }
}
export const getTasksList = (params) => async (dispatch) => {
  let sort = params?.isSort === true ? `order: {${params?.sortName}: ${params.sortBy}}` :""
  dispatch(base.getRequest(TASKS_CONST.GET_TASKS_REQUEST));
  dispatch(startLoading())
  let queryparam = getQueryParam(params)
  const payload = {
    query: `
    query DashboardTasks {
      dashboardTasks(${queryparam} ${sort} first : 300) {
          nodes {
              id
              title
              description
              clientId
              clientName
              dueDate
              isDraft
              isCompleted
              createdBy
              tags {
                  id
                  name
                  color
              }
              assignedTo {
                  id
                  name
                  email
                  profileImage
              }
          }
      }
  }
  
    
      `,
  };

  
  return await netWorkService.taksGraphql(payload)
    .then((response) => {
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(TASKS_CONST.GET_TASKS_SUCCESS, responseJson)
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.GET_TASKS_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const createTask = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.CREATE_TASKS_REQUEST));

  loadingToast("Creating task...");
  dispatch(startLoading())
  return await netWorkService.addTasks(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(TASKS_CONST.CREATE_TASKS_SUCCESS, "success")
          );
          setTimeout(
            () => successToast("Task has been created successfully"),
            980
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.CREATE_TASKS_FAILURE, responseJson)
          );
          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setTimeout(() => errorToast("Failed to create task. Please try again later"), 980)
    });
};
export const updateTask = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.UPDATE_TASKS_REQUEST));

  loadingToast("Updating task...");
  dispatch(startLoading())
  return await netWorkService.updateTasks(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(base.getSuccess(TASKS_CONST.UPDATE_TASKS_SUCCESS, "success"));
        setTimeout(
          () => successToast("Task has been updated successfully"),
          980
        );
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.UPDATE_TASKS_FAILURE, responseJson)
          );
          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {
      
    });
};

export const getTaskByid = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.GET_TASKS_BY_ID_REQUEST));
  dispatch(startLoading())

  return await netWorkService.getTasksDetails(params)
    .then((response) => {
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(TASKS_CONST.GET_TASKS_BY_ID_SUCCESS, responseJson)
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.GET_TASKS_BY_ID_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const TaskComplete = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.COMPLETE_TASKS_REQUEST));

  loadingToast("Please wait a moment...");
  dispatch(startLoading())
  return await netWorkService.taskCompleted(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(
          base.getSuccess(TASKS_CONST.COMPLETE_TASKS_SUCCESS, "success")
        );
        setTimeout(
          () =>
            successToast(
              params.IsCompleted === true
                ? "Task has been successfully marked as complete"
                : "Task has been successfully unmarked"
            ),
          980
        );
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.COMPLETE_TASKS_FAILURE, "error")
          );
          setTimeout(() => errorToast(responseJson?.errors?.[0]));
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setTimeout(() => errorToast("Failed to marked as complete. Please try again later"), 980)
    });
};

export const TaskDelete = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.DELETE_TASKS_REQUEST));

  loadingToast("Deleting task...");
  dispatch(startLoading())
  return await netWorkService.deleteTasks(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(base.getSuccess(TASKS_CONST.DELETE_TASKS_SUCCESS, "success"));
        setTimeout(
          () => successToast("Task has been deleted successfully"),
          980
        );
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.DELETE_TASKS_FAILURE, responseJson)
          );
          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {
      
    });
};

export const getTags = (params) => async (dispatch) => {
  let payload = {
    query: `
    query Tags {
      tags(order: {name:ASC}) {
          nodes {
              id
              name
              color
              isActive
              isDeleted
              createdOn
              modifiedOn
              createdBy
              modifiedBy
          }
      }
  }
  
  
    `,
  };
  dispatch(base.getRequest(TASKS_CONST.GET_TAGS_REQUEST));
  return await netWorkService.taksGraphql(payload)
    .then((response) => {
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(base.getSuccess(TASKS_CONST.GET_TAGS_SUCCESS, responseJson));
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(base.getFailure(TASKS_CONST.GET_TAGS_FAILURE, responseJson));
        });
      }
    })
    .catch((error) => {
      
    });
};

export const createTags = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.CREATE_TAGS_REQUEST));

  loadingToast("Creating tag...");
  dispatch(startLoading())
  return await netWorkService.addTag(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          //   dispatch(setUserList(responseJson));
          dispatch(base.getSuccess(TASKS_CONST.CREATE_TAGS_SUCCESS, responseJson));
          setTimeout(() => successToast("Tag has been created successfully"));
        });
      } else {
        response.json().then((responseJson) => {
          //   dispatch(setUserList(responseJson));
          dispatch(
            base.getFailure(TASKS_CONST.CREATE_TAGS_FAILURE, responseJson)
          );
          setTimeout(() => errorToast(responseJson?.errors?.[0]));
          // errorToast(responseJson);
        });
      }
    })
    .catch((error) => {
      // console.log(error);
      setTimeout(() => errorToast("Failed to create tag. Please try again later"), 980)
    });
};
export const getReminder = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.GET_REMINDER_REQUEST));

  let payload = {
    query: `
    query ReminderOptions {
      reminderOptions {
          id
          option
          
      }
  }
  
    `,
  };

  return await netWorkService.taksGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(TASKS_CONST.GET_REMINDER_SUCCESS, responseJson)
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(TASKS_CONST.GET_REMINDER_FAILURE, responseJson)
          );
        });
      }
    })
    .catch((error) => {
      
    });
};
export const DeleteMultipleTasks = (params) => async (dispatch) => {
  dispatch(base.getRequest(TASKS_CONST.DELETE_MULTIPLE_TASKS_REQUEST));

  loadingToast("Deleting Tasks..");
  dispatch(startLoading())
  return await netWorkService.deleteMultipleTasks(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(
          base.getSuccess(TASKS_CONST.DELETE_MULTIPLE_TASKS_SUCCESS, "success")
        );
        setTimeout(
          () => successToast("Tasks has been deleted successfully"),
          980
        );
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              TASKS_CONST.DELETE_MULTIPLE_TASKS_FAILURE,
              responseJson
            )
          );

          setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setTimeout(() => errorToast("Failed to delete task. Please try again later"), 980)
    });
};

