


import CryptoJS from 'crypto-js';

// Function to encrypt a string

let encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

function encryptString(plainText) {

    
    
    // Ensure the key and IV are of the correct length
    const keyBytes = CryptoJS.enc.Utf8.parse(encryptionKey.padEnd(32, '0').substr(0, 32));
    const ivBytes = CryptoJS.enc.Utf8.parse(encryptionKey.padEnd(16, '0').substr(0, 16));

    // Encrypt the text
    const encrypted = CryptoJS.AES.encrypt(plainText, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString(); // Converts encrypted bytes to Base64 string
}

// Function to decrypt a string
function decryptString(cipherText) {
    
    
    // Ensure the key and IV are of the correct length
    const keyBytes = CryptoJS.enc.Utf8.parse(encryptionKey.padEnd(32, '0').substr(0, 32));
    const ivBytes = CryptoJS.enc.Utf8.parse(encryptionKey.padEnd(16, '0').substr(0, 16));

    // Decrypt the text
    const decrypted = CryptoJS.AES.decrypt(cipherText, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8); // Converts decrypted bytes to string
}



export { encryptString, decryptString };
