import { NOTIFICATION_CONST } from "./actionTypes/NotificationActionTypes";
import netWorkService from "../../network/NetworkService";
import "react-toastify/dist/ReactToastify.css";
import { errorToast } from "components/Toast";
import * as base from "./BaseActions";

export const getNotification = (params) => async (dispatch) => {
  dispatch(base.getRequest(NOTIFICATION_CONST.GET_NOTIFICATION_REQUEST));

  try {
    const response = await netWorkService.getNotification(params);
    const responseJson = await response.json();

    if (response.ok) {
      dispatch(
        base.getSuccess(
          NOTIFICATION_CONST.GET_NOTIFICATION_SUCCESS,
          responseJson?.data
        )
      );
    } else {
      dispatch(
        base.getFailure(
          NOTIFICATION_CONST.GET_NOTIFICATION_FAILURE,
          responseJson
        )
      );
      setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
    }
  } catch (error) {
    console.error(error);
    setTimeout(
      () => errorToast("Failed to get notifications. Please try again later"),
      980
    );
  }
};
export const getLatestNotification = (params) => async (dispatch) => {
  dispatch(base.getRequest(NOTIFICATION_CONST.GET_LATEST_NOTIFICATION_REQUEST));

  try {
    const response = await netWorkService.getLatestNotification(params?.timestamp);
    const responseJson = await response.json();

    if (response.ok) {
      dispatch(
        base.getSuccess(
          NOTIFICATION_CONST.GET_LATEST_NOTIFICATION_SUCCESS,
          responseJson
        )
      );
    } else {
      dispatch(
        base.getFailure(
          NOTIFICATION_CONST.GET_LATEST_NOTIFICATION_FAILURE,
          responseJson
        )
      );
      setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
    }
  } catch (error) {
    
  }
};
export const clearNotification = (params) => async (dispatch) => {
  dispatch(base.getRequest(NOTIFICATION_CONST.CLEAR_NOTIFICATION_REQUEST));

  try {
    const response = await netWorkService.clearNotification(params);
    const responseJson = await response.json();

    if (response.ok) {
      dispatch(
        base.getSuccess(
          NOTIFICATION_CONST.CLEAR_NOTIFICATION_SUCCESS,
          responseJson
        )
      );
    } else {
      dispatch(
        base.getFailure(
          NOTIFICATION_CONST.CLEAR_NOTIFICATION_FAILURE,
          responseJson
        )
      );
      setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
    }
  } catch (error) {

  }
};
export const markAllAsReadNotification = (params) => async (dispatch) => {
  dispatch(base.getRequest(NOTIFICATION_CONST.MARK_ALL_AS_READ_NOTIFICATION_REQUEST));

  try {
    const response = await netWorkService.markAllAsReadNotification(params);
    const responseJson = await response.json();

    if (response.ok) {
      
      dispatch(
        base.getSuccess(
          NOTIFICATION_CONST.MARK_ALL_AS_READ_NOTIFICATION_SUCCESS,
          responseJson
        )
      );
    } else {
      dispatch(
        base.getFailure(
          NOTIFICATION_CONST.MARK_ALL_AS_READ_NOTIFICATION_FAILURE,
          responseJson
        )
      );
      setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
    }
  } catch (error) {
    
  }
};
