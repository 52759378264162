
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomCheckbox = ({ label, checked, onChange, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          {...props}
          sx={{
            color:"#98A2B3 !important",
            
          '&.Mui-checked': {
            color: "#53389e !important",
          }
          }}
        />
      }
      label={label}
      sx={{color:"#98A2B3 !important"}}
      
    />
  );
};

export default CustomCheckbox;
