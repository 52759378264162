import React from "react";
import {
  blockInvalidChar,
  countriesOptions,
  customFilterOption,
  dropDownOptions,
} from "../../../constants";
import { Box, Grid, Typography } from "@mui/material";
import GridContainer from "../../../components/GridContainer";
import { RenderTextInput } from "../../../components/TextField";
import Select from "react-select";
import { customStyles } from "styles/CustomStyle";
import Button from "../../../components/Button";
import CloseButton from "../../../components/CloseButton";

const Tab1 = ({
  tab,
  countries,
  handleCountryChange,
  HQCountry,
  errors,
  setHeadcount,
  headcount,
  setHQLocationName,
  locationName,
  resetFieldError,
  setHQLocationNumber,
  HQlocationNumber,
  setHQFirstLine,
  HQFirstLine,
  setHQSecondLine,
  HQSecondLine,
  setHQcity,
  HQcity,
  setHQstate,
  HQstate,
  setHQPostCode,
  HQpostCode,
  setHQFirstName,
  setHQLastName,
  handleClientCountry,
  handleEmailChange,
  emailexists,
  addContact,
  contactdata,
  contactDetailsData,
  removecontactDetails,
  HQFirstName,
  HQLastName,
  HQEmail,
  clientContry,

  removecontact,
  setClientName,
  clientName,

  phone,

  handlePhoneChange,

  contactType,
  handleContactTypeChange,
  selectedContactType,

  handleCompany,

  company,
}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 1 ? "active" : ""}`}
      id="pills-add-new"
      role="tabpanel"
      aria-labelledby="pills-add-new-tab"
      tabIndex="0"
    >
      <Box className="detials mb-3">Details</Box>
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Client*</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RenderTextInput
            type="text"
            id="exampleFormControlInput1"
            placeholder="Client name"
            onChange={(e) => {
              setClientName(e.target.value);
              resetFieldError("clientName", e.target.value);
            }}
            value={clientName || ""}
            // error={errorMessage}

            errors={errors.clientName}
            fullWidth
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row mb-3">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">HQ Location</Box>
          <Box className="client-subtext">
            The location of the clients headquarters{" "}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box className="mb-12">
            <Box className="client-subtext">Country* </Box>
            <Box className="country-flag">
              <Select
                options={countriesOptions(countries)}
                placeholder="Select a country"
                filterOption={customFilterOption}
                onChange={handleCountryChange}
                value={HQCountry || ""}
                styles={customStyles(errors.HQCountry)}
              />
            </Box>
            {errors.HQCountry && (
              <Typography
                component={"div"}
                className="error-msge"
                style={{ color: "#dc3545", fontSize: "14px" }}
              >
                {errors.HQCountry}
              </Typography>
            )}
          </Box>
          <Box>
            <Box className="client-subtext">Headcount* </Box>
            <RenderTextInput
              type="number"
              min={0}
              id="exampleFormControlInput1"
              placeholder="Head count"
              // onChange={(e) => setHeadcount(e.target.value)}
              onKeyPress={(e) => {
                // Get the pressed key code
                const keyCode = e.which || e.keyCode;

                // Allow digits (0-9) and prevent the minus sign
                if (keyCode < 48 || keyCode > 57) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                // Get the pasted text
                const pastedText = e.clipboardData.getData("text");

                // Prevent pasting if it contains a minus sign
                if (pastedText.includes("-")) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                // Update the state with the input value
                setHeadcount(e.target.value);
                resetFieldError("headcount");
              }}
              value={headcount || ""}
              errors={errors.headcount}
              onKeyDown={blockInvalidChar}
            />
          </Box>
        </Grid>
      </GridContainer>
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">HQ Address</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box className="mb-12">
            <Box className="client-subtext">Location name* </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="Location name"
              onChange={(e) => {
                setHQLocationName(e.target.value);
                resetFieldError("HQlocationName" , e.target.value);
              }}
              value={locationName || ""}
              errors={errors.HQlocationName}
            />
          </Box>
          <Box className="mb-2">
            <Box className="client-subtext">Location number* </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="Location number"
              onChange={(e) => {
                setHQLocationNumber(e.target.value);
                resetFieldError("HQlocationNumber" , e.target.value);
              }}
              value={HQlocationNumber || ""}
              errors={errors.HQlocationNumber}
            />
          </Box>
          <Box>
            <Box className="client-subtext mb-1">Address*</Box>

            <Typography component={"div"} sx={{ marginBottom: "10px" }}>
              <RenderTextInput
                type="text"
                id="exampleFormControlInput1"
                placeholder="First line*"
                onChange={(e) => {
                  setHQFirstLine(e.target.value);
                  resetFieldError("HQFirstLine" , e.target.value);
                }}
                maxLength={225}
                value={HQFirstLine || ""}
                errors={errors.HQFirstLine}
              />
            </Typography>

            <Typography component={"div"} sx={{marginBottom:"10px"}}>
            <RenderTextInput
              type="text"
              className="form-control mb-2"
              id="exampleFormControlInput1"
              placeholder="Second line"
              onChange={(e) => {setHQSecondLine(e.target.value , e.target.value); resetFieldError("HQSecondLine" , e.target.value)}}
              value={HQSecondLine || ""}
              maxLength={225}
              
              errors={errors.HQSecondLine}

            />

</Typography>

            <Typography component={"div"} sx={{ marginBottom: "10px" }}>
              <RenderTextInput
                type="text"
                id="exampleFormControlInput1"
                placeholder="City*"
                onChange={(e) => {
                  setHQcity(e.target.value);
                  resetFieldError("HQCity" , e.target.value);
                }}
                value={HQcity || ""}
                errors={errors.HQCity}
              />
            </Typography>

            <Typography component={"div"} sx={{ marginBottom: "10px" }}>
              <RenderTextInput
                type="text"
                id="exampleFormControlInput1"
                placeholder="State"
                onChange={(e) => {
                  setHQstate(e.target.value);
                  resetFieldError("HQState" , e.target.value);
                }}
                value={HQstate || ""}
                errors={errors.HQState}
              />
            </Typography>

            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="Zip/Postcode"
              onChange={(e) => {
                setHQPostCode(e.target.value);
                resetFieldError("HQpostCode" , e.target.value);
              }}
              value={HQpostCode || ""}
              errors={errors.HQpostCode}
            />
          </Box>
        </Grid>
      </GridContainer>
      <hr />
      <Box className="detials mb-3">Client Contact</Box>
      <GridContainer className="row">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Client contact name</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="mb-2">
            <Box className="client-subtext">First name </Box>
            <RenderTextInput
              type="text"
              id="exampleFormControlInput1"
              placeholder="First name"
              onChange={(e) => {
                setHQFirstName(e.target.value);
                resetFieldError("HQFirstName" , e.target.value);
              }}
              value={HQFirstName || ""}
              errors={errors.HQFirstName}
            />
          </Box>

          <Box>
            <Box className="client-subtext">Last name </Box>
            <RenderTextInput
              type="text"
              // className="form-control"
              id="exampleFormControlInput1"
              placeholder="Last name"
              onChange={(e) => {setHQLastName(e.target.value);resetFieldError("HQLastName" , e.target.value)}}
              value={HQLastName || ""}
              errors={errors.HQLastName}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="client-text">Phone number</Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box>
            <Box className="client-subtext">Phone number </Box>
            <RenderTextInput
              type="text"
              // className="form-control"
              id="exampleFormControlInput1"
              placeholder="Phone"
              onChange={(e) => handlePhoneChange(e.target.value)}
              value={phone}
              errors={errors.mobilenumber || ""}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="client-text">Company name</Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box>
            <Box className="client-subtext">Company </Box>
            <RenderTextInput
              type="text"
              // className="form-control"
              id="exampleFormControlInput1"
              placeholder="Company"
              onChange={(e) => handleCompany(e.target.value)}
              value={company || ""}
              errors={errors.company}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="client-text">Contact Type</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="country-flag">
            <Select
              aria-label="Default select example"
              onChange={handleContactTypeChange}
              placeholder={"Select a contact type"}
              value={selectedContactType || ""}
              styles={customStyles(errors.contactType)}
              options={dropDownOptions(contactType, "id", "name")}
            />
          </Box>
          {errors.contactType && (
            <Typography
              component={"div"}
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.contactType}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="client-text">Country</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="country-flag">
            <Select
              options={countriesOptions(countries)}
              placeholder="Select a country"
              filterOption={customFilterOption}
              styles={customStyles(errors.clientContry)}
              onChange={handleClientCountry}
              value={clientContry || ""}
            />
          </Box>
          {errors.clientContry && (
            <Typography
              component={"div"}
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.clientContry}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Box className="client-text">Email</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <RenderTextInput
            type="email"
            id="exampleFormControlInput1"
            placeholder="example@email.com"
            onChange={(e) => handleEmailChange(e.target.value)}
            value={HQEmail || ""}
            errors={
              errors.HQEmail
                ? errors.HQEmail
                : emailexists
                ? "Client already exists"
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6} className=" mb-3"></Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          <Button
            type="button"
            className="btn btn-default add-btn"
            variant={"outlined"}
            color={"primary"}
            onClick={() => addContact()}
          >
            Add Contact
          </Button>
        </Grid>
        <hr />
        {/* <GridContainer className="row"> */}
        <Grid item xs={12} md={6} lg={6}>
          <Box className="client-text">Contacts</Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className=" mb-3">
          {contactDetailsData?.map((item) => {
            return (
              <Box
                className="toast align-items-center d-block mb-3"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                key={item?.id}
              >
                <Box className="d-flex justify-content-between align-items-center p-1">
                  <Box className="toast-body">
                    {item.firstName} {item.secondName}
                  </Box>

                  <CloseButton onClick={() => removecontactDetails(item.id)} />
                </Box>
              </Box>
            );
          })}
          {contactdata?.map((item) => {
            return (
              <Box
                className="toast align-items-center d-block mb-3"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <Box className="d-flex justify-content-between align-items-center p-1">
                  <Box className="toast-body">
                    {item.firstName} {item.secondName}
                  </Box>

                  <CloseButton onClick={() => removecontact(item.email)} />
                </Box>
              </Box>
            );
          })}
        </Grid>
        {/* </GridContainer> */}
      </GridContainer>
    </Box>
  );
};

export default Tab1;
