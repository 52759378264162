import * as React from 'react';

// import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'

export default function ContainerComponent({sx , className ,  children , ref , id , style}) {
  return (
    <React.Fragment>
      
      <Grid
      container
      direction="row"
     // justifyContent="center"
      alignItems="center"
      sx={sx}
      className={className}
      ref={ref}
      id={id}
      style={style}
      >
       {children} 
      </Grid>
    </React.Fragment>
  );
}
