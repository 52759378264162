import React from "react";

//Material-UI components
import { Box, Typography } from "@mui/material";
import Select from "react-select";

//Custom styles for components
import {
  customStyles,
  usersCountryDropdown,
} from "styles/CustomStyle";
import {
  countriesOptions,
  customFilterOption,
  dropDownOptions,
} from "constants";

const FilterSection = ({
  countries,
  clientList,
  handleSelectClient,

  selectedClient,
  selectedCountry,
  handleCountryChange,
  selectedTab,

  handleTab,
}) => {
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      height={30}
    >
      <Typography
        variant="span"
        className="view-text me-2 filter-by"
        style={{
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        Filter by
      </Typography>
      <Box className="d-flex align-items-center filter-data">
        <Box
          className={selectedTab === "All" ? `active` : ""}
          onClick={() => handleTab("All")}
        >
          All
        </Box>

        <Typography
          variant="span"
          sx={{ width: "140px", zIndex: "100 !important" }}
        >
          <Select
            options={dropDownOptions(
              clientList?.length > 0 ? clientList : [],
              "id",
              "clientName"
            )}
            placeholder="Client"
            onChange={handleSelectClient}
            value={selectedClient}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            className="users-country-select"
            maxMenuHeight={300}
          />
        </Typography>
        <Typography
          variant="span"
          sx={{ width: "140px", zIndex: "100 !important" }}
        >
          <Select
            className="users-country-select"
            options={countriesOptions(countries)}
            placeholder="Country"
            filterOption={customFilterOption}
            onChange={handleCountryChange}
            value={selectedCountry}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            dropdownPosition="auto"
            maxMenuHeight={300}
          />
        </Typography>
      </Box>
    </Box>
  );
};
export default FilterSection;
