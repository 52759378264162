import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Box, Grid } from "@mui/material";

//Custom MUI components
import PageTitle from "../../components/PageTitle";

import Table from "../../components/Table";
import GridContainer from "../../components/GridContainer";
import ButtonComponent from "../../components/Button";

import FilterSection from "../client/FilterSection";

import { dateFormat, REPORTS_COLUMNS } from "../../constants";
import CreateReport from "./CreateReport";

import { getClientList } from "../../store/actions/ClientAction";
import DrawerComponent from "../../components/Drawer";
import {
  deleteReport,
  getGeneratedPDFReport,
  getGeneratedReport,
  getReports,
  getTemplates,
} from "../../store/actions/ReportsActions";
import * as base from "../../store/actions/BaseActions";
import { REPORTS_CONST } from "../../store/actions/actionTypes/ReportsActionsTypes";
import ConfirmationModal from "components/ConfirmationModal";

const Reports = () => {
  const [createReport, setCreateReport] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [reportId, setReportId] = useState("");
  const [reports, setReports] = useState([]);

  const statedata = useSelector((state) => {
    return {
      reports: state?.report?.reports,

      addedreports: state?.report?.addedreports,
      deletereports: state?.report?.deletedreports,
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  useEffect(() => {
    let payload = {
      isDraft: false,
      filterTab: "activeOnly",
      isFirst: true,
    };
    dispatch(getClientList(payload));

    dispatch(getTemplates());

    dispatch(getReports());
  }, [dispatch]);

  useEffect(() => {
    if (statedata?.addedreports) {
      dispatch(base.getSuccess(REPORTS_CONST.CREATE_REPORTS_SUCCESS, ""));
      dispatch(getReports());
      setCreateReport(false);
    }
  }, [statedata?.addedreports, dispatch]);

  useEffect(() => {
    if (statedata?.reports?.data?.reports?.nodes) {
      let reportsList = statedata?.reports?.data?.reports?.nodes?.map(
        (item, key) => {
          return {
            ...item,
            createdOn: item?.createdOn ? dateFormat(item?.createdOn) : "",
            clientName: item?.clients?.map((item, key) => item?.name),
          };
        }
      );

      setReports(reportsList);
    }
  }, [statedata?.reports?.data?.reports?.nodes]);
  useEffect(() => {
    if (statedata?.deletereports) {
      setShowConfirmation(false);
      setReportId("");
      dispatch(getReports());
      dispatch(base.getSuccess(REPORTS_CONST.DELETE_REPORTS_SUCCESS, ""));
    }
  }, [statedata?.deletereports, dispatch]);

  const handleReportsDetails = () => {};

  const handleDownloadReport = async (e, type) => {
    if (type === "Excel") {
      let reportName = reports?.filter((item, key) => item?.id === e);
      dispatch(getGeneratedReport(e, reportName?.[0]?.displayName));
    } else {
      dispatch(getGeneratedPDFReport(e));
    }
  };
  const handleDeleteeport = async (e) => {
    setReportId(e);
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    let payload = {
      id: reportId,
    };

    dispatch(deleteReport(payload));
  };

  return (
    <Box id="main" className="main">
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={() => setShowConfirmation(false)}
          handleConfirm={handleConfirm}
          title={"Report"}
          subTitle={"delete this report"}
          buttonname="Delete"
        />
      )}
      <Box>
        <GridContainer sx={{ marginBottom: "30px !important" }}>
          <Grid item xs={12} md={9} lg={9}>
            <PageTitle title={"Reports"} />
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <ButtonComponent
              variant={"contained"}
              color={"grey"}
              onClick={() => setCreateReport(true)}
              sx={{ float: "right" }}
            >
              Create a report
            </ButtonComponent>

            {createReport && (
              <DrawerComponent
                width={350}
                isOpen={createReport}
                handleClose={() => setCreateReport(false)}
                body={
                  <CreateReport
                    show={createReport}
                    handleClose={() => setCreateReport(false)}
                  />
                }
              />
            )}
          </Grid>
        </GridContainer>
      </Box>

      <Box className="view-contact-details">
        Showing all reports generated in the past 90 days
      </Box>

      {/* Filter and search section */}
      <Box className="section client-dashboard mt-4">
        <GridContainer>
          <Grid item xs={12} md={12} lg={12}>
            <Box className="dashboard-content">
              <Box className="dashboard-main-head row ms-0 me-0">
                <GridContainer>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    lg={9}
                    className=" d-flex align-items-center"
                  >
                    <FilterSection
                      filterTab={"All"}
                      filterby={["All"]}
                      showViewBy={false}
                    />
                  </Grid>
                </GridContainer>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>

      <Table
        columns={REPORTS_COLUMNS}
        rows={reports}
        handleRow={(e) => handleReportsDetails(e)}
        handleDownload={handleDownloadReport}
        handleDelete={(e) => handleDeleteeport(e)}
        from="reports"
      />
    </Box>
  );
};
export default Reports;
