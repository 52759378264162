import * as React from 'react';

import Button from '@mui/material/Button';
import "../components/common/Custom.css"

export default function ButtonComponent({variant , color , disabled , sx ,   size ,  onClick , children}) {
    
  return (
    
      
      <Button  variant={variant} className={`${color + variant}`} disabled={disabled} size={size} sx={sx} onClick={onClick}>{children}</Button>
      
    
  );
}