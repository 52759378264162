

import {ACTION_TYPES} from "../actions/ActionTypes"

const Reducer = (state = null, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_DOCUMENTS_TYPE:
            return {
                ...state,
                documentsTypes:action.payload
            }
        case ACTION_TYPES.UPLOAD_DOCUMENT:
            return {
                ...state,
                uploadDocument:action.payload
            }
        case ACTION_TYPES.ADD_DOCUMENT:
            return {
                ...state,
                addDocument:action.payload
            }
        case ACTION_TYPES.GET_DOCUMENTS_DATA:
            return {
                ...state,
                documentsData:action.payload
            }
        case ACTION_TYPES.GET_UPDATE_DOCUMENTS_DATA:
            return {
                ...state,
                updateddocumentsData:action.payload
            }
        
        default: return state
    }
}
export default Reducer