import React from 'react';
import Select from 'react-select';
// import Switch from 'react-switch';
import Switch from "../../components/Switch";
import { blockInvalidChar, dropDownOptions } from '../../constants';
import { customStyles } from '../../components/common/CustomStyle'

const renderField = (field, formData, handleInputChange , handleInputChangeWithValidation , numberlimit , isDisabled) => {
    
    const coverageFieldId = field.coverageFieldId;

    const fieldValue = formData[coverageFieldId];

    

    switch (field.fieldType) {
        case "Dropdown":
          const valuesArray = Object.values(formData);
  
          if (field.code === "SD") {
            if (valuesArray.includes("MS")) {
              return (
                <div key={field.coverageFieldId} className="mb-3">
                  <label>{field.name}</label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) =>
                      handleInputChange(
                        field.coverageFieldId,
                        e.target.value,
                        field.name,
                        field.code,
                        field.fieldOptions
                      )
                    }
                    // value={field.value}
                    // value={formData[field.coverageFieldId]}
                    value={fieldValue}
                  >
                    <option value="" selected disabled>
                      Select an option
                    </option>
                    {field.fieldOptions.map((option) => (
                      <option
                        key={option.fieldOptionId}
                        value={option.fieldOptionId}
                      >
                        {option.optionValue}
                      </option>
                    ))}
                  </select> */}

                  <Select

                    options={dropDownOptions(field.fieldOptions , "fieldOptionId" , "optionValue")}
                    onChange={(selectedOptions) =>
                      handleInputChange(
                        field.coverageFieldId,
                        // selectedOptions.map((option) => option.value),
                        selectedOptions?.value,
                        field.name,
                        field.code,
                        field.fieldOptions
                      )
                    }
                    value={dropDownOptions(field.fieldOptions , "fieldOptionId" , "optionValue")?.find((option) => option.value == fieldValue)}
                    // value={field.value}
                    // disabled={isDisabled}

                    placeholder="Select an option"
                    styles={customStyles()}
                    isDisabled={isDisabled}
                  />

                </div>
              );
            }
          }
          //Modified By: Anjali
          //Modified On: 01-17-2024
          //Reason: show/hide field based on field code for Grouped Private Medical and Individual Private Medical code
          else if (field.code === "HFCA") {
            if (valuesArray.includes("HCC")) {
              return (
                <div key={field.coverageFieldId} className="mb-3">
                  <label>{field.name}</label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) =>
                      handleInputChange(
                        field.coverageFieldId,
                        e.target.value,
                        field.name,
                        field.code,
                        field.fieldOptions
                      )
                    }
                    // value={field.value}
                    value={fieldValue}
                  >
                    <option value="" selected disabled>
                      Select an option
                    </option>
                    {field.fieldOptions.map((option) => (
                      <option
                        key={option.fieldOptionId}
                        value={option.optionValue}
                      >
                        {option.optionValue}
                      </option>
                    ))}
                  </select> */}

                  <Select

                    options={dropDownOptions(field.fieldOptions , "fieldOptionId" , "optionValue")}
                    onChange={(selectedOptions) =>
                      handleInputChange(
                        field.coverageFieldId,
                        // selectedOptions.map((option) => option.value),
                        selectedOptions?.value,
                        field.name,
                        field.code,
                        field.fieldOptions
                      )
                    }
                    // value={fieldValue}
                    value={dropDownOptions(field.fieldOptions , "fieldOptionId" , "optionValue")?.find((option) => option.value == fieldValue)}
                    // value={field.value}
                    // disabled={isDisabled}

                    placeholder="Select an option"
                    styles={customStyles()}
                    isDisabled={isDisabled}
                  />

                </div>
              );
            }
          } else {
            return (
              <div key={field.coverageFieldId} className="mb-3">
                <label>{field.name}</label>
                {/* <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    handleInputChange(
                      field.coverageFieldId,
                      e.target.value,
                      field.name,
                      field.code,
                      field.fieldOptions
                    )
                  }
                  // value={field.value}
                  value={fieldValue}
                  disabled={isDisabled}
                >
                  <option value="" selected disabled>
                    Select an option
                  </option>
                  {field.fieldOptions.map((option) => (
                    <option
                      key={option.fieldOptionId}
                      value={option.fieldOptionId}
                    >
                      {option.optionValue}
                    </option>
                  ))}
                </select> */}

                <Select

                  options={dropDownOptions(field.fieldOptions , "fieldOptionId" , "optionValue")}
                  onChange={(selectedOptions) =>
                    handleInputChange(
                      field.coverageFieldId,
                      // selectedOptions.map((option) => option.value),
                      selectedOptions.value,
                      field.name,
                      field.code,
                      field.fieldOptions
                    )
                  }
                  // value={fieldValue}
                  value={dropDownOptions(field.fieldOptions , "fieldOptionId" , "optionValue")?.find((option) => option.value == fieldValue)}
                  // value={field.value}
                  // disabled={isDisabled}

                  placeholder="Select an option"
                  styles={customStyles()}
                  isDisabled={isDisabled}
                />

              </div>
            );
          }
          break;
  
        case "Multiselection":
          return (
            <div key={field.coverageFieldId} className="mb-3">
              <label>{field.name}</label>
              <Select
                isMulti
                options={field.fieldOptions.map((option) => ({
                  value: option.fieldOptionId,
                  label: option.optionValue,
                }))}
                onChange={(selectedOptions) =>
                  handleInputChange(
                    field.coverageFieldId,
                    selectedOptions.map((option) => option.value),
                    field.name,
                    field.code,
                    field.fieldOptions
                  )
                }
                value={fieldValue}
                // value={field.value}
                disabled={isDisabled}
              />
            </div>
          );
          // break;
  
        case "IntegerCurrency":
        case "Decimal":
        case "Integer":
          if (field.code === "PS") {
            const valuesArray = Object.values(formData);
            if (valuesArray.includes("FA") || valuesArray.includes("MS")) {
              return (
                <div key={field.coverageFieldId} className="mb-3">
                  <label>{field.name}</label>
                  <input
                    className="form-control"
                    type={"number"}
                    onChange={(e) => valuesArray.includes("FA")? 
                    handleInputChange(
                      field.coverageFieldId,
                        e.target.value,
                        field.name,
                        field.code,
                        field.fieldOptions
                    )
                  :
                      handleInputChangeWithValidation(
                        field.coverageFieldId,
                        e.target.value,
                        field.name,
                        field.code,
                        field.fieldOptions
                      )
                    }
                    min={0}
                    max={100}
                    // value={field.value}
                    value={fieldValue}
                    disabled={isDisabled}
                    onKeyDown={blockInvalidChar}
                  />
                  {numberlimit === false && (
                    <div className="invalid-feedback">
                      Please enter valid principle sum
                    </div>
                  )}
                </div>
              );
            }
          } else {
            return (
              <div key={field.coverageFieldId} className="mb-3">
                <label>{field.name}</label>
                <input
                  className="form-control"
                  type={(field.fieldType === "IntegerCurrency" || field.fieldType === "Integer") ? "number" : "text"}
                  onChange={(e) =>
                    handleInputChange(
                      field.coverageFieldId,
                      e.target.value,
                      field.name,
                      field.code,
                      field.fieldOptions
                    )
                  }
                  // value={field.value}
                  value={fieldValue}
                  disabled={isDisabled}
                  onKeyDown={blockInvalidChar}
                />
              </div>
            );
          }
          break;
  
        case "Bool":
          return (
            <div key={field.coverageFieldId} className="mb-3">
              <label>{field.name}</label>
              <div className="">
                <Switch
                  id="checkboxinp"
                  type="checkbox"
                  onChange={(e) =>
                    handleInputChange(
                      field.coverageFieldId,
                      e.target.checked,
                      field.name,
                      field.code,
                      field.fieldOptions
                    )
                  }
                  // checked={fieldvalue ? fieldvalue : field.value}
                  checked={fieldValue === "true" ? true : false}
                  disabled={isDisabled}
                />
              </div>
            </div>
          );
          // break;
  
        case "Integer":
          return (
            <div key={field.coverageFieldId} className="mb-3">
              <label>{field.name}</label>
              <input
                type="number"
                onChange={(e) =>
                  handleInputChange(
                    field.coverageFieldId,
                    e.target.value,
                    field.name,
                    field.code,
                    field.fieldOptions
                  )
                }
                // value={field.value}
                disabled={isDisabled}
                onKeyDown={blockInvalidChar}
              />
            </div>
          );
          // break;
  
        default:
          return null;
      }
};

export default renderField;
