import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";


import ConfirmationModal from "../../components/common/ConfirmationModal";
import GridContainer from "../../components/GridContainer";
import { Box, Grid, Typography } from "@mui/material";
import ButtonComponent from "../../components/Button";
import { RenderTextInput } from "../../components/TextField";
import { customStyles } from "../../components/common/CustomStyle";

import { countriesOptions, customFilterOption, dropDownOptions, validateInput } from "../../constants";
import CloseButton from "../../components/CloseButton";
import CustomCheckbox from "../../components/Checkbox";
const AddContactModal = ({
  
  addContact,
  clientDetail,
  closeSidePanel,
  page,
  contactDetails,
  editContact,
  title,
}) => {
  const [contactName, setContactName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [suffix, setsuffix] = useState([]);
  const [pronoun, setPronoun] = useState([]);
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedsuffix, setSelectedSuffix] = useState("");
  const [selectedContactType, setSelectedContactType] = useState("");
  const [prefixes, setPrefixes] = useState([]);
  const [contactType, setcontactType] = useState([]);
  const [selectedPronoun, setSelectedPronoun] = useState("");
  const [error, setError] = useState({});
  const [isValidemail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [primaryContact, setPrimaryContact] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      prefixes: state?.contact?.contactsPrefixes,
      suffix: state?.contact?.contactsSuffix,
      pronoun: state?.contact?.contactspronoun,
      contactType: state?.contact?.contactTypeData,
      addedcontactData: state?.contact?.addedcontactData,
      updatedcontactData: state?.contact?.updatedcontactData,
    };
  });

  
  
  useEffect(() => {
    if (
      page === "edit" &&
      contactDetails &&
      prefixes &&
      suffix &&
      country &&
      contactType &&
      pronoun
    ) {
      setContactName(contactDetails?.firstName);
      let prefix = dropDownOptions(prefixes , "prefixId" , "name")?.find(
        (item, key) => item.value === contactDetails?.prefixId
      );
      let selectedsuffixoption = dropDownOptions(suffix , "suffixId" , "name")?.find(
        (item, key) => item.value === contactDetails?.suffixId
      );
      let selectedcontacttypeoption = dropDownOptions(contactType , "id" , "name")?.find(
        (item, key) => item.value === contactDetails?.contactTypeId
      );
      let selectedpronounoption = dropDownOptions(pronoun , "pronounId" , "name")?.find(
        (item, key) => item.value === contactDetails?.pronounId
      );
      let selectedcountryoption = countriesOptions(country)?.find(
        (item, key) => item.value === contactDetails?.countryId
      );
      setPrefix(prefix);
      setSelectedSuffix(selectedsuffixoption);
      setSelectedContactType(selectedcontacttypeoption);
      setSelectedPronoun(selectedpronounoption);
      setCompany(contactDetails?.companyName);
      setSelectedCountry(selectedcountryoption);
      setEmail(contactDetails?.email);
      setPhone(contactDetails?.workPhone);
      setLine1(contactDetails?.street1);
      setLine2(contactDetails?.street2);

      setPrimaryContact(contactDetails?.clientPrimaryContact)
      
    }
  }, [contactDetails, prefixes, suffix, country, contactType, pronoun]);

  useEffect(() => {
    if (stateData?.prefixes?.data?.prefixes) {
      setPrefixes(stateData.prefixes?.data?.prefixes);
    }
  }, [stateData.prefixes?.data?.prefixes]);
  useEffect(() => {
    if (stateData?.suffix?.data?.suffixes) {
      setsuffix(stateData.suffix?.data?.suffixes);
    }
  }, [stateData.suffix?.data?.suffixes]);
  useEffect(() => {
    if (stateData?.pronoun?.data?.pronouns) {
      setPronoun(stateData.pronoun?.data?.pronouns);
    }
  }, [stateData.pronoun?.data?.pronouns]);

  useEffect(() => {
    if (stateData.countries) {
      setCountry(stateData.countries);
    }
  }, [stateData.countries]);

  useEffect(() => {
    if (stateData?.contactType) {
      setcontactType(stateData.contactType);
    }
  }, [stateData.contactType]);

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  const handleConfirm = () => {
    closeSidePanel();
  };
  
  
  
  

  //Modified By: Anjali
  //Modified On: 05-03-2024
  //JiraId: https://bosframework.atlassian.net/browse/WBN-117
  //Reason: should not allow special characters

  const validation = () => {
    const updatedErrors = {
      email:
        email?.trim()?.length > 0
          ? isValidemail
            ? ""
            : "Please Enter Valid Email"
          : "Please Enter Email",
      contactType:
        selectedContactType !== "" ? selectedContactType?.value !=="" ? "":"Please Select Contact Type" : "Please Select Contact Type",
      country: selectedCountry !== "" ? selectedCountry?.value !=="" ? "" : "Please Select Country" : "Please Select Country",
      mobilenumber:
        phone?.trim()?.length > 0
          ? isValidPhone
            ? ""
            : "Please Enter Valid Phone Number"
          : "Please Enter Phone Number",
      company: company?.trim()?.length > 0 ? validateInput(company) || "" : "Please Enter Company",
      contactName:
        contactName?.trim()?.length > 0 ? validateInput(contactName) || "" : "Please Enter Contact Name",
        line1: line1 === "" ? "" : validateInput(line1) || "",
        line2: line2 === "" ? "" : validateInput(line2) || "",

    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };
  const handleAddContact = () => {
    const isValid = validation();
    if (isValid) {
      let prefixname = prefixes?.filter(
        (item, key) => item.prefixId === Number(prefix?.value)
      );
      let suffixname = suffix?.filter(
        (item, key) => item.suffixId === Number(selectedsuffix?.value)
      );
      let pronounname = pronoun?.filter(
        (item, key) => item.pronounId === Number(selectedPronoun?.value)
      );
      let countryname = country?.filter(
        (item, key) => item.id === Number(selectedCountry?.value)
      );

      let ContactType = contactType?.filter((item , key)=> item.id === selectedContactType?.value)

      let user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        // prefix , suffix , pronoun , email , phone ,
        // clientId: clientDetail?.clientDetail?.id,
        clientId: clientDetail?.id,
        ContactType:ContactType?.[0]?.name?ContactType?.[0]?.name:"",
        contactTypeId: selectedContactType?.value
          ? selectedContactType?.value
          : "",
        firstName: contactName ? contactName : "",
        lastName: "",
        pronounId: selectedPronoun?.value ? selectedPronoun?.value : null,
        isCompany: true,
        companyName: company ? company : "",
        workPhone: phone ? phone : "",
        email: email ? email : "",
        street1: line1 ? line1 : "",
        city: "",
        state: "",
        zipcode: 0,
        countryId: selectedCountry?.value ? selectedCountry?.value : "",
        country: countryname?.[0],
        prefixId: prefix?.value ? prefix?.value : null,
        middleName: "",
        prefixName: prefixname?.[0]?.name,
        suffixId: selectedsuffix?.value ? selectedsuffix?.value : null,
        suffixName: suffixname[0]?.name,
        pronounName: pronounname[0]?.name,
        mobilePhone: phone ? phone : "",
        fax: "",
        street2: "",
        county: "",
        MemberFimrId:
          user?.extension_Role === "MemberAdmin" ||
          user?.extension_Role === "MemberContributor"
            ? user?.extension_MemberFirmId
            : null,
            ClientProducingBroker : clientDetail?.producingBrokerId
      };
      if (page === "add") {
        addContact(payload);
      } else {
        payload.contactId = contactDetails?.id;
        
        payload.ClientPrimaryContact = primaryContact
        let ClientProducingBroker =  clientDetail?.producingBrokerId
        payload.ClientProducingBroker = ClientProducingBroker
        editContact(payload);
      }
    }
  };

  const handleDiscard = () => {
    setContactName("");
    setCompany("");
    setEmail("");
    setPhone("");
    setLine1("");
    setLine2("");
    setSelectedCountry("");
    setSelectedSuffix("");
    setSelectedContactType("");
    setSelectedPronoun("");
    setPrefix("");
    setSelectedCountry("");
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    // Basic email validation
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const emailRegex = /^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g;
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const isValid = emailRegex.test(enteredEmail);

    setIsValidEmail(isValid);

    setError((prevState) => ({
      ...prevState,
      email: !isValid ? "Please Enter Valid Email" : "",
    }));
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event);

    setError((prevState) => ({
      ...prevState,
      country: "",
    }));
  };

  const handleContactNameChange = (event) => {
    setContactName(event.target.value);

    setError((prevState) => ({
      ...prevState,
      contactName: event.target.value ? validateInput(event.target.value) || "": "",
    }));
  };

  const handleContactTypeChange = (event) => {
    setSelectedContactType(event);

    setError((prevState) => ({
      ...prevState,
      contactType: "",
    }));
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);

    setError((prevState) => ({
      ...prevState,
      company: event.target.value ? validateInput(event.target.value) || "":"",
    }));
  };
  const resetFieldError = (field , name) =>
    setError((prevState) => ({
      ...prevState,
      [field]: name?validateInput(name) || "":"",
    }));
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    // const phonenumberRegex = /^\+?[0-9\s-]+$/;
    const phonenumberRegex = /^\+?[1-9]\d{1,14}$/;

    const isValid = phonenumberRegex.test(event.target.value);

    setIsValidPhone(isValid);

    setError((prevState) => ({
      ...prevState,
      mobilenumber: !isValid ? "Please Enter Valid Phone Number" : "",
    }));
  };

  const handleCheckboxChange = (event) => {
    setPrimaryContact(event.target.checked);
  }

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Contact"}
          subTitle={"discard this contact"}
          buttonname={"Discard"}
        />
      )}

      <Box style={{ padding: "12px 20px" }}>
        <Box>
          <Box
            className="sidepanel-header add-contact"
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid var(--grey-200)",
              padding: "12px 20px",
            }}
          >
            {/* <a
              href="javascript:void(0)"
              className="btn-close"
              onClick={() => closeSidePanel()}
              style={{ marginRight: "5px", padding: "9px" }}
            ></a> */}

            <Typography sx={{marginRight: "5px"}}>
              <CloseButton onClick={() => closeSidePanel()} />
            </Typography>

            <ButtonComponent
              className="btn btn-default add-policy btn-md mr-8"
              style={{ backgroundColor: "grey", color: "#FFF" }}
              variant={"contained"}
              color={"grey"}
              onClick={() => handleAddContact()}
            >
              Save
            </ButtonComponent>
            <span style={{ marginLeft: "10px" }}>
              <ButtonComponent
                className="btn btn-default add-policy btn-md revoke-cancellation-btn"
                onClick={() => setShowConfirmation(true)}
                variant={"outlined"}
                color={"white"}
              >
                Discard
              </ButtonComponent>
            </span>
          </Box>
          <Box className="sidepanel-body add-contact">
            <Box class="sidepanel-body add-contact">
              <Box class="contact-new-head">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {title}
                </Typography>
              </Box>
              <GridContainer>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <RenderTextInput
                      value={contactName}
                      onChange={handleContactNameChange}
                      label="Contact name*"
                      placeholder="Type name"
                      block
                      required
                      errors={error.contactName}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Prefix
                    </label>
                    <Select
                      // className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setPrefix(e)}
                      value={prefix}
                      
                      options={dropDownOptions(prefixes , "prefixId" , "name")}
                      styles={customStyles()}
                      
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Contact Type*
                    </label>
                    <Select
                      aria-label="Default select example"
                      onChange={handleContactTypeChange}
                      value={selectedContactType}
                      styles={customStyles(error.contactType)}
                      
                      options={dropDownOptions(contactType , "id" , "name")}
                    />

                  {error.contactType && (
                    <Box
                      className="error-msge"
                      style={{ color: "#dc3545", fontSize: "14px" }}
                    >
                      {error.contactType}
                    </Box>
                  )}

                  </Box>

                  
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Suffix
                    </label>
                    <Select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setSelectedSuffix(e)}
                      styles={customStyles()}
                      
                      value={selectedsuffix}
                      
                      options={dropDownOptions(suffix , "suffixId" , "name")}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Pronoun
                    </label>
                    <Select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setSelectedPronoun(e)}
                      value={selectedPronoun}
                      styles={customStyles()}
                      
                      options={dropDownOptions(pronoun , "pronounId" , "name")}
                    />
                  </Box>
                </Grid>
                <hr />
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Company*
                    </label>
                    <RenderTextInput
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Company"
                      onChange={handleCompanyChange}
                      required
                      value={company}
                      errors={error.company}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Country*
                    </label>
                    <Select
                      aria-label="Default select example"
                      onChange={handleCountryChange}
                      value={selectedCountry}
                      styles={customStyles(error.country)}
                      filterOption={customFilterOption}
                      // options={countryoption}
                      options={countriesOptions(country)}
                    />

                    {error.country && (
                      <Box
                        className="error-msge mb-3"
                        style={{ color: "#dc3545", fontSize: "14px" }}
                      >
                        {error.country}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <hr />
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <RenderTextInput
                      type="email"
                      id="exampleFormControlInput1"
                      placeholder="email@email.com"
                      onChange={handleEmailChange}
                      label={"Email*"}
                      required
                      value={email}
                      errors={error.email}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box class="mb-3">
                    <RenderTextInput
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder=""
                      onChange={handlePhoneChange}
                      label={"Phone*"}
                      required
                      value={phone}
                      errors={error.mobilenumber}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Address
                    </label>
                    <Typography  sx={{marginBottom:"10px"}}>
                    <RenderTextInput
                      type="text"
                      
                      placeholder="Line 1"
                      onChange={(e) => {setLine1(e.target.value);resetFieldError("line1" , e.target.value)}}
                      value={line1}
                      fullWidth
                      required
                      errors={error.line1}
                    />
                    </Typography>
                    
                    <RenderTextInput
                      type="text"
                      
                      id="exampleFormControlInput1"
                      placeholder="Line 2"
                      onChange={(e) => {setLine2(e.target.value);resetFieldError("line2" , e.target.value)}}
                      value={line2}
                      required
                      errors={error.line2}
                    />
                  </Box>
                </Grid>
                {page === "edit" && 
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3" sx={{marginLeft:"3%"}}>
                    
                    <CustomCheckbox
                      label="Primary contact"
                      checked={primaryContact}
                      onChange={handleCheckboxChange}
                    />
                  </Box>
                </Grid>}
              </GridContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddContactModal;
