import React, { useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import "assets/css/Custom.css";
import {
  Box,
  Typography,
  AvatarGroup,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  StyledCheckbox,
  StyledTableCell,
} from "styles/CustomStyle";
import ReactCountryFlag from "react-country-flag";
import { countryIdToCodeMap } from "../constants/Countrycodes";


const getColor = (value) => {
  if (value === "Active") {
    return "complete";
  } else if (value === "Renewal Past Due") {
    return "past-due";
  } else if (value === "Renewal In Progress") {
    return "inprogress";
  } else if (value === "Inactive") {
    return "inactive";
  }
};
// Function to render additional content based on the column label and value
const renderAdditionalContent = (
  row,
  column,
  value,
  isDraft,
  from,
  category,
  assignedto
) => {
  if ((column.label === "Company" && from === "client") || (column.accessor === "policyNumber" && from === "clientPolicy")) {
    return (
      <>
        {column.label === "Company" && from === "client"&& <Typography variant="span" className="company-logo">
          AB
        </Typography>}
        <Typography variant="span" className="company-name ps-1">
          {value}
          {isDraft && (
            <Typography
              variant="span"
              style={{ marginLeft: "10px" }}
              className="draft"
            >
              Draft
            </Typography>
          )}
        </Typography>
      </>
    );
  } else if (column.accessor === "country" || column.label === "HQ Country") {
    const getCountryCode = countryIdToCodeMap?.[row.countryId];

    return (
      <Box>
        <ReactCountryFlag
          countryCode={getCountryCode}
          svg
          style={{ width: "30px", height: "15px" }}
        />
        {value}
      </Box>
    );
  } else if (column.accessor === "tags") {
    const categoryNames = category?.map((item) => item.name).join(", ");
    return (
      <Box title={categoryNames}>
        {category?.length > 1 ? (
          <span>
            <span
              title={category[0]?.name}
              style={{
                backgroundColor: category?.[0]?.color,
                padding: "5px 8px",
                color: "#475467",
                borderRadius: "10px",
              }}
            >
              {category[0]?.name}
            </span>{" "}
            <span
              style={{
                backgroundColor: "#EAECF0",
                padding: "5px 8px",
                borderRadius: "10px",
              }}
            >
              +{category?.length - 1}
            </span>
          </span>
        ) : (
          <span
            style={{
              backgroundColor: category?.[0]?.color,
              padding: "5px 8px",
              color: "#475467",
              borderRadius: "10px",
            }}
          >
            {category?.[0]?.name}
          </span>
        )}
      </Box>
    );
  } else if (column.accessor === "assignedTo") {
    const assignedNames = assignedto?.map((item) => item.name).join(", ");
    return (
      <Box>
        <AvatarGroup max={3} sx={{ float: "left" }} title={assignedNames}>
          {assignedto?.map((item) => {
            return (
              <Avatar
                variant="square"
                sx={{
                  borderRadius: "10px",
                  height: "25px",
                  width: "25px",
                  backgroundColor: "#BDBDBD !important",
                  color: "#fff !important",
                }}
                
                key={item?.id}
                className="client-initials"
              >
                {item?.name?.split(" ")?.[0]?.[0]}{" "}
                {item?.name?.split(" ")?.[1]?.[0]}
              </Avatar>
            );
          })}
        </AvatarGroup>
      </Box>
    );
  } else {
    return (
      <Box
        className={
          `${column.accessor}` === "policyStatus" ? getColor(value) : ""
        }
        title={value}
        style={{
          overflow: "hidden",
          whiteSpace: "noWrap",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
    );
  }
};

const TableComponent = ({
  columns,
  rows,
  handleRow,
  from,
  handleChange,
  checkedId,
  handleDelete,
  
  handleCheckAllCheckbox,
  allChecked,
  disableCheckbox,
  handleSort,
  

  handleDownload,

  // defaultRowsPerPage = 10,
}) => {
  
  
  const [anchorEl, setAnchorEl] = useState(null);

  

  const handleClose = () => {
    setAnchorEl(null);
  };
  const inputRef = useRef(null);
  const handleClick = (event, rowId) => {
    setAnchorEl({
      element: event.currentTarget,
      id: rowId,
    });
  };

  

  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden", boxShadow: "none !important" }}
      className={from === "dashboard" ? "client-collaboration-tables" : ""}
    >
      <TableContainer
        component={Paper}
        sx={{
          border:
            from === "dashboard" ? "" : "1px solid var(--color-border-default)",
            maxHeight: from === "dashboard" || from === "tasksDashboard"?250: "calc(100vh - 240px)"
        }}
        className={
          from === "dashboard" || from === "tasksDashboard"
            ? "clients-table"
            : ""
        }
        id={`scrollable-div-${from}`}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ height: "32px" }}>
            <TableRow>
              {(from === "client" ||
                from === "tasks" ||
                from === "reportsPolicy") && (
                <StyledTableCell padding="checkbox">
                  <StyledCheckbox
                    color="primary"
                    onChange={handleCheckAllCheckbox}
                    checked={allChecked}
                    disabled={disableCheckbox}
                  />
                </StyledTableCell>
              )}

              {columns.map((column , index) => (
                <StyledTableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                  {column.isSort === true && (
                    <span>
                      <img
                        src="wwwroot/assets/images/sort.png"
                        className="img-fluid"
                        alt=""
                        onClick={() => handleSort(column.accessor)}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  )}
                </StyledTableCell>
              ))}
              {(from === "clientPolicy" ||
                from === "clientContact" ||
                from === "policyDocument" ||
                from === "clientLocation" ||
                from === "reports") && (
                <StyledTableCell
                  style={{ minWidth: "100px" }}
                ></StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          {rows?.length > 0 ? (
            <TableBody>
              {rows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    // hover
                    // role="checkbox"
                    key={row.id}
                    tabIndex={-1}
                    
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    {(from === "client" ||
                      from === "tasks" ||
                      from === "reportsPolicy") && (
                      <StyledTableCell key={`checkbox-${row.id}`}>
                        <StyledCheckbox
                          value=""
                          onChange={() => handleChange(row)}
                          style={{ cursor: "pointer" }}
                          checked={checkedId.includes(row.id)}
                        />
                      </StyledTableCell>
                    )}
                    {columns.map((column) => {
                      const value = row[column.accessor];
                      const isDraft = row[column.isDraft];
                      const category =
                        from === "tasks" || from === "tasksDashboard"
                          ? row.tags
                          : "";
                      const assignedTo =
                        from === "tasks" ||
                        from === "tasksDashboard" ||
                        from === "reportsPolicy"
                          ? row.assignedTo
                          : "";

                      return (
                        <StyledTableCell
                        key={column.accessor} 
                          align={column.align}
                          onClick={() => handleRow(row.id)}
                          style={{
                            overflow: "hidden",
                            maxWidth: column.minWidth,
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : renderAdditionalContent(
                                row,
                                column,
                                value,
                                isDraft,
                                from,
                                category,
                                assignedTo
                              )}
                        </StyledTableCell>
                      );
                    })}
                    {(from === "clientPolicy" ||
                      from === "clientContact" ||
                      from === "policyDocument" ||
                      from === "clientLocation" ||
                      from === "reports") && (
                      <StyledTableCell className="text-end pe-2">
                        <i
                          className="fa-regular fa-trash-can"
                          style={{ fontSize: "medium" }}
                          alt=""
                          onClick={() => handleDelete(row.id)}
                        ></i>

                        {from === "reports" && (
                          <>
                            <i
                              className="fa-solid fa-download ps-3"
                              alt=""
                              onClick={(event) => {
                                if (row?.status?.toLowerCase() === "finished") {
                                  handleDownload(row.id, "Excel");
                                }
                              }}
                              style={{
                                cursor:
                                  row?.status?.toLowerCase() === "finished"
                                    ? "pointer"
                                    : "default",
                                visibility:
                                  row?.status?.toLowerCase() === "finished"
                                    ? "visible"
                                    : "hidden",
                              }}
                            ></i>

                            {/* <Menu
                              id="vertical-menu"
                              anchorEl={
                                anchorEl?.id === row?.id
                                  ? anchorEl?.element
                                  : null
                              } // Check if this row's id matches anchorEl
                              open={Boolean(
                                anchorEl && anchorEl?.id === row?.id
                              )}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              getContentAnchorEl={null}
                              sx={{
                                "& .MuiPaper-root": {
                                  padding: "5px",
                                  border: "1px grey solid",
                                  width: "60px",
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleDownload(row.id, "Excel");

                                  setTimeout(() => {
                                    if (inputRef.current) {
                                        inputRef.current.focus();
                                    }
                                  }, 20);
                                  handleClose();
                                }}
                                sx={{
                                  fontSize: "13px !important",
                                  borderBottom: "1px lightgrey solid",
                                }}
                              >
                                Excel
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDownload(row.id, "PDF");
                                  setTimeout(() => {
                                    if (inputRef.current) {
                                      inputRef.current.focus();
                                    }
                                  }, 20);
                                  handleClose();
                                }}
                                sx={{ fontSize: "13px !important" }}
                              >
                                PDF
                              </MenuItem>
                            </Menu> */}
                          </>
                        )}
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            // <TableBody sx={{ display: "table-caption", textAlign: "center" }}>
            //   <Box className="client-text">No records found</Box>
            // </TableBody>
            <TableBody>
            <TableRow>
              <StyledTableCell colSpan={columns.length} align="center">
                <Box className="client-text">
                No records found
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableComponent;
