import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import GridContainer from "../../components/GridContainer";

import Table from "../../components/Table";

import {
  ACTIVE_CLIENTS_COLUMNS,
  FREQUENT_COLLABORATION_COLUMNS,
  Paths,
} from "../../constants";
import { useNavigate } from "react-router-dom";
const ClientsCollaborations = ({frequentCollaborators , activeClients}) => {
  // const [clients, setClients] = useState([]);
  
  
  
  
  const navigate = useNavigate() 

  const handleFrequestCollaboration = () => {
    
  }

  
  const handleClient = (id) => {
    navigate(Paths.CLIENT_DASHBOARD , {state: {from:"clients-dashboard" ,  id:  id}})
  }

  return (
    <Box>
      <GridContainer sx={{alignItems:'start !important'}}>
        <Grid item xs={12} md={6} lg={6} className="pe-2">
          <Box className="subtext" sx={{ marginTop: "25px" }}>
            Active Clients
          </Box>

          {/* <ActiveClientsTable /> */}
          {activeClients?.length>0?
          <Table columns={ACTIVE_CLIENTS_COLUMNS} rows={activeClients} 
          handleRow={(id)=> handleClient(id)}
          from="dashboard"
          />
          :
          <Box className="company-name" sx={{color:"var(--color-text-grey-tertiary)"}}>No active clients have been added</Box>}
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="ps-2">
          <Box className="subtext" sx={{ marginTop: "25px" }}>
            Frequent Collaborators
          </Box>

          {/* <FrequentCollaborationsTable /> */}
          {frequentCollaborators?.length>0? 
          
          <Table
            columns={FREQUENT_COLLABORATION_COLUMNS}
            rows={frequentCollaborators}
            handleRow={()=> handleFrequestCollaboration()}
            from="dashboard"
          />
          : 
          <Box className="company-name" sx={{color:"var(--color-text-grey-tertiary)"}}>No frequent collaborators have been added</Box>
          }
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default ClientsCollaborations;
