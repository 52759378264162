export function getRequest (REQUEST) {
    return {
        type: REQUEST
    }
}
export function getSuccess (SUCCESS , payload) {
    return {
        type: SUCCESS,
        payload: payload
    }
}

export function getFailure (FAILURE , payload) {
    return {
        type: FAILURE,
        error: payload
    }
}