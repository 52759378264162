import { Box, Typography } from "@mui/material";
import React from "react";

const Progressbar = ({ progressbarpercentage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "5%",
        marginRight: "auto",
        marginTop: 16,
        width: "83%",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "lightgrey",
          height: 8,
          flex: "1",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#53389e",
            height: 8,
            width: `${progressbarpercentage}%`,
            borderRadius: "10px",
          }}
        />
      </Box>
      <Typography
        variant="span"
        style={{
          marginLeft: "5px",
          fontSize: "14px",
        }}
      >
        {progressbarpercentage}%
      </Typography>
    </Box>
  );
};
export default Progressbar;
