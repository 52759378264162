import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

//MUI-Components
import { Box, Typography, Grid } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";

//custom MUI-Components
import GridContainer from "../../components/GridContainer";

import Table from "../../components/Table";

import PageTitle from "../../components/PageTitle";

import Dialog from "../../components/Dialog";
import DrawerComponent from "../../components/Drawer";

import { RenderTextInput } from "../../components/TextField";

import ClientDetails from "./ClientDetails";
import MainModal from "./MainModal";

import SecondaryModal from "./SecondayModal";

import ClientGridView from "./ClientGridView";

import AddNewPolicy from "./AddNewPolicyModal";
import FilterSection from "./FilterSection";

import Button from "../../components/Button";

import { CLIENT_DASHBOARD_COLUMNS, loggedInUserDetails } from "../../constants";

import {
  archiveMultipleClients,
  
  getClientDetails,
  searchClients,
  setArchiveMutipleClientsData,
  setClientData,
  setPolicyData,
  getMember,
  GetLanguages,
  GetPolicyTypes,
  Insurer,
  GetCurrencies,
  GetGetCoverageTypes,
  getClientList,
  setClientListData,
} from "../../store/actions/ClientAction";

import { getCountries } from "../../store/actions/CountriesAction";
import { getDocumentsTypes } from "../../store/actions/DocumentsAction";



const ClientDashboard = () => {
  const [clientDetail, setClientDetail] = useState([]);
  const [clients, setClients] = useState([]);

  const [showMainModal, setShowMainModal] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [showNewPolicyModal, setShowNewPolicyModal] = useState(false);
  const [viewType, setViewType] = useState("List");
  const [contactsList, setContactsList] = useState(false);
  const [showref, setShowref] = useState(false);
  const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
  const [contactId, setContactId] = useState("");
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  const [sortASC, setSortASC] = useState(false);
  const [sortName, setSortName] = useState("");
  const [clientName, setClientName] = useState("");

  const [filterTab, setFilterTab] = useState("All");
  const [searchClient, setSearchClient] = useState("");
  const [allChecked, setAllChecked] = useState(false);

  const [id, setId] = useState([]);

  //get state from store
  const stateData = useSelector((state) => {
    return {
      clientsData: state?.fetchData?.clientListData,
      clientData: state?.fetchData?.clientdata,
      addpolicyData: state?.fetchData?.addpolicyData,
      contactsList: state?.contact?.contactListData,
      addedfielddata: state?.fetchData?.addedfielddata,
      clientDetailsData: state?.fetchData?.clientDetailsData,
      clientsListData: state?.fetchData?.clientListData,
      archivedMutipleClientsData: state?.fetchData?.archivedMutipleClientsData,
      activeClientsList: state?.dasboard?.activeClientsList,
    };
  });

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const showModal = location.state && location.state.showModal;
  const clientId = location.state && location.state.clientId;

  // Fetch data from Redux store on component mount
  useEffect(() => {
    fetchData();
    dispatch(getCountries());
    dispatch(getMember());

    dispatch(GetLanguages());
    dispatch(GetPolicyTypes());
    dispatch(Insurer());
    dispatch(GetCurrencies());
    dispatch(GetGetCoverageTypes());
    dispatch(getDocumentsTypes());

    return()=> {
      dispatch(setClientListData(""))
    }
  }, []);

  //show client search modal if showModal true
  useEffect(() => {
    if (showModal) {
      setShowMainModal(true);
      setShowMainModal(true);
      navigate({ pathname: location.pathname });
    }
  }, [showModal]);

  //show client details
  useEffect(() => {
    if (clientId && location.state.from === "clients-dashboard") {
      showClientDetails(clientId);
      navigate({ pathname: location.pathname });
    }
  }, [clientId]);

  //show contact details
  useEffect(() => {
    if (clientId && location.state.from === "recentContacts-dashboard") {
      showClientDetails(clientId);
      
      setContactId(location.state.contactId);
      navigate({ pathname: location.pathname });
    }
  }, [clientId]);

  //update contactsList
  useEffect(() => {
    if (stateData.contactsList?.data?.contacts) {
      setContactsList(stateData.contactsList?.data?.contacts);
    }
  }, [stateData.contactsList?.data?.contacts]);

  //update clientDetails
  useEffect(() => {
    if (stateData.clientDetailsData) {
      setClientDetail(stateData.clientDetailsData?.data?.clientsDetails[0]);
      
    }
  }, [stateData.clientDetailsData]);

  //fetch clients when client is archived
  useEffect(() => {
    if (stateData.archivedMutipleClientsData === "success") {
      if (filterTab === "All") {
        fetchData();
      } else {
        let payload = {
          isSort: false,
          sortBy: "ASC",
          sortName: "",
          isArchived: true,
          filterName: "Archived",
        };

        dispatch(getClientList(payload));
      }
      setId([]);

      dispatch(setArchiveMutipleClientsData(""));
    }
  }, [stateData.archivedMutipleClientsData]);

  //get clients
  const fetchData = async () => {
    
    let payload = {
      isSort: true,
      sortBy: "ASC",
      sortName: "clientName",
      isArchived: false,
      filterName: "All",
    };

    dispatch(getClientList(payload));
  };

  //handle to check/uncheck all checkbox
  useEffect(() => {
    const allClientIds = clients?.map((item) => item.id) || [];
    if (!allChecked) {
      setId([]);
    } else {
      setId(allClientIds);
    }
  }, [allChecked]);

  //update clients
  useEffect(() => {
    if (stateData.clientsListData?.data?.clients_List?.nodes) {
      setDisableCheckbox(false);
      setClients(stateData?.clientsListData?.data?.clients_List?.nodes);
    }
  }, [stateData.clientsListData?.data?.clients_List?.nodes]);

  useEffect(() => {
    if (stateData?.activeClientsList?.data?.activeClient?.nodes) {
      setDisableCheckbox(false);
      setClients(stateData?.activeClientsList?.data?.activeClient?.nodes);
    }
  }, [stateData?.activeClientsList?.data?.activeClient?.nodes]);

  //get clients when new client or policy added
  useEffect(() => {
    if (stateData.clientData?.data || stateData.addpolicyData) {
      const fetchData = async () => {
        if (stateData.clientData?.data) {
          
          let payload = {
            isSort: false,
            sortBy: "ASC",
            sortName: "",
            isArchived: false,
            filterName: "All",
          };

          dispatch(getClientList(payload));
        }
      };
      fetchData();
      handleCloseSecondaryModal();
      handleCloseNewPolicyModal();
      dispatch(setClientData(""));
      dispatch(setPolicyData(""));
    }
  }, [stateData.clientData?.data, stateData.addpolicyData]);

  //get sorted clients
  useEffect(() => {
    if (sortName) {
      let payload = {
        isSort: true,
        sortBy: sortASC === true ? "ASC" : "DESC",
        sortName: sortName,
        from: "clientsList",
      };

      handleAction(payload, filterTab);
    }
  }, [sortASC, sortName]);

  const handleContactId = () => {
    setContactId("");
  };

  const handlesetclientName = (name) => {
    setClientName(name);
  };
  const handleRow = (id) => {
    showClientDetails(id);
  };

  //get clientDetails
  const showClientDetails = (id) => {
    setShowClientDetailsModal(true);

    const fetchData = async () => {
      dispatch(getClientDetails(id));
    };

    fetchData();
  };

  const handleShowSecondaryModal = () => {
    setShowMainModal(false);
    setShowSecondaryModal(true);
  };
  const handleCloseSecondaryModal = () => {
    setShowSecondaryModal(false);
  };
  const handleCloseMainModal = () => {
    setShowMainModal(false);
  };

  //handle to check/uncheck checkbox
  const handleCheckboxChange = (event) => {
    const elementId = event.id;

    // Check if the ID already exists in the array
    if (id.includes(elementId)) {
      // If it exists, remove it
      let filteredIds = id.filter((existingId) => existingId !== elementId);
      setId(filteredIds);
    } else {
      // If it doesn't exist, add it to the array
      let updatedIds = [...id, elementId];
      setId(updatedIds);
    }
  };

  const locationRef = useRef(null);
  const handleOpenlocation = (id) => {
    showClientDetails(id);
    handleCloseMainModal();
    setShowref(true);

    let element = document.getElementsByClassName("locationRef");

    if (element?.length > 0) {
      element[0].onscroll({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  //search clients based on clientName
  const searchclients = (e) => {
    setSearchClient(e);
    if (e?.length > 1) {
      let payload = {
        isSort: false,
        sortBy: "ASC",
        sortName: "",
        isArchived: false,
        filterName: filterTab,
        clientName: e
      }

      dispatch(searchClients(payload));
    } else {
      if(e?.length===0) {
      let payload = {
        isSort: false,
        sortBy: "ASC",
        sortName: "",
        isArchived: false,
        filterName: filterTab,
      };

      dispatch(getClientList(payload));
    }
    }
  };
  const handleCloseNewPolicyModal = () => {
    setShowNewPolicyModal(false);
  };

  const handleFilter = (type) => {
    setFilterTab(type);
    setDisableCheckbox(true);
    setAllChecked(false);
    setId([]);
    setClients([]);
    setSearchClient("");

    setSortASC(false);
    setSortName("");
    let payload = {
      isSort: false,
      sortBy: sortASC === true ? "ASC" : "DESC",
      sortName: "",
      from: "clientsList",
    };

    handleAction(payload, type);
  };

  const handleCheckAllClients = () => {
    setAllChecked(!allChecked);
  };

  const handleArchiveClients = () => {
    let archive = filterTab === "Archived" ? false : true;
    let payload = {
      clientIds: id,
      actionPerformedBy: loggedInUserDetails?.sub?loggedInUserDetails?.sub:"",
      Archive: archive,
    };
    dispatch(archiveMultipleClients(payload));
  };

  const handleAction = (payload, tab) => {
    switch (tab) {
      case "Active":
        payload.isActive = true;
        payload.filterName = "Active";
        dispatch(getClientList(payload));
        break;
      case "Drafts":
        payload.isDraft = true;
        payload.filterName = "Drafts";
        dispatch(getClientList(payload));
        break;
      case "Archived":
        payload.isArchived = true;
        payload.filterName = "Archived";
        dispatch(getClientList(payload));
        break;
      case "Policy exp.date":
        payload.isExpirationdate = true;
        payload.filterName = "Policy exp.date";
        payload.isArchived = false;
        payload.isSort=false
        
        dispatch(getClientList(payload));
        break;
      case "Date modified":
        payload.isDateModified = true;
        payload.filterName = "Date modified";
        payload.isSort=false
        dispatch(getClientList(payload));
        break;
      case "All":
        payload.isArchived = false;
        payload.filterName = "All";
        dispatch(getClientList(payload));
        break;
      default:
        break;
    }
  };

  const handleSort = (e) => {
    if (e === sortName) {
      setSortName(e);
      setSortASC(!sortASC);
    } else {
      setSortName(e);
      setSortASC(true);
    }
  };

  const renderButton = () => {
    return filterTab === "Archived" ? "UnArchive" : "Archive";
  };

  return (
    
      

      <Box>
        <main id="main" className="main">
          <GridContainer
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ marginBottom: "32px" }}
          >
            <Grid item xs="12" md="12" lg="8">
              <PageTitle title={"Clients"} />
            </Grid>

            <Grid xs="12" md="12" lg="4" className="buttons-wrapper">
              <img
                src="/wwwroot/assets/images/help-circle.png"
                className="img-fluid mr-10"
                alt="help circle"
              />
              <Typography variant="span" style={{ marginRight: "10px" }}>
                <Button
                  type="button"
                  onClick={() => setShowNewPolicyModal(true)}
                  disabled={id?.length === 0 || id?.length > 1 || clients?.filter((item , key)=> item?.id === id?.[0])?.[0]?.isDraft}
                  
                  variant={(id?.length === 1 && !clients?.filter((item , key)=> item?.id === id?.[0])?.[0]?.isDraft) ? "contained" : "outlined"}
                  color={(id?.length === 1 && !clients?.filter((item , key)=> item?.id === id?.[0])?.[0]?.isDraft) ? "grey" : "white"}
                >
                  Add new policy
                </Button>
              </Typography>
              <Button
                type="button"
                onClick={() => setShowMainModal(true)}
                variant={"contained"}
                color={"grey"}
              >
                Add new client
              </Button>
            </Grid>
          </GridContainer>

          {showNewPolicyModal && (
            <Dialog
              maxWidth="false"
              maxHeight="150px"
              open={showNewPolicyModal}
              onClose={handleCloseNewPolicyModal}
              showCloseIcon="false"
              body={
                <AddNewPolicy
                  showNewPolicyModal={showNewPolicyModal}
                  handleCloseNewPolicyModal={handleCloseNewPolicyModal}
                  clientDetail={clients?.filter(
                    (item, key) => item.id === id[0]
                  )}
                  from="listing"
                  clientid={
                    clients?.filter((item, key) => item.id === id[0])?.[0]?.id
                  }
                  page="add"
                />
              }
            />
          )}

          {showMainModal && (
            <Dialog
              maxWidth="sm"
              height="150px"
              open={showMainModal}
              onClose={handleCloseMainModal}
              showCloseIcon="true"
              body={
                <MainModal
                  showMainModal={showMainModal}
                  handleShowSecondaryModal={handleShowSecondaryModal}
                  handleCloseMainModal={handleCloseMainModal}
                  handleOpenlocation={handleOpenlocation}
                  handlesetclientName={handlesetclientName}
                  clients={clients}
                  onClose={handleCloseMainModal}
                />
              }
            />
          )}

          {showSecondaryModal && (
            <Dialog
              className="secondary-modal-show"
              maxWidth="false"
              maxHeight="150px"
              open={showSecondaryModal}
              onClose={handleCloseSecondaryModal}
              showCloseIcon="false"
              sx={{ p: 0 }}
              body={
                <SecondaryModal
                  showSecondaryModal={showSecondaryModal}
                  handleCloseSecondaryModal={handleCloseSecondaryModal}
                  clientName={clientName}
                  page="add"
                />
              }
            />
          )}

          <Box className="section client-dashboard">
            <GridContainer>
              <Grid xs="12" md="12" lg="12">
                <Box className="dashboard-content">
                  <Box className="dashboard-main-head row ms-0 me-0">
                    {/* Client filter section */}

                    <GridContainer
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Grid xs="12" md="8" lg="8">
                        <FilterSection
                          handleView={(e) => {
                            setViewType(e);
                            setId([]);
                            setAllChecked(false);
                          }}
                          viewType={viewType}
                          viewby={["List", "Grid"]}
                          filterby={[
                            "All",
                            "Active",
                            "Policy exp.date",
                            "Date modified",
                            "Drafts",
                            "Archived",
                          ]}
                          handleFilter={(e) => handleFilter(e)}
                          filterTab={filterTab}
                        />
                      </Grid>
                      {/* Client Search bar */}

                      <Grid xs="12" md="3" lg="3" sx={{ marginRight: "23px" }}>
                        <RenderTextInput
                          placeholder="Type to search clients"
                          id="outlined-start-adornment"
                          sx={{ m: 1, width: "100%" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M14 14L11.1 11.1M12.6667 7.33334C12.6667 10.2789 10.2789 12.6667 7.33334 12.6667C4.38782 12.6667 2 10.2789 2 7.33334C2 4.38782 4.38782 2 7.33334 2C10.2789 2 12.6667 4.38782 12.6667 7.33334Z"
                                    stroke="#98A2B3"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => searchclients(e.target.value)}
                          value={searchClient}
                        />
                      </Grid>
                    </GridContainer>

                    {/* Client Table Header and table details */}
                    <Box className="tab-content" id="myTabContent">
                      {viewType === "List" ? (
                        
                          <Table
                            columns={CLIENT_DASHBOARD_COLUMNS}
                            rows={clients}
                            handleRow={handleRow}
                            from="client"
                            handleChange={handleCheckboxChange}
                            checkedId={id}
                            handleCheckAllCheckbox={handleCheckAllClients}
                            disableCheckbox={disableCheckbox}
                            allChecked={allChecked}
                            handleSort={(e) => handleSort(e)}
                          />
                        
                      ) : (
                        <Box className="row m-0 grid-view">
                          {clients?.length > 0 ? (
                            clients.map((client) => (
                              <ClientGridView
                                key={client?.id}
                                client={client}
                                showClientDetails={showClientDetails}
                              />
                            ))
                          ) : (
                            <></>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {showClientDetailsModal && (
                <DrawerComponent
                  isOpen={showClientDetailsModal}
                  handleClose={() => {
                    setShowClientDetailsModal(false);
                    setShowref(false);
                  }}
                  // width={1000}
                  width={"80vw"}
                  body={
                    <ClientDetails
                      clientDetails={clientDetail}
                      locationRef={locationRef}
                      contactsList={contactsList}
                      handleClose={() => {
                        setShowClientDetailsModal(false);
                        setShowref(false);
                      }}
                      showref={showref}
                      handlesetShowref={() => setShowref(false)}
                      contactId={contactId}
                      handleContactId={handleContactId}
                    />
                  }
                />
              )}
            </GridContainer>
          </Box>
        </main>

        {id?.length > 0 && (
          <Box class="group-float">
            <Button
              sx={{
                textTransform: "none !important",
                fontWeight: "bold !important",
              }}
              onClick={() => handleArchiveClients()}
            >
              
              {renderButton()}
            </Button>
            

            <Button
              variant={(id?.length > 1 ||  clients?.filter((item , key)=> item?.id === id?.[0])?.[0]?.isDraft)? "outlined" : "contained"}
              color={(id?.length > 1 || clients?.filter((item , key)=> item?.id === id?.[0])?.[0]?.isDraft) ? "white" : "grey"}
              disabled={id?.length > 1 || clients?.filter((item , key)=> item?.id === id?.[0])?.[0]?.isDraft}
              type="buttom"
              onClick={() => setShowNewPolicyModal(true)}
            >
              Add policy
            </Button>
          </Box>
        )}
       
      </Box>
    
  );
};

export default ClientDashboard;
