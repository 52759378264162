import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import ReactCountryFlag from "react-country-flag";

//Material-UI components
import { Avatar, Box, Grid, Typography } from "@mui/material";

//Custom MUI components
import GridContainer from "../../components/GridContainer";

import Button from "../../components/Button";

import ConfirmationModal from "../../components/common/ConfirmationModal"
import { deleteUser } from "../../store/actions/UserAction";


const AddUserModal = ({
  handleClose,
  userDetails,
  handleEdit,
  prefixes,
  suffixes,
  countries,
  pronouns,
  members,
}) => {
  const [country, setCountry] = useState("");
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [pronoun, setPronoun] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [avatar, setAvatar] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [memberFirm, setMemberFirm] = useState("");

  //This useEffect hook updates the prefix, suffix, pronoun, and country values in the component state based on the corresponding IDs present in userDetails
  useEffect(() => {
    //Check if userDetails, prefixes, suffixes, and pronouns are available
    if (userDetails && prefixes && suffixes && pronouns && members) {
      //Filter the prefixes options based on the prefix ID in userDetails
      let prefixesoption = prefixes?.filter(
        (item, key) => item.prefixId === userDetails?.prefixId
      );
      //Filter the suffix options based on the suffix ID in userDetails
      let sufixoption = suffixes?.filter(
        (item, key) => item.suffixId === userDetails?.suffixId
      );
      //Filter the pronoun options based on the pronoun ID in userDetails
      let pronounoption = pronouns?.filter(
        (item, key) => item.pronounId === userDetails?.pronounId
      );
      //Filter the country options based on the country ID in userDetails
      let countryoption = countries?.filter(
        (item, key) => item.id === userDetails?.countryId
      );
      let memberoption = members?.filter(
        (item, key) => item.id === userDetails?.memberFirmId
      );

      //Set the prefix state to the name of the first matching prefix option
      setPrefix(prefixesoption?.[0]?.name);
      //Set the suffix state to the name of the first matching suffix option
      setSuffix(sufixoption?.[0]?.name);
      //Set the country state to the name of the first matching country option
      setCountry(countryoption?.[0]?.country);
      setCountrycode(countryoption?.[0]?.countryCode)
      //Set the pronoun state to the name of the first matching pronoun option
      setPronoun(pronounoption?.[0]?.name);
      
      setMemberFirm(memberoption?.[0]?.memberName)
      
      const imageUrl = `data:image/jpeg;base64,${userDetails?.memberFirmAvatar}`
      setAvatar(imageUrl)
    }
  }, [userDetails, prefixes, suffixes, pronouns , members]);

  const dispatch = useDispatch()
  const handleConfirm = () => {
    dispatch(deleteUser({id:userDetails?.id}))
    
  }

  const handledelete = () => {
    setShowConfirmation(true)
    
  }

  return (
    <Box style={{ padding: "12px 20px" }}>
      {showConfirmation &&<ConfirmationModal
    showConfirmation={showConfirmation}
    handleCloseConfirmation={()=> setShowConfirmation(false)}
    handleConfirm={handleConfirm}
    title={"User"}
    subTitle={"delete this user"}
    buttonname="Delete"
    />}
      <Box>
        <Box
          className="sidepanel-header add-contact"
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--grey-200)",
            padding: "12px 0px",
          }}
        >
          <a
            href="javascript:void(0)"
            className="btn-close"
            onClick={() => handleClose()}
            style={{ marginRight: "5px", padding: "9px" }}
          ></a>

          <Button
            className="btn btn-default add-policy btn-md mr-8"
            style={{ backgroundColor: "grey", color: "#FFF" }}
            variant={"contained"}
            color={"grey"}
            onClick={() => handleEdit(userDetails?.id)}
          >
            Edit
          </Button>
        </Box>
        <Box className="sidepanel-body add-contact">
          <Box class="sidepanel-body add-contact">
            <GridContainer sx={{ marginTop: "10px" }}>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {userDetails?.givenName} {userDetails?.surName}
                </Typography>
              </Grid>
              <Grid xs="12" md="6" lg="6"></Grid>
              <Grid xs="12" md="12" lg="12" className="mb-1">
                <Box className="mb-1 client-text">Avatar</Box>
                <Avatar variant="square" sx={{ borderRadius: "10px", marginLeft:'0px !important' }} src={avatar}/>
              </Grid>
              <Grid xs="12" md="12" lg="12" sx={{marginTop:"10px"}}>
                <Box className=" client-text">Access</Box>
                <Box className="view-contact-details mb-3">{userDetails?.role}</Box>
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3 client-text">Member firm</Box>
                <Box
                  sx={{
                    border: "1px solid #EAECF0",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <GridContainer>
                    <Grid xs="2" md="2" lg="2">
                      {/* <Avatar sx={{ borderRadius: "10px" }} variant="square"> */}
                        <img src="wwwroot/assets/images/avatar.png" />
                      {/* </Avatar> */}
                    </Grid>
                    <Grid xs="6" md="6" lg="6">
                      <Box className="client-text">{userDetails?.memberFirmName}</Box>
                      <Box className="form-label">{country}</Box>
                    </Grid>
                  </GridContainer>
                </Box>
              </Grid>
              <GridContainer
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderBottom: "1px solid var(--grey-200)",
                }}
              ></GridContainer>
              <Box className="client-text mb-3">Personal information</Box>

              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">Prefix</Box>
                <Box className="view-contact-details">{prefix}</Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">First/Given name</Box>
                <Box className="view-contact-details">
                  {userDetails?.givenName}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">SurName</Box>
                <Box className="view-contact-details">
                  {userDetails?.surName}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">Email</Box>
                <Box className="view-contact-details">
                  {userDetails?.mail}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">Suffix</Box>
                <Box className="view-contact-details">{suffix}</Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">Pronoun</Box>
                <Box className="view-contact-details">{pronoun}</Box>
              </Grid>
              <hr />

              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">Job title</Box>
                <Box className="view-contact-details">
                  {userDetails?.jobTitle}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className="mb-3">
                <Box className="form-label">Country</Box>
                <Box className="view-contact-details"> <ReactCountryFlag countryCode={countrycode} svg style={{width:"21px" , height:"21px"}} /> {country}</Box>
              </Grid>
            </GridContainer>
            {/* <GridContainer className="row pb-3 w-100">
            <hr />
            <Box className="delete-text text-center" 
            onClick={() => handledelete()}
            >
              Delete
            </Box>
          </GridContainer> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddUserModal;
