import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ReactCountryFlag from "react-country-flag";

import { Box, Grid, Typography } from "@mui/material";

import ConfirmationModal from "../../components/common/ConfirmationModal";
import GridContainer from "../../components/GridContainer";

import Switch from "../../components/Switch";
import { deleteLocation } from "../../store/actions/ClientAction";

import "../../components/common/Custom.css";
import ButtonComponent from "../../components/Button";

const LocationDetailsModal = ({ handleClose, handleedit, locationdetails , clientDetail }) => {
  const [locationdetailsData, setlocationdetailsData] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      deletelocationData: state?.fetchData?.deletelocationData,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (locationdetails) {
      setlocationdetailsData(locationdetails);
    }
  }, [locationdetails]);
  useEffect(() => {
    if (stateData.deletelocationData) {
      setShowConfirmation(false);
      handleClose();
    }
  }, [stateData.deletelocationData]);

  const handledelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    let ClientProducingBroker =  clientDetail?.producingBrokerId
    dispatch(
      deleteLocation({
        id: locationdetails?.id,
        actionPerformedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
        ClientProducingBroker: ClientProducingBroker,
      })
    );
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Location"}
          subTitle={"delete this location"}
          buttonname={"Delete"}
        />
      )}
      <Box style={{ padding: "30px" }}>
        <Box>
          <Box
            className="sidepanel-header add-contact"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <a
              href="javascript:void(0)"
              className="btn-close"
              onClick={() => handleClose()}
            ></a>
            <Typography variant="span" sx={{ marginLeft: "10px" }}>
              {clientDetail?.isDraft !== true ?
                <ButtonComponent
                  variant={"contained"}
                  color={"grey"}
                  onClick={() => handleedit()}
                >
                  Edit
                </ButtonComponent>
              :""}
            </Typography>
          </Box>
          <Box className="sidepanel-body add-contact view-contact">
            <GridContainer className="row pt-3 contact-details">
              <Grid xs="12" md="12" lg="12" className=" mb-3">
                <Box className="form-label">Location name</Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.locationName}
                </Box>
              </Grid>
              <Grid xs="12" md="6" lg="6" className=" mb-3">
                <Box className="form-label">Head count</Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.headcount}
                </Box>
              </Grid>
              <Grid xs="12" md="6" lg="6" className=" mb-3">
                <Box className="form-label">Location number</Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.locationNumber}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className=" mb-3">
                <Box className="form-label">Country</Box>
                <Box className="view-contact-details d-flex">
                  <ReactCountryFlag
                    countryCode={locationdetailsData?.countryCode}
                    svg
                    style={{
                      width: "21px",
                      height: "21px",
                      marginRight: "10px",
                    }}
                  />
                  {locationdetailsData?.country}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className=" mb-3">
                <Box className="form-label">City</Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.city}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className=" mb-3">
                <Box className="form-label">State</Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.state}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12" className=" mb-3">
                <Box className="form-label">Address</Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.street1}
                </Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.street2}
                </Box>
                <Box className="view-contact-details d-flex">
                  {locationdetailsData?.zipcode}
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Switch
                  id="checkboxinp"
                  type="checkbox"
                  checked={locationdetailsData?.isActive}
                  disabled={true}
                />
              </Grid>
            </GridContainer>

            <GridContainer className="row pb-3 w-100">
              <hr />
              <Box
                className="delete-text text-center"
                onClick={() => handledelete()}
              >
                Delete
              </Box>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LocationDetailsModal;
