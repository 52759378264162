export const COUNTRIES_CONST = {
    GET_COUNTRIES_REQUEST:"GET_COUNTRIES_REQUEST",
    GET_COUNTRIES_SUCCESS:"GET_COUNTRIES_SUCCESS",
    GET_COUNTRIES_FAILURE:"GET_COUNTRIES_FAILURE",

    GET_REGIONS_REQUEST:"GET_REGIONS_REQUEST",
    GET_REGIONS_SUCCESS:"GET_REGIONS_SUCCESS",
    GET_REGIONS_FAILURE:"GET_REGIONS_FAILURE",
    
    GET_REGIONS_BY_COUNTRIES_REQUEST:"GET_REGIONS_BY_COUNTRIES_REQUEST",
    GET_REGIONS_BY_COUNTRIES_SUCCESS:"GET_REGIONS_BY_COUNTRIES_SUCCESS",
    GET_REGIONS_BY_COUNTRIES_FAILURE:"GET_REGIONS_BY_COUNTRIES_FAILURE",
    
}
