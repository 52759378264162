import React, { useEffect, useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Box,  } from "@mui/material";

import NavTabs from "../../components/NavTabs";

import { countriesOptions, dropDownOptions, validateInput } from "../../constants";

import {
  ClientData,
  setClientDataFailed,
  updateClient,
} from "../../store/actions/ClientAction";

import {
  AddMulitpleContacts,
  ContactType,
  deleteMultipleContactData,
} from "../../store/actions/ContactsAction";

import "react-toastify/dist/ReactToastify.css";
import Tab1 from "./addNewClient/Tab1";
import Tab2 from "./addNewClient/Tab2";
import Tab3 from "./addNewClient/Tab3";
import TopSection from "./addNewClient/TopSection";

const MyModal = (props) => {
  

  const [clientName, setClientName] = useState(props.clientName);
  const [headcount, setHeadcount] = useState(null);
  const [locationName, setHQLocationName] = useState("");
  const [HQlocationNumber, setHQLocationNumber] = useState("");
  const [HQFirstLine, setHQFirstLine] = useState(null);
  const [HQSecondLine, setHQSecondLine] = useState(null);
  const [HQpostCode, setHQPostCode] = useState(null);
  const [HQFirstName, setHQFirstName] = useState("");
  const [HQLastName, setHQLastName] = useState(null);
  const [HQEmail, setHQEmail] = useState(null);
  const [clientLocationName, setClientLocationName] = useState("");
  const [clientLocationNumber, setClientLocationNumber] = useState("");
  const [clientFirstLine, setClientFirstLine] = useState("");
  const [clientSecondLine, setClientSecondLine] = useState("");
  const [clientContry, setClientCountry] = useState("");
  const [clientPostcode, setClientPostcode] = useState("");

  const [selectedProducingCoverage, setselectedProducingCoverage] =
    useState("");

  const [selectedgCoverage2, setselectedgCoverage2] = useState("");

  const [clientHeadcount, setClientHeadcount] = useState("");
  const [country, setCountry] = useState("");
  const [HQCountry, setHQCountry] = useState("");

  const [brokerdats, setbrokerdata] = useState([]);

  const [brokerId, setbrokerid] = useState([]);
  const [tab, setTab] = useState(1);
  const [errors, setError] = useState({
    // (Tab 1) Details
    clientName: "",
    HQpostCode: "",
    producingbroker: "",
    HQFirstLine: "",
    HQCountry: "",
    HQlocationName: "",
    HQlocationNumber: "",
    HQCity: "",
    HQState: "",
    HQFirstName: "",

    // (Tab 2) Location
    clientLocationName: "",
    clientHeadcount: "",
    clientLocationNumber: "",
    clientCity: "",
    clientState: "",
    clientContry: "",
    clientSecondLine: "",
  });
  const [HQcity, setHQcity] = useState("");
  const [HQstate, setHQstate] = useState("");
  const [clientcity, setClientcity] = useState("");
  const [clientstate, setClientstate] = useState("");
  const [contactdata, setContactData] = useState([]);
  const [contactDetailsData, setContactDetailsData] = useState([]);
  const [deletedContactData,] = useState([]);
  const [locationDetailsData,] = useState([]);
  const [deletedlocationdata,] = useState([]);
  
  // const [deletedServicingbrokerData,] = useState([]);

  const [locationdata, setlocationData] = useState([]);
  // const [clientId, setClientId] = useState("");
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");
  const [countries, setCountries] = useState([]);
  const [producingBroker,] = useState([
    
  ]);
  const [members, setMembersData] = useState([]);
  const [producingbrokers, setProducingBrokers] = useState([]);
  const [contactType, setcontactType] = useState([]);
  const [emailexists, setEmailexists] = useState(false); 
  const [phone, setPhone] = useState(""); 
  const [selectedContactType, setSelectedContactType] = useState(""); 
  const [company, setCompany] = useState(""); 

  //extract specific pieces of state from the Redux store
  const statedata = useSelector((state) => {
    return {
      addedclientdata: state?.fetchData?.clientdata,
      countries: state?.countries?.Countries,
      membersadata: state?.fetchData?.memberData,
      clientError: state?.fetchData?.clientError,
      updatedClientData: state?.fetchData?.updatedClientData,
      contactType: state?.contact?.contactTypeData,
    };
  } , shallowEqual);

  const producingbrokeroption = producingBroker.map((country) => ({
    value: country.id,
    label: <div>{country.memberName}</div>,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setClientDataFailed(""));
    };
  }, [dispatch]);

  
  useEffect(()=> {

    dispatch(ContactType())
  } , [dispatch])

  useEffect(() => {
    if (statedata?.contactType) {
      setcontactType(statedata.contactType);
    }
  }, [statedata.contactType])

  useEffect(() => {
    if (
      props.page === "edit" &&
      props.clientDetail &&
      props.contactsList &&
      props.clientLocations &&
      countries
    ) {
      setContactDetailsData(props.contactsList);
      let location = props.clientLocations?.map((item, key) => {
        return {
          ...item,
          address: item.address,
          LocationName: item.locationName,
          LocationNumber: item.locationNumber,
          Street1: item.street1,
          Street2: item.street2,
          County: item.country,
          State: item.state,
          City: item.city,
          Zipcode: item.zipcode,

        };
      });
      
      setlocationData(location)

      let country = countriesOptions(countries)?.find(
        (item, key) => item?.value === props.clientDetail?.countryId
      );

      setHQCountry(country);
      setHeadcount(props.clientDetail?.headcount);
      setHQLocationName(props.clientDetail?.locationName);
      setHQLocationNumber(props.clientDetail?.locationNumber);
      setHQFirstLine(props.clientDetail?.street1);
      setHQSecondLine(props.clientDetail?.street2);
      setHQstate(props.clientDetail?.state);
      setHQcity(props.clientDetail?.city);
      setHQPostCode(props.clientDetail?.zipcode);
      

      
      let filterbroker = props.clientMembers?.filter(item => item?.brokerType !== "Producing")
      
      setbrokerdata(filterbroker)
      let brokerIds = filterbroker?.map((item) => {
        if(item?.brokerType !== "Producing") {
            return item.id
        }
      }
        )
      
      setbrokerid(brokerIds)
    }
  }, [
    props.clientDetail,
    props.contactsList,
    props.clientLocations,
    countries,
    props?.clientMembers,
    props?.page,

  ]);

  //update countries
  useEffect(() => {
    if (statedata.countries) {
      setCountries(statedata.countries);
    }
  }, [statedata.countries]);

  //update members
  useEffect(() => {
    if (statedata.membersadata) {
      setMembersData(statedata.membersadata);
      setProducingBrokers(statedata.membersadata);

    }
  }, [statedata.membersadata]);
  

  useEffect(() => {
    if (producingbrokers) {
    let user = JSON.parse(localStorage.getItem("user"))
    setLoggedInUserDetails(user)
    if(user?.extension_Role === "MemberAdmin" || user?.extension_Role === "MemberContributor") {
      let producingbroker = dropDownOptions(producingbrokers , "id" , "memberName")?.find((item , key)=> item.value === user?.extension_MemberFirmId)
      setselectedProducingCoverage(producingbroker)
    }
  
    if(props.clientDetail && props.page === "edit" && producingbrokers) {
      let producingbroker = dropDownOptions(producingbrokers , "id" , "memberName")?.find((item , key)=> item.value === props.clientDetail?.producingBrokerId)
          setselectedProducingCoverage(producingbroker!==undefined?producingbroker:"")
    }
  }
  } , [producingbrokers , props.clientDetail , props?.page])

  
  
  useEffect(() => {
    if (
      statedata.membersadata &&
      props.clientMembers &&
      props.page === "edit"
    ) {
      
      const filteredArray2 = statedata?.membersadata?.filter(
        (obj2) => !props?.clientMembers?.some((obj1) => obj1.id === obj2.id)
      );
      setMembersData(filteredArray2);
    }
  }, [statedata.membersadata, props.clientMembers, props.page]);

  //handle to add contacts
  useEffect(() => {
    if (
      statedata.addedclientdata?.data &&
      (HQFirstName || HQLastName || HQEmail)
    ) {
      // setClientId(statedata?.addedclientdata?.data?.id);

      const newPayload = contactdata?.map((item, key) => ({
        ...item,
        clientId: statedata.addedclientdata.data.id,
        ClientProducingBroker :  statedata.addedclientdata.data?.producingBrokerId
      }));

      dispatch(AddMulitpleContacts(newPayload));
    }
  }, [statedata.addedclientdata?.data , HQEmail , HQFirstName , HQLastName , contactdata , dispatch]);
  useEffect(() => {
    if (
      statedata.updatedClientData === "success" &&
      
      contactdata?.length > 0
    ) {
      const newPayload = contactdata?.map((item, key) => ({
        ...item,
        clientId: props.clientDetail?.id,
        ClientProducingBroker :  props.clientDetail?.producingBrokerId
      }));
      
      dispatch(AddMulitpleContacts(newPayload));
    }
  }, [statedata.updatedClientData , contactdata , dispatch , props?.clientDetail?.id , props?.clientDetail?.producingBrokerId]);

  useEffect(() => {
    if (
      statedata.updatedClientData === "success" &&
      deletedContactData?.length > 0
    ) {
      const params = new URLSearchParams();
      deletedContactData.forEach((id) => {
        params.append("ContactIds", id);
      });

      // let queryparam = params.toString();

      
      let payload = {
        ClientProducingBroker :  props.clientDetail?.producingBrokerId,
        ContactIds:[...deletedContactData]
      }
      dispatch(deleteMultipleContactData(payload));
    }
  }, [statedata.updatedClientData , deletedContactData , dispatch , props?.clientDetail?.producingBrokerId]);

  const handleProducingCoverageChange = (event) => {
    setError((prevState) => ({
      ...prevState,
      producingbroker: event?.value!==""?"":"Please Select Producing broker",
    }));

    setselectedProducingCoverage(event);

    if(event?.value === selectedgCoverage2?.value) {
      setselectedgCoverage2("")

      
}

const finddatainbrokerdata = brokerdats?.find(
  (item) => item.id === event?.value
);
// {console.log("finddatainbrokerdata" , finddatainbrokerdata)}
if(finddatainbrokerdata) {
setError((prevState) => ({
...prevState,
producingbroker: "Please select different producing broker. The selected broker is already listed as a servicing broker.",
}));

    }
    

    if(props.page === "edit") {
    const filteredArray2 = statedata?.membersadata?.filter(
      (obj2) => !props?.clientMembers?.some((obj1) => obj1.id === obj2.id)
    );
    setMembersData(filteredArray2?.filter((item , key)=> item?.id !== event?.value))
  }
  else {

    setMembersData(statedata.membersadata?.filter((item , key)=> item?.id !== event?.value))

  }
  };

  const handleCoverageChange2 = (event) => {
    setselectedgCoverage2(event);
    
    if(event?.value === selectedProducingCoverage?.value) {
      setselectedProducingCoverage("")
    }
  };

  const handleCountryChange = (selectedOption) => {
    setHQCountry(selectedOption);
    resetFieldError("HQCountry");
  };
  const handleCountry = (selectedOption) => {
    setCountry(selectedOption);
  };

  const handleClientCountry = (selectedOption) => {
    setClientCountry(selectedOption);
    resetFieldError("clientContry");
  };

  //handle to add broker
  const addbroker = () => {
    const finddata = members?.find(
      (item) => item.id === selectedgCoverage2?.value
    );

    const finddatainbrokerdata = brokerdats?.find(
      (item) => item.id === selectedgCoverage2?.value
    );

    setselectedgCoverage2("");
    if (!finddatainbrokerdata) {
      let brokers = [...brokerdats, finddata];
      setbrokerdata(brokers);
      let brokerIds = [...brokerId, selectedgCoverage2?.value];

      setbrokerid(brokerIds);
      setMembersData(members?.filter((item, key) => item.id !== finddata.id));
    }
  };

  //handle remove broker
  const removebroker = (index) => {
    
    const filterdata = brokerdats?.filter((item) => item.id !== index);
    setbrokerdata(filterdata);
    const fileterbrokerid = brokerId?.filter((item) => item !== index);
    
    setbrokerid(fileterbrokerid);

    const brokerdata1 = brokerdats?.filter((item) => item.id === index);
    if (brokerdata1[0]) {
      setMembersData((prevMembers) => [...prevMembers, brokerdata1[0]]);
    }
  };

  

  //handle to remove contact
  const removecontact = (index) => {
    const filterdata = contactdata?.filter((item) => item.email !== index);
    setContactData(filterdata);
  };
  const removecontactDetails = (index) => {
    const filterdata = contactDetailsData?.filter((item) => item.id !== index);
    setContactDetailsData(filterdata);

    if (deletedContactData.includes(index)) {
      return;
    } else {
      deletedContactData.push(index);
    }
  };

  //handle to remove location
  const removelocation = (index , type) => {
    if(type === "id") {
    removelocationDetails(index)
    const filterdata = locationdata?.filter((item, key) => item?.id !== index);
    setlocationData(filterdata);
    
    }
    else {
      const filterdata = locationdata?.filter((item, key) => key !== index);
      setlocationData(filterdata);
    }
  };
  const removelocationDetails = (index) => {
    

    if (deletedlocationdata.includes(index)) {
      return;
    } else {
      deletedlocationdata.push(index);
    }
  };

  // Function to Reset Validation Error Field in Error object of state
  const resetFieldError = (field , name) => {

    if(name) {

setError((prevState) => ({
  ...prevState,
  [field]: validateInput(name) || "" ,
}));
    }
    else {
    setError((prevState) => ({
      ...prevState,
      [field]: "",
    }));
  }
  }

  const handleContactTypeChange = (event) => {
    setSelectedContactType(event);

    setError((prevState) => ({
      ...prevState,
      contactType: "",
    }));
  };
  const handleCompany = (event) => {
    setCompany(event);

    setError((prevState) => ({
      ...prevState,
      company: validateInput(event) || "",
    }));
  };

  
  
  
  
  
  //validate add contact
  const validatecontact = () => {
    
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const phonenumberRegex = /^\+?[1-9]\d{1,14}$/
    const isValid = phonenumberRegex.test(phone)
    const updatedErrors = {
      HQFirstName: HQFirstName === "" ? "Please enter first name":validateInput(HQFirstName) || "",
      clientContry:
        clientContry === null || clientContry === "" || clientContry?.value === ""
          ? "Please select country"
          : "",
      HQEmail:
        HQEmail === null
          ? "Please enter email"
          : emailRegex.test(HQEmail)
          ? ""
          : "Please enter valid email",
          mobilenumber: phone === "" ? "Please enter phone number":!isValid ? "Please enter valid phone Number" : "",
          company: company === "" ? "Please enter company":validateInput(company) || "",
          contactType: (selectedContactType?.value !== "" && selectedContactType?.value) ? "": "Please select contact type",
          
          HQLastName: HQLastName === "" ?  "" : validateInput(HQLastName) || ""  ,
          
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  //add contact
  const addContact = () => {
    let isvalid = validatecontact();
    if (isvalid) {
      
      const emailRegex =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      const isValid = emailRegex.test(HQEmail);

      let user = JSON.parse(localStorage.getItem("user"))
      
      if (isValid) {
        let contactsdata1 = [...contactdata];
        

        let countryname = countries?.filter(
          (item, key) => item.id === clientContry?.value
        );
        
        let ContactType = contactType?.filter((item , key)=> item.id === selectedContactType?.value)

        let clientdata = {
          contactTypeId: selectedContactType?.value?selectedContactType?.value:"",
          ContactType: ContactType?.[0]?.name?ContactType?.[0]?.name:"",
          firstName: HQFirstName ? HQFirstName : "",
          lastName: HQLastName ? HQLastName : "",
          pronounId: null,
          isCompany: true,
          companyName: company?company:"",
          workPhone: phone?phone:"",
          email: HQEmail ? HQEmail : "",
          street1: "",
          city: "",
          state: "",
          zipcode: 0,
          countryId: clientContry?.value ? clientContry?.value : "",
          country: countryname?.[0]?countryname?.[0]:"",
          prefixId: null,
          middleName: "",
          prefixName: "",
          suffixId: null,
          suffixName: "",
          pronounName: "",
          mobilePhone: phone?phone:"",
          fax: "",
          street2: "",
          county: "",
          MemberFimrId: (user?.extension_Role === "MemberAdmin" || user?.extension_Role === "MemberContributor") ? user?.extension_MemberFirmId:null
        };

        // Assume there's some property, like email, to identify the contact
        let contactToUpdate = contactsdata1.find(
          (contact) => contact.email === clientdata.email
        );

        if (contactToUpdate) {
          setEmailexists(true);
          // If the contact is found, update its properties
          contactToUpdate.firstName = clientdata.firstName;
          contactToUpdate.secondName = clientdata.secondName;
          contactToUpdate.country = clientdata.country;
          let contrydata = countries?.find(
            (item, key) => item.id === clientContry
          );
          contactToUpdate.countrydetails = contrydata;
        } else {
          setEmailexists(false);
          // If the contact is not found, add it to the array
          let contrydata = countries?.find(
            (item, key) => item.id === clientContry
          );
          contactsdata1.countrydetails = contrydata;
          contactsdata1.push(clientdata);
          setHQFirstName("");
          setHQLastName("");
          setHQEmail("");
          setClientCountry("");
          setPhone("")
          setCompany("")
          setSelectedContactType("")
        }

        // Update the state with the modified contactsdata1
        setContactData([...contactsdata1]);
      }
    }
  };

  const validateLocationName = (clientLocationName) => {
    let location = locationdata?.filter((item , key)=> item.LocationName?.toLowerCase() === clientLocationName?.toLowerCase())
    setError((prevState) => ({
      ...prevState,
      duplicateLocationName: location?.length>0 ? "Location name already exist": clientLocationName?.toLowerCase() !== locationName?.toLowerCase() ? "" : "Location name already exist in HQ location" ,
    }))
    return location?.length>0 ? false: clientLocationName?.toLowerCase() !== locationName?.toLowerCase() ? true : false
  }
  const validateLocationFields = () => {
    const updatedErrors = {
      clientFirstLine: clientFirstLine == "" ? "Please Enter First Line" : validateInput(clientFirstLine) || "",
      clientSecondLine: clientSecondLine == "" ? "" : validateInput(clientSecondLine) || "",
      clientContry: country == "" ? "Please Select Country" : "",
      clientPostcode: clientPostcode == "" ? "Please Enter Zip/Postcode" : validateInput(clientPostcode) || "",
      clientLocationName:
        clientLocationName == "" ? "Please Select Location Name" : validateInput(clientLocationName) || "",
      clientHeadcount:
        clientHeadcount?.trim()?.length > 0 ? "" : "Please Enter Head count",
      clientLocationNumber:
        clientLocationNumber == "" ? "Please Select Location Number" : validateInput(clientLocationNumber) || "",
      clientCity: clientcity == "" ? "Please Enter City" : validateInput(clientcity) || "",
      clientState: clientstate == "" ? "Please Enter State" : validateInput(clientstate) || "",
      
    }
    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "")
  }

  

  //add location
  const addlocation = () => {
    if (
      clientLocationName !== "" &&
      clientLocationNumber !== "" &&
      clientHeadcount !== null &&
      clientFirstLine !== "" &&
      // clientSecondLine !== "" &&
      clientPostcode !== "" &&
      clientcity !== "" &&
      clientstate !== "" &&
      country !== ""
      
    )  {
      let isValidLocationName = validateLocationName(clientLocationName)
      if(isValidLocationName) {

      
      // let contactsDataCopy = [...contactdata];
      let countryData = countries?.find(
        (item, key) => item.id === country?.value
      );
      let clientData = {
        LocationHierarchy: "",
        LocationName: clientLocationName,
        LocationNumber: clientLocationNumber,
        Headcount: clientHeadcount,
        Street2: clientSecondLine,
        Zipcode: clientPostcode,
        CountryId: country?.value,
        Country: countryData?countryData:"",
        County: countryData?.country,
        Street1: clientFirstLine,
        clientcity,
        clientstate,
        Description: "",
        ActiveDate: "",
        InactiveDate: "",
        Elevation: "",
        Latitude: "",
        Longitude: "",
        ActionPerformedBy: loggedInUserDetails?.sub?loggedInUserDetails?.sub:"",
        
        City: clientcity,
        State: clientstate,
      };

      if(validateLocationFields) {
      // Add the new client data to the array using functional update
      setlocationData((prevData) => [...prevData, clientData]);
      

      
      setClientSecondLine("");
      setClientFirstLine("");
      setCountry("");
      setClientPostcode("");
      setClientLocationName("");
      setClientLocationNumber("");
      setClientcity("");
      setClientHeadcount("");
      setClientstate("");
      }
      // return Object.values(updatedErrors).every((error) => error === "");
    }
    } else {
      validateLocationFields()
    }
  }

  const handleEmailChange = (e) => {
    const enteredEmail = e;
    setHQEmail(enteredEmail);

    
    const emailRegex =
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const isValid = emailRegex.test(enteredEmail);

    setError((prevState) => {
      return {
        ...prevState,
        HQEmail: !isValid ? "Please enter valid email" : "",
      };
    });
  };

  //Modified By: Anjali
  //Modified On: 01-12-2024
  //Modified On: 01-12-2024
  //Reason: show validation when navigating to other tab without entering the values in required fields

  //show validation when navigating to other tab without entering the values in required fields
  const tabWiseValidation = (clickedtab) => {
    if (tab === 1) {
      // {console.log(clickedtab)}
      if (clickedtab === 2 || clickedtab === 3) {
        const updatedErrors = {
          clientName:
            clientName?.trim()?.length > 0 ? validateInput(clientName) || "" : "Please Enter Client Name",
          // HQpostCode:
          //   HQpostCode?.trim()?.length > 0 ? validateInput(HQpostCode) || "" : "",

          HQFirstLine:
            HQFirstLine?.trim()?.length > 0 ? validateInput(HQFirstLine) || "" : "Please Enter Address",

          headcount: headcount  ?  "" : "Please Enter Head count",


          HQCountry: (HQCountry?.value === ""  ? HQCountry?.value !== "" : HQCountry !== "") ? "" : "Please Select Country",
          HQlocationName:
            locationName?.trim() == "" ? "Please Enter Location Name" : validateInput(locationName) || "",
          HQlocationNumber:
            HQlocationNumber?.trim() == "" ? "Please Enter Location Number" :  validateInput(HQlocationNumber) || "",
          HQCity: HQcity?.trim() == "" ? "Please Enter City" : validateInput(HQcity) || "",
          HQState: HQstate?.trim() == "" ? "" : validateInput(HQstate) || "",
          HQFirstName: "",
          HQLastName: "",
          mobilenumber:"",
          company:"",
          contactType:"",
          clientContry:"",
          HQEmail:"",
          
          HQSecondLine: HQSecondLine === "" ? "" : validateInput(clientName) || "",
          HQpostCode: HQpostCode === "" ? "" : validateInput(HQpostCode) || "",

        };

        setError(updatedErrors);
        return Object.values(updatedErrors).every((error) => error === "");

        
      }
    } else if (tab === 2) {
      if (clickedtab === 3) {
        const updatedErrors = {};

        setError(updatedErrors);
        return Object.values(updatedErrors).every((error) => error === "");
      }
      if (clickedtab === 1) {
        setTab(clickedtab);
      }
    } else {
      setTab(clickedtab);
    }
  };


  const handleTab = (tab1) => {
    let isvalid = tabWiseValidation(tab1);
    if (isvalid) {
      setTab(tab1);
    }
  };

  const handlePhoneChange = (event) => {
    setPhone(event);
    
    const phonenumberRegex = /^\+?[1-9]\d{1,14}$/;

    const isValid = phonenumberRegex.test(event);

    

    setError((prevState) => ({
      ...prevState,
      mobilenumber: !isValid ? "Please enter valid phone number" : "",
    }));
  }

  const validClientName = () => {
    const updatedErrors = {
      clientName:
        clientName?.trim()?.length > 0 ? validateInput(clientName) || "" : "Please Enter Client Name",
            
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "")
  }
  
  const handleSubmit = async (type) => {
    

    const HQContryName = countries?.find(
      (item) => item.id === HQCountry?.value
    )?.country;

    const HQCountryObject = countries?.find(
      (item) => item.id === HQCountry?.value
    )
let location =  locationdata?.filter(item => !item.id);


    const payload = {
      isDraft: type === "saveasdraft" ? true : false,
      ClientName: clientName ? clientName : "",
      Street1: HQFirstLine ? HQFirstLine : "",
      Street2: HQSecondLine ? HQSecondLine : "",
      City: HQcity ? HQcity : "",
      State: HQstate ? HQstate : "",
      Zipcode: HQpostCode ? HQpostCode : "",
      County: HQContryName ? HQContryName : "",
      Country: HQCountryObject ? HQCountryObject : "",
      CountryId: HQCountry?.value ? HQCountry?.value : "",
      LocationNumber: HQlocationNumber ? HQlocationNumber : "",
      LocationName: locationName ? locationName : "",
      Headcount: headcount ? headcount : 0,
      ProducingBrokerId: selectedProducingCoverage?.value
        ? selectedProducingCoverage?.value
        : "",
      ActionPerformedBy: loggedInUserDetails?.sub?loggedInUserDetails?.sub:"",
      locations: location,

      servicingBrokers: brokerId,
    };
    if (type === "saveasdraft") {
      let isValidClientName = clientName?.trim() !== "" ? true : false
      if(isValidClientName) {
      if (props.page === "edit") {

        let isValidate = validClientName()
        if(isValidate) {
        let removedLocations = [...deletedlocationdata];
 
        // let removedServicingbrokers = [...deletedServicingbrokerData];

        payload.id = props.clientDetail?.id;
        // payload.RemovedServicingBrokers = removedServicingbrokers;

        payload.RemovedLocations = removedLocations;
        payload.createdOn = props.clientDetail.createdOn
        payload.createdBy = props.clientDetail.createdBy
        dispatch(updateClient(payload));
      } 
    }else {
      const isValid = validClientName()
      if(isValid) {
        dispatch(ClientData(payload));
      }
      }
    }
    else {
      setError((prevState) => {
        return {
          ...prevState,
          clientName: !isValidClientName ? "Please Enter Client Name" : "",
        };
      });
    }
    } else {
      const isValid = validation();
      if (isValid) {
        if (props.page === "edit") {
          let removedLocations = [...deletedlocationdata];

          // let removedServicingbrokers = [...deletedServicingbrokerData];

          payload.id = props.clientDetail?.id;
          // payload.RemovedServicingBrokers = removedServicingbrokers;

          payload.RemovedLocations = removedLocations;

          payload.createdOn = props.clientDetail.createdOn
          payload.createdBy = props.clientDetail.createdBy

          dispatch(updateClient(payload));
        } else {
          dispatch(ClientData(payload));
        }
      }
    }
    // }
  };

  const validation = () => {
    const updatedErrors = {
      clientName:
        clientName?.trim()?.length > 0 ? validateInput(clientName) || "" : "Please Enter Client Name",
      HQpostCode:
        HQpostCode?.trim()?.length > 0 ? validateInput(HQpostCode) || "" : "",
      producingbroker:
        selectedProducingCoverage != "" && selectedProducingCoverage?.value!=="" ? "" : "Please Select Producing broker",
      HQFirstLine:
        HQFirstLine?.trim()?.length > 0 ? "" : "Please Enter First Line",
      headcount: headcount  ? "" : "Please Enter Head count",

      HQCountry: (HQCountry?.value == "" ? HQCountry?.value !== "" : HQCountry !== "") ? "" : "Please Select Country",
      HQSecondLine: HQSecondLine === "" ? "" : validateInput(HQSecondLine) || "",
      clientPostcode: clientPostcode === "" ? "" : validateInput(clientPostcode) || "",

    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  return (
    <Box className="create-new-modal search-modal">
      <TopSection
        tab={tab}
        handleTab={handleTab}
        handleSubmit={handleSubmit}
        handleClose={() => props.handleCloseSecondaryModal()}
        clientDetail={props.clientDetail}
        page={props.page}
      />

      <div className="border"></div>
      <Box className="modal-body" style={{ padding: "10px 300px" }}>
        <Box className="new-client-text">
          {props.page === "edit" ? "Update Client" : "New Client"}
        </Box>
        <Box className="main-heading">Details and contact</Box>

        <NavTabs
          tabs={[
            { name: "1 Details", id: 1 },
            { name: "2 Locations", id: 2 },
            { name: "3 Broker", id: 3 },
          ]}
          activeTab={tab}
          onClick={handleTab}
          xs={12}
          md={3}
          lg={3}
          style={{ color: "red" }}
        />
        <Box className="tab-content" id="pills-tabContent">
          <Tab1
            tab={tab}
            clientName={clientName}
            countries={countries}
            handleCountryChange={handleCountryChange}
            HQCountry={HQCountry}
            errors={errors}
            setHeadcount={setHeadcount}
            headcount={headcount}
            setHQLocationName={setHQLocationName}
            locationName={locationName}
            resetFieldError={resetFieldError}
            setHQLocationNumber={setHQLocationNumber}
            HQlocationNumber={HQlocationNumber}
            setHQFirstLine={setHQFirstLine}
            HQFirstLine={HQFirstLine}
            setHQSecondLine={setHQSecondLine}
            HQSecondLine={HQSecondLine}
            setHQcity={setHQcity}
            HQcity={HQcity}
            setHQstate={setHQstate}
            HQstate={HQstate}
            setHQPostCode={setHQPostCode}
            HQpostCode={HQpostCode}
            setHQFirstName={setHQFirstName}
            setHQLastName={setHQLastName}
            handleClientCountry={handleClientCountry}
            handleEmailChange={handleEmailChange}
            emailexists={emailexists}
            addContact={addContact}
            contactdata={contactdata}
            removecontact={removecontact}
            setClientName={setClientName}
            contactDetailsData={contactDetailsData}
            removecontactDetails={removecontactDetails}
            HQFirstName={HQFirstName}
            HQLastName={HQLastName}
            HQEmail={HQEmail}
            clientContry={clientContry}
            handlePhoneChange={(e)=> handlePhoneChange(e)}
            phone={phone}
            contactType={contactType}
            handleContactTypeChange={(e)=> handleContactTypeChange(e)}
            selectedContactType={selectedContactType}
            handleCompany={handleCompany}
            company={company}

          />
          <Tab2
            tab={tab}
            setClientLocationName={setClientLocationName}
            resetFieldError={resetFieldError}
            clientLocationName={clientLocationName}
            errors={errors}
            setClientHeadcount={setClientHeadcount}
            clientHeadcount={clientHeadcount}
            setClientLocationNumber={setClientLocationNumber}
            clientLocationNumber={clientLocationNumber}
            setClientcity={setClientcity}
            clientcity={clientcity}
            setClientstate={setClientstate}
            clientstate={clientstate}
            setClientFirstLine={setClientFirstLine}
            clientFirstLine={clientFirstLine}
            setClientSecondLine={setClientSecondLine}
            clientSecondLine={clientSecondLine}
            handleCountry={handleCountry}
            country={country}
            setClientPostcode={setClientPostcode}
            countries={countries}
            clientPostcode={clientPostcode}
            addlocation={addlocation}
            locationdata={locationdata}
            removelocation={removelocation}
            locationDetailsData={locationDetailsData}
            removelocationDetails={removelocationDetails}
          />

          <Tab3
            tab={tab}
            producingbrokeroption={producingbrokeroption}
            errors={errors}
            handleProducingCoverageChange={handleProducingCoverageChange}
            selectedProducingCoverage={selectedProducingCoverage}
            members={members}
            selectedgCoverage2={selectedgCoverage2}
            handleCoverageChange2={handleCoverageChange2}
            addbroker={addbroker}
            brokerdats={brokerdats}
            removebroker={removebroker}
            // membersDetailsData={membersDetailsData}
            // removememberBrokerFromDetails={removememberBrokerFromDetails}
            loggedInUserDetails={loggedInUserDetails}
            producingbrokers={producingbrokers}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MyModal;
