import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography,  DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import Dialog from "../../components/Dialog"
import ButtonComponent from "../Button";
const Dropzone = ({ onFileUpload , validateFile }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);

  // const onDrop = useCallback((acceptedFiles) => {
  //   setFileToUpload(acceptedFiles[0]);
  //   setConfirmationOpen(true);
  // }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const isValid = validateFile(file);
      if (isValid) {
        // setConfirmationOpen(true);
        setFileToUpload([file]);
        onFileUpload([file])
      }
    }
  }, [validateFile])
  
  

  

  let all = `image/*,
  
  application/pdf,
 `;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: all,
    multiple: false,
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="span" className="browse-files-text pe-1">
            Click to upload
          </Typography>
          or
        </Typography>
        <Typography variant="span">
          <Typography variant="h3" className="dynamic-message ps-1" sx={{fontSize:"1rem"}}>
            {" "}
            drag and drop{" "}
          </Typography>
        </Typography>
        <Box class="upload-info-text">
          The file name will be the document name (max. 5mb)
        </Box>
      </div>

      
    </div>
  );
};

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default Dropzone;



