import React from "react";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {
  const loading = useSelector((state) => state.loader.loading);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 9999,
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
