import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  IconButton,
  Badge,
  Stack,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";

//Icons
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import Dialog from "components/Dialog";
import SettingsDialog from "./Settings";
import ProfileDialog from "./Profile";

import { setUpdatedUser } from "store/actions/UserAction";
import {
  clearNotification,
  getLatestNotification,
  getNotification,
  markAllAsReadNotification,
} from "store/actions/NotificationAction";

import {
  BootstrapTooltip,
  
  changeDateFormat,
  MODULENAME,
} from "constants";
import { NAVIGATION , MODULES } from "constants";
import { navigateTo } from "utils";

const Header = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [latestTimestamp, setLatestTimestamp] = useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const [latestnotificationCount, setLatestNotificationCount] = useState(null);
  const [notifications, setNotifications] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    updatedUser,
    notificationsList,
    latestnotificationsList,
    clearNotificationList,
    markAllAsReadNotificationList,
  } = useSelector((state) => ({
    updatedUser: state?.user?.updateduser,
    notificationsList: state?.notifications,
    latestnotificationsList: state?.notifications?.latestNotificationList,
    clearNotificationList: state?.notifications?.clearNotificationList,
    markAllAsReadNotificationList:
      state?.notifications?.markAllAsReadNotificationList,
  }) , shallowEqual);

  const notificationRef = useRef(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedInUserDetails(user);

    dispatch(getNotification());
  }, [dispatch]);

  useEffect(() => {
    if (latestTimestamp) {
      const intervalId = setInterval(() => {
        dispatch(getLatestNotification({ timestamp: latestTimestamp }));
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [latestTimestamp , dispatch]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (notificationsList?.notificationsList?.notifications?.length > 0) {
      setNotifications(notificationsList?.notificationsList?.notifications);

      setNotificationCount((prevCount) => {
        const newCount = notificationsList?.notificationsList?.totalUnReadNotifications;
        
        return prevCount == null ? newCount : prevCount + newCount;
      });

      setLatestTimestamp(notificationsList.notificationsList.lastNotificationTimestamp);
      setLatestNotificationCount(0);
    } else {
      setLatestTimestamp(new Date()?.toISOString());
      setNotificationCount(0);
      setLatestNotificationCount(0);
    }
  }, [notificationsList?.notificationsList?.notifications?.length , notificationsList?.notificationsList?.lastNotificationTimestamp , notificationsList?.notificationsList?.notifications , notificationsList?.notificationsList?.totalUnReadNotifications]);

  useEffect(() => {
    if (latestnotificationsList?.data?.notifications?.length > 0) {
      setNotifications((prev) => [
        ...latestnotificationsList?.data?.notifications,
        ...(prev || []),
      ]);

      setLatestTimestamp(
        latestnotificationsList?.data?.lastNotificationTimestamp
      );
      setLatestNotificationCount(
        (prevCount) => {
          const newCount = latestnotificationsList?.data.totalUnReadNotifications;
          
          return prevCount == null ? newCount : prevCount + newCount;
        }
      );
    } 
  }, [latestnotificationsList?.data?.notifications , latestnotificationsList?.data?.lastNotificationTimestamp , latestnotificationsList?.data?.totalUnReadNotifications]);

  useEffect(() => {
    if (
      clearNotificationList ||
      markAllAsReadNotificationList?.statusCode === 200
    ) {
      dispatch(getNotification());
    }
  }, [clearNotificationList, markAllAsReadNotificationList?.statusCode , dispatch]);

  useEffect(() => {
    if (updatedUser) {
      setShowProfileModal(false);
      dispatch(setUpdatedUser(""));
    }
  }, [updatedUser, dispatch]);

  const togglePanel = () => setIsNotificationOpen((prev) => !prev);

  const handleNotificationClick = (notification) => {
    togglePanel();

    dispatch(
      markAllAsReadNotification({
        notificationIds: [notification.notificationRecordId],
      })
    );
  
    const { moduleName, moduleRef, parentModuleRef } = notification;

    const id = moduleName === MODULES.POLICY_RENEWAL ? parentModuleRef : moduleRef;
    const subId = moduleName === MODULES.POLICY_RENEWAL ? moduleRef : "";


    navigateTo(navigate, NAVIGATION[moduleName], {
      from: MODULENAME[moduleName],
      id,
      subId,
      subModuleName: moduleName,
    });


  };
  

  const clearAllNotifications = () => {
    let notificationId = notifications?.map(
      (item) => item?.notificationRecordId
    );
    setNotifications([]);
    dispatch(clearNotification({ notificationIds: notificationId }));
  };
  const markAllAsRead = () => {
    let notificationId = notifications?.filter(
      (item) => item?.isRead === false
    );

    let notificationIds = notificationId?.map(
      (item) => item?.notificationRecordId
    );

    dispatch(markAllAsReadNotification({ notificationIds: notificationIds }));
  };

  const handleClearClick = (id, e) => {
    dispatch(clearNotification({ notificationIds: [id] }));
  };

  const logoImagePath = "/wwwroot/assets/img/logo.png";

  return (
    <AppBar
      id="header"
      className="header fixed-top d-flex flex-row justify-content-between align-items-center violet-header"
      sx={{ justifyContent: "space-between" }}
    >
      {showSettingsModal && (
        <Dialog
          maxWidth="xl"
          open={showSettingsModal}
          onClose={() => setShowSettingsModal(false)}
          body={
            <SettingsDialog handleClose={() => setShowSettingsModal(false)} />
          }
        />
      )}

      {showProfileModal && (
        <Dialog
          maxWidth="xl"
          open={showProfileModal}
          onClose={() => setShowProfileModal(false)}
          body={
            <ProfileDialog handleClose={() => setShowProfileModal(false)} />
          }
        />
      )}

      <Box className="d-flex">
        <NavLink to="/" className="logo d-flex align-items-center">
          <img src={logoImagePath} alt="" />
          <Typography variant="span" className="d-lg-block">
            <img
              src="wwwroot/assets/images/White-horizontal-logo.png"
              className="img-fluid"
              alt="Logo"
            />
          </Typography>
        </NavLink>
      </Box>

      <Box>
        <Box className="header-nav ms-auto">
          <List className="d-flex align-items-center">
            <ListItem className="nav-item justify-content-center">
              <BootstrapTooltip title="Notifications">
                <Box onClick={togglePanel}>
                  <Badge
                    badgeContent={notificationCount + latestnotificationCount}
                    color="error"
                  >
                    <NotificationsIcon />
                  </Badge>
                </Box>
              </BootstrapTooltip>

              {isNotificationOpen && (
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "100%",
                    width: 300,
                    bgcolor: "background.paper",
                    boxShadow: 3,
                    p: 2,
                    mt: 1,
                  }}
                  ref={notificationRef}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "black" }}>Notifications</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        {notifications && notifications.length > 0 && (
                          <>
                            <BootstrapTooltip title="Mark all as read">
                              <IconButton onClick={markAllAsRead} size="small">
                                <BeenhereIcon fontSize="small" />
                              </IconButton>
                            </BootstrapTooltip>
                            <BootstrapTooltip title="Clear all notifications">
                              <IconButton onClick={clearAllNotifications} size="small">
                                <ClearAllIcon fontSize="small" />
                              </IconButton>
                            </BootstrapTooltip>
                          </>
                        )}
                        <BootstrapTooltip title="Close">
                          <IconButton onClick={togglePanel} size="small">
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </BootstrapTooltip>
                      </Stack>
                  </Box>
                  <Box sx={{ maxHeight: 400, overflow: "auto" }}>
                      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                        {(!notifications || notifications.length === 0) ? (
                          <ListItem>
                              <Typography
                                  variant="body1"
                                  sx={{
                                    textAlign: "center",
                                    py: 2,
                                    color: "grey.900"
                                    
                                  }}
                                >
                                  No notifications
                                </Typography>
                          </ListItem>
                        ) : (
                          notifications.map((notification) => (
                            <ListItem
                              key={notification.notificationRecordId}
                              sx={{
                                py: 1,
                                px: 2,
                                borderBottom: "1px solid",
                                borderColor: "divider",
                                bgcolor: notification.isNew
                                  ? "rgba(255, 0, 0, 0.05)"
                                  : "inherit",
                                cursor: "pointer",
                                position: "relative",
                              }}
                              onClick={() => handleNotificationClick(notification)}
                            >
                              <ListItemText
                                primary={

                                  <Box sx={{display:"flex"}}>
                                  <Box sx={{width:"19px"}}>
                                    {!notification.isRead && (
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: 12,
                                          color: "error.main",
                                          mr: 1,
                                        }}
                                      />
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      mb: 0.5,
                                    }}
                                  >
                                    
                                    <Typography
                                      variant="span"
                                      color="text.secondary"
                                      sx={{ textAlign: "left", color: "#000" }}
                                    >
                                      {notification.moduleName}
                                    </Typography>
                                  </Box>
                                  </Box>
                                }
                                secondary={
                                  <Box sx={{display:"flex" , flexDirection:"column" , marginLeft:"10%"}}>
                                    <Typography
                                      variant="span"
                                      sx={{
                                        mb: 0.5,
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color:"grey.600"
                                      }}
                                    >
                                      {notification.message}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      sx={{ textAlign: "left", color: "grey.600" }}
                                    >
                                      {changeDateFormat(notification.timestamp)}
                                    </Typography>
                                  </Box>
                                }
                                sx={{
                                  textAlign: "left",
                                  "& .MuiTypography-root": { textAlign: "left" },
                                }}
                              />

                              <Tooltip
                                title="Clear notification"
                                arrow
                                placement="left"
                              >
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearClick(notification.notificationRecordId);
                                  }}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: "text.secondary",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                      color: "error.main",
                                    },
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: 16 }} />
                                </IconButton>
                              </Tooltip>
                            </ListItem>
                          ))
                        )}
                      </List>
                    </Box>
                </Box>
              )}
            </ListItem>

            <ListItem className="nav-item justify-content-center">
              <BootstrapTooltip
                title="Profile"
                onClick={() => setShowProfileModal(true)}
              >
                {loggedInUserDetails?.given_name ||
                loggedInUserDetails?.family_name ? (
                  <Box style={headersvg}>
                    {" "}
                    <Box className="initials">
                      {loggedInUserDetails?.given_name?.split("")[0]}
                      {loggedInUserDetails?.family_name?.split("")[0]}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    style={{ position: "relative", left: "3px", bottom: "6px" }}
                  >
                    <Box className="initials">
                      <Avatar
                        sx={{ backgroundColor: "transparent !important" }}
                      />
                    </Box>
                  </Box>
                )}
              </BootstrapTooltip>
            </ListItem>
          </List>
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
const headersvg = {
  position: "relative",
  bottom: "2px",
  cursor: "pointer",
};
