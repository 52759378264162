import React from "react";

import Tabs from "components/Tabs";
const FilterSection = ({
  handleView,
  viewType,
  viewby,
  handleFilter,
  filterTab,
  filterby,
  showViewBy,
}) => {
  const handleTab = (tab) => {
    handleView(tab);
  };
  return (
    <Tabs
      viewby={viewby}
      filterby={filterby}
      handleTab={handleTab}
      handleFilter={handleFilter}
      filterTab={filterTab}
      viewType={viewType}
      showViewBy={showViewBy}
    />
  );
};
export default FilterSection;
