import React from "react";
import Header from "./Header";
import SideBar from "./SideBar";

const AuthLayout = ({ children }) => (
  <>
    <Header />
    <SideBar />
    {children}
  </>
);

export default AuthLayout;
