import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "../../../components/Button";
import NavigationButton from "../../../components/NavigationButton";
import CloseButton from "../../../components/CloseButton";

const TopSection = ({ tab, handleSaveNewPolicy, handleCloseNewPolicyModal, handleTab, renewPolicy, page }) => {
  const handleSave = (action) => {
    handleSaveNewPolicy(action);
  };

  const handleClose = () => {
    handleCloseNewPolicyModal();
  };

  return (
    <Box
      className="modal-header"
      sx={{ backgroundColor: "#FFF", padding: "10px 300px" }}
    >
      {tab === 5 && (
        <Typography variant="span">
          <CloseButton onClick={handleClose} />
          <ButtonComponent
            className="btn btn-default add-policy btn-md mr-8"
            style={{ backgroundColor: "grey", color: "#FFF" }}
            variant={"contained"}
            color={"grey"}
            onClick={() => handleSave("save")}
            sx={{marginLeft:"10px"}}
          >
            Save
          </ButtonComponent>
          {!renewPolicy &&
          <ButtonComponent
            className="btn btn-default add-policy btn-md"
            onClick={() => handleSave("saveasdraft")}
            variant={"contained"}
            color={"grey"}
            sx={{marginLeft:"10px"}}
            
          >
            Save as Draft
          </ButtonComponent>
          }
        </Typography>
      )}
      {(tab === 2 || tab === 3 || tab === 4) && (
        <>
          <span className="d-flex align-items-center">
            <CloseButton onClick={handleClose} />
            {page === "edit" && 
              <ButtonComponent
                className="btn btn-default add-policy btn-md mr-8"
                style={{ backgroundColor: "grey", color: "#FFF" }}
                variant={"contained"}
                color={"grey"}
                onClick={() => handleSave("save")}
                sx={{marginLeft:"10px"}}
              >
                Save
              </ButtonComponent>
            }
            {!renewPolicy &&
              <ButtonComponent
                className="btn btn-default add-policy btn-md"
                onClick={() => handleSave("saveasdraft")}
                variant={"contained"}
                color={"grey"}
                sx={{ marginLeft: "10px" }}
              >
                Save as Draft
              </ButtonComponent>
            }
          </span>
          <span style={{ display: "flex" }}>
            <NavigationButton
              arrowDirection={"left"}
              handleTab={() => handleTab(tab - 1)}
            />
            <NavigationButton
              arrowDirection={"right"}
              handleTab={() => handleTab(tab + 1)}
              sx={{ marginLeft: "10px" }}
            />
          </span>
        </>
      )}
      {tab === 1 && (
        <>
          <Typography variant="span">
            <CloseButton onClick={handleClose} />
            {page === "edit" && 
              <ButtonComponent
                className="btn btn-default add-policy btn-md mr-8"
                style={{ backgroundColor: "grey", color: "#FFF" }}
                variant={"contained"}
                color={"grey"}
                onClick={() => handleSave("save")}
                sx={{marginLeft:"10px"}}
              >
                Save
              </ButtonComponent>
            }
            {!renewPolicy &&
              <ButtonComponent
                className="btn btn-default add-policy btn-md"
                onClick={() => handleSave("saveasdraft")}
                variant={"contained"}
                color={"grey"}
                sx={{ marginLeft: "10px" }}
              >
                Save as Draft
              </ButtonComponent>
            }
          </Typography>
          <NavigationButton
            arrowDirection={"right"}
            handleTab={() => handleTab(tab + 1)}
          />
        </>
      )}
    </Box>
  );
};

export default TopSection;