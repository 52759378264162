import React from "react";
import { Box, Typography } from "@mui/material";

const PageTitle = ({ title, sx }) => {
  return (
    <Box className="pagetitle mb-0" sx={sx}>
      <Typography variant="h1" className="mb-0">
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
