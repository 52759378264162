import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";

const Dropzone = ({ onFileUpload, validateFile }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const isValid = validateFile(file);
        if (isValid) {
          onFileUpload([file]);
        }
      }
    },
    [validateFile]
  );

  
  const acceptedFileTypes = [
    "image/*",
    "application/pdf",
  ];

  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: acceptedFileTypes,
    multiple: false,
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="span" className="browse-files-text pe-1">
            Click to upload
          </Typography>
          or
        </Typography>
        <Typography variant="span">
          <Typography
            variant="h3"
            className="dynamic-message ps-1"
            sx={{ fontSize: "1rem" }}
          >
            {" "}
            drag and drop{" "}
          </Typography>
        </Typography>
        <Box className="upload-info-text">
          The file name will be the document name (max. 5mb)
        </Box>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default Dropzone;
