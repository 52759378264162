import { Box, Typography, Grid, Button, Avatar } from "@mui/material"

const SettingsDialog = ({ handleClose }) => {
    return (
        <Box className="outer-header-modal">
            <Box className="settings-header"
                style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid var(--grey-200)",
                    paddingBottom: '10px'
                    //padding: "12px 20px",
                }}
            >
                <button
                    
                    className="btn-close"
                    // onClick={() => closeNav1()}
                    onClick={() => handleClose()}
                    style={{ marginRight: "5px", padding: "9px" }}
                ></button>
            </Box>

            <Box className="settings-header">
                <Typography className="main-text">Settings</Typography>
                <Typography className="help-support">Help and Support</Typography>
                <Box>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Contact us</Typography>
                            <Typography className="head-subtext">If you need any type of assistance with the platform please don’t hesitate to get in contact with us.</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Button className="contact-button">Contact us</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-subtext">You can also follow us on LinkedIn to be notified of any new or updates we may have.</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Button className="follow-button">Follow us on LinkedIn</Button>
                        </Grid>
                    </Grid>
                </Box>
                <hr />
                <Typography className="help-support mt-5">Member firm</Typography>
                <Box>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Member firm avatar</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Avatar className="ms-0"></Avatar>
                        </Grid>
                    </Grid>
                </Box>
                <hr />
                <Box>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Member firm name</Typography>
                            <Typography className="head-subtext">This would be your company name as the producing broker</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <input type="text" className="form-control"></input>
                        </Grid>
                    </Grid>
                </Box>

                <hr />
                <Box>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Member firm HQ location</Typography>
                            <Typography className="head-subtext">Location of your company</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <input type="text" className="form-control"></input>
                        </Grid>
                    </Grid>
                </Box>
                <hr />
                <Box>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Members</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Grid container spacing={2}>
                                {/* loop this grid */}
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box className="members-box">
                                        <Grid container spacing={0}>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <Box className="initials">AB</Box>
                                            </Grid>
                                            <Grid item xs={7} md={7} lg={7}>
                                                <Typography className="head-text">Name Surname You</Typography>
                                                <Typography className="head-subtext">email@gmail.com</Typography>
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <Typography className="member-title">Admin</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box className="members-box">
                                        <Grid container spacing={0}>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <Box className="initials">AB</Box>
                                            </Grid>
                                            <Grid item xs={7} md={7} lg={7}>
                                                <Typography className="head-text">Name Surname You</Typography>
                                                <Typography className="head-subtext">email@gmail.com</Typography>
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <Typography className="member-title">Admin</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box className="members-box">
                                        <Grid container spacing={0}>
                                            <Grid item xs={2} md={2} lg={2}>
                                                <Box className="initials">AB</Box>
                                            </Grid>
                                            <Grid item xs={7} md={7} lg={7}>
                                                <Typography className="head-text">Name Surname You</Typography>
                                                <Typography className="head-subtext">email@gmail.com</Typography>
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3}>
                                                <Typography className="member-title">Admin</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* loop this grid till here */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Permissoin request</Typography>
                            <Typography className="head-subtext">Contact us for access change.</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Button className="contact-button">Contact us</Button>
                        </Grid>
                    </Grid>
                </Box>
                <hr />
                <Box>
                    <Typography className="help-support">Member invite</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-subtext">Invite requests will be sent to us first, we will run checks, then forward on the invite. This can take upto 2 working days.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">First name</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <input type="text" className="form-control"></input>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Last name</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <input type="text" className="form-control"></input>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Email address</Typography>
                            <Typography className="head-subtext">Invite will be sent to this email</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <input type="text" className="form-control"></input>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Button className="contact-button">Send invite</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
export default SettingsDialog;