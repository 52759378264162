export const MEMBER_FIRM_CONST = {
    GET_MEMBER_FIRM_REQUEST:"GET_MEMBER_FIRM_REQUEST",
    GET_MEMBER_FIRM_SUCCESS:"GET_MEMBER_FIRM_SUCCESS",
    GET_MEMBER_FIRM_FAILURE:"GET_MEMBER_FIRM_FAILURE",

    GET_MEMEBR_FIRM_BY_ID_REQUEST:"GET_MEMEBR_FIRM_BY_ID_REQUEST",
    GET_MEMBER_FIRM_BY_ID_SUCCESS:"GET_MEMBER_FIRM_BY_ID_SUCCESS",
    GET_MEMBER_FIRM_BY_ID_FAILURE:"GET_MEMBER_FIRM_BY_ID_FAILURE",

    CREATE_MEMBER_FIRM_REQUEST:"CREATE_MEMBER_FIRM_REQUEST",
    CREATE_MEMBER_FIRM_SUCCESS:"CREATE_MEMBER_FIRM_SUCCESS",
    CREATE_MEMBER_FIRM_FAILURE:"CREATE_MEMBER_FIRM_FAILURE",

    UPDATE_MEMBER_FIRM_REQUEST:"UPDATE_MEMBER_FIRM_REQUEST",
    UPDATE_MEMBER_FIRM_SUCCESS:"UPDATE_MEMBER_FIRM_SUCCESS",
    UPDATE_MEMBER_FIRM_FAILURE:"UPDATE_MEMBER_FIRM_FAILURE",

    DELETE_MEMBER_FIRM_REQUEST:"DELETE_MEMBER_FIRM_REQUEST",
    DELETE_MEMBER_FIRM_SUCCESS:"DELETE_MEMBER_FIRM_SUCCESS",
    DELETE_MEMBER_FIRM_FAILURE:"DELETE_MEMBER_FIRM_FAILURE",

    GET_USERS_BY_MEMBER_FIRM_ID_REQUEST:"GET_USERS_BY_MEMBER_FIRM_ID_REQUEST",
    GET_USERS_BY_MEMBER_FIRM_ID_SUCCESS:"GET_USERS_BY_MEMBER_FIRM_ID_SUCCESS",
    GET_USERS_BY_MEMBER_FIRM_ID_FAILURE:"GET_USERS_BY_MEMBER_FIRM_ID_FAILURE",
    
    GET_MEMBER_FIRM_BY_NAME_REQUEST:"GET_MEMBER_FIRM_BY_NAME_REQUEST",
    GET_MEMBER_FIRM_BY_NAME_SUCCESS:"GET_MEMBER_FIRM_BY_NAME_SUCCESS",
    GET_MEMBER_FIRM_BY_NAME_FAILURE:"GET_MEMBER_FIRM_BY_NAME_FAILURE",

    GET_MEMBER_FIRM_BY_CLIENTS_REQUEST:"GET_MEMBER_FIRM_BY_CLIENTS_REQUEST",
    GET_MEMBER_FIRM_BY_CLIENTS_SUCCESS:"GET_MEMBER_FIRM_BY_CLIENTS_SUCCESS",
    GET_MEMBER_FIRM_BY_CLIENTS_FAILURE:"GET_MEMBER_FIRM_BY_CLIENTS_FAILURE",
    
    GET_SORTED_MEMBER_FIRM_REQUEST:"GET_SORTED_MEMBER_FIRM_REQUEST",
    GET_SORTED_MEMBER_FIRM_SUCCESS:"GET_SORTED_MEMBER_FIRM_SUCCESS",
    GET_SORTED_MEMBER_FIRM_FAILURE:"GET_SORTED_MEMBER_FIRM_FAILURE",
    
    GET_SEARCHED_MEMBER_FIRM_REQUEST:"GET_SEARCHED_MEMBER_FIRM_REQUEST",
    GET_SEARCHED_MEMBER_FIRM_SUCCESS:"GET_SEARCHED_MEMBER_FIRM_SUCCESS",
    GET_SEARCHED_MEMBER_FIRM_FAILURE:"GET_SEARCHED_MEMBER_FIRM_FAILURE",

}