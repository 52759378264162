import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import ReactCountryFlag from "react-country-flag";

import { Box, Grid, Typography } from "@mui/material";

import ConfirmationModal from "components/ConfirmationModal";
import GridContainer from "../../components/GridContainer";

import Switch from "../../components/Switch";
import ButtonComponent from "../../components/Button";
import CloseButton from "../../components/CloseButton";

import { countryIdToCodeMap } from "../../constants/Countrycodes";

import { deleteContactData } from "../../store/actions/ContactsAction";
import "assets/css/Custom.css";

const ContactDetails = ({ closeSidePanel, handleEdit, contactDetails , clientDetail }) => {
  const [contactDetailsData, setContactDetailsData] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (contactDetails) {
      setContactDetailsData(contactDetails);
    }
  }, [contactDetails]);

  const handledelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    
    let ClientProducingBroker =  clientDetail?.producingBrokerId
    dispatch(deleteContactData(contactDetails?.id , ClientProducingBroker));
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Contact"}
          subTitle={"delete this contact"}
          buttonname="Delete"
        />
      )}
      <Box style={{ padding: "30px" }}>
        <Box>
          <Box
            className="sidepanel-header add-contact"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="span">
              <CloseButton onClick={() => closeSidePanel()} />

              {clientDetail?.isDraft !== true ?
                <ButtonComponent
                  variant={"contained"}
                  color={"grey"}
                  onClick={() => handleEdit()}
                  sx={{ marginLeft: "10px" }}
                >
                  Edit
                </ButtonComponent>
              :""}
            </Typography>
          </Box>
          <Box className="sidepanel-body add-contact view-contact">
            <GridContainer className="row pt-3 contact-details">
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <Box className="form-label">Name</Box>
                <Box className="view-contact-details">
                  {contactDetailsData?.firstName}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className=" mb-3">
                <Box className="form-label">Prefix</Box>
                <Box className="view-contact-details">
                  {contactDetailsData?.prefixName}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className=" mb-3">
                <Box className="form-label">Pronoun</Box>
                <Box className="view-contact-details">
                  {contactDetailsData?.pronounName}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <Box className="form-label">Country</Box>
                <Box className="view-contact-details">
                  <ReactCountryFlag
                    countryCode={
                      countryIdToCodeMap[contactDetailsData?.countryId]
                    }
                    svg
                    style={{ width: "30px", marginRight: "5px" }}
                  />
                  {contactDetailsData?.country}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <Box className="form-label">Email</Box>
                <Box className="view-contact-details">
                  {contactDetailsData?.email}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <Box className="form-label">Phone</Box>
                <Box className="view-contact-details">
                  {contactDetailsData?.mobilePhone}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <Box className="form-label">Address</Box>
                <Box className="view-contact-details">
                  {contactDetails?.street1}
                </Box>
                <Box className="view-contact-details">
                  {contactDetails?.street2}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Switch
                  id="checkboxinp"
                  type="checkbox"
                  checked={contactDetails?.isActive || false}
                  disabled={true}
                />
              </Grid>
            </GridContainer>

            <GridContainer className="row pb-3 w-100">
              <hr />
              <Box
                className="delete-text text-center"
                onClick={() => handledelete()}
              >
                Delete
              </Box>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ContactDetails;
