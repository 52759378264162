export const countryIdToCodeMap = {
    1: "AF",
    2: "AW",
    3: "AL",
    4: "DZ",
    5: "AD",
    6: "AM",
    7: "AO",
    8: "AI",
    9: "AQ",
    10: "AR",
    11: "AG",
    12: "AS",
    13: "AX",
    14: "AU",
    15: "AT",
    16: "AZ",
    17: "BS",
    18: "BH",
    19: "BD",
    20: "BB",
    21: "BY",
    22: "BE",
    23: "BZ",
    24: "BJ",
    25: "BM",
    26: "BT",
    27: "BO",
    28: "BQ",
    29: "BA",
    30: "BW",
    31: "BV",
    32: "BR",
    33: "IO",
    34: "BN",
    35: "BG",
    36: "BF",
    37: "BI",
    38: "KH",
    39: "CM",
    40: "CA",
    41: "CV",
    42: "KY",
    43: "CF",
    44: "TD",
    45: "CL",
    46: "CN",
    47: "CX",
    48: "CC",
    49: "CO",
    50: "KM",
    51: "CG",
    52: "CD",
    53: "CK",
    54: "CR",
    55: "CI",
    56: "HR",
    57: "CU",
    58: "CW",
    59: "CY",
    60: "CZ",
    61: "DK",
    62: "DJ",
    63: "DM",
    64: "DO",
    65: "EC",
    66: "EG",
    67: "SV",
    68: "GQ",
    69: "ER",
    70: "EE",
    71: "ET",
    72: "FK",
    73: "FO",
    74: "FJ",
    75: "FI",
    76: "FR",
    77: "GF",
    78: "PF",
    79: "TF",
    80: "GA",
    81: "GM",
    82: "GE",
    83: "DE",
    84: "GH",
    85: "GI",
    86: "GR",
    87: "GL",
    88: "GD",
    89: "GP",
    90: "GU",
    91: "GT",
    92: "GG",
    93: "GN",
    94: "GW",
    95: "GY",
    96: "HT",
    97: "HM",
    98: "VA",
    99: "HN",
    100: "HK",
    101: "HU",
    102: "IS",
    103: "IN",
    104: "ID",
    105: "IR",
    106: "IQ",
    107: "IE",
    108: "IM",
    109: "IL",
    110: "IT",
    111: "JM",
    112: "JP",
    113: "JE",
    114: "JO",
    115: "KZ",
    116: "KE",
    117: "KI",
    118: "KP",
    119: "KR",
    120: "QZ",
    121: "KW",
    122: "KG",
    123: "LA",
    124: "LV",
    125: "LB",
    126: "LS",
    127: "LR",
    128: "LY",
    129: "LI",
    130: "LT",
    131: "LU",
    132: "MO",
    133: "MK",
    134: "MG",
    135: "MW",
    136: "MY",
    137: "MV",
    138: "ML",
    139: "MT",
    140: "MH",
    141: "MQ",
    142: "MR",
    143: "MU",
    144: "YT",
    145: "MX",
    146: "FM",
    147: "MD",
    148: "MC",
    149: "MN",
    150: "ME",
    151: "MS",
    152: "MA",
    153: "MZ",
    154: "MM",
    155: "NA",
    156: "NR",
    157: "NP",
    158: "NL",
    159: "AN",
    160: "NC",
    161: "NZ",
    162: "NI",
    163: "NE",
    164: "NG",
    165: "NU",
    166: "NF",
    167: "MP",
    168: "NO",
    169: "OM",
    170: "PK",
    171: "PW",
    172: "PS",
    173: "PA",
    174: "PG",
    175: "PY",
    176: "PE",
    177: "PH",
    178: "PN",
    179: "PL",
    180: "PT",
    181: "PR",
    182: "QA",
    183: "SS",
    184: "RE",
    185: "RO",
    186: "RU",
    187: "RW",
    188: "GS",
    189: "BL",
    190: "SH",
    191: "KN",
    192: "LC",
    193: "MF",
    194: "PM",
    195: "WS",
    196: "SM",
    197: "ST",
    198: "SA",
    199: "SN",
    200: "RS",
    201: "SC",
    202: "SL",
    203: "SG",
    204: "SX",
    205: "SK",
    206: "SI",
    207: "SB",
    208: "SO",
    209: "ZA",
    210: "ES",
    211: "LK",
    212: "VC",
    213: "SD",
    214: "YY",
    215: "SR",
    216: "SJ",
    217: "SZ",
    218: "SE",
    219: "CH",
    220: "SY",
    221: "TW",
    222: "TJ",
    223: "TZ",
    224: "TH",
    225: "TP",
    226: "TG",
    227: "TK",
    228: "TO",
    229: "TT",
    230: "TN",
    231: "TM",
    232: "TC",
    233: "TR",
    234: "TV",
    235: "UM",
    236: "UG",
    237: "UA",
    238: "AE",
    239: "GB",
    240: "US",
    241: "UY",
    242: "UZ",
    243: "VU",
    244: "VE",
    245: "VN",
    246: "VG",
    247: "VI",
    248: "WF",
    249: "EH",
    250: "YE",
    251: "YE",
    252: "ZM",
    253: "ZW"
}