import React, { useEffect } from "react";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Paths } from "../../constants";
import Welcome from "../../pages/authentication/Welcome";
import ResetPassword from "../../pages/authentication/ResetPassword";

const PageLayout = ({ children }) => {
  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Router>
          <Routes>
            <Route path={Paths.WELCOME} element={<Welcome />} />
            <Route path={Paths.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path="*" element={<Navigate to={Paths.WELCOME} />} />
          </Routes>
        </Router>
      </UnauthenticatedTemplate>
    </>
    
  );
};
export default PageLayout;
