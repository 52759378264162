import React, { useState, useCallback } from "react";

const Tab = ({ id, label, isActive, onClick }) => {
  return (
    <li className="nav-item nav-tab" role="presentation">
      <button
        className={`tab-button nav-link ${isActive ? "active" : ""}`}
        onClick={() => onClick(id)}
      >
        {label}
      </button>
    </li>
  );
};

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.id); // Set the first tab as active

  const handleTabChange = useCallback((tabId) => {
    setActiveTab(tabId);
  }, []);

  return (
    <div>
      <ul className="nav nav-pills mb-3 tabs" id="pills-tab" role="tablist">
        {React.Children.map(children, (child) => {
          return (
            <Tab
              id={child.props.id}
              label={child.props.label}
              isActive={activeTab === child.props.id}
              onClick={handleTabChange}
            />
          );
        })}
      </ul>
      <div className="tab-content">
        {React.Children.map(children, (child) =>
          activeTab === child.props.id ? (
            <TabPanel id={child.props.id} isActive>
              {child.props.children}
            </TabPanel>
          ) : null
        )}
      </div>
    </div>
  );
};

const TabPanel = ({ id, children }) => {
  return (
    <div id={`pills-${id}`} role="tabpanel" aria-labelledby={`pills-${id}-tab`}>
      {children}
    </div>
  );
};

export { Tabs, TabPanel };
