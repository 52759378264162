import * as React from 'react';

import Card from '@mui/material/Card';




export default function BasicCard({className , children}) {
  return (
    <Card className={`card ${className}`}>
      {children}
    </Card>
  );
}
